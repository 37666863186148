import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {deleteConnecProduct, getStripeProviderProducts, toggleFreeProduct} from "../../../services/stripe.service";
import ItemProduct from "./ItemProduct";
import CreateProductButton from "./CreateProductButton";
import {toast} from "react-toastify";
import {handleLicenseStatus} from "../../../services/license.service";
import {AuthContext} from "../../../utilities/auth/AuthContext";
import ConfirmModal from "../../../views/Modal/ConfirmModal";
import {authTypes} from "../../../utilities/types/types";

const ProductDetail = (props) => {

    const {t} = useTranslation(["profile", "common"]);
    const [loading, setLoading] = useState(true);

    const [products, setProducts] = useState([]);
    const {user, dispatch} = useContext(AuthContext);

    useEffect(() => {
        handleProducts().then();
    }, []);

    const handleReload = async () => {
        await handleLicenseStatus(user, dispatch);
        await handleProducts();
    }

    const handleProducts = async () => {
        setLoading(true);
        try {
            const productsResult = await getStripeProviderProducts(user.user.idProvider);
            if (productsResult != null) {
                setProducts(productsResult);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            toast.error(t("common:commercial-account.error-get-products"));
        }

    }

    const handleConfirmDeleteButton = (product) => {
        props.modal.current.setModal(<ConfirmModal
                modal={props.modal}
                title={t("common:commercial-account.confirm-delete-product-title")}
                message={t("common:commercial-account.confirm-delete-product-message", {productName: product.productName})}
                acceptLabel={t("common:commercial-account.confirm-delete-account-button-label")}
                acceptColor="primary"
                onAccept={() => handleProductDelete(product.productId)}
            />
        );
    }

    const handleProductDelete = async (productId) => {
        try {
            await deleteConnecProduct(productId)
            await handleReload();
            toast.success(t("common:commercial-account.success-delete-product"));
        } catch (error) {
            toast.error(t("common:commercial-account.error-delete-product"));
            throw error;
        }
    }

    return (
        <>
            <div className="products-detail ">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between title-wrapper pr-4">
                            <h5>{t("Stripe.stripe_product_tittle")}</h5>
                            <div>
                                <CreateProductButton {...props} user={user.user} reload={handleReload}/>

                                <button className="btn btn-outline-primary btn-sm ml-2"
                                        onClick={async () => {
                                            try {
                                                let data = await toggleFreeProduct({idCompany: user.user.idCompany.idCompany});
                                                user.user.idCompany.productsFree = data.company.productsFree;
                                                dispatch({
                                                    type: authTypes.profile,
                                                    payload: {
                                                        user: user.user,
                                                        token: user.token,
                                                        company: user.company,
                                                        license: user.license
                                                    }
                                                });
                                            } catch (error) {
                                                console.error(error);
                                            }
                                        }}>
                                    {user.user.idCompany.productsFree ? (<>{t("common:organization.disable-free-product")}</>) : (<>{t("common:organization.enable-free-product")}</>)}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {loading ?
                            <div className="d-flex justify-content-center">
                                <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                            </div>
                            :
                            <div className="row">
                                {products.length > 0 ?
                                    products.map((product) => (
                                        <div key={product.productId}
                                             className="col-12 col-sm-12 col-lg-12 col-xl-6 mt-4">
                                            <ItemProduct product={product}
                                                         onDelete={() => handleConfirmDeleteButton(product)}/>
                                        </div>
                                    )) :
                                    <div className="col-12 mt-4">
                                        <div className="alert alert-warning d-flex justify-content-start" role="alert">
                                            <FontAwesomeIcon icon={faExclamationTriangle}
                                                             className="mr-4 align-self-center"/>
                                            <div className=" align-self-center">
                                                <p className="m-0 p-0">
                                                    <strong>{t("profile.onboarding-products")}</strong></p>
                                                <small
                                                    className="mr-2 align-self-center">{t("profile.onboarding-products-add-message")}</small>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetail;
