export const EHIcon = (props) => {
    return getIcon(props.name, props.className, props.width, props.height)
}

const getIcon = (name, className, width, height) => {
    switch (name) {
        case "icon appointments":
            return (
                <svg width={width} height={height} viewBox="0 0 40 40" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: '2'
                }}>
                    <path className={`svgStyle ${className}`}
                          d="M31.667,6.333L30,6.333L30,3L26.667,3L26.667,6.333L13.333,6.333L13.333,3L10,3L10,6.333L8.333,6.333C6.483,6.333 5.017,7.833 5.017,9.667L5,33C5,34.833 6.483,36.333 8.333,36.333L31.667,36.333C33.5,36.333 35,34.833 35,33L35,9.667C35,7.833 33.5,6.333 31.667,6.333ZM31.667,33L8.333,33L8.333,14.667L31.667,14.667L31.667,33ZM11.667,18L20,18L20,26.333L11.667,26.333L11.667,18Z"
                    />
                </svg>
            );
        case "icon reports":
            return (
                <svg width={width} height={height} viewBox="0 0 40 40" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: '2'
                }}>
                    <path className={`svgStyle ${className}`}
                          d="M35.333,14.333C32.917,14.333 31.567,16.733 32.117,18.517L26.2,24.45C25.7,24.3 24.967,24.3 24.467,24.45L20.217,20.2C20.783,18.417 19.433,16 17,16C14.583,16 13.217,18.4 13.783,20.2L6.183,27.783C4.4,27.233 2,28.583 2,31C2,32.833 3.5,34.333 5.333,34.333C7.75,34.333 9.1,31.933 8.55,30.15L16.133,22.55C16.633,22.7 17.367,22.7 17.867,22.55L22.117,26.8C21.55,28.583 22.9,31 25.333,31C27.75,31 29.117,28.6 28.55,26.8L34.483,20.883C36.267,21.433 38.667,20.083 38.667,17.667C38.667,15.833 37.167,14.333 35.333,14.333ZM6.167,19.333L7,16L10.333,15.167L7,14.333L6.167,11L5.333,14.333L2,15.167L5.333,16L6.167,19.333ZM25.333,16L26.9,12.55L30.333,11L26.9,9.45L25.333,6L23.8,9.45L20.333,11L23.8,12.55L25.333,16Z"
                    />
                </svg>
            );
        case "icon my-team":
            return (
                <svg width={width} height={height} viewBox="0 0 40 40" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: '2'
                }}>
                    <path className={`svgStyle ${className}`}
                          d="M20,21.25C22.717,21.25 25.117,21.9 27.067,22.75C28.867,23.55 30,25.35 30,27.3L30,30L10,30L10,27.317C10,25.35 11.133,23.55 12.933,22.767C14.883,21.9 17.283,21.25 20,21.25ZM6.667,21.667C8.5,21.667 10,20.167 10,18.333C10,16.5 8.5,15 6.667,15C4.833,15 3.333,16.5 3.333,18.333C3.333,20.167 4.833,21.667 6.667,21.667ZM8.55,23.5C7.933,23.4 7.317,23.333 6.667,23.333C5.017,23.333 3.45,23.683 2.033,24.3C0.8,24.833 0,26.033 0,27.383L0,30L7.5,30L7.5,27.317C7.5,25.933 7.883,24.633 8.55,23.5ZM33.333,21.667C35.167,21.667 36.667,20.167 36.667,18.333C36.667,16.5 35.167,15 33.333,15C31.5,15 30,16.5 30,18.333C30,20.167 31.5,21.667 33.333,21.667ZM40,27.383C40,26.033 39.2,24.833 37.967,24.3C36.55,23.683 34.983,23.333 33.333,23.333C32.683,23.333 32.067,23.4 31.45,23.5C32.117,24.633 32.5,25.933 32.5,27.317L32.5,30L40,30L40,27.383ZM20,10C22.767,10 25,12.233 25,15C25,17.767 22.767,20 20,20C17.233,20 15,17.767 15,15C15,12.233 17.233,10 20,10Z"
                    />
                </svg>
            );

        case "icon profile":
            return (
                <svg width={width} height={height} viewBox="0 0 40 40" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: '2'
                }}>
                    <path className={`svgStyle ${className}`}
                          d="M30.333,8.667L22,8.667L22,3.667C22,1.833 20.5,0.333 18.667,0.333L15.333,0.333C13.5,0.333 12,1.833 12,3.667L12,8.667L3.667,8.667C1.833,8.667 0.333,10.167 0.333,12L0.333,30.333C0.333,32.167 1.833,33.667 3.667,33.667L30.333,33.667C32.167,33.667 33.667,32.167 33.667,30.333L33.667,12C33.667,10.167 32.167,8.667 30.333,8.667ZM12,17C13.383,17 14.5,18.117 14.5,19.5C14.5,20.883 13.383,22 12,22C10.617,22 9.5,20.883 9.5,19.5C9.5,18.117 10.617,17 12,17ZM17,27L7,27L7,26.283C7,25.283 7.6,24.367 8.533,23.967C9.6,23.5 10.767,23.25 12,23.25C13.233,23.25 14.4,23.5 15.467,23.967C16.383,24.367 17,25.267 17,26.283L17,27ZM18.667,12L15.333,12L15.333,3.667L18.667,3.667L18.667,12ZM25.75,24.5L21.583,24.5C20.9,24.5 20.333,23.933 20.333,23.25C20.333,22.567 20.9,22 21.583,22L25.75,22C26.433,22 27,22.567 27,23.25C27,23.933 26.433,24.5 25.75,24.5ZM25.75,19.5L21.583,19.5C20.9,19.5 20.333,18.933 20.333,18.25C20.333,17.567 20.9,17 21.583,17L25.75,17C26.433,17 27,17.567 27,18.25C27,18.933 26.433,19.5 25.75,19.5Z"
                    />
                </svg>
            );
        case "icon video-call":
            return (
                <svg width={width} height={height} viewBox="0 0 40 40" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: '2'
                }}>
                    <path className={`svgStyle ${className}`}
                          d="M28.333,17.5L28.333,11.667C28.333,10.75 27.583,10 26.667,10L6.667,10C5.75,10 5,10.75 5,11.667L5,28.333C5,29.25 5.75,30 6.667,30L26.667,30C27.583,30 28.333,29.25 28.333,28.333L28.333,22.5L35,29.167L35,10.833L28.333,17.5ZM23.333,21.667L18.333,21.667L18.333,26.667L15,26.667L15,21.667L10,21.667L10,18.333L15,18.333L15,13.333L18.333,13.333L18.333,18.333L23.333,18.333L23.333,21.667Z"
                    />
                </svg>
            );
        case "icon add-user":
            return (
                <svg width={width} height={height} viewBox="0 0 24 24" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: '2'
                }}>
                    <path className={`svgStyle ${className}`}
                          d="M15,12C17.21,12 19,10.21 19,8C19,5.79 17.21,4 15,4C12.79,4 11,5.79 11,8C11,10.21 12.79,12 15,12ZM6,10L6,8C6,7.45 5.55,7 5,7C4.45,7 4,7.45 4,8L4,10L2,10C1.45,10 1,10.45 1,11C1,11.55 1.45,12 2,12L4,12L4,14C4,14.55 4.45,15 5,15C5.55,15 6,14.55 6,14L6,12L8,12C8.55,12 9,11.55 9,11C9,10.45 8.55,10 8,10L6,10ZM15,14C12.33,14 7,15.34 7,18L7,19C7,19.55 7.45,20 8,20L22,20C22.55,20 23,19.55 23,19L23,18C23,15.34 17.67,14 15,14Z"
                    />
                </svg>
            );
        case "icon commercial-account":
            return (
                <svg width={width} height={height} viewBox="0 0 160 160" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink"
                     xmlSpace="preserve" style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: '2'
                }}>
                    <g>
                        <path className={`svgStyle ${className}`}
                              d="M138.888,50.696l-34.721,-0l-0,-20.833c-0,-7.642 -6.25,-13.892 -13.888,-13.892l-13.891,-0c-7.638,-0 -13.888,6.25 -13.888,13.892l0,20.833l-34.721,-0c-7.641,-0 -13.891,6.25 -13.891,13.887l-0,76.388c-0,7.642 6.25,13.892 13.891,13.892l111.109,-0c7.641,-0 13.891,-6.25 13.891,-13.892l0,-76.388c0,-7.637 -6.25,-13.887 -13.891,-13.887Zm-55.555,27.842c17.485,-0 31.68,14.195 31.68,31.679c-0,17.485 -14.195,31.68 -31.68,31.68c-17.484,-0 -31.679,-14.195 -31.679,-31.68c-0,-17.484 14.195,-31.679 31.679,-31.679Zm-1.172,55.258l0,-4.394c-2.197,-0.277 -3.983,-0.769 -5.358,-1.477c-1.376,-0.708 -2.564,-1.852 -3.565,-3.43c-1.001,-1.579 -1.583,-3.508 -1.745,-5.786l4.418,-0.831c0.342,2.361 0.944,4.094 1.807,5.201c1.237,1.562 2.718,2.433 4.443,2.612l0,-13.989c-1.806,-0.342 -3.654,-1.042 -5.542,-2.1c-1.399,-0.781 -2.478,-1.863 -3.234,-3.247c-0.757,-1.383 -1.136,-2.954 -1.136,-4.712c0,-3.125 1.107,-5.656 3.321,-7.593c1.481,-1.302 3.678,-2.099 6.591,-2.392l0,-2.1l2.588,0l0,2.1c2.556,0.244 4.582,0.993 6.079,2.246c1.921,1.595 3.077,3.784 3.467,6.567l-4.541,0.684c-0.26,-1.725 -0.801,-3.048 -1.623,-3.967c-0.822,-0.92 -1.949,-1.526 -3.382,-1.819l0,12.671c2.214,0.553 3.679,0.984 4.395,1.294c1.367,0.602 2.482,1.334 3.345,2.197c0.862,0.862 1.525,1.888 1.989,3.076c0.464,1.188 0.696,2.474 0.696,3.857c0,3.044 -0.968,5.583 -2.905,7.618c-1.937,2.034 -4.443,3.125 -7.52,3.271l0,4.443l-2.588,0Zm2.588,-8.105c1.709,-0.212 3.121,-0.952 4.236,-2.222c1.115,-1.269 1.673,-2.84 1.673,-4.712c-0,-1.595 -0.395,-2.876 -1.185,-3.845c-0.789,-0.968 -2.364,-1.835 -4.724,-2.6l0,13.379Zm-2.588,-30.371c-1.709,0.26 -3.055,0.944 -4.04,2.051c-0.985,1.106 -1.477,2.417 -1.477,3.93c-0,1.498 0.419,2.751 1.257,3.76c0.838,1.009 2.259,1.815 4.26,2.417l0,-12.158Zm8.118,-30.737l-13.891,0l-0,-34.72l13.891,-0l0,34.72Z"/>
                    </g>
                </svg>
            )
                ;
        default:
            return (<></>);

    }
}
