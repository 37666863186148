export const videoStateReducer = (state, action) => {
    switch (action.payload) {
        case 'set-service':
            return {
                service: action.source,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-in-room':
            return {
                service: state.service,
                inRoom: action.source,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-is-screen':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: action.source,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-mute-microphone':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: action.source,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-mute-camera':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: action.source,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-consult':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: action.source,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-sidebar':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: action.source,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-video-device':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: action.source,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-audio-device':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: action.source,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-modal':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: action.source,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-audio-devices':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...action.source],
                localVideoDevices: [...state.localVideoDevices],
                audioDevice: state.audioDevice,
                videoDevice: state.videoDevice,
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-video-devices':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...action.source],
                initializing: state.initializing,
                patientConnected: state.patientConnected
            }
        case 'set-initializing':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                initializing: action.source,
                patientConnected: state.patientConnected
            }
        case 'set-patientConnected':
            return {
                service: state.service,
                inRoom: state.inRoom,
                isScreen: state.isScreen,
                muteMicrophone: state.muteMicrophone,
                muteCamera: state.muteCamera,
                consult: state.consult,
                showSideBar: state.showSideBar,
                modal: state.modal,
                localAudioDevices: [...state.localAudioDevices],
                localVideoDevices: [...state.localVideoDevices],
                initializing: state.initializing,
                patientConnected: action.source
            }
        default:
            throw new Error();
    }
}
