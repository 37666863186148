import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../../../utilities/auth/AuthContext";
import {useTranslation} from "react-i18next";
import CreateConnectButton from "../commercialAccount/CreateConnectButton";
import {getStripeConnectedAccount} from "../../../services/stripe.service";

function LicenseAlert(_props) {
    const {t} = useTranslation("profile");
    const {user: {user}} = useContext(AuthContext);
    const [isRegistered, setIsRegistered] = useState(true);

    useEffect(() => {
        getStripeConnectedAccount(user.idProvider).then((result) => {
            setIsRegistered(result.isStripeRegister === 1);
        })
    }, []);

    return (
        <>
            {!isRegistered &&
            <div className="alert alert-warning d-flex justify-content-between" role="alert">
                <div className=" align-self-center">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-4 align-self-center"/>
                    <span className="mr-2 align-self-center">{t("profile.onboarding")}</span>
                </div>
                <CreateConnectButton/>
            </div>
            }
        </>

    );
}

export default LicenseAlert;
