import moment from "moment";
import i18next from "i18next";

export const setWidth = (width, margin) => {
    let cameras = document.getElementsByClassName('Camera');
    for (const element of cameras) {
        element.style.width = width + "px";
        element.style.margin = margin + "px";
        element.style.height = (width * 0.75) + "px";
    }
}

export const Area = (Increment, Count, Width, Height, Margin = 10) => {
    let i = 0, w = 0;
    let h = Increment * 0.75 + (Margin * 2);

    while (i < (Count)) {
        if ((w + Increment) > Width) {
            w = 0;
            h = h + (Increment * 0.75) + (Margin * 2);
        }

        w = w + Increment + (Margin * 2);
        i++;
    }

    if (h > Height) return false;
    else return Increment;
}

export const setBlock = () => {
    let Margin = 2;
    let Scenary = document.getElementById("Dish");
    let Width = Scenary.offsetWidth - (Margin * 2);
    let Height = Scenary.offsetHeight - (Margin * 2);
    let Cameras = document.getElementsByClassName('Camera');
    let max = 0;
    let i = 1;

    while (i < 2000) {
        let w = Area(i, Cameras.length, Width, Height, Margin);
        if (w === false) {
            max =  i - 1;
            break;
        }

        i++;
    }

    max = max - (Margin * 2);
    setWidth(max, Margin);
}

export const messageSent = (from, message, time, key, isLast, ref) => <div key={key} className="msg right-msg">
    <div className="d-flex flex-column msg-bubble">
        <div className="d-flex  flex-fill justify-content-between msg-info">
            <div className="msg-info-name">{from}</div>
            <div className="msg-info-time">{moment(time).locale(i18next.language).format('LLL')}</div>
        </div>
        <div className="d-flex flex-shrink justify-content-end">
            <div className="msg-text">
                <p>{message}</p>
            </div>
        </div>

        {isLast === true &&  <div className="chatEnd" ref={ref}/>}
    </div>
</div>

export const messageReceived = (from, message, time, key, isLast, ref) => <div key={key} className="msg left-msg">
    <div className="msg-bubble">
        <div className="msg-info">
            <div className="msg-info-name">{from}</div>
            <div className="msg-info-time">{moment(time).locale(i18next.language).format('LLL')}</div>
        </div>
        <div className="msg-text">
            <p>{message}</p>
        </div>

        {isLast === true &&  <div className="chatEnd" ref={ref}/>}
    </div>
</div>
