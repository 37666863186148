import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import AppointmentButton from "./AppointmentButton";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../utilities/auth/AuthContext";
import ServiceCreate from "../../views/Modal/ServiceCreate";
import {Modal} from "reactstrap";
import {toast} from "react-toastify";
import MenuItemOption from "./MenuItemOption";


const MenuItems = (props) => {
    const {t} = useTranslation("appointments");
    const {user: {user}} = useContext(AuthContext);
    const license = useContext(AuthContext).user.license;
    const [content, setContent] = useState(null);
    const [modal, setModal] = useState(false);
    const history = useHistory();

    const activeRoute = (routeName) => {
        return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get('checkout') === 'redirect') {
            history.push({
                pathname: `/${user.idCompany.name}/commercial-account`,
            });
            toast(t('sidebar.success'));
        }

    }, []);

    const openNewAppointmentModal = () => {
        setContent(
            <ServiceCreate toogle={() => setModal(false)} idCompany={user.idCompany.idCompany} onCreate={() => {
                history.replace('/appointments');
            }}/>
        );
        toggleModal();
    }

    const toggleModal = () => setModal(!modal);

    return (
        <>
            {props.routes.map((prop, key) => {
                if (prop.icon) {
                    return (
                        <li key={key} className={
                            prop.path !== "/new-appointment"
                                ? activeRoute(prop.path) + (prop.pro ? " active" : "") : undefined
                        }>
                            {prop.path === '/new-appointment'
                                ?
                                <AppointmentButton
                                    onClick={openNewAppointmentModal}
                                    className="d-flex flex-row nav-link"
                                    isMobile={props.isMobile}
                                    activeclassname={activeRoute(prop.path) ? 'active' : 'no-active'}
                                    disabled={!license}
                                    icon={prop.icon} name={t(`sidebar.${prop.name}`)}/>
                                :
                                <>
                                    {(prop.roles.includes(user.idRole.idRole)) &&
                                    <MenuItemOption {...props} option={prop}
                                                    activeRoute={activeRoute}
                                                    isMobile={props.isMobile}/>
                                    }
                                </>
                            }
                        </li>
                    )
                } else
                    return <li key='none'/>
            })
            }

            <Modal isOpen={modal} centered={true} toggle={toggleModal} keyboard={false} backdrop={"static"}>
                {content}
            </Modal>
        </>
    );
}


export default MenuItems;
