import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";

/**
 * Obtiene todos los tipos de comunicación de la base de datos
 * @function
 * @return {Promise<T>} [Communication](/api#tag/communication_model) Lista de medios de comunicación
 */
export const getAllCommunications = async () => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.comunication.all
    ).catch(error => {
        throw error;
    })

    return response.data;
}