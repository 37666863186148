import React, {useContext, useState} from "react";
import {NavLink, useHistory} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader,} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";

import {mailPattern, noSpacesPattern, passwordPattern, phonePattern} from "../../utilities";
import {loaderContext} from "../../EHApp";
import {isRegistered, providerCommunticationVerify} from "../../services/auth.service";
import {createAdminProvider} from "../../services/provider.service";
import {getAllCommunications} from "../../services/communication.service";
import AuthLayout from "../../routers/AuthLayout";

import 'assets/scss/styles/_register.scss';
import i18next from "i18next";

export const Register = () => {
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const {t} = useTranslation('common');
    const [communication, setCommunication] = useState([]);
    const [canSubmit, setCanSubmit] = useState(true);
    const [renderForce, setRenderForce] = useState(0);
    let history = useHistory();

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    const callbackNavigate = (navigate, communicationContent, idProvider, doctorName, identification) => {
        history.push({
            pathname: navigate,
            state: {
                communicationContent: communicationContent,
                idProvider: idProvider,
                doctorName: doctorName,
                identification: identification,
                username: communicationContent
            },
        });
    }

    const onSubmit = (data) => {
        setLoader(true);
        isRegistered(true, data.communicationContent, data.idCommunication).then(response => {
            if (response === false) {
                providerCommunticationVerify((data.idCommunication !== 1), data.communicationContent, i18next.language)
                    .then((_response) => {
                        if (_response.status === 400) {
                            toast.error(t('global.incorrect'));
                            setLoader(false);
                        } else {
                            createAdminProvider({
                                name: data.name.trim(),
                                secondName: data.lastname.split(' ').join(' '),
                                communicationContent: data.communicationContent,
                                identification: data.identification,
                                idCommunication: data.idCommunication,
                                acceptTerms: true
                            }).then(provider => {
                                let navigate = (provider.mail == null) ? "/auth/phone/validate" : "/auth/email/validate";
                                setLoader(false);
                                callbackNavigate(navigate, data.communicationContent, provider.idProvider, provider.name, data.identification);

                            }).catch(error => {
                                setLoader(false);
                                console.error(error);
                                toast.error(error.message);
                            });
                        }

                    }).catch((error) => {
                    toast.error(error.message);
                });
            } else {
                setLoader(false);
                toast.error(t("global.already-registered"));
            }

        }).catch(error => {
            setLoader(false);
            console.error(error.message);
            toast.error(error.message);
        });
    }

    function onChange(value) {
        if (value) setCanSubmit(false)
        else setCanSubmit(true)
    }

    React.useEffect(() => {
        (async () => {
            const data = await getAllCommunications();
            setCommunication(data.map((item) => ({label: item.type, value: item.idCommunication})));
        })();
    }, []);

    return (
        <>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters register-wrapper">
                    <div className="col-12 col-md-8 col-lg-7 col-xl-7 left-half">
                        <AuthLayout showLanguageSelector={true} forceUpdate={setRenderForce}>
                            <article
                                className="h-100 row no-gutters justify-content-center align-items-center register-form">
                                <div className="col-10 col-lg-9 col-xl-9">
                                    <h1>{t('create-provider.welcome')}</h1>
                                    <label>
                                        {t('create-provider.top-description')}
                                        <NavLink className="change-view mt-3" exact to="/auth/login"
                                                 activeClassName="active">{t('login.module-title')}</NavLink>
                                    </label>
                                    <label>
                                        {t('create-provider.more-info')}&nbsp;
                                        <a className="change-view mt-3"
                                           href={(i18next.language == "es") ? `https://www.telemedik.com/index.php/healthy-encounter/` : `https://www.telemedik.com/index.php/en/healthy-encounter-2/`}
                                           target="_blank">
                                            {t('create-provider.click-here')}</a>
                                    </label>
                                    <form className="pb-1" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group m-0 p-0">
                                            <label className="mt-3">{t('create-provider.name')}</label>
                                            <input className="form-control form-control-sm" type="text"
                                                   name="name" {...register("name", {
                                                required: {
                                                    value: true,
                                                    message: t('global.required')
                                                },
                                                pattern: {
                                                    value: noSpacesPattern,
                                                    message: t('global.notOnlySpaces')
                                                }
                                            })}
                                                   placeholder={t('create-provider.name')}/>
                                            {errors.name && <span className="error">{errors.name.message}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label className="mt-3">{t('create-provider.lastname')}</label>
                                            <input className="form-control form-control-sm" type="text"
                                                   name="lastname" {...register("lastname", {
                                                required: {
                                                    value: true,
                                                    message: t('global.required')
                                                },
                                                pattern: {
                                                    value: noSpacesPattern,
                                                    message: t('global.notOnlySpaces')
                                                }
                                            })}
                                                   placeholder={t('create-provider.lastname')}/>
                                            {errors.lastname &&
                                            <span className="error">{errors.lastname.message}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label>{t('create-provider.communication-type')}</label>
                                            <select className="form-control form-control-sm"
                                                    name="idCommunication" {...register("idCommunication",
                                                {required: true})}
                                                    placeholder={t('create-provider.communication-type')}>
                                                {
                                                    communication.map((item) => {
                                                        if (item.value === 2) {
                                                            return (
                                                                <option key={item.value}
                                                                    //disabled={(item.value !== 2)}
                                                                        defaultValue={(item.value === 2) ? "true" : ""}
                                                                        value={item.value}>{t(`communication.${item.label}`)}
                                                                </option>
                                                            )
                                                        }
                                                    })
                                                }
                                            </select>

                                            {errors.name && <span className="error">{t('global.required')}</span>}
                                            {errors.idCommunication &&
                                            <span className="error">{errors.idCommunication.message}</span>}
                                        </div>

                                        {/*<div className="form-group">
                                                <label>{t('create-provider.communication-type')}</label>
                                                <select className="form-control form-control-sm"
                                                        defaultValue=""
                                                        name="idCommunication" {...register("idCommunication",
                                                    {required: t('global.required')})}
                                                        placeholder={t('create-provider.communication-type')}>
                                                    <option value=""
                                                            hidden>{t('global.select-option-placeholder')}</option>
                                                    {
                                                        communication.map((item) => {
                                                            return <option key={item.value} disabled={(item.value == 2) ? false : true} selected={ (item.value == 2) ? "selected" : "" }
                                                                           value={item.value}>{t(`communication.${item.label}`)}</option>
                                                        })
                                                    }
                                                </select>

                                                {errors.idCommunication &&
                                                <span className="error">{errors.idCommunication.message}</span>}
                                            </div>*/}

                                        <div className="form-group">
                                            <label>{t('communication.Electronic mail')}</label>
                                            <input className="form-control form-control-sm" type="text"
                                                   key={renderForce}
                                                   name="communicationContent" {...register("communicationContent", {
                                                required: true,
                                                pattern: {
                                                    value: watch("idCommunication") === "2" ? mailPattern : phonePattern,
                                                    message: t('global.invalid-format')
                                                }

                                            })} placeholder={t('communication.Electronic mail')}/>
                                            {errors.name && <span className="error">{t('global.required')}</span>}
                                            {errors.communicationContent &&
                                            <span className="error">{errors.communicationContent.message}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label>{t('create-provider.password')}</label>
                                            <input className="form-control form-control-sm" type="password"
                                                   autoComplete="on" key={renderForce}
                                                   name="identification" {...register("identification", {
                                                required: true,
                                                pattern: {
                                                    message: t('global.min-length'),
                                                    value: passwordPattern
                                                },
                                            })}
                                                   placeholder={t('create-provider.password')}/>
                                            {errors.name && <span className="error">{t('global.required')}</span>}
                                            {errors.identification &&
                                            <span className="error">{errors.identification.message}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label>{t('create-provider.retype-password')}</label>
                                            <input className="form-control form-control-sm" type="password"
                                                   key={renderForce}
                                                   autoComplete="on"
                                                   name="retypeIdentification" {...register("retypeIdentification", {required: true})}
                                                   placeholder={t('create-provider.retype-password')}/>
                                            {errors.retypeIdentification &&
                                            <span className="error">{t('global.required')}</span>}
                                            {watch("identification") !== watch("retypeIdentification") &&
                                            <span className="error">{t('global.no-match')}</span>}
                                        </div>

                                        <div className="form-group form-check">
                                            <input className="form-check-input" name="acceptTerms"
                                                   type="checkbox" {...register("acceptTerms", {required: true})}
                                                   id="acceptTerms"/>
                                            <label className="form-check-label">
                                                {t('create-provider.terms')}
                                                <a href={(i18next.language == "es") ? `https://www.telemedik.com/index.php/terminos-y-condiciones-de-uso/` : `https://www.telemedik.com/index.php/en/terms-and-conditions-of-use/`}
                                                   target="_blank">{t('create-provider.terms-conditions')}</a>
                                                {t("create-provider.and")}
                                                <a href={(i18next.language == "es") ? `https://www.telemedik.com/index.php/politica-de-privacidad-2/` : `https://www.telemedik.com/index.php/en/politica-de-privacidad/`}
                                                   target="_blank">{t('create-provider.privacy-policies')}</a>
                                            </label>
                                            {errors.acceptTerms &&
                                            <span className="error ml-2">{t('global.required')}</span>}
                                        </div>

                                        <ReCAPTCHA
                                            key={renderForce}
                                            hl={i18next.language}
                                            sitekey="6Le0kSAeAAAAANfOHSj6Fg6lQokOMKDLm8i929ij"
                                            onChange={onChange}
                                        />

                                        <button
                                            disabled={canSubmit || watch("identification") !== watch("retypeIdentification")}
                                            className="btn btn-primary btn-sm submit mt-3 mb-4">{t('login.create-user')}</button>
                                    </form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                    <div className=" col-md-4 col-lg-5 col-xl-5 d-md-block d-none right-half">
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
