import React, {useContext, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "reactstrap";
import 'assets/scss/styles/_experience.scss';

import {loaderContext} from "../../EHApp";
import {authLogin, checkProtectedRoutes, progressUpdate} from "../../services/auth.service";
import {authTypes} from "../../utilities/types/types";
import {AuthContext} from "../../utilities/auth/AuthContext";
import AchievementList from "../../components/Partial/AchievementList";

import CertificationList from "../../components/Partial/CertificationList";
import FormationList from "../../components/Partial/FormationList";
import {toast} from "react-toastify";
import AuthLayout from "../../routers/AuthLayout";

export const Experience = () => {

    const {dispatch} = useContext(AuthContext);
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {formState: {errors}} = useForm();
    let history = useHistory();

    const [canSubmit, setCanSubmit] = useState(false);
    const location = useLocation();
    const {t} = useTranslation('common');
    const activeStep = 5;

    checkProtectedRoutes(location, history);

    const achievementRef = useRef(), certificationRef = useRef(), formationRef = useRef();

    const authLoginProvider = () => {
        authLogin(true, location.state.username, location.state.identification).then((response => {
            dispatch({
                type: authTypes.login,
                payload: {
                    user: response.user,
                    token: response.token,
                    company: "/" + response.company.name
                }
            });

            setLoader(false);
            history.replace('/' + response.company.name + "/appointments");
        })).catch(error => {
            if (error.response !== undefined) {
                if (error.response.status === 400)
                    toast.error('Usuario o contraseña incorrectos');
                else
                    toast.error('Error conectando a base de datos');
            }

            setLoader(false);
        });
    }

    return (
        <>
            <a></a>
            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters experience-wrapper">
                    <div className="col-12 half2">
                        <AuthLayout activeStep={activeStep} isProvider={location.state.isProvider}>
                            <article className="h-100 row no-gutters justify-content-center align-items-center"
                                     style={{maxWidth: 675, margin: "0 auto"}}>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-center mb-2">
                                        <h4 className="font-weight-bold text-center formation-bold formation-description">{t('account-experience.add-professional')}</h4>
                                        <div className="col-11 card card-body">
                                            <h5 className="text-center text-sm-center text-md-left font-weight-bold">{t('account-experience.formation')}</h5>
                                            <div className="formation-container">
                                                <AchievementList ref={achievementRef}
                                                                 idProvider={location.state?.idProvider}
                                                                 submitData={canSubmit}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line-separator mb-4"/>
                                    <div className="row justify-content-center mb-2 mt-2">
                                        <div className="col-11 card card-body">
                                            <h5 className="font-weight-bold text-center text-sm-center text-md-left">{t('account-experience.certification')}</h5>
                                            <div className="certification-container">
                                                <CertificationList ref={certificationRef}
                                                                   idProvider={location.state?.idProvider}
                                                                   submitData={canSubmit}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line-separator mb-3"/>
                                    <div className="row justify-content-center mb-2 mt-2">
                                        <div className="col-11 card card-body">
                                            <h5
                                                className="text-center text-sm-center text-md-left font-weight-bold">{t('account-experience.achievement')}</h5>
                                            <div className="achievements-container">
                                                <FormationList ref={formationRef}
                                                               idProvider={location.state?.idProvider}
                                                               submitData={canSubmit}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-10 text-center">
                                            <Button color="link" onClick={() => {
                                                progressUpdate(location.state.idProvider).then(() => {
                                                    console.log('progress update', location.state.company);
                                                    if (location.state.company)
                                                        authLoginProvider();
                                                    else
                                                        history.push({
                                                            pathname: '/auth/branding',
                                                            state: {
                                                                idProvider: location.state.idProvider,
                                                                doctorName: location.state.doctorName,
                                                                identification: location.state.identification,
                                                                username: location.state.username
                                                            },
                                                        });

                                                }).catch((error) => {
                                                    console.log(error);
                                                });
                                            }}
                                                    className="form-label-blue d-none omit text-center experience-omit">{t('account-experience.omit')}</Button>
                                        </div>
                                        <div className="col-10 text-center">
                                            <Button onClick={async () => {
                                                setLoader(true);
                                                achievementRef.current.externalSubmit()
                                                formationRef.current.externalSubmit()
                                                certificationRef.current.externalSubmit()
                                                setTimeout(() => {
                                                    if (achievementRef.current.checkValid()
                                                        && formationRef.current.checkValid()
                                                        && certificationRef.current.checkValid()) {
                                                        setCanSubmit(true);
                                                        setTimeout(function () {
                                                            achievementRef.current.externalSubmit()
                                                            formationRef.current.externalSubmit()
                                                            certificationRef.current.externalSubmit()

                                                            progressUpdate(location.state.idProvider).then(() => {
                                                                if (location.state.company) {
                                                                    authLoginProvider();
                                                                    setLoader(false);
                                                                } else {
                                                                    setLoader(false);
                                                                    history.push({
                                                                        pathname: '/auth/branding',
                                                                        state: {
                                                                            idProvider: location.state.idProvider,
                                                                            doctorName: location.state.doctorName,
                                                                            identification: location.state.identification,
                                                                            username: location.state.username
                                                                        },
                                                                    });
                                                                }

                                                            }).catch((error) => {
                                                                console.log(error);
                                                                setLoader(false);
                                                            });
                                                        }, 500);
                                                    } else {
                                                        setLoader(true);
                                                        setCanSubmit(false);
                                                    }

                                                }, 500);
                                                /*  */
                                            }} className="w-25 center button-link">{t('global.next')}</Button>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Experience;
