import {request} from "../utilities/base/request";
import {authTypes, methodTypes} from "../utilities/types/types";
import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {
    getStripeConnectedAccount,
    getStripeProviderProducts,
} from "./stripe.service";

/**
 * Obtiene todos los tipos de licencia activos
 * @return {Promise<T>}
 */
export const getActiveLicenseTypes = async () => {
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.license.all}`,
        null,
        null,
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene todos los tipos de licencia activos y que no sean internos
 * @return {Promise<T>}
 */
export const getActiveNonInternalLicenseTypes = async () => {
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.license.allPaid}`,
        null,
        null,
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene la licencia del provider
 * @param {string} idProvider - Identificador único del proveedor
 * @return {Promise<T>}
 */
export const getLicense = async (idProvider) =>{
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.license.getLicense}${idProvider}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene/actualiza el estado de la licencia del provider globalmente para su uso a traves de la app
 * @param {any} provider - Objeto(Provider) user
 * @param {any} dispatch - Dispatch del user
 * @param {boolean} - Estado de la licencia
 *
 * @returns {Promise<void>}
 */
export const handleLicenseStatus = async (provider, dispatch, status = null) => {
    if (status != null) {
        await setStatusCurrentLicense(dispatch, status);
    } else {
        const stripeAccount = await getStripeConnectedAccount(provider.user.idProvider);
        await getStatusCurrentLicense(stripeAccount, provider, dispatch);
    }
}

/**
 * Actualiza el estado de la licencia según el status pasado
 * @param {any} dispatch -  Dispatch del user
 * @param {boolean} status - Estado de la licencia
 * @returns {Promise<void>}
 */
const setStatusCurrentLicense = async (dispatch, status) => {
    dispatch({
        type: authTypes.license,
        license: status
    });
}

/**
 * Verifica el status de la licencia actual de un provider y la expone globalmente
 * @param {object} stripeAccount - Objeto con datos cuenta Stripe
 * @param {string} stripeAccount.stripeAccountId - Objeto con datos cuenta Stripe
 * @param {string} stripeAccount.customerId - Objeto con datos cuenta Stripe
 * @param {number} stripeAccount.isStripeRegister - Objeto con datos cuenta Stripe
 * @param {any} provider - Objeto(Provider) user
 * @param {any} dispatch -  Dispatch del user
 * @return {Promise<void>}
 */
const getStatusCurrentLicense = async (stripeAccount, provider, dispatch) => {
    if (provider.user) {
        const licenseStatus = (provider.user.idRole.name !== "ADMIN")
            ? await verifyLicenseUpdatedNoAdmin(provider.user)
            : await verifyLicenseUpdated(provider.user, stripeAccount.isStripeRegister);
        dispatch({
            type: authTypes.license,
            license: licenseStatus
        });
    }
}

/**
 * Verifica la licencia actualizada de los roles admin
 * @param {any} provider - Objeto(Provider) user
 * @param {number} isRegistered - Indicador de registro en Stripe. 1: Registrado, otro valor: No registrado
 * @returns {Promise<*|boolean>}
 */
const verifyLicenseUpdated = async (provider, isRegistered) => {
    const products = await getStripeProviderProducts(provider.idProvider) || [];
    const license = await getLicense(provider.idProvider);
    return (license?.isActive && isRegistered === 1 && products.length > 0);
}

/**
 * Verifica la licencia actualizada de los roles no admin
 * @param {any} provider - [Provider](/api#tag/provider_model) user
 * @returns {Promise<*>}
 */
const verifyLicenseUpdatedNoAdmin = async (provider) => {
    const license = await getLicense(provider.idProvider);
    return (license?.isActive);
}