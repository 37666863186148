import {Button, ModalBody, ModalFooter} from "reactstrap";
import moment from "moment";
import {serviceDelete} from "../../services/service.service";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const ServiceDelete = (props) => {
    const {t} = useTranslation(['appointments', 'common']);
    const [loading, setLoading] = useState(false);

    return (<>
        <ModalBody>
            <h5>{t("appointments:scheduled-appointments.cancelAppointmentModal")}</h5>
            {!loading ?
                <p>{t("appointments:scheduled-appointments.cancelAppointmentDescription1Modal")} {props.idUser.name} {props.idUser.secondName}
                    {' '} {t("appointments:scheduled-appointments.cancelAppointmentDescription2Modal")} {moment(props.itemAppointment.serviceDate).format('DD/MM/YYYY')} {t("common:global.at")}{' '}{props.itemAppointment.time}.</p>
                :
                <div className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                </div>
            }
        </ModalBody>
        <ModalFooter>
            <Button disabled={loading}
                    color="secondary"
                    onClick={() => {
                        props.modal.current.openModal();
                    }}>{t("common:global.cancel")}</Button>
            <Button color="primary"
                    disabled={loading}
                    onClick={() => {
                        setLoading(true);
                        serviceDelete(props.idService, props.idProvider, props.idUser, props.locale).then((_response) => {
                            props.setReload();
                            props.modal.current.openModal();
                        });
                    }}>{t("appointments:scheduled-appointments.cancel")}</Button>

        </ModalFooter>
    </>);
}

export default ServiceDelete;
