import React from "react";
import {useTranslation} from "react-i18next";

const AppointmentItemCondition = ({condition}) => {
    const {t} = useTranslation('common');
    return (
        <>
            {t(`conditions.${condition}`)}
        </>
    );
}

export default AppointmentItemCondition;