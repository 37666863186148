import React from 'react';
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";

function CreateProductModal({
                                toggle,
                                showProcessing,
                                onClose,
                                onCreateProduct
                            }) {

    const {t} = useTranslation('profile', 'appointments');
    const {register, handleSubmit, formState: {errors}} = useForm();

    const handleCreateProduct = (data) => {
        onCreateProduct(data);
    }

    const handleClose = () => {
        console.log('handleClose');
        onClose();
    }


    return (
        <Modal
            isOpen={toggle}
            fade={false}
            backdrop={"static"}
            centered={true}
        >
            <form onSubmit={handleSubmit(handleCreateProduct)}>
                <ModalBody>
                    <h5>{t("Stripe.stripe_product_add")}</h5>
                    {showProcessing ?
                        <div className="d-flex justify-content-center">
                            <FontAwesomeIcon icon={faSpinner} className="spinner mt-5 mb-5 align-self-center"/>
                        </div>
                        :
                        <div className="row">

                            <div className="col-12">
                                <div className="form-group">
                                    <label>{t("Stripe.stripe_product_name")}</label>

                                    <input className="form-control form-control-sm" type="text"
                                           name="productName" {...register("productName", {required: t('common:global.required')})}
                                           placeholder={t("Stripe.stripe_product_name_plh")}
                                    />
                                    {errors.productName &&
                                    <small className="error">{errors.productName.message}</small>}

                                </div>
                                <div className="form-group">
                                    <label>{t("Stripe.stripe_product_price")}</label>
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">$</span>
                                        </div>
                                        <input className="form-control form-control-sm" type="number"
                                               onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                               min={0.6} step={.01}
                                               name="productPrice"
                                               {...register("productPrice", {
                                                   required: t('common:global.required'),
                                                   pattern: {
                                                       value: /^\$?(([1-9](\d*|\d{0,2}(,\d{3})*))|0)(\.\d{1,2})?$/,
                                                       message: t('appointments:scheduled-appointments.form.invalid-format')
                                                   }
                                               })}
                                               placeholder={t("Stripe.stripe_product_price_plh")}
                                        />
                                        {errors.productPrice &&
                                        <small className="error">{errors.productPrice.message}</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <div id="prod_feedback" style={{display: "none"}}>
                        <span style={{color: "red"}}><p/> </span>
                    </div>

                    <button type="button" className="btn btn-secondary btn-sm" disabled={showProcessing}
                            color="secondary"
                            onClick={handleClose}>{t("Stripe.stripe_cancel")}</button>
                    <button className="btn btn-primary btn-sm"
                            type="submit"
                            disabled={showProcessing}>{t("Stripe.stripe_product_add")}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

CreateProductModal.propTypes = {
    toggle: PropTypes.bool.isRequired,
    showProcessing: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreateProduct: PropTypes.func.isRequired,
}

export default CreateProductModal;
