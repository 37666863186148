import React, {useContext, useState} from 'react';
import {AuthContext} from "../../utilities/auth/AuthContext";
import PropTypes from "prop-types";
import Hamburger from 'hamburger-react'
import CompanyLogo from "../common/CompanyLogo";
import LanguageSelector from "./LanguageSelector";
import UserProfileDropdown from "./UserProfileDropdown";
import MenuItems from "../SideBar/MenuItems";
import {Link} from "react-router-dom";

const TopNavBar = (props) => {

    const {user: {user}} = useContext(AuthContext);
    const [isOpen, setOpen] = useState(false);
    return (<>
            {props.visible &&
            <nav className="navbar navbar-light bg-light navbar-expand-md">
                <Link to="/">
                    <CompanyLogo className="logo" alt={user.idCompany.brandingName} logo={user.idCompany.logo}/>
                </Link>
                <button className="navbar-toggler border-0" type="button" data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <Hamburger size={24} toggled={isOpen} toggle={setOpen}/>
                </button>
                <div className="collapse navbar-collapse desktopMenuHide" id="navbarNav">
                    <ul className="navbar-nav mr-auto mt-2">
                        <MenuItems {...props} isMobile={true}/>
                        <li className="mt-3 mb-2"><LanguageSelector/></li>
                    </ul>
                </div>
                <div className="d-inline-flex mobileMenuHide">
                    <LanguageSelector user={user}/>
                    <UserProfileDropdown {...props} />
                </div>
            </nav>
            }
        </>
    );
}

TopNavBar.propTypes = {
    visible: PropTypes.bool.isRequired
}

export default TopNavBar;
