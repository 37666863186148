import {Button, ModalBody, ModalFooter} from "reactstrap";
import React from "react";
import {sendURL} from "../../services/user.service";
import {useTranslation} from "react-i18next";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServiceCommunicate = (props) => {
    const {t} = useTranslation(['appointments', 'common']);
    const sendAccess = (idUser, isPhone, accessLink, idProvider, idService) => {
        sendURL(idUser, isPhone, accessLink, idProvider, idService, (localStorage.getItem('language')) ? localStorage.getItem('language') : 'es').then(() => {
            toast(t('common:global.sent'))
        }).catch(error => {
            throw error;
        });
    };

    return (<>
        <ModalBody>
            <h5>{t("appointments:scheduled-appointments.linkResendModal")}</h5>
            <p>{t("appointments:scheduled-appointments.linkResendDescriptionModal")} {props.idUser.name} {props.idUser.secondName} {' '}
                {t("appointments:scheduled-appointments.to")}
                {' '}
                {props.idUser.mail && props.idUser.mail !== '0'
                    ? <span>{t("appointments:scheduled-appointments.email")} {props.idUser.mail}</span>
                    : <span>{t("appointments:scheduled-appointments.phone")} {props.idUser.idPhone.phoneNumber}</span>
                }?</p>
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={() => {
                props.modal.current.openModal();
            }}>{t("common:global.cancel")}</Button>
            <Button color="primary" onClick={() => {
                sendAccess(props.idUser.idUser, props.isPhone, props.accessLink, props.idProvider, props.idService);
                props.modal.current.openModal();
            }}>{t("common:global.send")}</Button>{' '}

        </ModalFooter>
    </>);
};

export default ServiceCommunicate;
