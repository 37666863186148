import React, {useEffect, useState} from "react";
import defaultAvatar from "../../assets/images/default-avatar.svg";

const UserAvatar = ({profile, className, alt}) => {

    const [userBlob, setUserBlob] = useState(null);

    useEffect(() => {
        if (profile && profile.data?.length > 4) {
            let arrayBufferView = new Uint8Array(profile.data);
            let blob = new Blob([arrayBufferView], {type: "image/jpeg"});
            let urlCreator = window.URL || window.webkitURL;
            setUserBlob(urlCreator.createObjectURL(blob));
        }
    }, [profile]);

    return (
        <img className={"rounded-circle " + className}
             alt={alt}
            //src={(userBlob) ? userBlob : defaultAvatar}/>
             src={(userBlob && profile) ? userBlob : defaultAvatar}/>
    );
}

export default UserAvatar;
