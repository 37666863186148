import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";
import {hostname} from "../utilities";
import {APIROUTES} from "./api";

/**
 * Guarda la compañía asociada a un proveedor con la configuración
 * @function
 * @param {object} config - Objeto con los datos de configuración de la compañía.
 * @param {string} [config.name = null] Nombre que aparecerá en la url de la compañía, sin espacios ni caracteres especiales
 * @param {string} config.color Color principal del tema en formato hexadecimal de la compañía
 * @param {string} config.colorSecondary Color secundario del tema en formato hexadecimal de la compañía
 * @param {blob} config.logo Logo de la compañía
 * @param {string} config.idCompany Identificador único de la compañía
 * @returns {Promise<any>}
 */
export const saveCompany = async (config) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.company.save,
        null,
        config
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza la información de organización de la compañía
 * @function
 * @param {Object} organization - Objeto con la información de la organización de la compañia.
 * @param {string} organization.brandingName - Nombre de la compañía
 *
 * @returns {Promise<any>}
 */
export const updateCompanyOrganization = async (organization) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.company.updateCompanyOrganization,
        null,
        organization, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza la información de branding de la compañía
 * @function
 * @param {object} data - Objeto
 *
 * @returns {Promise<any>}
 */
export const updateCompanyBranding = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.company.updateCompanyBranding,
        null,
        data,
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtine los proveedores de una compañía
 * @function
 * @param {string} idCompany - Identificador único de la compañía
 * @param {string} idProvider - Identificador único del proveedor
 *
 * @returns {Promise<any>}
 */
export const getProviderByIdCompany = async (idCompany, idProvider, status, take, limit) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.company.getProvidersByIdCompany,
        {idCompany: idCompany, idProvider: idProvider, status: status, take: take, limit: limit},
        null,
        true
    ).catch(error => {
        throw error;
    })
    return response.data;
}

/**
 * Obtiene el total de providers.
 * @param {string} idCompany - Identificador único de la compañía
 *
 * @returns {Promise<any>}
 * @function
 */
export const getProviderTotalByCompany = async (idCompany) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.company.getProvidersTotalByIdCompany,
        null,
        {idCompany: idCompany},
        true
    ).catch(error => {
        throw error;
    })
    return response.data;
}

/**
 * Obtiene los detalles de una compañía por su identificador
 * @param {string} idCompany - Identificador único de la compañía
 *
 * @returns {Promise<any>}
 * @function
 */
export const getCompanyById = async (idCompany) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.company.getById,
        {idCompany: idCompany},
        null,
        true
    ).catch(error => {
        throw error;
    })

    return response.data;
}