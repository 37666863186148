import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";

export const getGroup = async (idUser) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.group,
        {idUser: idUser},
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getUsers = async (idCompany) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.user.getByCompany,
        {idCompany: idCompany},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getByMail = async (email, idCompany) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.user.getByEmail,
        null,
        {email: email, idCompany: idCompany},
        true
    ).catch(error => {
        throw error;
    })
    return response.data;
}

export const getByPhone = async (phone, idCompany) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.user.getByPhone,
        null,
        {phone: phone, idCompany: idCompany},
        true
    ).catch(error => {
        throw error;
    })
    return response.data;
}

export const sendURL = async (idUser, isPhone, accessLink, idProvider, idService, locale) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.sendURL,
        null,
        {idUser: idUser, isPhone: isPhone, accessLink: accessLink, idProvider: idProvider, idService: idService, locale: locale},
        true
    ).catch(error => {
        throw error;
    })

    return response.data;
}