import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../../utilities/auth/AuthContext";
import {faFileInvoiceDollar, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {getStripeSubscription, handleActiveCancelSubscription} from "../../../services/stripe.service";
import Moment from "react-moment";
import DeleteSubscriptionButton from "./DeleteSubscriptionButton";
import moment from "moment";
import i18next from "i18next";
import CreateSubscriptionButton from "./CreateSubscriptionButton";
import {handleLicenseStatus} from "../../../services/license.service";

const SubscriptionDetail = (props) => {
    const {t} = useTranslation(["profile", "common"]);
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState(null);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const {user, dispatch} = useContext(AuthContext);

    i18next.on('languageChanged', function (lng) {
        moment.locale(lng);
    })

    useEffect(() => {
        handleSubscription();
    }, []);

    const handleReload = async () => {
        await handleLicenseStatus(user, dispatch);
        handleSubscription();
    }

    const buildDateFormat = () => {
        if (moment.locale() === "en") {
            return ("dddd, MMMM DD [,] YYYY, hh:mm a");
        }
        if (moment.locale() === "es") {
            return ("dddd, DD [de] MMMM  [de] YYYY, hh:mm a");
        }
        return "";
    }

    const handleSubscription = () => {
        setLoading(true);
        getStripeSubscription(user.user.idProvider).then((stripeSubscription) => {
            setSubscription(stripeSubscription);
            setShowCancelButton(handleActiveCancelSubscription(stripeSubscription));
            setLoading(false);
            moment.locale(i18next.language);
        }).catch((_error) => {
            setLoading(false);
            console.error(_error);
        });
    }

    return (
        <>
            <h5>{t("Stripe.stripe_subcription_details")}</h5>
            <div className="card suscription-detail ">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {loading ?
                                    <div className="col-12 d-flex justify-content-center">
                                        <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                                    </div>
                                    :
                                    <>
                                        <div
                                            className="col-12 col-md-8 d-flex justify-content-between align-items-center">
                                            <div className="d-inline-flex align-items-center mr-2">
                                                <FontAwesomeIcon icon={faFileInvoiceDollar}
                                                                 className={(subscription?.status === 'active' || subscription?.status === 'trialing') ? "valid-subscription" : "invalid-subscription"}/>
                                                <div className="ml-3 d-flex flex-column">
                                            <span><strong
                                                className="mr-2">{t("Stripe.stripe_subcription_status")}</strong>{subscription?.status ? t('common:subscriptionStatus.' + subscription?.status) : t('Stripe.no-subscription')}</span>
                                                    {subscription &&
                                                    <>
                                                <span><strong
                                                    className="mr-2">{t("Stripe.stripe_subcription_periodStart")}</strong><Moment
                                                    format={buildDateFormat()}>{subscription?.currentPeriodStart}</Moment></span>
                                                        <span><strong
                                                            className="mr-2"> {t("Stripe.stripe_subcription_periodEnd")}</strong><Moment
                                                            format={buildDateFormat()}>{subscription?.currentPeriodEnd}</Moment></span>
                                                        <span><strong
                                                            className="mr-2"> {t("Stripe.stripe_subcription_type")}</strong> {t("Stripe.stripe_subcription_cicle")}</span>
                                                        {subscription.cancel_at_period_end &&
                                                        <>
                                                            <span><strong
                                                                className="mr-2 error1"> {t("Stripe.stripe_cancel_at_end_message")}</strong></span>
                                                        </>
                                                        }
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex col-12 col-md-4 mt-2 mt-md-0 justify-content-md-end align-items-center">
                                            {subscription ?
                                                showCancelButton &&
                                                <DeleteSubscriptionButton
                                                    {...props}
                                                    reload={handleReload}
                                                />
                                                :
                                                <CreateSubscriptionButton
                                                    {...props}
                                                    reload={handleReload}
                                                />
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubscriptionDetail;
