import React, {useEffect, useState} from "react";
import {Button, Form, Label} from "reactstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import 'assets/scss/styles/_receipt.scss';
import moment from 'moment';
import {getSubcriptionsList} from "../../services/stripe.service";
import {checkProtectedRoutes} from "../../services/auth.service";
import AuthLayout from "../../routers/AuthLayout";

export const Receipt = (_props) => {
    const {handleSubmit} = useForm();
    const {t} = useTranslation('common');
    const history = useHistory();
    const location = useLocation();
    const [price, setPrice] = useState(0);
    const n = moment().format('D');
    const activeStep = 3;

    checkProtectedRoutes(location, history);

    const onSubmit = () => {
        history.push({
            pathname: '/auth/add/profile',
            state: {
                idProvider: location.state.idProvider,
                doctorName: location.state.doctorName,
                identification: location.state.identification,
                username: location.state.username,
                idProviderType: location.state.idProviderType
            },
        });
    }
    let subdetails = async () => {
        getSubcriptionsList().then((res) => {
            setPrice(res.price);
        });

    }
    useEffect(() => {
        subdetails();

    }, []);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters receipt-wrapper">
                    <div className="col-12 half">
                        <AuthLayout activeStep={activeStep}>
                            <article className="h-100 row justify-content-center align-items-center">

                                <div className="col-10 col-md-6 col-lg-5 col-xl-4">
                                    <Form className="pb-4 block-width" onSubmit={handleSubmit(onSubmit)}>
                                        <h1>{t("payment.confirmed")}</h1>
                                        <Label className="recover-description">{t("payment.recover-label")}
                                            <strong>{t("payment.recover-bold")}{price} {t("payment.recover-bold2")} </strong></Label>
                                        <Label>{t("payment.confirm-description")} {n} {t("payment.confirm-description2")} </Label>

                                        <Button className="submit mt-4 mb-4 button-link">{t("payment.access")}</Button>
                                    </Form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Receipt;
