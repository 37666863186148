import React from "react";
import PropTypes from "prop-types";

const AppointmentButton = (props) => {

    return (
        <>
            {!props.isMobile &&
            <button onClick={props.onClick} className={`btn ${props.className} ${props.isMobile ? 'ml-1' : ''}`}
                    disabled={props.disabled}>
                <div className={`icon-wrapper`}>
                    <div className={`${props.icon} filter-green`}/>
                </div>
                <span className={`text-left align-self-center mr-1`}>{props.name}</span>
            </button>
            }
        </>
    );
}
AppointmentButton.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default AppointmentButton;
