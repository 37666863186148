import React from 'react';
import {Card, CardBody, CardHeader, CardText} from "reactstrap";
import {useTranslation} from "react-i18next";

function LicenseTypeItem({licenseType, onSelect}) {

    const {t} = useTranslation('common');

    return (
        <Card onClick={() => {
            onSelect(licenseType)
        }}>
            <CardHeader>
                <h5>{t(licenseType.name)}</h5>
            </CardHeader>
            <CardBody>
                <CardText>{t(licenseType.description)}</CardText>
                <span className="price">${licenseType.cost}</span>
                <span className="price-footer">{t("payment.price-month")}</span>
            </CardBody>
        </Card>
    );
}

export default LicenseTypeItem;
