import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {localhost, serviceStatusEnum} from "../../utilities";
import logo from "../../assets/images/logo-beta.svg";
import iconAlert from "../../assets/images/icons/icon-alert.svg";
import appointmentsFinishedIcon from "../../assets/images/icons/icon-provider-disconnect.svg";
import LanguageSelector from "../../components/TopNavBar/LanguageSelector";
import i18next from "i18next";
import "../../assets/scss/views/clients.scss";
import {useForm} from "react-hook-form";

export default function Remove(_props) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {t} = useTranslation("common");
    const [statusForShow, setStatusForShow] = useState(null);
    let history = useHistory();

    useEffect(() => {
        setStatusForShow({
            status: serviceStatusEnum.finished,
            icon: appointmentsFinishedIcon,
            title: t('common:appointment-check.status-finished'),
            message: t('appointment-check.provider-finished'),
            messageAlert: t('appointment-check.provider-finished-alert'),
        })
    }, [i18next.language]);

    const onSubmit = (formData) => {
        console.log("onSubmit_:", formData)
        history.push("/client/verification/");

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="container overflow-hidden h-100">
                <div className="mt-3 row d-flex justify-content-between align-items-center">
                    <div className="w-25"></div>
                    <div className="w-25 text-center"><LanguageSelector/></div>
                </div>
                <div className="d-flex justify-content-center mt-1">
                    <img className="" width={250} alt="Logo Healthy Encounter" src={logo}/>
                </div>

                <div className="checkoutContainer">
                    <div className="align-self-center self text-center">
                        <img className="mb-3" width="135px" src={statusForShow?.icon} alt=""/>
                        {statusForShow?.title && <h3 className="mt-4">{statusForShow?.title}</h3>}
                        <p className="mt-4">{statusForShow?.message}</p>
                        {statusForShow?.messageAlert &&
                            <div>
                                <div className="iconAlert">
                                    <img src={iconAlert} alt=""/>
                                </div>
                                <div className="message-alert" role="alert">
                                    <p className="">{statusForShow?.messageAlert}</p>
                                </div>
                            </div>
                        }


                    </div>
                </div>


            </div>
        </form>

    )
        ;
}
