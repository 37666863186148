import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Modal, Nav} from "reactstrap";

import MenuItems from "./MenuItems";

const SideBar = forwardRef((props, reference) => {

    const [menu, setMenu] = React.useState(false);
    const sidebar = React.useRef();
    const [modal, setModal] = useState(false);
    const [content, setContent] = useState(null);

    useImperativeHandle(reference, () => ({
        openModal: () => {
            toggleModal()
        },
        menu: menu,
        setMenu: setMenu,
        setModalOnly: (data) => {
            setContent(data);
        },
        toggleOnly: () => {
            toggleModal()
        },
        setModal: (data) => {
            setContent(data);
            toggleModal();
        }
    }));

    const toggleModal = () => setModal(!modal);

    return (
        <>
            {props.sideBarVisible &&
            <div className={`sidebar d-none d-md-inline-flex ${(menu) ? 'show' : ''} `}>
                <div className="sidebar-wrapper" ref={sidebar}>
                    <Nav className="flex-column">
                        <MenuItems {...props} isMobile={false}/>
                    </Nav>
                </div>
            </div>
            }

            <Modal isOpen={modal} centered={true} toggle={toggleModal} keyboard={false} backdrop={"static"}>
                {content}
            </Modal>
        </>
    )
});

export default SideBar;
