import React, {useContext, useState} from 'react';
import ConfirmModal from "../../../views/Modal/ConfirmModal";
import {useTranslation} from "react-i18next";
import {deleteStripeSubscription} from "../../../services/stripe.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../../../utilities/auth/AuthContext";
import {toast} from "react-toastify";

const DeleteSubscriptionButton = (props) => {

    const {t} = useTranslation(["profile", "common"]);
    const [loading, setLoading] = useState(false);
    const {user: {user}} = useContext(AuthContext);
    const currentUser = props.user != null ? props.user : user;

    const handleConfirmDeleteButton = () => {
        props.modal.current.setModal(<ConfirmModal
                modal={props.modal}
                title={t("common:commercial-account.confirm-delete-subscription-title")}
                message={t("common:commercial-account.confirm-delete-subscription-message")}
                acceptLabel={t("common:commercial-account.confirm-delete-subscription-button-label")}
                acceptColor="primary"
                onAccept={deleteSubscription}
            />
        );
    }

    const deleteSubscription = async () => {
        setLoading(true);
        try {
            await deleteStripeSubscription(currentUser.idProvider);
            setLoading(false);
            props.reload();
            toast.success(t("common:commercial-account.success-delete-subscription"));
        } catch (error) {
            toast.error(t("common:commercial-account.error-delete-subscription"));
        }
    }
    return (
        <button disabled={loading} onClick={handleConfirmDeleteButton}
                className='btn btn-outline-danger btn-sm cancel-btn'>
            {loading ? <FontAwesomeIcon icon={faSpinner}
                                        className="spinner-button spinner-button-cancel"/> : t("profile:Stripe.stripe_subcription_cancel")}
        </button>
    );
}

export default DeleteSubscriptionButton;
