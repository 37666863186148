import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";

import Dropzone from "react-dropzone";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../utilities/auth/AuthContext";
import {Controller, useForm} from "react-hook-form";
import ColorPicker from "../ColorPicker/ColorPicker";
import {updateCompanyBranding} from "../../services/company.service";
import {authTypes} from "../../utilities/types/types";
import {toast} from "react-toastify";


export const BrandingForm = (props) => {
    const {t} = useTranslation(['myTeam', 'common']);
    const [image, setImage] = useState(null);
    const [blob, setBlob] = useState(null);
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    const {user, dispatch} = useContext(AuthContext);
    const {handleSubmit, control} = useForm({
        defaultValues: {
            colorPrimary: props.company.color,
            colorSecondary: props.company.secondColor
        }
    });

    useEffect(() => {
        if (props.company.logo && props.company.logo.data?.length > 4) {
            let arrayBufferView = new Uint8Array(props.company.logo.data);
            let _blob = new Blob([arrayBufferView], {type: "image/jpeg"});
            let urlCreator = window.URL || window.webkitURL;
            setImage(urlCreator.createObjectURL(_blob));
        }
        setPrimaryColor(props.company.color);
        setSecondaryColor(props.company.secondColor);
    }, [props.company]);

    const goBack = () => {
        props.close();
    }

    const onSubmit = (_data) => {
        let formData = new FormData();
        formData.append("color", primaryColor);
        formData.append("colorSecondary", secondaryColor);
        formData.append("logo", blob);
        formData.append("idCompany", props.company.idCompany);

        updateCompanyBranding(formData).then((response) => {
            if (response === true) {
                toast.error(t("company-registered"));
            } else {
                localStorage.setItem('user', JSON.stringify(user));
                user.user.idCompany = response;
                dispatch({
                    type: authTypes.login,
                    payload: {
                        user: user.user,
                        token: user.token,
                        company: user.company,
                        license: user.license
                    }
                });

                props.reload();
                props.close();
            }

        });
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mt-2 p-3 mb-4 align-items-center branding-form">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 text-center">
                        <Dropzone class="dropzone" accept="image/jpeg, image/png" minSize={0}
                                  maxSize={1097152} onDrop={(acceptedFiles, _rejectedFiles) => {
                            let reader = new FileReader();
                            reader.onload = (e) => {
                                let contents = e.target.result;
                                const _blob = new Blob([contents]);
                                const url = URL.createObjectURL(_blob);
                                setImage(url);
                            };

                            if (acceptedFiles.length > 0) {
                                setBlob(acceptedFiles[0]);
                                reader.readAsArrayBuffer(acceptedFiles[0]);
                            }
                        }}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input className="drop-input " {...getInputProps()} />
                                        <img className="image-branding" src={image} alt=""/>
                                        <label>{t('common:branding.add-logo')}</label>
                                        <p className="m-0 p-0">
                                            <small>{t('common:global.only-images')}</small>
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-3 mt-md-1">
                        <div className="d-flex mb-2 justify-content-center">
                            <span className="mr-3 labelColor">{t('myTeam:branding.primaryColor')}</span>
                            <Controller
                                control={control}
                                name='colorPrimary'
                                rules={{required: false}}
                                render={({field}) => (
                                    <ColorPicker color={props.company?.color} onSelectColor={(color) => {
                                        setPrimaryColor(color);
                                        field.onChange(color);
                                    }}/>
                                )}/>
                        </div>
                        <div className="d-flex justify-content-center">
                            <span className="mr-3 labelColor">{t('myTeam:branding.secondaryColor')}</span>
                            <Controller
                                control={control}
                                name='colorSecondary'
                                rules={{required: false}}
                                render={({field}) => (
                                    <ColorPicker color={props.company?.secondColor} onSelectColor={(color) => {
                                        setSecondaryColor(color);
                                        field.onChange(color);
                                    }}/>
                                )}/>
                        </div>
                    </div>
                    <div className="form-group col-md-12 d-flex justify-content-end">
                        <button className="btn btn-secondary btn-sm mr-2" type="button"
                                onClick={goBack}>{t('common:global.cancel')}</button>
                        <button className="btn btn-primary btn-sm submit"
                                type="submit">{t('common:global.save-changes')}</button>
                    </div>
                </div>
            </form>
        </>
    );
}

BrandingForm.propTypes = {
    company: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired
}
