import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolder, faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";

const ButtonAppointmentInfo = (props) => {
    const {t} = useTranslation(["video", "common"]);
    return (
        <>
            <button data-tip={t("appointment-info")} className="color-white btn btn-link" onClick={() => {
                props.dispatch({
                    source: !props.videoState().consult,
                    payload: "set-consult",
                });
            }}>
                {(props.videoState().consult) ? <FontAwesomeIcon icon={faFolderOpen}/> :
                    <FontAwesomeIcon icon={faFolder}/>}
            </button>
            <ReactTooltip/>
        </>
    );
}

export default ButtonAppointmentInfo;
