import React from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {CardElement, useElements} from "@stripe/react-stripe-js";
import doctorPaytmentIcon from "../../../assets/images/icons/doctor_payment.svg";

const StripeCheckoutModal = (props) => {
    const {t} = useTranslation("profile");
    const elements = useElements();

    const handlePayment = () => {
        props.onPay(elements.getElement(CardElement));
    }

    const handleClose = () => {
        props.onClose();
    }

    return (
        <Modal isOpen={props.toggle} centered={true} fade={false}>
            <ModalBody>
                {props.showLoading ?
                    <div className="d-flex justify-content-center text-center payment-loader-content">
                        <div className="align-self-center">
                            <img className="mt-4" width="180px" src={doctorPaytmentIcon} alt=""/>
                            <h3 className="mt-4">{t("Stripe.stripe_processing_pay")}</h3>
                            <FontAwesomeIcon icon={faSpinner} className="spinner mt-4 mb-4"/>
                        </div>
                    </div>
                    :
                    <div className="text-center">
                        <img className="mt-4" width="180px" src={props.icon ? props.icon : doctorPaytmentIcon} alt=""/>
                        <h3 className="mt-4">{props.title}</h3>
                        <p className="mt-3">{props.message}</p>
                    </div>
                }
                <CardElement className="p-3 pb-5"
                             options={{
                                 style: {
                                     base: {
                                         fontSize: '16px',
                                         color: '#424770',
                                         '::placeholder': {
                                             color: '#aab7c4',
                                         },
                                     },
                                     invalid: {
                                         color: '#9e2146',
                                     },
                                 },
                             }}
                />
                {props.paymentErrorMessage &&
                <div className="text-center" style={{color: "red"}}>
                    <span>{t("Stripe.stripe_subcription_pay_err")}</span>
                    <p>{props.paymentErrorMessage}</p>
                </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button disabled={props.showLoading} color="secondary"
                        onClick={handleClose}> {t("Stripe.stripe_cancel")}</Button>
                <Button disabled={props.showLoading} color="primary"
                        onClick={handlePayment}>{t("Stripe.stripe_subcription_pay")}</Button>
            </ModalFooter>
        </Modal>
    );
}

StripeCheckoutModal.propTypes = {
    toggle: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    showLoading: PropTypes.bool.isRequired,
    onPay: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default StripeCheckoutModal;
