import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import waitingRoomIcon from "../../assets/images/icons/waiting-room.svg"
import {useTranslation} from "react-i18next";
import {providerCommunication, validateData} from "../../services/service.service";
import moment from "moment";
import {serviceStatusEnum} from "../../utilities";
import LanguageSelector from "../../components/TopNavBar/LanguageSelector";
import appointmentsCancelledIcon from "../../assets/images/icons/appointment-cancelled.svg";
import logo from "../../assets/images/logo.svg";
import i18next from "i18next";
import {useForm} from "react-hook-form";
import userLogo from "../../assets/images/email-icons/user.svg";
import calendarLogo from "../../assets/images/email-icons/calendar.svg";
import horaLogo from "../../assets/images/email-icons/hora.svg";
import costoLogo from "../../assets/images/email-icons/costo.svg";

import "../../assets/scss/views/clients.scss";

export default function ClientScheduled() {
    const history = useHistory();
    const [statusForShow, setStatusForShow] = useState(null);
    const [data, setData] = useState(null);
    const [localDate, setLocalDate] = useState(null);
    const {roomName} = useParams();
    const {t} = useTranslation(['video, common']);
    const {register, handleSubmit, formState: {errors}} = useForm();

    let timerCheckAppointmentTime;
    const timerCheckAppointmentTimeValue = 10000;
    let timerCheckProvider;
    const timerCheckProviderValue = 5000;

    useEffect(() => {
        request();
    }, []);

    useEffect(() => {
        if (data) {
            configSchedule(data);
        }
    }, [i18next.language]);

    const request = () => {

        validateData(roomName).then((room) => {
            setData(room);
            configSchedule(room);
        });
    }

    const configSchedule = (room) => {
        let time = new moment.utc(`${room.serviceDate}T${room.time}`).local();
        setLocalDate(time);
        let currentTime = new moment(new Date()).add(30, "minutes").toDate();
        let currentMoment = new moment(currentTime);
        let objectStatusForShow = {
            status: room.status,
            icon: waitingRoomIcon,
            title: "",
            message: "",
            message2: "",
            showButtonAccess: false,
            showTitle: false,
            showResume: false,
            showMessage2: false,
            showPaymentMessage: false,
        };
        if (room.status === serviceStatusEnum.cancelled) {
            clearInterval(timerCheckAppointmentTime);
            objectStatusForShow.icon = appointmentsCancelledIcon;
            objectStatusForShow.title = t('common:appointment-check.status-cancelled');
            objectStatusForShow.message = t('common:appointment-check.cancelled');
            objectStatusForShow.showTitle = true;
        } else {
            if (currentMoment.isAfter(time)) {
                clearInterval(timerCheckAppointmentTime);
                objectStatusForShow.message = t('common:global.scheduled-message');
                objectStatusForShow.showButtonAccess = true;
                objectStatusForShow.showResume = true;
            } else {
                objectStatusForShow.message = t('common:global.scheduled-message-no-session');
                objectStatusForShow.showResume = true;
                checkAppointmentTime(room);
            }
        }
        setStatusForShow(objectStatusForShow)
    }

    const checkAppointmentTime = (room) => {
        timerCheckAppointmentTime = setInterval(() => {
            let time = new moment.utc(`${room.serviceDate}T${room.time}`).local();
            let currentTime = new moment(new Date()).add(30, "minutes");
            if (currentTime.isAfter(time)) {
                configSchedule(room);
                clearInterval(timerCheckAppointmentTime);
            }
        }, timerCheckAppointmentTimeValue);
    }

    const checkAccessProvider = (room) => {
        timerCheckProvider = setInterval(() => {
            validateData(roomName).then((response) => {
                    if (response) {
                        if (response.status === serviceStatusEnum.inProgress && response.isPaid === 1) {
                            clearInterval(timerCheckProvider);
                            history.push({
                                pathname: `/client/conference/${response.idService}/`,
                                state: {
                                    roomName: response.idCompany.idCompany,
                                    user: `${response.idUser.name} ${response.idUser.secondName}`,
                                    communication: (response.idUser.mail) ? response.idUser.mail : response.idUser.idPhone.phoneNumber,
                                    isLegit: true,
                                    isPatient: true
                                },
                            });

                        }
                    }
                }
            );
        }, timerCheckProviderValue);
    }

    const onSubmit = (formData) => {
        console.log("onSubmit_:", formData)
        validateData(roomName).then((response) => {
            if (response) {
                if (response.status === serviceStatusEnum.inProgress && response.isPaid === 1) {
                    console.log('redirect from onSubmit');
                    history.push({
                        pathname: `/client/conference/${response.idService}/`,
                        state: {
                            roomName: response.idCompany.idCompany,
                            user: `${response.idUser.name} ${response.idUser.secondName}`,
                            communication: (response.idUser.mail) ? response.idUser.mail : response.idUser.idPhone.phoneNumber,
                            isLegit: true,
                            isPatient: true
                        },
                    });
                } else {
                    providerCommunication(roomName, response.idUser.idUser, response.idProvider.idProvider, localDate.format('YYYY-MM-DD HH:mm')).then((_room) => {

                        setStatusForShow({
                            status: _room.status,
                            icon: waitingRoomIcon,
                            title: t('common:global.waiting-room'),
                            message: t('common:global.scheduled-message-no-provider'),
                            message2: t('common:global.scheduled-message2-no-provider'),
                            showButtonAccess: false,
                            showTitle: true,
                            showResume: false,
                            showMessage2: true,
                            showPaymentMessage: false,
                        });
                        checkAccessProvider();
                    });
                }
            }
        });
    }

    return (
        <>
            <div className="container scheduledContainer">
                <div className="row d-inline">
                    <div className="col-12 flex justify-content-between align-items-center logo-container">
                        <div className="w-25"></div>
                        <img className="" width={250} alt="Healthy Encounter" src={logo}/>
                        <div className="w-25 text-right"><LanguageSelector room={roomName}/></div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12 align-self-center">

                        <form onSubmit={handleSubmit(onSubmit)} className="">

                            <div className="text-center" style={{maxWidth: 645, margin: "0 auto", display: "block"}}>
                                <div className="wrapper" style={{right: 15}}>
                                    <img className="mb-4" width="150px" src={statusForShow?.icon} alt=""/>
                                </div>
                                {(statusForShow?.showTitle) && <h4 className="mb-3">{statusForShow?.title}</h4>}
                                <p className="">{statusForShow?.message}</p>
                                {(statusForShow?.showResume) &&
                                    <table className="data mt-4" cellPadding="0" cellSpacing="0" border="0"
                                           style={{margin: "0 auto"}}>
                                        <tbody>
                                        <tr>
                                            <td align="left">
                                                <p className="">
                                                    <img src={userLogo}/> &nbsp;
                                                    <span>{data?.idProvider.name} {data?.idProvider.secondName}</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left">
                                                <p>
                                                    <img src={calendarLogo}/> &nbsp;
                                                    <span>{localDate?.format('MM-DD-YYYY')}</span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left">
                                                <p>
                                                    <img src={horaLogo}/> &nbsp;
                                                    <span>{localDate?.format('h:mm a')} {t('common:appointment-confirmation.hours')}</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left">
                                                <p>
                                                    <img src={costoLogo}/> &nbsp;
                                                    <span>${data?.price} {t('common:appointment-confirmation.cost')}</span>
                                                </p>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                }

                                {(statusForShow?.showPaymentMessage) &&
                                    <p className="message">{t('common:appointment-confirmation.payment')}:</p>}


                                {(statusForShow?.showButtonAccess) &&
                                    <>
                                        <div className="form-group form-check mt-4">
                                            <input className="form-check-input" name="acceptTerms"
                                                   type="checkbox" {...register("acceptTerms", {required: true})}
                                                   id="acceptTerms"/>
                                            <label className="form-check-label">
                                                {t('common:create-provider.terms')}
                                                <a href={(i18next.language == "es") ? `https://www.telemedik.com/index.php/terminos-y-condiciones-de-uso/` : `https://www.telemedik.com/index.php/en/terms-and-conditions-of-use/`}
                                                   target="_blank">{t('common:create-provider.terms-conditions')}</a>&nbsp;
                                                <a href={(i18next.language == "es") ? `https://www.telemedik.com/index.php/politica-de-privacidad-2/` : `https://www.telemedik.com/index.php/en/politica-de-privacidad/`}
                                                   target="_blank">{t('common:create-provider.privacy-policies')}</a>
                                            </label>
                                            {errors.acceptTerms &&
                                                <span
                                                    className="error ml-2 d-block">{t('common:global.required')}</span>}
                                        </div>

                                        <button type="submit" className="btn btn-primary">
                                            {t("common:appointment-confirmation.access")}
                                        </button>
                                    </>
                                }

                                <p className="font-weight-bold mt-2">{statusForShow?.message2}</p>

                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </>
    );
}
