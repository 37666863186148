import React, {useContext, useState} from 'react';
import {loaderContext} from "../../../EHApp";
import {useTranslation} from "react-i18next";
import {createStripeAccount} from "../../../services/stripe.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../../../utilities/auth/AuthContext";
import {authLogin} from "../../../services/auth.service";
import {useLocation} from "react-router-dom";
import {authTypes} from "../../../utilities/types/types";


const CreateConnectButton = (props) => {
    const {t} = useTranslation(["profile", "common"]);
    const [processLoading, setProcessLoading] = useState(false);
    const {user: {user}, dispatch} = useContext(AuthContext);
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const location = useLocation();

    const handleCreateStripeAccount = async () => {
        const idProvider = props.idProvider ? props.idProvider : user.idProvider;
        setProcessLoading(true);
        if (props.userLogin) {
            const response = await authLogin(true, location.state.username, location.state.identification);
            dispatch({
                type: authTypes.login,
                payload: {
                    user: response.user,
                    token: response.token,
                    company: location.state.company
                }
            });
        }

        const session = await createStripeAccount(idProvider);
        window.open(session.url, '_self', "Popup",);
        setLoader(false);
    }

    return (
        <button disabled={processLoading}  {...props} onClick={handleCreateStripeAccount}
                className='btn btn-outline-primary btn-sm btn-create-account'>
            {processLoading ? <FontAwesomeIcon icon={faSpinner}
                                               className="spinner-button spinner-button-create-account"/> : t("profile:Stripe.RegisterOnboarding")}
        </button>
    );
}

export default CreateConnectButton;
