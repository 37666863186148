import React from "react";
import UserAvatar from "../common/UserAvatar";

const AppointmentItemDoctor = ({doctor}) => {

    return (
        <div className="d-flex">
            <UserAvatar className="user-avatar" alt="Foto doctor" profile={doctor?.idDetail.profile}/>
            <div>
                <p className="doctor-name pl-1">{doctor?.name} {doctor?.secondName}</p>
                <p className="doctor-email pl-1">{doctor?.mail}</p>
            </div>
        </div>
    );
}

export default AppointmentItemDoctor;
