export const authTypes = {
    login: '[auth] login',
    company: '[auth] company',
    license: '[auth] license',
    logout: '[auth] logout',
    theme: '[auth] theme',
    profile: '[auth] profile'
}

export const statusAccountTypes = {
    commercial: '[status] commercial',
    subscription: '[status] subscription',
    products: '[status] products',
}

export const methodTypes = {
    POST: "POST",
    GET: "GET"
}

export const kTypes = {
    privacy: "privacy"
}

export const consultationTypes = {
    FREE: "FREE"
}
