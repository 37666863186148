import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ListaCitasProgramadas from "../../components/ListaCitasProgramadas/ListaCitasProgramadas";
import {AuthContext} from "../../utilities/auth/AuthContext";
import ServiceCreate from "../Modal/ServiceCreate";
import {getTodayPendentCount} from "../../services/service.service";
import {Button, ModalBody, ModalFooter} from "reactstrap";
import {getStripeConnectedAccount} from "../../services/stripe.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProviderAlerts from "../../components/CommercialAccount/Alerts/ProviderAlerts";

const CitasProgramadasPage = (props) => {
    const {t} = useTranslation('appointments');
    const [appointments, setAppointments] = useState(0);
    const [activeTab, setActiveTab] = useState('list');
    const [loading] = useState(false);
    const [reloadAppointments, setReloadAppointments] = useState(null);
    const {user: {user}} = useContext(AuthContext);
    const license = useContext(AuthContext).user.license;
    const [disableNewAppointmentButton, setDisableNewAppointmentButton] = useState(true);

    const selectTab = (e, option) => {
        e.preventDefault();
        setActiveTab(option);
    }

    const isRegister = useCallback((id) => {
        if (user.idRole.name === "ADMIN" && license) {
            getStripeConnectedAccount(id).then((stripeAccount) => {
                if (stripeAccount.isStripeRegister === 0 || stripeAccount.isStripeRegister == null) {

                    props.modal.current.setModal(<>
                        <ModalBody>
                            <h5>{t('scheduled-appointments.nostripetitle')}</h5>
                            <p>{t('scheduled-appointments.nostripebody')}</p>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary"
                                    onClick={() => props.modal.current.openModal()}>{t('scheduled-appointments.accept')}</Button>
                        </ModalFooter>
                    </>)
                }
            });
        }
    }, []);

    useEffect(() => {
        isRegister(user.idProvider);
        setDisableNewAppointmentButton(!license);
    }, []);

    React.useEffect(() => {
        getTodayPendentCount(user.idCompany.idCompany).then((response) => {
            setAppointments(response.count);
        });
    }, [reloadAppointments]);

    return (
        <div className="pb-3 px-3" style={{overflowX: "hidden", overflowY: "auto", height: "100%"}}>
            <ProviderAlerts className="mt-3"/>
            <div className="page-header row justify-content-between" >
                <div className="col-sm-12 col-md-9">
                    <h1 className="page-title ">{t('scheduled-appointments.title')}</h1>
                    <p>{t('scheduled-appointments.subtitle', {data: appointments})}</p>
                </div>

                <div className="col-sm-12 col-md-3 text-md-right">
                    <button className="btn btn-primary btn-nueva-cita"
                            onClick={() => {
                                props.modal.current.setModal(<ServiceCreate {...props}
                                                                            idCompany={user.idCompany.idCompany}
                                                                            onCreate={() => {
                                                                                setTimeout(() => {
                                                                                    setReloadAppointments(Date.now());
                                                                                }, 500);

                                                                            }}/>)
                            }}
                            disabled={disableNewAppointmentButton}>
                        {loading ?
                            <FontAwesomeIcon icon={faSpinner} className="spinner-button "/>
                            :
                            t('scheduled-appointments.newAppointment')
                        }
                    </button>
                </div>
            </div>

            <div className="d-inline-block mt-1 mt-md-2">
                <div className="mb-md-1 mb-sm-1">
                    <a onClick={(e) => selectTab(e, 'list')}
                       className={activeTab === 'list' ? "mr-3 custom-tab active" : "mr-3 custom-tab"}>
                        <i className="fa fa-list-ul pr-2"/>
                        {t('scheduled-appointments.tabViewList')}
                    </a>
                    {/*
                    <a href="/admin/calendar" onClick={(e)=>selectTab(e,'calendar')} className={activeTab === 'calendar' ? "custom-tab active":"custom-tab "}>
                        <i className="far fa-calendar pr-2"></i>
                        Ver calendario
                    </a>
                    */}
                </div>
            </div>

            {activeTab === 'list' ?
                <ListaCitasProgramadas {...props} setReloadAppointments={setReloadAppointments}
                                       reloadAppointments={reloadAppointments}/> :
                <h1>{t('scheduled-appointments.calendar')}</h1>}
        </div>
    );
}

export default CitasProgramadasPage;
