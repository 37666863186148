import React from "react";
import {Route, Switch} from "react-router-dom";
import {AuthRoute} from "routes.js";
import "react-notification-alert/dist/animate.css";
import OutPage from "../views/OutPage";

function AuthRoutes() {
    const mainPanel = React.useRef();

    return (
        <>
            <div ref={mainPanel}>
                <Switch>
                    {AuthRoute.map((prop, key) => {
                        return (
                            <Route
                                path={prop.layout + prop.path}
                                component={prop.component}
                                key={key}/>
                        );
                    })}

                    <Route path={"*"} component={OutPage}/>
                </Switch>
            </div>
        </>
    );
}

export default AuthRoutes;