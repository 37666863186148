import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {localhost, serviceStatusEnum} from "../../utilities";
import {checkoutUser, verifyService} from "../../services/stripe.service";
import logo from "../../assets/images/logo-beta.svg";
import appointmentsFinishedIcon from "../../assets/images/icons/icon-finished.svg";
import appointmentsExpiredIcon from "../../assets/images/icons/icon-expired.svg";
import appointmentsCancelledIcon from "../../assets/images/icons/icon-cancelled.svg";
import LanguageSelector from "../../components/TopNavBar/LanguageSelector";
import i18next from "i18next";
import "../../assets/scss/views/clients.scss";

export default function ClientCheckOut(_props) {
    const {t} = useTranslation("common");
    const {id} = useParams();
    const [statusForShow, setStatusForShow] = useState(null);
    let parameters = id.split(",");
    let data = {
        "idUser": parameters[0],
        "mail": parameters[1],
        "idProvider": parameters[2],
        "idServ": parameters[3]
    };
    let pay = async (params) => {
        verifyService(data.idServ).then((res) => {
            console.log(res);
            switch (res.status) {
                case serviceStatusEnum.pending:
                    window.open(localhost + "/client/scheduled/" + data.idServ, '_self');
                    break;
                case serviceStatusEnum.inProgress:
                    window.open(localhost + "/client/scheduled/" + data.idServ, '_self');
                    break;
                case serviceStatusEnum.pendingPayment:
                    if (res.idProviderProduct == null) {
                        window.open(localhost + "/client/scheduled/" + data.idServ, '_self');
                        break;
                    }
                    checkoutUser(params).then((response) => {
                        executePayment(response.id, response.url);
                    })
                    break;
                case serviceStatusEnum.finished:
                    setStatusForShow({
                        status: serviceStatusEnum.finished,
                        icon: appointmentsFinishedIcon,
                        title: t('common:appointment-check.status-finished'),
                        message: t('appointment-check.finished'),
                        message2: t('appointment-check.finished-2'),
                    })
                    break;
                case serviceStatusEnum.cancelled:
                    setStatusForShow({
                        status: serviceStatusEnum.cancelled,
                        icon: appointmentsCancelledIcon,
                        title: t('common:appointment-check.status-cancelled'),
                        message: t('appointment-check.cancelled'),
                        message2: t('appointment-check.cancelled-2'),
                    })
                    break;
                case serviceStatusEnum.expired:
                    setStatusForShow({
                        status: serviceStatusEnum.expired,
                        icon: appointmentsExpiredIcon,
                        title: t('common:appointment-check.status-expired'),
                        message: t('appointment-check.expired'),
                        message2: t('appointment-check.expired-2'),
                    })
                    break;
                default:
                    setStatusForShow(res.status)
                    break;
            }
        });
    }

    let executePayment = async (_id, url) => {
        window.open(url, '_self');
    }

    useEffect(() => {
        if (data) {
            pay(data);
        }
    }, [i18next.language]);

    useEffect(() => {
        pay(data);
    }, []);

    return (
        <div className="container overflow-hidden h-100">
            <div className="mt-3 row d-flex justify-content-between align-items-center">
                <div className="w-25"></div>
                <div className="w-25 text-center"><LanguageSelector/></div>
            </div>
            <div className="d-flex justify-content-center mt-1">
                <img className="" width={250} alt="Logo Healthy Encounter" src={logo}/>
            </div>


            {statusForShow === null
                ?
                <div className="checkoutContainer">
                    <div className="align-self-center">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faSpinner} className="spinner spinner-status-client"/>
                            <p className="mt-4">{t('global.verify')}</p>
                        </div>
                    </div>
                </div>

                :

                <div className="checkoutContainer">
                    <div className="align-self-center text-center">
                        <img className="mb-3" width="135px" src={statusForShow?.icon} alt=""/>
                        <h3 className="mt-4">{statusForShow?.title}</h3>
                        <p className="mt-4">{statusForShow?.message}</p>
                        {statusForShow?.message2 && <p className="mt-4">{statusForShow?.message2}</p>}
                    </div>
                </div>
            }

        </div>

    );
}
