import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useFieldArray, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {getDetail, updateExperience} from "../../services/provider.service";
import {Accordion} from "react-bootstrap";
import moment from "moment";
import {noSpacesOnly} from "../../utilities";

let isValid = true;
const CertificationList = forwardRef((props, ref) => {

    const {t} = useTranslation('common');
    const toggleModal = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const {
        control,
        register,
        formState: {errors},
        handleSubmit,
        watch: watchFieldArray
    } = useForm({
        mode: "onChange"
    });

    const {
        fields: certificationField,
        append: certificationAppend,
        remove: certificationRemove
    } = useFieldArray({control, name: "certification"});

    const {
        register: modalRegister,
        handleSubmit: modalHandleSubmit,
        setValue: modalSetValue,
        watch,
        reset,
        formState: {errors: modalError}
    } = useForm();
    const onSubmitModal = data => {
        if (watch("certified"))
            data['endDate'] = ""

        data['edit'] = true;
        certificationAppend(data);
        setCertificationEdit(false);
        toggleModal();
        reset({});
        reference.current.click();
    };

    const [certificationEdit, setCertificationEdit] = useState(true);
    const reference = useRef(null);

    useImperativeHandle(ref, () => ({
        externalSubmit: async () => {
            if (certificationField.length > 0)
                reference.current.click();
        },
        checkValid: () => {
            console.log(isValid)
            return isValid;
        }
    }));

    const onSubmit = (data) => {
        for (const [, value] of Object.entries(data.certification)) {
            delete value['edit']
            if (value['certified'])
                value['endDate'] = ""
        }

        setCertificationEdit(true);
        updateExperience({
            certifications: JSON.stringify(data.certification),
            idProvider: (props.user) ? props.user.idProvider : props.idProvider
        }).then(() => {
            console.log("saved")
            return true
        }).catch((error) => {
            console.log(error.message)
            return false
        });
    }

    useEffect(() => {
        if (props.user) {
            getDetail(props.user.idDetail.idDetail).then(response => {
                let temp = JSON.parse(response.certifications);
                for (let element of temp)
                    certificationAppend(element)
            }).catch(error => {
                console.error(error);
            })
        } else
            setCertificationEdit(false);
    }, []);

    return (
        <>
            <h5 style={{position: "absolute", right: "60px", top: "10px"}}>
                {props.user && certificationField.length > 0 &&
                <FontAwesomeIcon onClick={() => {
                    setCertificationEdit(!certificationEdit)
                }} className="edit-icon" icon={faPencilAlt}/>
                }
            </h5>

            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} size="lg" backdrop={"static"}>
                <ModalHeader toggle={toggleModal}>
                    {t('account-experience.add')} {t('account-experience.certification')}
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={modalHandleSubmit(onSubmitModal)}>
                        <div className="row mt-4">
                            <div className="col-12 col-md-6">
                                <FormGroup>
                                    <Label for="name" className="">{t('account-experience.name')}</Label>
                                    <input className="form-control-sm form-control"
                                           {...modalRegister(`name`, {
                                               required: true, maxLength: 255,
                                               pattern: noSpacesOnly

                                           })}
                                           placeholder={t('account-experience.name')}
                                           defaultValue=""/>
                                    {errors.name &&
                                    <span className="error ml-2">{errors.name.message}</span>}
                                    {modalError.name && modalError.name.type === "required" &&
                                    <span className="error">{t('global.required')}</span>}
                                    {modalError.name && modalError.name.type === "maxLength" &&
                                    <span className="error">{t('global.max-length')}</span>}
                                    {modalError.name && modalError.name.type === "pattern" &&
                                    <span className="error">{t('global.notOnlySpaces')}</span>}
                                </FormGroup>
                            </div>
                            <div className="col-12 col-md-6">
                                <FormGroup>
                                    <Label for="name" className="">{t('account-experience.institution')}</Label>
                                    <input className="form-control-sm form-control"
                                           {...modalRegister(`institution`, {
                                               required: true, maxLength: 255, pattern: noSpacesOnly
                                           })}
                                           placeholder={t('account-experience.institution')}
                                           defaultValue=""/>
                                    {modalError.institution && modalError.institution.type === "required" &&
                                    <span className="error">{t('global.required')}</span>}
                                    {modalError.institution && modalError.institution.type === "maxLength" &&
                                    <span className="error">{t('global.max-length')}</span>}
                                    {modalError.institution && modalError.institution.type === "pattern" &&
                                    <span className="error">{t('global.notOnlySpaces')}</span>}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                <FormGroup>
                                    <Label className="">{t('account-experience.emition-date')}</Label>
                                    <input type="date" className="form-control-sm form-control"
                                           {...modalRegister(`initialDate`, {
                                               required: true
                                           })}
                                           max={moment(watch("endDate")).format('YYYY-MM-DD')}
                                           defaultValue=""/>
                                    {modalError.initialDate && <span className="error">{t('global.required')}</span>}
                                </FormGroup>
                            </div>
                            <div className={(watch('certified')) ? "d-none" : "col-12 col-sm-6 col-md-6 col-lg-4"}>
                                <FormGroup>
                                    <Label className="">{t('account-experience.end-date')}</Label>

                                    <input type="date" className="form-control-sm form-control"
                                           {...modalRegister(`endDate`, {
                                               required: watch('certified') ? false : true
                                           })}
                                           min={moment(watch("initialDate")).format('YYYY-MM-DD')}
                                           defaultValue=""/>
                                    {modalError.endDate && <span className="error">{t('global.required')}</span>}
                                </FormGroup>
                            </div>
                            <div className="col-12 col-md-12 mt-0 mt-lg-4 col-lg-4">
                                <input
                                    {...modalRegister(`certified`, {
                                        required: false
                                    })}
                                    type="checkbox"
                                    defaultValue={false}/>
                                {modalError.certified && <p>{t('global.required')}</p>}
                                <label className="form-label-dark ml-2">{t('account-experience.expires')}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <FormGroup className="">
                                    <Label for="name" className="">{t('account-experience.description')}</Label>
                                    <input className="form-control-sm form-control"
                                           {...modalRegister(`description`, {
                                               required: false, maxLength: 255
                                           })}
                                           placeholder="--"
                                           defaultValue=""/>
                                    {modalError.description && modalError.description.type === "required" &&
                                    <span className="error">{t('global.required')}</span>}
                                    {modalError.description && modalError.description.type === "maxLength" &&
                                    <span className="error">{t('global.max-length')}</span>}
                                </FormGroup>
                            </div>
                        </div>

                        <ModalFooter>
                            <button type="submit"
                                    className="btn btn-primary"> {t('account-experience.submit')}  </button>
                        </ModalFooter>

                    </form>
                </ModalBody>
            </Modal>

            <form style={{marginTop: "4em"}} onSubmit={handleSubmit((data, _event) => {
                isValid = true;
                if (props.submitData) {
                    onSubmit(data)
                } else if (props.user) onSubmit(data)
            }, () => {
                if (!props.user && certificationField.length > 0) isValid = false
            })}>
                {certificationField.map((item, index) => (
                    <div className="card mb-3" key={item.id}>
                        <div className="card-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey={"0"}>
                                    <Accordion.Header>
                                        <div>
                                            <div
                                                className="d-flex flex-row justify-content-between  align-items-center mb-4">
                                                <h6 className="text-left"> {watchFieldArray(`certification[${index}].name`)}</h6>
                                                {!certificationEdit &&
                                                <FontAwesomeIcon className="remove-item-list ml-5 mr-3"
                                                                 onClick={() => {
                                                                     if (!certificationEdit) {
                                                                         certificationRemove(index);
                                                                         if (certificationField.length === 1)
                                                                             reference.current.click();

                                                                         setCertificationEdit(false);
                                                                     }
                                                                 }} icon={faTrash}/>
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="position-relative">
                                            <div className="row mt-4">

                                                <div className="col-12 col-md-6">
                                                    <FormGroup>
                                                        <Label for="name"
                                                               className="">{t('account-experience.name')}</Label>
                                                        {(certificationEdit) ?
                                                            <label
                                                                className="form-control-sm form-control-plaintext">{watchFieldArray(`certification[${index}].name`)}</label>
                                                            :
                                                            <input
                                                                className={(certificationEdit) ? "form-control-sm form-control-plaintext" : "form-control-sm form-control"}
                                                                {...register(`certification[${index}].name`, {
                                                                    required: true,
                                                                    maxLength: 255,
                                                                    pattern: noSpacesOnly
                                                                })}
                                                                control={control}
                                                                disabled={certificationEdit}
                                                                minLength={2}
                                                                placeholder={t('account-experience.name')}
                                                                defaultValue=""/>}
                                                        {errors.certification?.[index]?.name && errors.certification?.[index]?.name.type === "required" &&
                                                        <span className="error">{t('global.required')}</span>}
                                                        {errors.certification?.[index]?.name && errors.certification?.[index]?.name.type === "maxLength" &&
                                                        <span className="error">{t('global.max-length')}</span>}
                                                        {errors.certification?.[index]?.name && errors.certification?.[index]?.name.type === "minLength" &&
                                                        <span
                                                            className="error">{t('global.min-length-text')}</span>}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <FormGroup>
                                                        <Label for="name"
                                                               className="">{t('account-experience.institution')}</Label>
                                                        {(certificationEdit) ?
                                                            <label
                                                                className="form-control-sm form-control-plaintext">{watchFieldArray(`certification[${index}].institution`)}</label>
                                                            :
                                                            <input
                                                                className={(certificationEdit) ? "form-control-sm form-control-plaintext" : "form-control-sm form-control"}
                                                                {...register(`certification[${index}].institution`, {
                                                                    required: true,
                                                                    maxLength: 255,
                                                                    pattern: noSpacesOnly
                                                                })}
                                                                control={control}
                                                                disabled={certificationEdit}
                                                                placeholder={t('account-experience.institution')}
                                                                defaultValue=""/>}
                                                        {errors.certification?.[index]?.institution && errors.certification?.[index]?.institution.type === "required" &&
                                                        <span className="error">{t('global.required')}</span>}
                                                        {errors.certification?.[index]?.institution && errors.certification?.[index]?.institution.type === "maxLength" &&
                                                        <span className="error">{t('global.max-length')}</span>}
                                                        {errors.certification?.[index]?.institution && errors.certification?.[index]?.institution.type === "pattern" &&
                                                        <span className="error">{t('global.notOnlySpaces')}</span>}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <FormGroup>
                                                        <Label
                                                            className="">{t('account-experience.emition-date')}</Label>
                                                        <input type="date"
                                                               className={(certificationEdit) ? "conceal-input" : "form-control-sm form-control"}
                                                               {...register(`certification[${index}].initialDate`, {
                                                                   required: true
                                                               })}
                                                               control={control}
                                                               max={moment(watchFieldArray(`certification[${index}].endDate`)).format('YYYY-MM-DD')}
                                                               disabled={certificationEdit}
                                                               defaultValue=""/>
                                                        {errors.certification?.[index]?.initialDate &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className={(watchFieldArray(`certification[${index}].certified`)) ? "d-none" : "col-12 col-sm-6 col-md-6 col-lg-6"}>
                                                    <FormGroup>
                                                        <Label className="">{t('account-experience.end-date')}</Label>

                                                        <input type="date"
                                                               className={(certificationEdit) ? "conceal-input" : "form-control-sm form-control"}
                                                               {...register(`certification[${index}].endDate`, {
                                                                   required: watchFieldArray(`certification[${index}].certified`) ? false : true
                                                               })}
                                                               control={control}
                                                               min={moment(watchFieldArray(`certification[${index}].initialDate`)).format('YYYY-MM-DD')}
                                                               disabled={certificationEdit}
                                                               defaultValue=""/>
                                                        {errors.certification?.[index]?.endDate &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </FormGroup>
                                                </div>
                                                {watchFieldArray(`certification[${index}].endDate`) === "" ?
                                                    <div
                                                        className={certificationEdit ? "col-12 col-md-12 mt-0 mt-lg-0 col-lg-6" : "col-12 col-md-12 mt-0 mt-lg-4 col-lg-6"}>

                                                        <div className={certificationEdit ? "mt-0" : "mt-2"}>
                                                            <input
                                                                {...register(`certification[${index}].certified`, {
                                                                    required: false
                                                                })}
                                                                type="checkbox"
                                                                disabled={certificationEdit}
                                                                className={(certificationEdit) ? "conceal-input" : ""}
                                                                control={control}
                                                                defaultValue={false}/>
                                                            {errors.certification?.[index]?.certified &&
                                                            <p>{t('global.required')}</p>}
                                                            <label
                                                                className="form-label-dark ml-2">{t('account-experience.expires')}</label>
                                                        </div>

                                                    </div> : <></>}
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">

                                                    <FormGroup className="">
                                                        <Label for="name"
                                                               className="">{t('account-experience.description')}</Label>
                                                        <textarea rows="5" style={{minHeight: "100px"}}
                                                                  className={(certificationEdit) ? "form-control-sm form-control-plaintext" : "form-control-sm form-control"}
                                                                  {...register(`certification[${index}].description`, {
                                                                      required: false, maxLength: 255
                                                                  })}
                                                                  disabled={certificationEdit}
                                                                  control={control}
                                                                  placeholder={(certificationEdit) ? "--" : t('account-experience.description')}
                                                                  defaultValue=""/>
                                                        {errors.certification?.[index]?.description && errors.certification?.[index]?.description.type === "required" &&
                                                        <span className="error">{t('global.required')}</span>}
                                                        {errors.certification?.[index]?.description && errors.certification?.[index]?.description.type === "maxLength" &&
                                                        <span className="error">{t('global.max-length')}</span>}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                ))}

                {certificationField.length === 0 ? <div>{t("global.no-data")}</div> :
                    <button className={(props.user && !certificationEdit) ? "btn btn-primary mb-3" : "d-none"}
                            ref={reference}
                            disabled={certificationEdit} type="submit">
                        {t("global.save")}</button>
                }
            </form>

            <div className="add-item-list" onClick={() => {
                setModal(!modal);
            }}>
                <FontAwesomeIcon icon={faPlus}/>
            </div>
        </>
    );
});

export default CertificationList;
