import React from "react";
import moment from "moment";

const AppointmentItemHour = ({date ,hour}) => {
    let localDate = moment.utc(`${date} ${hour}`,'YYYY-MM-DD HH:mm').local();
    return (
       <>{localDate.format("hh:mm A")}</>
    );
}

export default AppointmentItemHour;
