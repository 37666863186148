import React from 'react';
import {NavbarBrand} from "reactstrap";
import CompanyLogo from "../components/common/CompanyLogo";
import FooterAuth from "../components/common/FooterAuth";
import {Step, Stepper} from "react-mui-stepper";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../components/TopNavBar/LanguageSelector";

function AuthLayout({
                        className = '',
                        activeStep,
                        children,
                        showLanguageSelector = false,
                        isProvider = false,
                        forceUpdate
                    }) {

    const {t} = useTranslation('common');

    return (
        <>
            <div className={`d-flex flex-column vh-100 auth-layout-container ${className}`}>
                <div className="nav flex-grow-0 justify-content-between mr-1 ml-2 mt-2">
                    <NavbarBrand href="/">
                        <CompanyLogo className="logo" alt="healthy-encounter-logo"/>
                    </NavbarBrand>
                    <div className="mt-3 mr-4">
                        {showLanguageSelector === true && <LanguageSelector forceUpdate={forceUpdate}/>}
                    </div>

                </div>
                {activeStep != null ?
                    (isProvider === false) ?
                        <Stepper withNumbers activeStep={activeStep}>
                            <Step>{t("register.validate-identifier")}</Step>
                            <Step>{t("register.profile")}</Step>
                            <Step>{t("register.license-type")}</Step>
                            <Step>{t("register.payment-confirm")}</Step>
                            <Step>{t("register.account-configuration")}</Step>
                            <Step>{t("register.professional-profile")}</Step>
                            <Step>{t("register.branding")}</Step>
                        </Stepper>
                        :
                        <Stepper withNumbers activeStep={activeStep - 4}>
                            <Step>{t("register.profile")}</Step>
                            <Step>{t("register.professional-profile")}</Step>
                        </Stepper>
                    : <></>
                }
                <div className="auth-layout-wrapper flex-grow-1">
                    {children}
                </div>

                <FooterAuth/>
            </div>

        </>

    );
}

export default AuthLayout;
