import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useFieldArray, useForm} from "react-hook-form";
import {getDetail, updateExperience} from "../../services/provider.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Accordion} from "react-bootstrap";
import moment from "moment";
import {noSpacesOnly} from "../../utilities";

let isValid = true;
const AchievementList = forwardRef((props, ref) => {
    const {t} = useTranslation('common');
    const [educationEdit, setEducationEdit] = useState(true);
    const toggleModal = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const reference = useRef(null);

    const {
        control,
        register,
        formState: {errors},
        handleSubmit,
        watch: watchFieldArray
    } = useForm({
        defaultValues: {},
        mode: "onChange"
    });

    const {
        register: modalRegister,
        handleSubmit: modalHandleSubmit,
        watch,
        reset,
        formState: {errors: modalError}
    } = useForm();

    const onSubmitModal = data => {
        if (watch("current"))
            data['endDate'] = ""

        data['edit'] = true;
        educationAppend(data);
        setEducationEdit(false);
        toggleModal();
        reset({});
        reference.current.click();
    };

    const {
        fields: educationField,
        append: educationAppend,
        remove: educationRemove

    } = useFieldArray({control, name: "education"});

    useImperativeHandle(ref, () => ({
        externalSubmit: async () => {
            if (educationField.length > 0)
                reference.current.click();
        },
        checkValid: () => {
            console.log(isValid)
            return isValid;
        }
    }));

    const onSubmit = (data) => {
        for (const [, value] of Object.entries(data.education)) {
            delete value['edit']
            if (value['certified'])
                value['endDate'] = ""
        }

        setEducationEdit(true)

        updateExperience({
            education: JSON.stringify(data.education),
            idProvider: (props.user) ? props.user.idProvider : props.idProvider
        }).then(() => {
            console.log("saved");
            return true
        }).catch((error) => {
            console.log(error.message);
            return false
        });
    }

    useEffect(() => {
        if (props.user) {
            getDetail(props.user.idDetail.idDetail).then(response => {
                let temp = JSON.parse(response.education);
                for (const element of temp)
                    educationAppend(element)
            }).catch(error => {
                console.log(error);
            })
        } else
            setEducationEdit(false);
    }, []);

    return (
        <>
            <h5 style={{position: "absolute", right: "60px", top: "10px"}}>
                {props.user && educationField.length > 0 &&
                <FontAwesomeIcon onClick={() => {
                    setEducationEdit(!educationEdit)
                }} className="edit-icon" icon={faPencilAlt}/>
                }
            </h5>

            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} size="lg" backdrop={"static"}>

                <ModalHeader toggle={toggleModal}>
                    {t('account-experience.add')} {t('account-experience.formation')}
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={modalHandleSubmit(onSubmitModal)}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <FormGroup>
                                    <Label for="name" className="">{t('account-experience.grade')}</Label>
                                    <input className="form-control-sm form-control"
                                           {...modalRegister(`grade`, {
                                               required: true, maxLength: 255, pattern: noSpacesOnly
                                           })}
                                           minLength={2}
                                           placeholder={t('account-experience.grade')}
                                           defaultValue=""/>
                                    {modalError.grade && modalError.grade.type === "required" &&
                                    <span className="error">{t('global.required')}</span>}
                                    {modalError.grade && modalError.grade.type === "maxLength" &&
                                    <span className="error">{t('global.max-length')}</span>}
                                    {modalError.grade && modalError.grade.type === "pattern" &&
                                    <span className="error">{t('global.notOnlySpaces')}</span>}
                                    {modalError.grade && modalError.grade.type === "minLength" &&
                                    <span className="error">{t('global.min-length-text')}</span>}
                                </FormGroup>
                            </div>
                            <div className="col-12 col-md-6">
                                <FormGroup>
                                    <Label for="name" className="">{t('account-experience.institution')}</Label>
                                    <input className="form-control-sm form-control"
                                           {...modalRegister(`institution`, {
                                               required: true, maxLength: 255, pattern: noSpacesOnly
                                           })}
                                           minLength={2}
                                           placeholder={t('account-experience.institution')}
                                           defaultValue=""/>
                                    {modalError.institution && modalError.institution.type === "required" &&
                                    <span className="error">{t('global.required')}</span>}
                                    {modalError.institution && modalError.institution.type === "maxLength" &&
                                    <span className="error">{t('global.max-length')}</span>}
                                    {modalError.institution && modalError.institution.type === "pattern" &&
                                    <span className="error">{t('global.notOnlySpaces')}</span>}
                                    {modalError.institution && modalError.institution.type === "minLength" &&
                                    <span className="error">{t('global.min-length-text')}</span>}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <FormGroup>
                                    <Label for="name" className="">{t('account-experience.initial-date')}</Label>
                                    <input type="date" className="form-control-sm form-control"
                                           {...modalRegister(`initialDate`, {
                                               required: true
                                           })}
                                           max={moment(watch("endDate")).format('YYYY-MM-DD')}
                                    />
                                    {modalError.initialDate && <span className="error">{t('global.required')}</span>}
                                </FormGroup>
                            </div>
                            <div className={(watch('current')) ? "d-none" : "col-12 col-sm-6 col-md-6 col-lg-6"}>
                                <FormGroup>
                                    <Label for="name" className="">{t('account-experience.finalize-date')}</Label>
                                    <input type="date" className="form-control-sm form-control"
                                           {...modalRegister(`endDate`, {
                                               required: (watch("current") ? false : true)
                                           })}
                                           min={moment(watch("initialDate")).format('YYYY-MM-DD')}
                                           defaultValue=""/>
                                    {modalError.endDate && <span className="error">{t('global.required')}</span>}
                                </FormGroup>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <input
                                    {...modalRegister(`current`, {
                                        required: false
                                    })}
                                    type="checkbox"/>
                                {modalError.current && <span className="error">{t('global.required')}</span>}
                                <label
                                    className="form-label-dark ml-2 mt-0 mt-lg-4">{t('account-experience.currently-coursing')}</label>
                            </div>
                        </div>

                        <ModalFooter>
                            <button type="submit"
                                    className="btn btn-primary"> {t('account-experience.submit')} </button>
                        </ModalFooter>

                    </form>
                </ModalBody>
            </Modal>

            <form style={{marginTop: "4em"}} onSubmit={handleSubmit((data, _event) => {
                isValid = true;
                if (props.submitData) {
                    onSubmit(data)
                } else if (props.user) onSubmit(data)
            }, () => {
                if (!props.user && educationField.length > 0) isValid = false
            })}>
                {educationField.map((item, index) => (
                    <div className="card mb-3" key={item.id}>
                        <div className="card-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey={"0"}>
                                    <Accordion.Header>
                                        <div>
                                            <div
                                                className="d-flex flex-row justify-content-between  align-items-center mb-4">
                                                <h6 className="text-left"> {watchFieldArray(`education[${index}].institution`)}</h6>

                                                {!educationEdit &&
                                                <FontAwesomeIcon className="remove-item-list ml-5 mr-3"
                                                                 onClick={() => {
                                                                     if (!educationEdit) {
                                                                         educationRemove(index);
                                                                         if (educationField.length === 1)
                                                                             reference.current.click();

                                                                         setEducationEdit(false);
                                                                     }
                                                                 }} icon={faTrash}/>
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="position-relative mt-3">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <FormGroup>
                                                        <Label for="name"
                                                               className="">{t('account-experience.grade')}:</Label>
                                                        {(educationEdit) ?
                                                            <label
                                                                className="form-control-sm form-control-plaintext">{watchFieldArray(`education[${index}].grade`)}</label>
                                                            :
                                                            <input
                                                                className={(educationEdit) ? "form-control-sm form-control-plaintext" : "form-control-sm form-control"}
                                                                {...register(`education[${index}].grade`, {
                                                                    required: true,
                                                                    maxLength: 255,
                                                                    pattern: noSpacesOnly
                                                                })}
                                                                control={control}
                                                                disabled={educationEdit}
                                                                minLength={2}
                                                                placeholder={t('account-experience.grade')}
                                                                defaultValue=""/>}
                                                        {errors.education?.[index]?.grade && errors.education?.[index]?.grade.type === "required" &&
                                                        <span className="error">{t('global.required')}</span>}
                                                        {errors.education?.[index]?.grade && errors.education?.[index]?.grade.type === "maxLength" &&
                                                        <span className="error">{t('global.max-length')}</span>}
                                                        {errors.education?.[index]?.grade && errors.education?.[index]?.grade.type === "pattern" &&
                                                        <span className="error">{t('global.notOnlySpaces')}</span>}
                                                        {errors.education?.[index]?.grade && errors.education?.[index]?.grade.type === "minLength" &&
                                                        <span
                                                            className="error">{t('global.min-length-text')}</span>}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <FormGroup>
                                                        <Label for="name"
                                                               className="">{t('account-experience.institution')}:</Label>
                                                        {(educationEdit) ?
                                                            <label
                                                                className="form-control-sm form-control-plaintext">{watchFieldArray(`education[${index}].institution`)}</label>
                                                            :
                                                            <input
                                                                className="form-control-sm form-control"
                                                                {...register(`education[${index}].institution`, {
                                                                    required: true,
                                                                    maxLength: 255,
                                                                    pattern: noSpacesOnly
                                                                })}
                                                                control={control}
                                                                placeholder={t('account-experience.institution')}
                                                                disabled={educationEdit}
                                                                minLength={2}
                                                                defaultValue=""/>}

                                                        {errors.education?.[index]?.institution && errors.education?.[index]?.institution.type === "required" &&
                                                        <span className="error">{t('global.required')}</span>}
                                                        {errors.education?.[index]?.institution && errors.education?.[index]?.institution.type === "maxLength" &&
                                                        <span className="error">{t('global.max-length')}</span>}
                                                        {errors.education?.[index]?.institution && errors.education?.[index]?.institution.type === "pattern" &&
                                                        <span className="error">{t('global.notOnlySpaces')}</span>}
                                                        {errors.education?.[index]?.institution && errors.education?.[index]?.institution.type === "minLength" &&
                                                        <span
                                                            className="error">{t('global.min-length-text')}</span>}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6">
                                                    <FormGroup>
                                                        <Label for="name"
                                                               className="">{t('account-experience.initial-date')}:</Label>
                                                        <input type="date"
                                                               className={(educationEdit) ? "conceal-input" : "form-control-sm form-control"}
                                                               {...register(`education[${index}].initialDate`, {
                                                                   required: true
                                                               })}
                                                               max={moment(watchFieldArray(`education[${index}].endDate`)).format('YYYY-MM-DD')}
                                                               disabled={educationEdit}
                                                               control={control}
                                                               defaultValue=""/>
                                                        {errors.education?.[index]?.initialDate &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className={(watchFieldArray(`education[${index}].current`)) ? "d-none" : "col-12 col-sm-6 col-md-6"}>
                                                    <FormGroup>
                                                        <Label for="name"
                                                               className="">{t('account-experience.finalize-date')}:</Label>
                                                        <input type="date"
                                                               className={(educationEdit) ? "conceal-input" : "form-control-sm form-control"}
                                                               {...register(`education[${index}].endDate`, {
                                                                   required: watchFieldArray(`education[${index}].current`) ? false : true
                                                               })}
                                                               control={control}
                                                               min={moment(watchFieldArray(`education[${index}].initialDate`)).format('YYYY-MM-DD')}
                                                               disabled={educationEdit}
                                                               defaultValue=""/>
                                                        {errors.education?.[index]?.endDate &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-6">
                                                    {watchFieldArray(`education[${index}].endDate`) === "" ?
                                                        <>
                                                            <input
                                                                {...register(`education[${index}].current`, {
                                                                    required: false
                                                                })}
                                                                type="checkbox"
                                                                className={(educationEdit) ? "conceal-input" : ""}
                                                                disabled={educationEdit}
                                                                control={control}
                                                                defaultValue={false}/>
                                                            {errors.education?.[index]?.current &&
                                                            <span className="error">{t('global.required')}</span>}
                                                            <label
                                                                className="form-label-dark ml-2 mt-0 mt-lg-4">{t('account-experience.currently-coursing')}</label>
                                                        </> : <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                ))}

                {educationField.length === 0 ? <div>{t("global.no-data")}</div> : <button ref={reference}
                                                                                          className={(props.user && !educationEdit) ? "btn btn-primary mb-3" : "d-none"}
                                                                                          disabled={educationEdit}
                                                                                          type="submit">
                    {t("global.save")}</button>
                }

            </form>

            <div className="add-item-list" onClick={() => {
                setModal(!modal);
            }}>
                <FontAwesomeIcon icon={faPlus}/>
            </div>
        </>
    );
});

export default AchievementList;
