import React, {useContext, useEffect, useReducer, useState} from 'react';
import {useTranslation} from "react-i18next";
import GraficaReportes from "../../components/Reportes/GraficaReportes";
import DatosGeneralesReportes from "../../components/Reportes/DatosGeneralesReportes";
import "../../assets/scss/components/reports.scss";
import {filterServicesChart, filterServicesConcluded, getServicesConcluded,} from "../../services/service.service";
import {AuthContext} from "../../utilities/auth/AuthContext";
import {getSpecialist} from "../../services/specielist.service";
import {Input, Modal, ModalBody, ModalHeader} from "reactstrap";
import {getUsers} from "../../services/user.service";
import moment from "moment";
import {Cell, Column, HeaderCell, Table} from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import {Pagination} from 'rsuite';
import UserAvatar from "../../components/common/UserAvatar";
import {formatTableElapsed, serviceStatusEnum} from "../../utilities";
import {reportReducer} from "../../utilities/reportReducer";
import i18next from "i18next";

let filters = {from: "", toStop: "", idSpecialist: "", idUser: "", option: 'all', time: 'year'};
const initialState = {from: "", toStop: "", idSpecialist: "", idUser: "", option: 'all', time: 'year'};

const isValidDate = function (date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

const ReportesPage = () => {
    const {t} = useTranslation(['reports', 'common']);
    const [consults, setConsults] = useState([]);
    const [specialist, setSpecialist] = useState([]);
    const [users, setUsers] = useState([]);
    const {user: {user}} = useContext(AuthContext);

    const [labels, setLabels] = useState([]);
    const [datas, setDatas] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [state, dispatch] = useReducer(reportReducer, initialState);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();

    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [modal, setModal] = useState(false);
    const [selectedSoap, setSelectedSoap] = useState(null);
    const toggle = () => setModal(!modal);

    const maxDate = moment.utc().local().format('YYYY-MM-DD')


    const openSoapModal = (rowMap) => {
        setSelectedSoap(rowMap.soap);
        setModal(true);

    }

    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
    };

    const HourCell = ({rowData, dataKey, time, ...rest}) => {
        let localDate = moment.utc(`${rowData[dataKey]} ${rowData[time]}`, 'YYYY-MM-DD HH:mm').local();
        return (<Cell {...rest}>
            {localDate.format("hh:mm A")}
        </Cell>);
    };

    const DateCell = ({rowData, dataKey, time, ...rest}) => {
        let localDate = moment.utc(`${rowData[dataKey]} ${rowData[time]}`, 'YYYY-MM-DD HH:mm').local();
        return (<Cell {...rest}>
            {localDate.format("YYYY-MM-DD")}
        </Cell>);
    };

    const PriceCell = ({rowData, dataKey, isPaid, status, ...rest}) => {
        const localeCurrency = Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "USD"
        });
        let value = 0;
        if (rowData[isPaid] === 1 && rowData[status] === serviceStatusEnum.finished) {
            value = rowData[dataKey];
        }

        return (<Cell {...rest}>{localeCurrency.format(value)}</Cell>);
    }

    const ConditionsCell = ({rowData, _dataKey, _time, ...rest}) => {
        return (<Cell {...rest}>{rowData.idCondition?.name}</Cell>);
    }

    const SoapCell = ({rowData, _dataKey, _time, ...rest}) => {
        return (<Cell {...rest}><a href="#" onClick={(e) => {
            e.preventDefault();
            openSoapModal(rowData)
        }}>{rowData.soap}</a></Cell>);
    }

    const timeConvert = (value = 0) => {
        let duration = value != null ? value : 0;
        return formatTableElapsed(duration);

    }

    const MinutesCell = ({rowData, dataKey, ...rest}) => (
        <Cell {...rest}>
            {timeConvert(rowData[dataKey])}
        </Cell>
    );

    const StatusCell = ({rowData, dataKey, ...rest}) => {
        const serviceStatusEnuLabel = {
            0: t('reports:status.pending-payment'),
            1: t('reports:status.pending'),
            2: t('reports:status.expired'),
            3: t('reports:status.in-progress'),
            4: t('reports:status.cancelled'),
            5: t('reports:status.finished')
        }

        return (
            <Cell {...rest}>
                {`${serviceStatusEnuLabel[rowData[dataKey]]}`}
            </Cell>
        );
    }

    const formatChartLabelByType = (element, type) => {
        let value;
        switch (type) {
            case 'day':
                value = moment(element, 'YYYY-MM-DD').locale(i18next.language).format('ll');
                break;
            case 'month':
                value = moment(element, 'MM YYYY').locale(i18next.language).format('MMMM YYYY');
                break;
            case 'weekly':
                value = moment(element, 'ww YYYY').locale(i18next.language).format('ww YYYY');
                break;
            default:
                value = element;
                break;
        }

        return value;
    }

    const filterChart = () => {
        filterServicesChart(filters).then((response) => {
            let value;
            let tempDictionary = {};
            for (const element of response.times) {
                let label = formatChartLabelByType(element, response.type);
                tempDictionary[label] = {profits: 0, times: 0, counter: 0}
            }

            for (const element of response.service) {
                let label = formatChartLabelByType(element.date, response.type);
                tempDictionary[label] = {
                    profits: element.totalPrice || 0,
                    times: element.totalElapsed || 0,
                    counter: element.count || 0
                }
            }
            setLabels(Object.keys(tempDictionary));
            setDatas(Object.values(tempDictionary));
            setServiceData(response);
        })
    }

    const filterData = () => {
        filterServicesConcluded(filters).then((response) => {
            setConsults(mapConsults(response));
            filterChart();
        })
    }

    const mapConsults = (response) => {
        return response.map((consult) => {
            consult.idCondition.name = t('common:conditions.' + consult.idCondition?.name);
            return consult;
        });
    }

    const getData = () => {
        if (sortColumn && sortType) {
            consults.sort((a, b) => {
                let splitData = sortColumn.split(".");
                let x = a[splitData[0]];
                let y = b[splitData[0]];

                if (sortColumn === "idProvider.name" || sortColumn === "idUser.name" || sortColumn === "idCondition.name") {
                    x = x.name.toLowerCase();
                    y = y.name.toLowerCase();
                } else if (sortColumn === "idProvider.secondName" || sortColumn === "idUser.secondName") {
                    x = x.secondName.toLowerCase();
                    y = y.secondName.toLowerCase();
                }

                if (sortType === 'asc') {
                    if (x < y) {
                        return -1;
                    }
                    if (x > y) {
                        return 1;
                    }
                    return 0;
                } else {
                    if (y < x) {
                        return -1;
                    }
                    if (y > x) {
                        return 1;
                    }
                    return 0;
                }
            });
        }

        return consults.filter((_v, i) => {
            const start = limit * (page - 1);
            const end = start + limit;
            return i >= start && i < end;
        });

    };

    const handleSortColumn = (_sortColumn, _sortType) => {
        setTimeout(() => {
            setSortColumn(_sortColumn);
            setSortType(_sortType);
        }, 500);
    };

    const handleFilter = (event, type) => {
        const value = event.target.value;
        filters[type] = value;
        filterData();
        dispatch({type: type, value: value})

    }

    useEffect(async () => {

        i18next.on('languageChanged', () => {
            filterChart();
        });

        getServicesConcluded(user.idCompany.idCompany).then((response) => {
            setConsults(mapConsults(response));
            filterChart();
        });

        setUsers((await getUsers(user.idCompany.idCompany)).map((item) => ({
            label: `${item.name} ${item.secondName}`,
            value: item.idUser
        })));
        setSpecialist((await getSpecialist(user.idCompany.idCompany)).map((item) => ({
            label: `${item.name} ${item.secondName}`,
            value: item.idProvider
        })));
        filters['idCompany'] = user.idCompany.idCompany;

    }, []);

    return (
        <div className="pb-3 px-3" style={{overflowX: "hidden", overflowY: "auto", height: "100%"}}>
            <div className="page-header row align-self-center">
                <div className="col-12 col-md-8">
                    <div className="">
                        <h1 className="page-title col">{t('reports.title')}</h1>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div>
                        <div className="row">
                            <div className="col-sm-6">
                                {t('reports.Patient')}
                                <Input type="select" bsSize="sm" value={state.idUser}
                                       className="d-inline" name="select" onChange={(event) => {
                                    handleFilter(event, 'idUser');
                                }}>
                                    <option key={""} value={""}>{t("common:global.all")}</option>
                                    {
                                        users.map((item) => {
                                            return (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </div>
                            <div className="col-sm-6">
                                {t('reports.Provider')}
                                <Input type="select" bsSize="sm" value={state.idSpecialist} name="select"
                                       className="d-inline" onChange={(event) => {
                                    handleFilter(event, 'idSpecialist');
                                }}>
                                    <option key={null} value={""}>{t("common:global.all")}</option>
                                    {
                                        specialist.map((item) => {
                                            return (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                {t('common:global.from')}
                                <input type="date" className="form-control-sm form-control" value={state.from}
                                       max={maxDate}
                                       onChange={(event) => {
                                           handleFilter(event, 'from');
                                       }}/>
                            </div>
                            <div className="col-sm-6">
                                {t('common:global.to')}
                                <input type="date" className="form-control-sm form-control" value={state.toStop}
                                       max={maxDate}
                                       onChange={(event) => {
                                           handleFilter(event, 'toStop');
                                       }}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {t('common:global.filter')}
                                <Input type={"select"} className="form-control-sm" value={state.option}
                                       onChange={(event) => {
                                           handleFilter(event, 'option');
                                       }}>

                                    <option value={'all'} key={'all'}>{t('reports:reports.All')}</option>
                                    <option value={'pending'} key={'pending'}>{t('reports:reports.Archived')}</option>
                                    <option value={'finished'} key={'finished'}>{t('reports:reports.Finalize')}</option>
                                    <option value={'expired'} key={'expired'}>{t('reports:reports.Expired')}</option>
                                    <option value={'cancelled'}
                                            key={'cancelled'}>{t('reports:reports.Cancelled')}</option>
                                </Input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 d-flex justify-content-end">
                                <button type="button" className="btn btn-link" onClick={() => {
                                    filters = {
                                        from: null,
                                        toStop: null,
                                        idSpecialist: null,
                                        idUser: null,
                                        option: 'all',
                                        time: 'year'
                                    };
                                    getServicesConcluded(user.idCompany.idCompany).then((response) => {
                                        setConsults(mapConsults(response));
                                        filterChart();
                                        dispatch({type: 'reset'});
                                    });

                                    filters['idCompany'] = user.idCompany.idCompany;
                                }}>{t('reports:reports.Reset')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DatosGeneralesReportes
                total={consults.length}
                amount={consults.filter((i) => {
                    if (i.status === serviceStatusEnum.finished && i.price > 0) {
                        return i.price;
                    }
                })}
                finalize={consults.filter((i) => {
                    return i.status === serviceStatusEnum.finished
                })}
                cancelled={consults.filter((i) => {
                    return i.status === serviceStatusEnum.expired
                })}
                duration={consults.filter((i) => {
                    return (i.status === serviceStatusEnum.finished) ? i.elapsed : 0
                })}
                pending={consults}/>

            <>
                <div className="row">
                    <div className="col-4">
                        <div className="col-sm-12">

                        </div>
                    </div>
                    <div className="col">

                        <div className="row justify-content-end">

                            <button type="button" style={{width: "60px"}} className="btn btn-primary mr-1"
                                    onClick={() => {
                                        filters.time = "day";
                                        filterChart()
                                    }}>{t('reports:dates.Day')}</button>
                            <button type="button" style={{width: "90px"}} className="btn btn-primary mr-1"
                                    onClick={() => {
                                        filters.time = "weekly";
                                        filterChart()
                                    }}>{t('reports:dates.weekly')}</button>
                            <button type="button" style={{width: "60px"}} className="btn btn-primary mr-1"
                                    onClick={() => {
                                        filters.time = "month";
                                        filterChart()
                                    }}>{t('reports:dates.Month')}</button>
                            <button type="button" style={{width: "60px"}} className="btn btn-primary mr-2"
                                    onClick={() => {
                                        filters.time = "year";
                                        filterChart()
                                    }}>{t('reports:dates.Year')}</button>
                        </div>
                    </div>
                </div>
                <GraficaReportes labels={labels} dataSets={datas} services={serviceData}/>
                <div className="row mt-5">
                    <div className="col">
                        <Table data={getData()}
                               autoHeight={true}
                               sortColumn={sortColumn}
                               sortType={sortType}
                               onSortColumn={handleSortColumn}>
                            <Column width={55} fixed>
                                <HeaderCell/>
                                <Cell>
                                    {(rowData) => {
                                        return (
                                            <UserAvatar
                                                className="user-avatar-reports"
                                                alt="Avatar"
                                                profile={rowData.idProvider?.idDetail?.profile}
                                            />
                                        )
                                    }}
                                </Cell>
                            </Column>
                            <Column width={120} sortable fixed resizable>
                                <HeaderCell>{t('reports.Provider')}</HeaderCell>
                                <Cell dataKey="idProvider.name"/>
                            </Column>

                            <Column width={160} sortable fixed resizable>
                                <HeaderCell>{t('reports:reports.lastname')}</HeaderCell>
                                <Cell dataKey="idProvider.secondName"/>
                            </Column>

                            <Column width={100} sortable resizable>
                                <HeaderCell>{t('reports:reports.Status')}</HeaderCell>
                                <StatusCell dataKey="status"/>
                            </Column>

                            <Column width={120} sortable resizable>
                                <HeaderCell>{t('reports:reports.Date')}</HeaderCell>
                                <DateCell dataKey="serviceDate" time="time"/>
                            </Column>

                            <Column width={100} sortable resizable>
                                <HeaderCell>{t('reports:reports.Hour')}</HeaderCell>
                                <HourCell dataKey="serviceDate" time="time"/>
                            </Column>

                            <Column width={140} sortable resizable>
                                <HeaderCell>{t('reports:reports.Patient-name')}</HeaderCell>
                                <Cell dataKey="idUser.name"/>
                            </Column>

                            <Column width={160} sortable resizable>
                                <HeaderCell>{t('reports:reports.Patient-lastName')}</HeaderCell>
                                <Cell dataKey="idUser.secondName"/>
                            </Column>

                            <Column width={100} sortable resizable>
                                <HeaderCell>{t('reports:reports.Length')}</HeaderCell>
                                <MinutesCell dataKey="elapsed"/>
                            </Column>

                            <Column width={100} sortable resizable>
                                <HeaderCell>{t('reports:reports.Gain')}</HeaderCell>
                                <PriceCell dataKey="price" isPaid="isPaid" status="status"/>
                            </Column>

                            <Column width={170} sortable resizable>
                                <HeaderCell>{t('reports:reports.Primary-Concern')}</HeaderCell>
                                <ConditionsCell dataKey="idCondition.name"/>
                            </Column>

                            <Column width={400} resizable>
                                <HeaderCell>{t('reports:reports.soap')}</HeaderCell>
                                <SoapCell dataKey="soap"/>
                            </Column>
                        </Table>
                        <div style={{padding: 20}}>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="xs"
                                layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                                total={consults.length}
                                limitOptions={[10, 20]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleChangeLimit}
                                locale={{
                                    more: t('reports:pagination.more'),
                                    prev: t('reports:pagination.prev'),
                                    next: t('reports:pagination.next'),
                                    first: t('reports:pagination.first'),
                                    last: t('reports:pagination.last'),
                                    limit: t('reports:pagination.limit'),
                                    total: t('reports:pagination.total'),
                                    skip: t('reports:pagination.skip')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>


            {consults.length === 0 &&
            <div className="reports-message">
                <p className="empty font-weight-bold">{t("reports.Empty")}</p>
            </div>
            }

            <Modal scrollable={true} isOpen={modal} backdrop={'static'} toggle={toggle}>
                <ModalHeader toggle={toggle} tag={"h5"}>{t('reports.soap')}</ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={{__html: selectedSoap?.replace(/\r?\n/g, "<br />")}}/>
                </ModalBody>
            </Modal>
        </div>
    );


}

export default ReportesPage;
