import React, {useContext, useEffect, useRef} from "react";
import TopNavBar from "../components/TopNavBar/TopNavBar";
import {Redirect, Route, Switch} from "react-router-dom";
import {AdminRoute} from "../routes";
import SideBar from "../components/SideBar/SideBar";
import {AuthContext} from "../utilities/auth/AuthContext";
import AcceptPrivacy from "../views/Modal/AcceptPrivacy";
import FooterAdmin from "../components/common/FooterAdmin";
import {kTypes} from "../utilities/types/types";
import {handleLicenseStatus} from "../services/license.service";

export const LicActive = React.createContext(null);

export const AdminRoutes = (props) => {
    const [backgroundColor] = React.useState("black");
    const [activeColor] = React.useState("info");
    const sidebar = useRef(null);
    const mainPanel = React.useRef();
    const {user, dispatch} = useContext(AuthContext);

    const handleLicense = async (provider) => {
        await handleLicenseStatus(provider, dispatch);
    }

    useEffect(async () => {

        handleLicense(user).then(() => {
            if (!localStorage.getItem(kTypes.privacy)) {
                sidebar.current.setModal(<AcceptPrivacy modal={sidebar}/>);
                sidebar.current.openModal();
            }
        });
    }, []);

    return (
        <>
            <TopNavBar {...props} routes={AdminRoute} menu={sidebar}
                       visible={(!window.location.pathname.includes("conference"))}/>
            <div className="container-fluid pr-0">
                <div className="row main-panel" ref={mainPanel}>

                    <SideBar className="col" {...props}
                             sideBarVisible={(!window.location.pathname.includes("conference"))}
                             routes={AdminRoute}
                             bgColor={backgroundColor}
                             activeColor={activeColor}
                             ref={sidebar}
                             lic={false}/>

                    <div className="col view-container ">
                        <div className={(!window.location.pathname.includes("conference")) ? "container-fluid vista" : "vista"}>
                            <Switch>
                                {AdminRoute.map((prop, key) => {
                                    if (prop.roles.includes(user.user.idRole.idRole))
                                        return (
                                            <Route
                                                exact
                                                path={props.uri + prop.path}
                                                component={() => <prop.component modal={sidebar}/>}
                                                key={key}
                                                lics={prop.lic}
                                            />
                                        );
                                })}

                                <Route path="*">
                                    <Redirect to={props.uri + '/appointments'}/>
                                </Route>
                            </Switch>

                        </div>
                        <FooterAdmin/>
                    </div>

                </div>
            </div>
        </>
    );
};
