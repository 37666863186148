import React, {createContext, useContext, useRef, useState} from "react";
import CreateSubscriptionButton from "components/CommercialAccount/Subscription/CreateSubscriptionButton";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import 'assets/scss/styles/_licenseType.scss';
import {loaderContext} from "../../EHApp";
import {setProviderLicense} from "../../services/provider.service";
import {getActiveNonInternalLicenseTypes} from "../../services/license.service";
import {checkProtectedRoutes} from "../../services/auth.service";
import LicenseTypeItem from "../../components/LicenseType/LicenseTypeItem";
import {toast} from "react-toastify";
import AuthLayout from "../../routers/AuthLayout";

export const docData2 = React.createContext(null);
export const licContext = createContext({
    lic: "",
});


export const LicenseType = (props) => {
    const setlic = useContext(licContext);
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {t} = useTranslation('common');
    const [selectedLicense, setLicense] = useState(null);
    const [licenseList, setLicenseList] = useState([]);

    const history = useHistory();
    const location = useLocation();
    checkProtectedRoutes(location, history);
    const buttonPaymentRef = useRef();
    const activeStep = 2;

    React.useEffect(() => {
        (async () => {
            setLicenseList(await getActiveNonInternalLicenseTypes());
        })();
    }, []);

    const onPay = async () => {
        console.log('on Pay');

        if (selectedLicense) {
            console.log('selected');
            await setProviderLicense(location.state.idProvider, selectedLicense.idLicenseType);

            history.push({
                pathname: '/auth/payment/3',
                state: {
                    idProvider: location.state.idProvider,
                    doctorName: location.state.doctorName,
                    identification: location.state.identification,
                    username: location.state.username,
                    idProviderType: location.state.idProviderType
                },
            });
        } else {

            toast.error(t("global.select-license"));
        }
    }

    const handleSelectLicenseType = (licenseType) => {
        setLicense(licenseType);
        setlic.lic = licenseType.idLicenseType;
        buttonPaymentRef.current.openModal();
    }

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters license-type-wrapper">
                    <div className="col-12 half">
                        <AuthLayout activeStep={activeStep}>
                            <article className="h-100 row no-gutters justify-content-center align-items-center">
                                <div className="col-12 text-center">
                                    <h1 className="">{t('payment.title')}</h1>
                                    <ul className="mt-4 d-flex flex-inline payment-list flex-fill">
                                        {licenseList.map((item, _index) => (
                                            <li key={item.idLicenseType} className="flex-grow-0 license-type-item">
                                                <LicenseTypeItem licenseType={item} onSelect={handleSelectLicenseType}/>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="clearfix"/>
                                    <CreateSubscriptionButton {...props} hide={true} ref={buttonPaymentRef}
                                                              reload={onPay}/>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LicenseType;
