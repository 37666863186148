import React from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import FooterLinks from "./footerLinks";

const FooterAuth = ({className}) => {
    const {t} = useTranslation('common');

    return (
        <footer className={className + ' auth-footer'}>
            <FooterLinks className={"d-flex flex-row mb-0 align-items-center justify-content-start"}/>
        </footer>
    );
}

export default FooterAuth;
