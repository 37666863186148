import {forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState} from "react";
import {localVideoSourceReducer} from "../../utils/localVideoSourceReducer";
import {isMobile} from 'react-device-detect';

const initialVideoSource = {audio: [], video: [], currentAudioSource: null, currentVideoSource: null};
let streamReference = null;
let timeout = null;
let videoDevices = [], audioDevices = [];
let ConnectionsLayoutLimit = isMobile ? 1 : 2;
let join = false;

const LocalVideoStream = forwardRef((props, reference) => {
    const localCamera = useRef(null);
    const [videoSourceState, dispatch] = useReducer(localVideoSourceReducer, initialVideoSource);
    const [connections, setConnections] = useState(0);

    if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions.query({name: 'camera'}).then((permissionStatus) => {
            permissionStatus.onchange = () => {
                clearTimeout(timeout);
                if (permissionStatus.state === 'prompt') {
                    timeout = setTimeout(() => {
                        getMediaDevices();
                    }, 2000);
                }
            };
        }).catch((error) => {
            console.error(error);
        });
    }

    useImperativeHandle(reference, () => ({
        setAudio: (value) => {
            streamAudio(value);
        },
        setVideo: (value) => {
            streamVideo(value);
        },
        getHardwareState: () => {
            return videoSourceState;
        },
        updateVideo: (item) => {
            updateHardware(item, 'video');
        },
        updateAudio: (item) => {
            updateHardware(item, 'audio');
        },
        getStream: () => {
            return localCamera.current.srcObject
        },
        setConnections: (value) => {
            setConnections(value);
        }
    }));

    const streamAudio = (value) => {
        changeMicro(value);
    }

    const streamVideo = (video) => {
        changeVideo(video);
    }

    const socketEmitStatus = () => {
        props.socket.emit('feedback', {
            socketId: props.socket.id,
            audio: localCamera.current.srcObject.getAudioTracks()[0].enabled,
            video: localCamera.current.srcObject.getVideoTracks()[0].enabled
        });
    }

    const changeVideo = (value) => {
        try {
            localCamera.current.srcObject.getVideoTracks().forEach(track => track.enabled = value); //!track.enabled
            socketEmitStatus();
        } catch (error) {
            console.error(error);
        }
    }

    const changeMicro = (value) => {
        try {
            localCamera.current.srcObject.getAudioTracks().forEach(track => track.enabled = value); //!track.enabled
            socketEmitStatus();
        } catch (error) {
            console.error(error);
        }
    }

    const updateHardware = (item, type) => {
        switch (type) {
            case 'audio':
                dispatch({
                    source: item.target.value,
                    payload: "setAudio",
                });

                getMediaDevices(item.target.value, videoSourceState.currentVideoSource);
                break;

            case 'video':
                dispatch({
                    source: item.target.value,
                    payload: "setVideo",
                });

                getMediaDevices(videoSourceState.currentAudioSource, item.target.value);
                break;

            default:
                break;
        }
    }


    // Genera lista con los dispositivos de video y audio disponibles en el equipo
    const fillDeviceList = (devices) => {
        for (let i = 0; i !== devices.length; ++i) {
            const deviceInfo = devices[i];
            if (deviceInfo.kind === "audioinput" && audioDevices.filter(ai => ai.name === deviceInfo.label).length === 0)
                audioDevices.push({deviceId: deviceInfo.deviceId, name: deviceInfo.label});

            else if (deviceInfo.kind === "videoinput" && videoDevices.filter(vi => vi.name === deviceInfo.label).length === 0)
                videoDevices.push({deviceId: deviceInfo.deviceId, name: deviceInfo.label});
        }
    }
    const setDevicesList = (_audio, _camera, devices) => {
        fillDeviceList(devices);
        dispatch({
            add: videoDevices,
            payload: "video",
        });

        dispatch({
            add: audioDevices,
            payload: "audio",
        });

        props.audioVideoDevices(audioDevices, videoDevices);
    }

    // Obtenemos la lista de dispositivos
    const getMediaDevices = (audioSelect, videoSelect) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            let camera = null, audio = null;
            if (videoSourceState.audio.length === 0 && videoSourceState.video.length === 0) {
                setDevicesList(audio, camera, devices);
                camera = videoDevices[0]
                audio = audioDevices[0]
            } else {
                camera = videoDevices.find(device => device.deviceId === videoSelect);
                audio = audioDevices.find(device => device.deviceId === audioSelect);
            }

            try {
                if (camera || audio) {
                    let videoConstraint, audioConstraint;
                    if (audio) {
                        audioConstraint = {
                            deviceId: {exact: audio.deviceId},
                            echoCancellation: true,
                            autoGainControl: true,
                            noiseSuppression: true,
                            channelCount: 2
                        };
                    }
                    dispatch({
                        source: audio.deviceId,
                        payload: "setAudio",
                    });

                    if (camera) {
                        videoConstraint = {
                            deviceId: {exact: camera.deviceId},
                            width: {min: 320, ideal: 640, max: 1920},
                            height: {min: 240, ideal: 480, max: 1080},
                            aspectRatio: {ideal: 1.7777777778},
                            frameRate: {min: 10, ideal: 30},
                            facingMode: 'user',
                            resizeMode: 'crop-and-scale'
                        };
                    }
                    dispatch({
                        source: camera.deviceId,
                        payload: "setVideo",
                    });

                    return navigator.mediaDevices.getUserMedia({
                        video: (videoConstraint.deviceId.exact === "") ? true : videoConstraint,
                        audio: (audioConstraint.deviceId.exact === "") ? true : audioConstraint
                    })
                }
            } catch (error) {
                alert('You have to enable the mic and the camera');
            }

        }).then((stream) => {
            streamReference = stream;
            localCamera.current.muted = true;
            localCamera.current.srcObject = stream;
            let videoTrack = stream.getVideoTracks() ? stream.getVideoTracks()[0] : null;
            let audioTrack = stream.getAudioTracks() ? stream.getAudioTracks()[0] : null;

            for (const [, value] of Object.entries(props.getPeers())) {
                let senderVideo = value.getSenders().find(function (current) {
                    return current.track.kind === videoTrack.kind;
                });
                senderVideo.replaceTrack(videoTrack).then().catch(error => console.error(error));
                let senderAudio = value.getSenders().find(function (current) {
                    return current.track.kind === audioTrack.kind;
                });
                senderAudio.replaceTrack(audioTrack).then().catch(error => console.error(error));
            }

        }).then(onSuccess, onError).catch(error => console.error(error));
    }

    const onSuccess = () => {
        if (videoDevices[0].deviceId === '' || audioDevices[0].deviceId === '') {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    let camera = null, audio = null;
                    audioDevices = [];
                    videoDevices = [];
                    setDevicesList(audio, camera, devices);
                }).catch(error => console.log(error));
        }
        if (join) {
            join = false;
            props.callback();
        }
    }

    const onError = (error) => {
        console.error('Get media devides error', error);
    }

    useEffect(() => {
        getMediaDevices();
        return () => {
            if (streamReference) {
                try {
                    streamReference.getTracks().forEach((track) => {
                        if (track.readyState === 'live')
                            track.stop();
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        };
    }, []);

    useEffect(() => {
        join = props.join;
        localCamera.current.srcObject = streamReference;
    }, [connections]);

    return (
        <>
            <div className="fixedLocalVideo">
                <div className="videoItem">
                    <video className="video-stream-local" autoPlay playsInline ref={localCamera}/>
                </div>
            </div>
        </>
    );
})
export default LocalVideoStream;
