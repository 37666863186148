import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../../utilities/auth/AuthContext";
import {faBan, faCheckCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createStripeLoginLink, getStripeConnectedAccount} from "../../../services/stripe.service";
import DeleteConnectButton from "./DeleteConnectButton";
import CreateConnectButton from "./CreateConnectButton";

const CommercialAccountDetail = (props) => {

    const {t} = useTranslation(["profile", "common"]);
    const {user} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [providerStripeAccount, setProviderStripeAccount] = useState(null);

    useEffect(() => {
        handleCommercialAccount();
    }, []);

    const handleReload = () => {
        handleCommercialAccount();
    }

    const handleCommercialAccount = () => {
        setLoading(true);
        getStripeConnectedAccount(user.user.idProvider).then((connectAccount) => {
            if (connectAccount.isStripeRegister === 1) {
                setProviderStripeAccount(connectAccount);
            }
            setLoading(false);
        }).catch((_error) => {
            setLoading(false);
        });
    }

    return (
        <>
            <h5>{t("Stripe.stripe_acct_details")}</h5>
            <div className="card commercial-account-detail">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {loading ?
                                    <div className="col-12 d-flex justify-content-center">
                                        <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                                    </div>
                                    :
                                    <>
                                        <div
                                            className="col-12 col-md-6 d-flex justify-content-between align-items-center">
                                            <div className="d-inline-flex align-items-center">
                                                <FontAwesomeIcon
                                                    icon={providerStripeAccount !== null ? faCheckCircle : faBan}
                                                    className={providerStripeAccount !== null ? "valid-account" : "invalid-account"}/>
                                                <div className="ml-3 d-flex flex-column">
                                                    <span
                                                        className={providerStripeAccount !== null
                                                            ? "active-message"
                                                            : "inactive-message"}>
                                                        {providerStripeAccount !== null
                                                            ? t("common:commercial-account.activated-account")
                                                            : t("common:commercial-account.no-business-account")}
                                                    </span>
                                                    {providerStripeAccount == null &&
                                                    <small>{t("Stripe.stripe_acct_error1")}</small>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                                            {providerStripeAccount ?
                                                <div className="d-flex justify-content-between title-wrapper pr-4">
                                                    <button disabled={loading}
                                                            onClick={async (_e) => {
                                                                const link = await createStripeLoginLink(user.user.idProvider);
                                                                window.open(link.url, "_blank");
                                                            }}
                                                            className="btn btn-outline-primary btn-sm mr-2"
                                                    >
                                                        {t("profile:Stripe.goToStripeAccount")}
                                                    </button>
                                                    <DeleteConnectButton
                                                        {...props}
                                                        user={user}
                                                        reload={handleReload}
                                                    />
                                                </div>
                                                :
                                                <CreateConnectButton
                                                    {...props}
                                                    user={user}
                                                />
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CommercialAccountDetail;
