import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import joinIcon from "../../../../../assets/images/video/join.svg";
import hangIcon from "../../../../../assets/images/video/hang.svg";
import {AuthContext} from "../../../../../utilities/auth/AuthContext";
import {useContext} from "react";
import FinishService from "../../../../Modal/FinishService";
import {serviceStatus} from "../../../../../services/service.service";
import {useHistory} from "react-router-dom";
import Leave from "../../../Leave";
import {serviceStatusEnum} from "../../../../../utilities";

const ButtonConnectDisconnect = (props) => {
    const {t} = useTranslation("video", "common");
    const context = useContext(AuthContext);
    let history = useHistory();

    const showModal = () => {
        props.dispatch({
            source: true,
            payload: "set-modal",
        });
    }
    const showFinishConfirmationModal = () => {
        props.setContent(<FinishService
            idService={props.videoState().service.idService}
            user={`${props.videoState().service.idUser.name} ${props.videoState().service.idUser.secondName}`}
            socket={props.socket} {...props} />);
        showModal();
    };

    const showLeaveConfirmationModal = () => {
        props.setContent(<Leave {...props} callback={() => {
            props.leaveLocal();
            history.push("/client/finished/" + `${props.videoState().service.idUser.idUser},${props.videoState().service.idUser.mail},${props.videoState().service.idProvider.idProvider},${props.videoState().service.idService}`);
        }}/>);
        showModal();
    }

    const connect = () => {
        props.join(props.roomName, props.videoState().service);
    }

    const disconnect = () => {
        if (context.user.logged) {
            const notLoggedConnections = props.getConnectedUsers().find(connection => connection.isLogged !== true);
            if (notLoggedConnections) {
                showFinishConfirmationModal();
            } else {
                serviceStatus(props.videoState().service.idService, serviceStatusEnum.pending).then((_response) => {
                    props.socket.emit('remove', {});
                    history.push("/appointments");
                });
            }

        } else {
            showLeaveConfirmationModal();
        }
    }

    return (
        <>
            <>
                <button data-tip={t("disconnect")}
                        className="btn btn-link circle hang color-white"
                        onClick={props.videoState().inRoom ? disconnect: null}>
                    <img src={hangIcon} alt="hangIcon"/>
                </button>
                <ReactTooltip/>
            </>
            { /* props.videoState().inRoom
                ?
                <>
                    <button data-tip={t("disconnect")}
                            className="btn btn-link circle hang color-white"
                            onClick={disconnect}>
                        <img src={hangIcon} alt="hangIcon"/>
                    </button>
                    <ReactTooltip/>
                </>
                :
                <>
                    <button data-tip={t("connect")}
                            className="btn btn-link circle leave color-white"
                            onClick={connect}>
                        <img src={joinIcon} alt="joinIcon"/>
                    </button>
                    <ReactTooltip/>
                </>
            */}
        </>
    );
}
export default ButtonConnectDisconnect;
