import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {deleteStripeConnected, getStripeConnectedAccount} from "../../../services/stripe.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../../views/Modal/ConfirmModal";
import {toast} from "react-toastify";
import {handleLicenseStatus} from "../../../services/license.service";
import {AuthContext} from "../../../utilities/auth/AuthContext";

const DeleteConnectButton = (props) => {
    const {t} = useTranslation(["profile", "common"]);
    const [loading, setLoading] = useState(false);
    const {user, dispatch} = useContext(AuthContext);

    const handleConfirmDeleteButton = () => {
        props.modal.current.setModal(<ConfirmModal
                modal={props.modal}
                title={t("common:commercial-account.confirm-delete-account-title")}
                message={t("common:commercial-account.confirm-delete-account-message")}
                acceptLabel={t("common:commercial-account.confirm-delete-account-button-label")}
                acceptColor="primary"
                onAccept={deleteAccount}
            />
        );
    }

    const deleteAccount = async () => {
        setLoading(true);
        try {
            const connectAccount = await getStripeConnectedAccount(user.user.idProvider);
            if (connectAccount.isStripeRegister === 1) {
                const connected = await deleteStripeConnected(connectAccount.stripeAccountId);
                if (connected?.deleted) {
                    await handleLicenseStatus(user, dispatch, false);
                    props.reload();
                    toast.success(t('common:commercial-account.success-delete-account'));
                } else {
                    toast.error(t("common:commercial-account.error-delete-account"));
                    console.error('No se ha podido eliminar');
                }
            } else {
                console.error('Provider no dispone de cuenta comercial');
                toast.error(t("common:commercial-account.error-delete-account"));
            }
            setLoading(false);
        } catch (error) {
            toast.error(t("common:commercial-account.error-delete-account"));
            console.error(error);
            setLoading(false);
        }
    }
    return (
        <>
            <button disabled={loading} onClick={handleConfirmDeleteButton}
                    className='btn btn-outline-danger btn-sm cancel-btn'>
                {loading ? <FontAwesomeIcon icon={faSpinner}
                                            className="spinner-button spinner-button-cancel"/> : t("profile:Stripe.deleteSalesAccount")}
            </button>
        </>
    );
}

export default DeleteConnectButton;
