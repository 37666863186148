import {Label} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

const AppointmentItemStatus = ({status}) => {
    const {t} = useTranslation('appointments');
    let label = "";
    switch (status) {
        case 0 :
            label = "pending-payment";
            break;
        case 1:
            label = "pending";
            break;
        case 2:
            label = "expired";
            break;
        case 3:
            label = "in-progress";
            break;
        case 4:
            label = "cancelled";
            break;
        case 5:
            label = "finished";
            break;
        default:
            label = "unknown"
    }
    return (
        <span className={`status ${label}`}>
            <Label>{t(`scheduled-appointments.status.${label}`)}</Label>
        </span>
    );
}

export default AppointmentItemStatus;