import React, {useState} from "react";
import editIcon from "../../assets/images/icons/edit.svg";
import {OrganizationDetail} from "./OrganizationDetail";
import {OrganizationForm} from "./OrganizationForm";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

export const Organization = (props) => {

    const [editOrganization, setEditOrganization] = useState(false);
    const {t} = useTranslation(["myTeam", "common"]);
    const handleEditOrganization = () => {
        setEditOrganization(!editOrganization);
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h5>{t('myTeam:organization.title')}</h5>
                {
                    !editOrganization
                    && (
                        <img onClick={handleEditOrganization} src={editIcon} alt="edit"
                             className="edit-icon"/>
                    )
                }

            </div>
            {
                editOrganization
                    ?
                    <div className="team-form-wrapper p-2 mb-4 mt-3">
                        <OrganizationForm company={props.company} reload={props.reload} close={handleEditOrganization}/>
                    </div>
                    :
                    <div className="team-form-wrapper p-4 mb-4 mt-3">
                        <OrganizationDetail company={props.company}/>
                    </div>

            }
        </>
    );
}

Organization.propTypes = {
    company: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
}

export default Organization;
