import "../../css/consultContent.scss";
import {useTranslation} from "react-i18next";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useEffect} from "react";

const ConsultInfo = ({videoState, dispatch}) => {

    const {t} = useTranslation("video", "common");

    useEffect(() => {
        console.log((videoState))
    }, [])


    return (
        <div className="consult-content card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5>{t('video-consultation')} {videoState.service.idProvider.name} {videoState.service.idProvider.secondName}</h5>
                <FontAwesomeIcon className="closeVideoConsultation" icon={faTimes} onClick={(_e) => {
                    dispatch({
                        source: !videoState.consult,
                        payload: "set-consult",
                    });
                }}/>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <ul>
                            <li>{t('attended-by')}</li>
                            <li>{t('specialty')}</li>
                            <li>{t('patient')}</li>
                            <li>{t('main-complaint')}</li>
                            { /* <li>{t('appointment-link')}</li> */}
                            <li>{t('consultation-cost')}</li>
                        </ul>
                    </div>
                    <div className="col-7">
                        <ul>
                            <li>
                                <strong>{videoState.service.idProvider.name} {videoState.service.idProvider.secondName}</strong>
                            </li>
                            <li>
                                <strong>
                                    {videoState.service.idProvider.idSpecialty != null ?
                                        t(`common:providerTypes.${videoState.service.idProvider.idProviderType.name}`) + " - " + t(`common:specialties.${videoState.service.idProvider.idSpecialty.name}`)
                                        :
                                        t(`common:providerTypes.${videoState.service.idProvider.idProviderType.name}`)
                                    }
                                </strong>
                            </li>
                            <li><strong>{videoState.service.idUser.name} {videoState.service.idUser.secondName}</strong>
                            </li>
                            <li><strong>{t(`common:conditions.${videoState.service.idCondition.name}`)}</strong></li>
                            <li><strong>${videoState.service.price}</strong></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsultInfo;
