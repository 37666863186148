import React from 'react';
import {ModalBody, ModalFooter} from "reactstrap";
import PropTypes from "prop-types";

const ConfirmModal = ({
                          onAccept,
                          title,
                          message,
                          modal,
                          acceptColor,
                          acceptLabel,
                          cancelLabel,
                          ..._props
                      }) => {

    const handleAcceptButton = () => {
        modal.current.toggleOnly();
        onAccept();
    }

    return (
        <>
            <ModalBody>
                <h5>{title}</h5>
                <p>{message}</p>
            </ModalBody>
            <ModalFooter>
                <button onClick={modal.current.toggleOnly}
                        className="btn btn-secondary btn-sm">{cancelLabel}</button>
                <button onClick={handleAcceptButton} className={`btn btn-${acceptColor} btn-sm`}>{acceptLabel}</button>
            </ModalFooter>
        </>
    );
}

ConfirmModal.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    modal: PropTypes.any.isRequired
}

ConfirmModal.defaultProps = {
    acceptLabel: "Aceptar",
    cancelLabel: "Cancelar",
    acceptColor: "primary"
}

export default ConfirmModal;
