import React, {useEffect} from 'react';
import {Line} from 'react-chartjs-2';
import {useTranslation} from "react-i18next";
import {formatDuration} from "../../utilities";

const GraficaReportes = (props) => {
    const {t} = useTranslation('reports');
    //console.log(props);
    const data = {
        labels: props.labels,
        datasets: [
            {
                label: t('reports.Gain'),
                data: props.dataSets.map(i => i.profits),
                fill: false,
                backgroundColor: '#1565c0',
                borderColor: 'rgba(21,101,192,0.59)',
                yAxisID: 'yGain'
            },
            {
                label: t('reports.time'),
                data: props.dataSets.map(i => i.times),
                fill: false,
                backgroundColor: '#c01573',
                borderColor: 'rgba(192, 21, 115, 0.59)',
                yAxisID: 'yTime'
            },
        ],
    };

    const options = {
        plugins: {
            tooltip: {
                callbacks:{
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            if (context.datasetIndex === 0) {
                                label += new Intl.NumberFormat('en-US',
                                    { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                            } else {
                                label += formatDuration(context.parsed.y);
                            }

                        }
                        return label;
                    },
                    beforeBody: function(context) {
                        return t('reports.video-consultations') + ": " + props.dataSets[context[0].dataIndex].counter;
                    }

                }
            },
        },
        scales: {
            yGain: {
                type: "linear",
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: t('reports.Gain')
                },
                ticks: {
                    beginAtZero: true,
                    callback: function(label) {
                        return new Intl.NumberFormat('en-US',
                            { style: 'currency', currency: 'USD' }).format(label);
                    }
                },
            },
            yTime: {
                type: "linear",
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: t('reports.time')
                },
                ticks: {
                    beginAtZero: true,
                    callback: function(label) {
                        return formatDuration(label)
                    }
                },
            },
        }
    };

    return (
        <>
            <div className="row mt-5">
                <div className=" col-md-11 col-xl-11 col-lg-11 mx-auto">
                    <Line data={data} height={100} options={options}/>
                </div>
            </div>
        </>
    );
}

export default GraficaReportes;
