import React, {useContext, useRef} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {AdminRoute} from "../routes";
import {AuthContext} from "../utilities/auth/AuthContext";

export const LicActive = React.createContext(null);

export const VideoRoutes = (props) => {
    const sidebar = useRef(null);
    const mainPanel = React.useRef();
    const {user} = useContext(AuthContext);

    return (
        <>
            <div className="container-fluid">
                <div className="row main-panel" ref={mainPanel}>

                    <div className="col view-container">
                        <div className={(!window.location.pathname.includes("conference")) ? "container-fluid" : ""}>
                            <Switch>
                                {AdminRoute.map((prop, key) => {
                                    if (prop.roles.includes(user.user.idRole.idRole))
                                        return (
                                            <Route
                                                exact
                                                path={props.uri + prop.path}
                                                component={() => <prop.component modal={sidebar}/>}
                                                key={key}
                                                lics={prop.lic}
                                            />
                                        );
                                })}

                                <Route path="*">
                                    <Redirect to={props.uri + '/appointments'}/>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
