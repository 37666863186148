import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../utilities/auth/AuthContext";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

import {Organization} from "../../components/MiEquipo/Organization";
import {getCompanyById} from "../../services/company.service";
import Providers from "../../components/MiEquipo/Providers";
import {LineSeparator} from "../../components/common/LineSeparator";
import Branding from "../../components/MiEquipo/Branding";
import "../../assets/scss/components/miEquipo.scss";
import ProviderAlerts from "../../components/CommercialAccount/Alerts/ProviderAlerts";
import i18next from "i18next";
import moment from "moment/moment";
moment.locale(i18next.language);

const MiEquipoPage = (props) => {
    const {t} = useTranslation(["myTeam", "common"]);
    const {user: {user}} = useContext(AuthContext);
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reloadCompany, setReloadCompany] = useState(null);
    useEffect(() => {
        setLoading(true);
        getCompanyById(user.idCompany.idCompany).then((result) => {
            setCompany(result);
            setLoading(false);
        });
    }, [reloadCompany]);


    return (
        <div className="pb-3 px-3" style={{overflowX: "hidden", overflowY: "auto", height: "100%"}}>
            <ProviderAlerts className="mt-3"/>
            <div className="page-header row mb-4">
                <div className="col">
                    <h1 className="page-title">{t("title")}</h1>
                </div>
            </div>
            {!loading ?
                <>
                    {!loading &&  <Organization {...props} company={company} user={user} reload={() => {
                        setReloadCompany(Date.now());
                    }}/>}
                    <LineSeparator/>
                    {!loading && <Branding {...props} company={company} user={user} reload={() => {
                        setReloadCompany(Date.now());
                    }}/>}
                    <LineSeparator/>
                    {!loading && <Providers {...props} company={company} user={user}/>}
                </>
                :
                <div className="spinner-container">
                    <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                </div>
            }
        </div>
    );
}

export default MiEquipoPage;
