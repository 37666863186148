import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";

/**
 * Obtinene todas los sintomas de la base de datos
 *
 * @return {Promise<T>}
 */
export const getAllConditions = async () => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.condition.all
    ).catch(error => {
        throw error;
    })
    return response.data;
}