import React from "react";

import AppointmentItemStatus from "./AppointmentItemStatus";
import AppointmentItemDoctor from "./AppointmentItemDoctor";
import AppointmentItemHour from "./AppointmentItemHour";
import AppointmentItemPatient from "./AppointmentItemPatient";
import AppointmentItemPayment from "./AppointmentItemPayment";
import AppointmentItemCondition from "./AppointmentItemCondition";
import AppointmentItemOptions from "./AppointmentItemOptions";


const AppointmentItem = (props) => {

    return (
        <div id={"collapse" + props.groupIndex} className="collapse show pb-1" aria-labelledby="heading1"
             data-parent={"#accordionData-" + props.groupIndex}>
            {props.appointments.map((itemAppointment) => {
                return (
                    <div key={itemAppointment.idService} className="card table-item-appointment pl-2 pr-2 mb-2 mt-2">
                        <div className="card-body p-1">
                            <div className="row  no-gutters">
                                <div className="col-9 col-md-8 col-lg-6 col-xl-8 order-1 order-md-1 align-self-center">
                                    <AppointmentItemDoctor doctor={itemAppointment.idProvider}/>
                                    {itemAppointment.idCompany.brandingName !== props.user.user.user.idCompany.brandingName &&
                                    <span className="ml-5">{itemAppointment.idCompany.brandingName}</span>
                                    }
                                </div>
                                <div
                                    className="col-3 col-md-2 col-lg-2 col-xl-2 order-2 order-md-4 align-self-center text-right text-md-right ">
                                    <AppointmentItemStatus status={itemAppointment.status}/>
                                </div>
                                <div className="col-12 order-3 order-md-5 separator hidden-md"/>
                                <div
                                    className="col-3 col-md-1 col-lg-2 col-xl-1 order-6 order-md-3 text-right text-md-right align-self-center">
                                    <AppointmentItemHour date={itemAppointment.serviceDate}
                                                         hour={itemAppointment.time}/>
                                </div>
                                <div className="col-12 col-md-5 col-lg-5 col-xl-5 order-4 order-md-6 align-self-center">
                                    <AppointmentItemPatient user={itemAppointment.idUser}/>
                                </div>
                                <div
                                    className="col-3 col-md-1 col-lg-2 col-xl-1 order-7 order-md-2 text-right text-md-right align-self-center">
                                    <AppointmentItemPayment status={itemAppointment.status}
                                                            price={itemAppointment.price}
                                                            isPaid={itemAppointment.isPaid}/>
                                </div>
                                <div className="col-6 col-xs-4 col-md-3 col-lg-3 order-5 order-md-7 align-self-center">
                                    <AppointmentItemCondition condition={itemAppointment.idCondition.name}/>
                                </div>
                                <div className="col-12 order-8 order-md-12 separator separator2"/>
                                <div
                                    className="col-12 col-xs-8 col-md-4 col-lg-4 order-9 order-md-8  text-right text-md-right align-self-center">
                                    <AppointmentItemOptions {...props} itemAppointment={itemAppointment}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default AppointmentItem;
