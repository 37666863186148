import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import videoOff from "../../../../../assets/images/video/video-off.svg";
import videoIcon from "../../../../../assets/images/video/video-active.svg";
import {forwardRef, useImperativeHandle, useState} from "react";

const ButtonMuteCamera = forwardRef((props, reference) => {
    const {t} = useTranslation(["video","common"] );
    const [videoStatus, setVideoStatus] = useState(false);

    useImperativeHandle(reference, () => ({
        toggleMute: () => {
            setVideoStatus(!videoStatus);
        }
    }));

    return (
        <>
            <button data-tip={videoStatus ? t("camera-active") : t("camera-deactivate")}
                    className="btn btn-link color-white"
                    onClick={() => {
                        setVideoStatus(!videoStatus);
                        /*
                        props.dispatch({
                            source: !props.videoState().muteCamera,
                            payload: "set-mute-camera",
                        });

                         */
                        //props.localVideoReference.current.setVideo();
                        props.localVideoReference.current.setVideo(videoStatus);
                    }}>
                {videoStatus
                    ? <img src={videoOff} alt="videoOff"/>
                    : <img src={videoIcon} alt="videoIcon"/>
                }
            </button>
            <ReactTooltip/>
        </>

    );
})
export default ButtonMuteCamera;
