import React from 'react';
import DayGroupHeader from "./DayGroupHeader";
import AppointmentItem from "./AppointmentItem";

const DayGroup = (props) => {
    return (
        <div key={props.index}>
            <div className="accordion" id={"accordionData-" + props.index}>
                <div className="mb-2">
                    <DayGroupHeader {...props}/>
                    <AppointmentItem {...props} groupIndex={props.index} appointments={props.item.appointments}/>
                </div>
            </div>
        </div>
    );
}

export default DayGroup;
