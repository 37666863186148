import React, {useContext, useState} from "react";
import Dropzone from "react-dropzone";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import moment from "moment";

import {loaderContext} from "../../EHApp";
import {setDetail} from "../../services/provider.service";
import {getAllSpecialty} from "../../services/specialty.service";
import {orderByLabel} from "../../utilities";
import 'assets/scss/styles/_configureAccount.scss';
import {checkProtectedRoutes} from "../../services/auth.service";
import AuthLayout from "../../routers/AuthLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import defaultAvatar from "../../assets/images/default-avatar.svg";

export const ConfigureAccount = () => {
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {t} = useTranslation('common');
    let history = useHistory();
    const location = useLocation();
    const minDate = moment().subtract(120, "years").format('YYYY-MM-DD');
    const maxDate = moment().subtract(21, "years").format('YYYY-MM-DD');
    const activeStep = 4;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    checkProtectedRoutes(location, history);

    const onAcceptTerms = () => {
        toggle();
    }

    const onSubmit = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("DEA", data.DEA);
        formData.append("biography", data.presentation);
        formData.append("gender", data.gender);
        formData.append("specialty", data.idSpecialty || null);
        formData.append("dateBirth", data.bornDate);
        formData.append("profile", (blob) ? blob : null);
        formData.append("idProvider", location.state.idProvider);
        formData.append("language", localStorage.getItem('language'));

        for (let prop in location.state.data)
            formData.append(prop, location.state.data[prop]);

        setDetail(formData).then(() => {
            setLoader(false);
            history.push({
                pathname: '/auth/formation',
                state: {
                    company: location.state.company,
                    idProvider: location.state.idProvider,
                    doctorName: location.state.doctorName,
                    identification: location.state.identification,
                    username: location.state.username,
                    isProvider: location.state.isProvider
                },
            });
        }).catch((error) => {
            setLoader(false);
            console.log(error);
        });
    }

    const [image, setImage] = useState(null);
    const [blob, setBlob] = useState(null);
    const [specialty, setSpecialty] = useState([]);
    const [placeHolder, setPlaceHolder] = useState([]);

    React.useEffect(() => {
        (async () => {
            setLoader(true);
            const response = await getAllSpecialty();
            const translatedSpecialties = response.map((item) => ({
                label: t(`specialties.${item.name}`),
                value: item.idSpecialty
            }));
            setSpecialty(orderByLabel(translatedSpecialties));
            setPlaceHolder(t('complete-profile.add-image'))
            setLoader(false);
            setModal(location.state.isProvider);
        })();
    }, []);

    return (
        <>

            <Modal isOpen={modal} backdrop={'static'}>
                <ModalHeader tag={"h5"}>{t('common:login.legal-title')}</ModalHeader>
                <ModalBody>
                    {t('common:login.legal-message1')}<a target="_blank"
                                                         href={t('common:login.legal-terms-link')}>{t('create-provider.terms-conditions')}</a>{t('create-provider.and')}
                    <a target="_blank"
                       href={t('common:login.legal-policy-link')}>{t('create-provider.privacy-policies')}</a>{t('common:login.legal-message2')}
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Button color="primary" onClick={onAcceptTerms}>{t('common:login.legal-btn')}</Button>

                </ModalFooter>
            </Modal>

            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters configure-account-wrapper">
                    <div className="col-12 half">
                        <AuthLayout activeStep={activeStep} isProvider={location.state.isProvider}>
                            <article className="h-100 row no-gutters justify-content-center align-items-center">
                                <div className="col-8 text-center">
                                    <form onSubmit={handleSubmit(onSubmit)} className="max-725 p-0">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="mb-4">{t('complete-profile.sub-title')}</h1>
                                            </div>
                                            <div className="col-12 col-md-4 mb-4 d-flex justify-content-center">
                                                <div className="drop-container mt-md-4">
                                                    <img src={(image) ? image : defaultAvatar}
                                                         className="rounded-circle user-avatar"
                                                         alt="profile"/>
                                                    <Dropzone accept="image/jpeg, image/png"
                                                              minSize={1}
                                                              maxSize={1097152}
                                                              onDrop={(acceptedFiles, _rejectedFiles) => {
                                                                  let reader = new FileReader();
                                                                  reader.onload = (e) => {
                                                                      let contents = e.target.result;
                                                                      const _blob = new Blob([contents]);
                                                                      const url = URL.createObjectURL(_blob);
                                                                      setImage(url);
                                                                      setPlaceHolder("");
                                                                  };


                                                                  if (acceptedFiles.length > 0) {
                                                                      setBlob(acceptedFiles[0]);
                                                                      reader.readAsArrayBuffer(acceptedFiles[0]);
                                                                  }


                                                              }}>
                                                        {({
                                                              getRootProps,
                                                              getInputProps,
                                                              fileRejections
                                                          }) => (
                                                            <section className="">
                                                                <div className="section-container " {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <div className="dropzone-placeholder ">
                                                                        <FontAwesomeIcon className="edit-icon-avatar"
                                                                                         icon={faPencilAlt}/>
                                                                    </div>

                                                                    <p className="m-0 p-0 info">
                                                                        <small
                                                                            className="">{t('global.only-images')}</small>
                                                                    </p>

                                                                    {fileRejections.length > 0 && fileRejections[0].file.size > 2097152 && (
                                                                        <div className="text-danger mt-2">
                                                                            {t('global.file-large')}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>
                                            <div
                                                className="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-5 mt-sm-5 pt-5 mt-md-0  pt-md-0">
                                                <div className="half pt-2 pt-md-0">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-4">
                                                            <div className="form-group">
                                                                <label>{t('complete-profile.gender')}</label>
                                                                <select className="form-control form-control-sm"
                                                                        defaultValue=""
                                                                        name="gender" {...register("gender", {required: true})}>
                                                                    <option
                                                                        hidden
                                                                        value="">{t('global.select-option-gender')}</option>
                                                                    <option
                                                                        value="F">{t('gender.female')}</option>
                                                                    <option
                                                                        value="M">{t('gender.male')}</option>
                                                                    <option
                                                                        value="O">{t('gender.other')}</option>
                                                                </select>
                                                                {errors.gender &&
                                                                <span
                                                                    className="error">{t('global.required')}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="form-group">
                                                                <label>{t('complete-profile.born-date')}</label>
                                                                <input className="form-control form-control-sm"
                                                                       type="date" name="bornDate"
                                                                       min={minDate}
                                                                       max={maxDate}
                                                                       {...register("bornDate", {required: true})}/>
                                                                {errors.bornDate &&
                                                                <span
                                                                    className="error">{t('global.required')}</span>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {location.state.idProviderType === "1" &&
                                                    <div className="form-group">
                                                        <label>{t('complete-profile.specialty')}</label>
                                                        <select className="form-control form-control-sm"
                                                                defaultValue=""
                                                                placeholder={t('complete-profile.specialty')}
                                                                name="idSpecialty" {...register("idSpecialty", {required: true})}>
                                                            <option
                                                                hidden
                                                                value="">{t('global.select-option-placeholder')}</option>
                                                            {
                                                                specialty.map((item) => {
                                                                    return <option key={item.value}
                                                                                   value={item.value}>{item.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {errors.idSpecialty &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </div>
                                                    }

                                                    <div className="form-group">
                                                        <label>DEA</label>
                                                        <input className="form-control form-control-sm" type="text"
                                                               placeholder="DEA"
                                                               name="DEA" {...register("DEA", {
                                                            maxLength: {
                                                                value: 15,
                                                                message: t('global.maxlengthDea')
                                                            }
                                                        })} />
                                                        {errors.DEA &&
                                                        <span className="error">{errors.DEA.message}</span>}
                                                    </div>

                                                    <div className="form-group">
                                                        <label>{t('complete-profile.presentation')}</label>
                                                        <textarea className="form-control form-control-sm"
                                                                  placeholder={t('complete-profile.presentation')}
                                                                  name="presentation" {...register("presentation", {required: false})} />
                                                        {errors.presentation &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3">
                                            <Button className="btn btn-primary btn-sm submit align-content-center">
                                                {t('global.next')}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfigureAccount;
