import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Card, CardBody, CardText, CardTitle, Label} from "reactstrap";
import {getAllCommunications} from "../../services/communication.service";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {serviceStatusEnum} from "../../utilities";
import i18next from "i18next";
import logo from "../../assets/images/logo.svg";
import LanguageSelector from "../../components/TopNavBar/LanguageSelector";
import {verifyService} from "../../services/stripe.service";
import appointmentsFinishedIcon from "../../assets/images/icons/appointment-finished.svg";
import appointmentsCancelledIcon from "../../assets/images/icons/appointment-cancelled.svg";
import appointmentsExpiredIcon from "../../assets/images/icons/appointment-expired.svg";
import appointmentsIcon from "../../assets/images/icons/appointment-loading.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";


export default function ClientVideoData() {
    let history = useHistory();
    const {roomName} = useParams();

    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const {t} = useTranslation('common');
    const [communication, setCommunication] = useState([]);
    const [isLoadingVerify, setIsLoadingVerify] = useState(false);

    const [statusForShow, setStatusForShow] = useState(null);

    const onSubmit = (data) => {
        localStorage.setItem("user-external", `${data.name} ${data.lastName}`);
        history.push({
            pathname: `/guest/conference/${roomName}`,
            state: {
                roomName: roomName,
                user: `${data.name} ${data.lastName}`,
                communication: data.communicationContent,
                isLegit: true,
                isGuest: true
            },
        });
    }

    React.useEffect(() => {
        (async () => {
            const data = await getAllCommunications();
            setCommunication(data.map((item) => ({label: item.type, value: item.idCommunication})));
        })();
    }, []);


    React.useEffect(() => {
        setIsLoadingVerify(true)
        verifyService(roomName).then((response) => {
            setIsLoadingVerify(false)
            switch (response.status) {
                case serviceStatusEnum.finished:
                    console.log('fisnished');
                    setStatusForShow({
                        status: serviceStatusEnum.finished,
                        icon: appointmentsFinishedIcon,
                        title: t('common:appointment-check.status-finished'),
                        message: t('appointment-check.finished'),
                    })
                    break;
                case serviceStatusEnum.cancelled:
                    console.log('canceled');
                    setStatusForShow({
                        status: serviceStatusEnum.cancelled,
                        icon: appointmentsCancelledIcon,
                        title: t('common:appointment-check.status-cancelled'),
                        message: t('appointment-check.cancelled'),
                    })
                    break;
                case serviceStatusEnum.expired:
                    console.log('expired');
                    setStatusForShow({
                        status: serviceStatusEnum.expired,
                        icon: appointmentsExpiredIcon,
                        title: t('common:appointment-check.status-expired'),
                        message: t('appointment-check.expired'),
                    })
                    break;
                default:
                    setStatusForShow({
                        status: response.status,
                        icon: appointmentsIcon,
                        title: t('common:appointment-check.status-pending'),
                        message: t('appointment-check.pending'),
                    })
                    break;
            }
        });
    }, []);


    if (isLoadingVerify) return (
        <div className="row vh-100">
            <div className="col-12 align-self-center">
                <div className="text-center">
                    <img className="mb-3" width="150px" src={appointmentsIcon} alt=""/>
                    <p className="">{t('global.verify')}</p>
                    <FontAwesomeIcon icon={faSpinner} className="spinner mb-3 spinner-status"/>
                </div>
            </div>
        </div>
    )

    return (
        <div className="container">
            <div className="row d-inline">
                <div className="col-12 text-center logo-container">
                    <img className="" width={250} alt="Healthy Encounter" src={logo}/>
                    <LanguageSelector room={roomName}/>
                </div>
            </div>

            {(statusForShow !== null && statusForShow.status === serviceStatusEnum.inProgress) ?

                <div className="mt-3 row row-flex-center">
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5" className="text-center">{t('global.invited-data')}</CardTitle>
                            <CardText>{t('global.invited-data-header')}</CardText>

                            <form onSubmit={handleSubmit(onSubmit)} className="">
                                <div className="">
                                    <div className=" form-group">
                                        <Label for="name" className="">{t('create-provider.name')}</Label>
                                        <input className="form-control form-control-sm" type="text"
                                               name="name" {...register("name", {required: true})}
                                               placeholder={t('create-provider.name')}/>
                                        {errors.name && <small className="error">{t('global.required')}</small>}
                                    </div>

                                    <div className="form-group">
                                        <Label className="">{t('create-provider.lastname')}</Label>
                                        <input className="form-control form-control-sm" type="text"
                                               name="lastName" {...register("lastName", {required: true})}
                                               placeholder={t('create-provider.lastname')}/>
                                        {errors.lastName &&
                                        <small className="error">{t('global.required')}</small>}
                                    </div>

                                    <div className="form-group">
                                        <Label for="idCommunication"
                                               className="">{t('create-provider.communication-type')}</Label>
                                        <select className="form-control form-control-sm"
                                                defaultValue=""
                                                name="idCommunication" {...register("idCommunication",
                                            {required: true})}
                                                placeholder={t('create-provider.communication-type')}>
                                            <option value="" hidden>{t('global.select-option-placeholder')}</option>
                                            {
                                                communication.map((item, _index) => {
                                                    return <option key={item.value}
                                                                   value={item.value}>{t(`communication.${item.label}`)}</option>
                                                })
                                            }
                                        </select>

                                        {errors.idCommunication &&
                                        <span className="error ml-2 d-block">{t('global.required')}</span>}
                                    </div>

                                    <div className="form-group">
                                        <Label for="communicationContent"
                                               className="">{t('create-provider.communication')}</Label>
                                        <input className="form-control form-control-sm" type="text"
                                               name="communicationContent" {...register("communicationContent", {
                                            required: true,
                                            pattern: {
                                                value: watch("idCommunication") === "2" ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ : /[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                                                message: t('global.invalid-format')
                                            }
                                        })} placeholder={t('create-provider.communication')}/>

                                        {errors.communicationContent &&
                                        <span className="error ml-2 d-block">{t('global.required')}</span>}
                                    </div>

                                    <div className="form-group form-check">
                                        <input className="form-check-input" name="acceptTerms"
                                               type="checkbox" {...register("acceptTerms", {required: true})}
                                               id="acceptTerms"/>
                                        <label className="form-check-label">
                                            {t('create-provider.terms')}
                                            <a href={(i18next.language == "es") ? `https://www.telemedik.com/index.php/terminos-y-condiciones-de-uso/` : `https://www.telemedik.com/index.php/en/terms-and-conditions-of-use/`}
                                               target="_blank">{t('create-provider.terms-conditions')}</a>
                                            {t('create-provider.and')}
                                            <a href={(i18next.language == "es") ? `https://www.telemedik.com/index.php/politica-de-privacidad-2/` : `https://www.telemedik.com/index.php/en/politica-de-privacidad/`}
                                               target="_blank">{t('create-provider.privacy-policies')}</a>
                                        </label>
                                        {errors.acceptTerms &&
                                        <span className="error ml-2 d-block">{t('global.required')}</span>}
                                    </div>
                                    <button type="submit" className="mt-4 btn btn-primary">
                                        {t('global.next')}
                                    </button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>

                :

                <div className="row vh-100">
                    <div className="col-12 align-self-center">
                        <div className="text-center">
                            <img className="mb-3" width="150px" src={statusForShow?.icon} alt=""/>
                            <h3>{statusForShow?.title}</h3>
                            <p className="">{statusForShow?.message}</p>
                        </div>
                    </div>
                </div>
            }


        </div>
    );
}
