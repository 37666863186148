export const localVideoSourceReducer = (state, action) => {
    switch (action.payload) {
        case 'video':
            return {
                video: action.add,
                audio: [...state.audio],
                currentAudioSource: state.currentAudioSource,
                currentVideoSource: state.currentVideoSource
            }
        case 'audio':
            return {
                audio: action.add,
                video: [...state.video],
                currentAudioSource: state.currentAudioSource,
                currentVideoSource: state.currentVideoSource
            }
        case 'setAudio':
            return {
                audio: [...state.audio],
                video: [...state.video],
                currentAudioSource: action.source,
                currentVideoSource: state.currentVideoSource
            }
        case 'setVideo':
            return {
                audio: [...state.audio],
                video: [...state.video],
                currentAudioSource: state.currentAudioSource,
                currentVideoSource: action.source
            }
        default:
            throw new Error();
    }
}
