import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {request} from "../../../../../utilities/base/request";
import {hostname} from "../../../../../utilities";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {BrowserView, MobileView, isMobile, isBrowser} from 'react-device-detect';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import i18next from "i18next";

let saveTimeout = null;

const Note = forwardRef((props, reference) => {
    const [show, setShow] = useState(false);
    const [note, setNoteInput] = useState("");
    const [time, setTime] = useState("");
    const {t} = useTranslation("video");

    const saveNote = (dataInput) => {
        request("POST", hostname + "/service/soap", null, {
            idService: props.idService,
            soap: dataInput
        }, true).then(() => setTime(moment().format('MM/DD/YY h:mm:ss A')))
    }

    useImperativeHandle(reference, () => ({
        toggle: () => setShow(!show),
        getNoteView: () => show,
        setFalse: () => setShow(false)
    }));

    useEffect(() => {
        request("GET", hostname + "/service/get/soap", {idService: props.idService}, null, true)
            .then((response) => {
                setNoteInput(response.data);
            });
        return () => {
        };
    }, []);

    return <>
        {show &&
            <div className="soapContainer">
                <div className="soapLayout">
                    <div className="soapHeader d-flex justify-content-between align-items-center">
                        <span className="title">{t('soap-title')}</span>
                        {isMobile ? <div>
                            <button className="btn btn-outline-secondary mr-2 mt-2"
                                    onClick={() => {
                                        setShow(!show);
                                        props.chatReference.current.setState({
                                            toggle: true,
                                            payload: "toggle-chat"
                                        });
                                    }}>
                                {t('chat')}
                            </button>
                            <button className="btn btn-danger mr-2 mt-2"
                                    onClick={() => {
                                        setShow(!show);
                                        props.closeSideBar();
                                    }}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </button>
                        </div>:<></>}

                    </div>
                    <div className="documentContainer">
                        <textarea className="soapField" placeholder={t("input-soap-placeholder")} value={note} onChange={event => {
                            setNoteInput(event.target.value);
                            clearTimeout(saveTimeout);
                            saveTimeout = setTimeout(() => saveNote(note), 3000);
                        }}/>
                    </div>
                    <div className="soapFooter">
                        {time ? <span className="saved"><strong>{t("saved")}</strong> {moment(time).locale(i18next.language).calendar()}</span> :
                            <span className="saved "><strong>{t("saved")}</strong> --</span>}
                    </div>
                </div>
            </div>
        }
    </>
});

export default Note;
