import {Button, ModalBody, ModalFooter} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

const AcceptPrivacy = (props) => {
    const {t} = useTranslation(['common']);

    return (<>
        <ModalBody>
            <h5>{t('global.policy')}</h5>
            <p>
                {t('policy.cookies')}
            </p>
        </ModalBody>

        <ModalFooter>
            <Button color="primary" onClick={() => {
                localStorage.setItem('privacy', true);
                props.modal.current.openModal();
            }}>{t('global.accept')}</Button>
        </ModalFooter>
    </>);
};

export default AcceptPrivacy;
