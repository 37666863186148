import React from 'react';
import {useTranslation} from "react-i18next";
import {LineSeparator} from "../common/LineSeparator";
import {formatDuration, formatElapsed, formatTableElapsed} from "../../utilities";

const DatosGeneralesReportes = (props) => {
    const {t} = useTranslation('reports');
    const calculateMedia = () => {
        const localeCurrency = Intl.NumberFormat('en-US', {
            maximumFractionDigits: 3,
            style: "currency",
            currency: "USD"
        });

        if (props.amount.length > 0) {
            let total = props.amount.reduce((a, b) => a + b.price, 0);
            return localeCurrency.format(total/props.amount.length);
        } else {
            return 0;
        }

    }

    const calculateElapsedMedia = () => {
        if (props.finalize.length > 0) {
            let total = props.finalize.reduce((a, b) => a + b.elapsed, 0);
            return formatDuration(total/props.finalize.length);
        } else {
            return formatDuration(0);
        }
    }

    return (
        <>
            <div className="col reports-data mt-5 mb-5">
                <div className="row m-12">
                    <div className="col-sm-2">
                        <div className="row value-text">
                            {props.total}
                        </div>
                        <div className="row">
                            {t('generalData.Archived')}
                        </div>
                    </div>

                    <div className="col-sm-2">
                        <div className="row value-text">
                            {props.finalize.length}
                        </div>
                        <div className="row">
                            {t('generalData.Finalize')}
                        </div>
                    </div>

                    <div className="col-sm-2">
                        <div className="row value-text">
                            {props.cancelled.length}
                        </div>
                        <div className="row">
                            {t('generalData.Expired')}
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="row value-text">
                            {(props.duration.length > 0) ? calculateElapsedMedia() : "0m"}
                        </div>
                        <div className="row">
                            {t('generalData.Duration')}
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="row value-text">
                            {(props.amount.length > 0) ? calculateMedia() : 0}
                        </div>
                        <div className="row">
                            {t('generalData.Average')}
                        </div>
                    </div>

                </div>
            </div>
            <LineSeparator/>
        </>
    );
}

export default DatosGeneralesReportes;
