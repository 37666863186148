import React, {useState} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

import {getProviderByIdCompany, getProviderTotalByCompany} from "../../services/company.service";
import {ProviderItem} from "./ProviderItem";
import providersIcon from "../../assets/images/icons/providers.svg";
import addUserIcon from "../../assets/images/icons/add-user.svg";
import NewProvider from "../../views/Modal/NewProvider";
import {FormGroup, Input} from "reactstrap";

export const Providers = (props) => {
    const {t} = useTranslation(['myTeam', 'common']);
    const [loading, setLoading] = useState(false);
    const [createProvider, setCreateProvider] = useState(false);
    const [providers, setProviders] = useState([]);
    const [reloadProviders, setReloadProviders] = useState(null);

    const [status] = useState(1);

    const [filterPage, setFilterPage] = useState(0);
    const [filterTake, setFilterTake] = useState(2);
    const [pages, setPages] = useState([]);
    const [, setTotal] = useState([]);

    const statusChange = async (event) => {
        setLoading(true);
        console.log(props.company.idCompany)
        const providersResult = await getProviderByIdCompany(props.company.idCompany, props.user.idProvider, event.target.value, filterTake, filterPage);
        setProviders(providersResult);

        getProviderTotalByCompany(props.company.idCompany).then((response) => {
            setTotal(response - 1);
            setPages(Array((status == event.target.value) ? Math.ceil((response - 1) / filterTake) : Math.ceil(providersResult.length / filterTake)).fill(null))
        });
        setLoading(false);
    }

    const handleCreateProvider = () => {
        setCreateProvider(!createProvider);
        props.modal.current.setModal(<NewProvider {...props} onCreate={() => {
            setReloadProviders(Date.now());
        }}/>)
    }

    const pagesCallback = async (page, take) => {
        setFilterPage(page);
        setFilterTake(take);
        const providersResult = await getProviderByIdCompany(props.company.idCompany, props.user.idProvider, 1, take, page);
        setProviders(providersResult);
        //setPages(Array(Math.ceil(providersResult.length / filterTake)).fill(null))
    }

    React.useEffect(async () => {
        setLoading(true);
        console.log(props.company.idCompany)
        const providersResult = await getProviderByIdCompany(props.company.idCompany, props.user.idProvider, 1, filterTake, filterPage);
        setProviders(providersResult);

        getProviderTotalByCompany(props.company.idCompany).then((response) => {
            setTotal(response - 1);
            setPages(Array((status == 1) ? Math.ceil((response - 1) / filterTake) : Math.ceil(providersResult.length / filterTake)).fill(null))
        });
        setLoading(false);
    }, [props.company, reloadProviders]);

    return (
        <div className="roles mb-5">
            <div className="row mb-4 align-items-center">
                <div className="col">
                    <h5>{t('myTeam:providers.title')}</h5>
                </div>
                <div className="col-sm-12 col-md-3 text-md-right">
                    <button className="btn btn-primary btn-sm button-create-user" onClick={handleCreateProvider}><img
                        src={addUserIcon} alt=""/>
                        {t('myTeam:providers.create-button')}
                    </button>
                </div>
            </div>
            <div className="rol-list">

                <FormGroup className="p-0 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 " onChange={statusChange}>
                    <Input defaultValue={status} type="select" bsSize="sm" name="select">
                        <option value={1}>{t('myTeam:active')}</option>
                        <option value={0}>{t('myTeam:inactive')}</option>
                    </Input>
                </FormGroup>

                {!loading ?
                    <>
                        {providers.length > 0 ?
                            <>
                                {providers.map((item, _index) => {
                                    return (
                                        <ProviderItem key={item.idProvider} props={{...props}} provider={item}/>
                                    );
                                })}
                            </> :
                            <div className="noresults-container">
                                <img width="120px" src={providersIcon} alt=""/>
                                <p>{t('myTeam:providers.noResults')}</p>
                            </div>
                        }

                        <div className="mt-2 d-inline-block" style={{background: "#fafafc"}}>
                            {pages.map((_item, index) => {
                                return <button key={index} className="rs-pagination-btn" onClick={() => {
                                    pagesCallback(index, filterTake);
                                }}>{index + 1}</button>
                            })}
                        </div>
                    </> :
                    <div className="spinner-container">
                        <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                    </div>
                }
            </div>

        </div>
    )
}

Providers.propTypes = {
    user: PropTypes.object.isRequired
}

export default Providers;
