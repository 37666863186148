import React, {useCallback, useContext, useEffect, useReducer, useRef, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import {io as socketClient} from "socket.io-client";
import {AuthContext} from "../../../utilities/auth/AuthContext";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Modal} from "reactstrap";
import {toast, ToastContainer} from "react-toastify";
import moment from "moment";
import {BrowserView, isMobile, MobileView} from 'react-device-detect';
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {useTranslation} from "react-i18next";
import mobileRotationIcon from "../../../assets/images/mobile-rotation.svg";

import {
    hostname,
    localhost,
    kInitStats,
    kEnableStats,
    kRefreshStatsInterval,
    serviceStatusEnum
} from "../../../utilities";
import Note from "./components/sideBar/Note";
import Chat from "./components/sideBar/Chat";
import {iceServers} from "../../../utilities/Constants";
import LocalVideoStream from "./components/videos/LocalVideoStream";
import RemoteVideoStream from "./components/videos/RemoteVideoStream";
import {getService, validateData} from "../../../services/service.service";
import {videoStateReducer} from './utils/videoStateReducer';
import VideoHeader from "./components/header/VideoHeader";
import VideoFooter from "./components/footer/videoFooter";
import ConsultInfo from "./components/consultInfo/ConsultInfo";

import "./css/videoConference.scss";
import {InvitationModal} from "./components/invitation/invitation";
import costoLogo from "../../../assets/images/email-icons/costo.svg";

const RTCPeerConnection = window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection ||
    window.msRTCPeerConnection;

const RTCSessionDescription = window.RTCSessionDescription ||
    window.mozRTCSessionDescription ||
    window.webkitRTCSessionDescription ||
    window.msRTCSessionDescription;

const constraints = {
    video: {
        width: {min: 160, max: 1920},
        height: {min: 120, max: 1080}
    }
};

navigator.getUserMedia = navigator.getUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.msGetUserMedia;

const configuration = {iceServers};
let socket = null;
let peersList = {};
let connectedUsers = [];
let audioDevices = [];
let videoDevices = [];
let patientConnected = false;
let intervalStats;
let badConnections = [];
let lastVideoPacketsLost = 0;
let lastAudioPacketsLost = 0;

const initialVideoState = {
    service: null,
    inRoom: false,
    isScreen: false,
    muteMicrophone: false,
    muteCamera: false,
    consult: false,
    showSideBar: false,
    modal: false,
    localAudioDevices: [],
    localVideoDevices: [],
    audioDevice: "",
    videoDevice: "",
    initializing: true,
    patientConnected: false
}

const filterName = (socketItem) => {
    try {
        return connectedUsers.find((item) => item.id === socketItem).loggedUser;
    } catch (error) {
        console.error(error)
        return "Unknown";
    }
}

function countPeers(obj) {
    return Object.keys(obj).length;
}

const VideoConference = (props) => {

    const {t} = useTranslation(["video", "common"]);
    const context = useContext(AuthContext);
    const {roomName} = useParams();
    let history = useHistory();
    const location = useLocation();
    const handleScreen = useFullScreenHandle();

    const localVideoReference = useRef(null);
    const watchReference = useRef(null);
    const chatReference = useRef(null);
    const soapReference = useRef(null);
    const modalReference = useRef(null);
    const remoteReference = useRef(null);
    const statusAlertReference = useRef(null);
    const invitationModalReference = useRef(null);

    const [videoState, dispatch] = useReducer(videoStateReducer, initialVideoState);
    const [initializing, setInitializing] = useState(true);
    const [modalContent, setContent] = useState(false);
    const [layout, setLayout] = useState('');

    const toggle = useCallback(() => {
        dispatch({
            source: !videoState.modal,
            payload: "set-modal",
        });
    }, [videoState.modal]);

    const leaveLocal = () => {
        clearInterval(intervalStats);
        socket.emit('leave', roomName);
        patientConnected = false;
        dispatch({
            source: false,
            payload: "set-in-room",
        });
        dispatch({
            source: patientConnected,
            payload: "set-patientConnected",
        });

        peersList = {};
        connectedUsers = [];
        chatReference.current.setState({payload: "reset"});
        remoteReference.current.setDispatch({payload: "reset",});

        watchReference.current.watchReset();
    }

    const leave = useCallback((idSocket) => {
        let peer = peersList[idSocket];
        if (peer)
            peer.close();

        try {
            delete peersList[idSocket];
        } catch (error) {
            console.error(error);
        }

        console.log('leave remote', peersList);
        //setConnections(countPeers(peersList));
        if (Object.keys(peersList).length === 0 && videoState.inRoom)
            watchReference.current.watchReset();

        let chatList = [];
        chatReference.current.getState().channels.forEach((item, index) => {
            if (item.socket === idSocket)
                item.channel.close();
            else
                chatList.push(item);
        });

        chatReference.current.setState({
            chat: (chatList.length !== 0),
            channels: chatList,
            payload: "set-channel",
        });

        let set = remoteReference.current.getState().connections.filter((item) => {
            return item.socketId !== idSocket;
        });

        if (!set)
            set = [];

        remoteReference.current.setDispatch({
            set: set,
            payload: "set",
        });

    }, [videoState.inRoom]);

    const exchange = (data) => {
        //console.log('exchange',data);
        let fromId = data.from;
        let peer;
        if (fromId in peersList) peer = peersList[fromId];
        else {
            try {
                peer = createPC(fromId, false);
            } catch (err) {
                console.error(err);
            }

        }

        if (data.sdp)
            if (peer)
                peer.setRemoteDescription(new RTCSessionDescription(data.sdp))
                    .then(() => {
                        if (peer.remoteDescription.type === 'offer') {
                            peer.createAnswer()
                                .then(description => {
                                    peer.setLocalDescription(description)
                                        .then(() => {
                                            socket.emit('exchange', {
                                                to: fromId,
                                                sdp: peer.localDescription
                                            })
                                        }).catch(error => console.error(error));
                                })
                                .catch(error => console.error(error));
                        }
                    })
                    .catch(error => console.error(error));
    };

    const candidate = (data) => {
        let fromId = data.from;
        let peer = peersList[fromId];
        if (peer)
            peer.addIceCandidate(new RTCIceCandidate(data.candidate))
                .then(() => {
                })
                .catch((error) => {
                    console.log('candidate:addIceCandidate')
                    console.error(error)
                });
    };

//Ininializamos eventos del socket
    const setSocketEvents = (socket) => {
        socket.on('exchange', data => {
            //console.log('on socket exchange');
            exchange(data);
        });

        socket.on('feedback', data => {
            console.log("on socket feedback", data);
            remoteReference.current.setDispatch({
                filter: data,
                payload: "audio-video",
            });
        });

        socket.on('candidate', data => {
            //console.log('on socket candidate');
            candidate(data);
        });

        socket.on('leave', socketId => {
            console.log('on socket leave', socketId);
            leave(socketId);
        });

        socket.on('watch', roomEnter => {
            console.log('on socket watch', roomEnter);
            //const milliseconds = Math.abs(new Date().getTime() - roomEnter);
            watchReference.current.watchStart(roomEnter);
        });

        socket.on('remove', socketId => {
            //console.log('on socket remove');
            if (!context.user.logged)
                history.push({
                    pathname: '/client/removed',
                    state: {
                        remove: true,
                    },
                });
        });

        socket.on('connect', data => {
            console.log('on socket connected!');
            console.log(socket.id);
            getService(roomName).then((response) => {
                if (context.user.logged) {
                    if (response.status !== serviceStatusEnum.inProgress)
                        history.goBack()
                } else {
                    // Is patient
                    if (!location.state && response.status !== serviceStatusEnum.inProgress)
                        history.goBack()
                }

                dispatch({
                    source: response,
                    payload: "set-service",
                });

                dispatch({
                    source: false,
                    payload: "set-initializing",
                });
            }).catch(error => console.error(error));
        });

        socket.on('qualityConnections', (qualityConnection) => {
           // console.log('on socket qualityConnections', qualityConnection);
            remoteReference.current.setQualityConnection(qualityConnection);
        });
    }

//Inicializamos socket
    const socketInit = async () => {
        socket = socketClient(hostname, {secure: true});
        if (!socket.connected) {
            socket.connect();
        }
        setSocketEvents(socket);
    }

// Setea los dispositivos de audio y video disponibles
    const setAudioVideoDevices = (aDevices, vDevices) => {
        console.log('set audio video devices', aDevices, vDevices);
        dispatch({
            source: aDevices,
            payload: "set-audio-devices",
        });

        dispatch({
            source: vDevices,
            payload: "set-video-devices",
        });
        audioDevices = [...aDevices];
        videoDevices = [...vDevices];
    }

    const localVideoCallBack = () => {
        if (context.user.logged)
            getService(roomName).then((response) => {
                console.log("join in localVideoCallBack, logged");
                join(roomName, response);
            });
        else {
            validateData(roomName).then((response) => {
                if (!location.state && response.status !== serviceStatusEnum.inProgress) {
                    history.goBack();
                } else {
                    console.log("join in localVideoCallBack not logged");
                    join(roomName, response);
                }
            });
        }
    }

    const join = useCallback((roomName, service) => {
        dispatch({
            source: true,
            payload: "set-in-room",
        });
        socket.emit('join', roomName, {
            name: _detectName(),
            isLogged: !!(context.user.logged),
            patientId: (context.user.logged) ? null : service.idUser.idUser,
            mobile: isMobile,
            status: {audio: true, video: true},
        }, callback => {
            connectedUsers = callback.connectedList;
            console.log('join idsInRoom', callback.idInRoom);
            console.log('join connected', callback.connectedList);
            for (let i in callback.idInRoom) createPC(callback.idInRoom[i], true);
        });
    }, [videoState.service]);

    const _detectName = () => {
        if (context.user.logged) {
            return `${context.user.user.name} ${context.user.user.secondName}`;
        } else {
            if (location.state) {
                return location.state.user;
            }
        }
    }

    const generateStats = async (peerConnection) => {
        let baselineOutboundRtcReport, currentOutboundRtcReport;
        let baselineInboundRtcReport, currentInboundRtcReport;
        let statsOutput = "";
        try {
            const stats = await peerConnection.getStats(null);
            stats.forEach(report => {
                if (report.type === 'outbound-rtp' && report.kind === 'video') {
                    baselineOutboundRtcReport = report;
                } else if (report.type === 'inbound-rtp' && report.kind === 'video') {
                    baselineInboundRtcReport = report;
                }
            })
        } catch (e) {
            console.error(e);
        }


        setTimeout(async () => {

            try {
                const inBoundStats = await peerConnection.getStats(null).catch(error => {
                    console.error(error)
                });
                inBoundStats.forEach(report => {
                    if (report.type === 'outbound-rtp' && report.kind === 'video') {
                        currentOutboundRtcReport = report;
                    } else if (report.type === 'inbound-rtp' && report.kind === 'video') {
                        currentInboundRtcReport = report;
                    }
                });
            } catch (e) {
                console.error(e);
            }

            console.log('currentOutboundRtcReport', currentOutboundRtcReport);
            console.log('currentInboundRtcReport', currentInboundRtcReport);
            processStats(peerConnection, baselineOutboundRtcReport, currentOutboundRtcReport, baselineInboundRtcReport, currentInboundRtcReport);
        }, 1000)
    }


    const processStats = (peerConnection, baselineOutboundRtcReport, currentOutboundRtcReport, baselineInboundRtcReport, currentInboundRtcReport) => {
        try {
            const bytesSent = currentOutboundRtcReport.bytesSent - baselineOutboundRtcReport.bytesSent;
            const bytesReceived = currentInboundRtcReport.bytesReceived - baselineInboundRtcReport.bytesReceived;
            let qualityConnection = {};
            let bandwidthLimit = 0;
            let cpuLimit = 0;
            let otherLimit = 0;
            let noneLimit = 0;

            console.log(baselineOutboundRtcReport["qualityLimitationReason"]);
            //statusAlertReference.current.showAlert()
            const qualityLimitationDurations = baselineOutboundRtcReport["qualityLimitationDurations"];
            if (qualityLimitationDurations) {
                const total = Object.keys(qualityLimitationDurations).reduce((acc, cur) => acc + qualityLimitationDurations[cur], 0);
                bandwidthLimit = qualityLimitationDurations.bandwidth / total;
                cpuLimit = qualityLimitationDurations.cpu / total;
                otherLimit = qualityLimitationDurations.other / total;
                noneLimit = qualityLimitationDurations.none / total;
            }
            qualityConnection = {
                id: socket.id,
                durations: {
                    bandwidth: bandwidthLimit,
                    cpu: cpuLimit,
                    other: otherLimit,
                    none: noneLimit
                },
                reason: baselineOutboundRtcReport["qualityLimitationReason"]
            };

            console.log(`QUALITY CONNECTION SEND: ${socket.id}`, qualityConnection, peerConnection);
            socket.emit('qualityConnections', qualityConnection);
        } catch (err) {
            // console.error(err);
        }
    }


    const createPC = useCallback((socketId, isOffer) => {
        let peer;
        let dataChannel;
        try {
            peer = new RTCPeerConnection(configuration);
            dataChannel = peer.createDataChannel(roomName, {negotiated: true, id: 0});
        } catch (error) {
            console.error(error);
        }

        peersList = {
            ...peersList,
            [socketId]: peer,
        };

        console.log('createPC: peersList', peersList);
        //console.log('peersList count', countPeers(peersList));
        peer.onicecandidate = event => {
            console.log("onicecandidate");
            if (event.candidate)
                socket.emit('candidate', {to: socketId, candidate: event.candidate});
        };

        peer.onnegotiationneeded = () => {
            //console.log("onnegotiationneeded");
            if (isOffer) {
                peer.createOffer().then(description => {
                    peer.setLocalDescription(description).then(() => {
                        socket.emit('exchange', {to: socketId, sdp: peer.localDescription});
                    });
                });
            }
        };

        peer.ontrack = event => {
            console.log('peer on track');
            socket.emit('retrieve', callback => {
                connectedUsers = callback.connectedList;
                console.log('retrieve', connectedUsers);
                if (remoteReference.current.getState().connections.filter(e => e.socketId === socketId).length === 0) {


                    let currentStatus = connectedUsers.filter((user) => {
                        if (user.id === socketId) return user;
                    });

                    if (currentStatus.length > 0) {
                        remoteReference.current.setDispatch({
                            add: {
                                id: event.streams[0].id,
                                text: filterName(socketId),
                                socketId: socketId,
                                stream: event.streams[0],
                                video: currentStatus[0].status?.video,
                                audio: currentStatus[0].status?.audio
                            },
                            payload: "update",
                        });

                        const patient = connectedUsers.find(connection => connection.patientId != null);
                        if (patient) {
                            patientConnected = true;
                            dispatch({
                                source: patientConnected,
                                payload: "set-patientConnected",
                            });
                        }
                    }
                }
            });
        }

        try {
            localVideoReference.current.getStream().getTracks().forEach(function (track) {
                peer.addTrack(track, localVideoReference.current.getStream());
            });
        } catch (error) {
            console.error(error);
        }

        dataChannel.onerror = error => {
            console.error('dataChannel.onerror', error);
        };

        dataChannel.onmessage = event => {
            let nameSocket = filterName(socketId);
            toast(`${nameSocket}: ${event.data}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });

            chatReference.current.setState({
                add: {
                    isLocal: false,
                    message: {inputValue: event.data, time: moment().format('LLL')},
                    name: nameSocket
                },
                payload: "plus",
            });
        };

        dataChannel.onopen = () => {
        };

        dataChannel.onclose = () => {
            //console.log('dataChannel.onclose');
            setLayout(updateLayout());
        };

        chatReference.current.setState({
            channel: {socket: socketId, channel: dataChannel},
            payload: "add-channel",
        });

        peer.ondatachannel = dataChannel;

        peer.oniceconnectionstatechange = event => {
            if (event.target.iceConnectionState === 'connected') {
                console.log('enable:', kEnableStats);
                console.log('init:', kInitStats);
                console.log('refresh:', kRefreshStatsInterval);

                if (kEnableStats) {
                    console.log('show stats');
                    setTimeout(() => {
                        intervalStats = setInterval(() => {
                            try {
                                generateStats(peer).then().catch(error => console.error(error));
                            } catch (e) {
                                console.error(e);
                            }
                        }, kRefreshStatsInterval);
                    }, kInitStats);
                } else {
                    console.log('no stats')
                }

            }

            if (event.target.iceConnectionState === 'disconnected') {
                console.log('oniceConnectionState disconnected');

            }

            if (event.target.iceConnectionState === 'closed') {
                console.log('oniceConnectionState closed');
                peer.close();
            }
        };

        peer.onsignalingstatechange = event => {
            if (event.target.iceConnectionState === 'connected') {

            }
        };

        peer.onremovestream = event => {
            setLayout(updateLayout());
        };

        setLayout(updateLayout());

        return peer;

    }, []);

    const updateLayout = () => {
        if (localVideoReference.current) {
            localVideoReference.current.setConnections(countPeers(peersList));
            return countPeers(peersList);
        }
    }

    useEffect(() => {

        const handleOrientationChange = () => {
            if (window.orientation === 90 || window.orientation === -90) {
                //alert("Por favor, usa el modo vertical para una mejor experiencia.");
            }
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        // Limpieza
        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);



    useEffect(() => {
        if (socket) {
            console.log('socket', socket.id);
            console.log('peer', peersList);
        }

    }, [peersList]);

    useEffect(() => {

        if (location.pathname.split('/')[1] === 'client' || location.pathname.split('/')[1] === 'guest') {
            if (location.state?.user) {
                if (initializing === true) {
                    socketInit().then();
                }
            } else {
                redirectToForm();
            }
        } else {
            if (initializing === true) {
                socketInit().then();
            }
        }

        return () => {
            socket.emit('leave', roomName);
            socket.disconnect();
            socket.close();
        };



    }, []);


    const redirectToForm = () => {
        getService(roomName).then((response) => {
            if (location.pathname.split('/')[1] === 'client') {
                window.location.href = `${localhost}/client/verification/${response.idUser.idUser},${response.idUser.mail},${response.idProvider.idProvider},${response.idService}`
            } else {
                window.location.href = `${localhost}/guest/invited/${response.idService}`;
            }
        });
    }
    const closeSideBar = () => {
        //console.log('close sideBar');
        dispatch({
            source: false,
            payload: "set-sidebar",
        });
    }

    return (
        <div className="bodyContainer">
            <InvitationModal ref={invitationModalReference} chatReference={chatReference}/>
            <Modal backdrop="static" isOpen={videoState.modal} centered={true} toggle={toggle} ref={modalReference}>
                {modalContent}
            </Modal>
            {videoState.initializing === true
                ?
                <div className="d-flex align-items-center justify-content-center spinnerContainer">
                    <div className="">
                        <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                    </div>
                </div>
                :
                <>

                    <MobileView>
                        <div className="videoMobileLayout">
                            <div className="alertWrapper">
                                <div className="alertLabel">
                                    <img className="iconMobileRotation" src={mobileRotationIcon}/>
                                    <div>{t("better-experience")}</div>
                                </div>
                            </div>
                            <div
                                className={(videoState.showSideBar) ? "rightSideBarContent onTop" : "rightSideBarContent onBack"}>
                                {context.user.logged &&
                                    <Note ref={soapReference} idService={roomName} closeSideBar={closeSideBar}
                                          chatReference={chatReference}/>
                                }
                                <Chat ref={chatReference} closeSideBar={closeSideBar} soapReference={soapReference}
                                      service={videoState.service}/>
                            </div>
                            <div className="videoMobileContainer">
                                <div className="mainContent">
                                    <VideoHeader className="videoHeader"
                                                 videoState={() => videoState}
                                                 dispatch={dispatch}
                                                 context={context}
                                                 socket={socket}
                                                 setContent={setContent}
                                                 audioDevices={() => audioDevices}
                                                 videoDevices={() => videoDevices}
                                                 modalReference={modalReference}
                                                 localVideoReference={localVideoReference}
                                                 watchReference={watchReference}
                                                 chatReference={chatReference}
                                                 soapReference={soapReference}
                                                 handleScreen={handleScreen}
                                                 getConnectedUsers={() => connectedUsers}
                                                 {...props}
                                    />
                                    <div className={"videoContent " + "m" + layout}>
                                        <RemoteVideoStream ref={remoteReference} mobile={true} socket={socket}/>
                                        <LocalVideoStream ref={localVideoReference} mobile={false}
                                                          join={true}
                                                          socket={socket}
                                                          audioVideoDevices={setAudioVideoDevices}
                                                          callback={localVideoCallBack}
                                                          connections={countPeers(peersList)}
                                                          getPeers={() => peersList}
                                                          {...props}
                                        />
                                    </div>
                                    <VideoFooter videoState={() => videoState}
                                                 dispatch={dispatch}
                                                 roomName={roomName}
                                                 join={join}
                                                 socket={socket}
                                                 setContent={setContent}
                                                 leaveLocal={leaveLocal}
                                                 modalReference={modalReference}
                                                 chatReference={chatReference}
                                                 soapReference={soapReference}
                                                 invitationModalReference={invitationModalReference}
                                                 localVideoReference={localVideoReference}
                                                 getConnectedUsers={() => connectedUsers}
                                                 {...props}
                                    />
                                </div>
                            </div>
                        </div>
                    </MobileView>
                    <BrowserView>
                        <FullScreen handle={handleScreen} className="forced-fullscreen">
                            <div className="videoBrowserLayout">
                                <div className="videoBrowserContainer"
                                     style={{gridTemplateColumns: (videoState.showSideBar) ? "7fr 3fr" : "10fr"}}
                                >
                                    {/*<StatusAlert ref={statusAlertReference}></StatusAlert>*/}
                                    <div className="mainContent">
                                        <VideoHeader className="videoHeader"
                                                     videoState={() => videoState}
                                                     dispatch={dispatch}
                                                     context={context}
                                                     socket={socket}
                                                     setContent={setContent}
                                                     audioDevices={() => audioDevices}
                                                     videoDevices={() => videoDevices}
                                                     modalReference={modalReference}
                                                     localVideoReference={localVideoReference}
                                                     watchReference={watchReference}
                                                     chatReference={chatReference}
                                                     soapReference={soapReference}
                                                     handleScreen={handleScreen}
                                                     getConnectedUsers={() => connectedUsers}
                                                     {...props}
                                        />
                                        <div className={"videoContent " + "l" + layout}>

                                            <RemoteVideoStream ref={remoteReference} mobile={false} socket={socket}/>
                                            <LocalVideoStream ref={localVideoReference} mobile={false}
                                                              join={true}
                                                              socket={socket}
                                                              audioVideoDevices={setAudioVideoDevices}
                                                              callback={localVideoCallBack}
                                                              connections={countPeers(peersList)}
                                                              getPeers={() => peersList}
                                                              {...props}
                                            />
                                        </div>
                                        <VideoFooter videoState={() => videoState}
                                                     dispatch={dispatch}
                                                     roomName={roomName}
                                                     join={join}
                                                     socket={socket}
                                                     setContent={setContent}
                                                     leaveLocal={leaveLocal}
                                                     modalReference={modalReference}
                                                     chatReference={chatReference}
                                                     soapReference={soapReference}
                                                     localVideoReference={localVideoReference}
                                                     invitationModalReference={invitationModalReference}
                                                     getConnectedUsers={() => connectedUsers}
                                                     {...props}
                                        />
                                    </div>
                                    <div className={"rightSideBarBrowserContent"}>
                                        {context.user.logged &&
                                            <Note ref={soapReference} idService={roomName}/>
                                        }
                                        <Chat ref={chatReference} {...props} service={videoState.service}/>
                                    </div>
                                </div>
                            </div>
                        </FullScreen>
                    </BrowserView>

                    {(videoState.consult) && <ConsultInfo videoState={videoState} dispatch={dispatch}/>}
                </>}

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover={false}/>
        </div>

    );
}

export default VideoConference;
