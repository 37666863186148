import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useHistory} from "react-router-dom";

import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {Button, Form} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import 'assets/scss/styles/_passwordChange.scss';

import {findCommunicationByLabel, hostname, validateEmail, validatePhone} from "utilities";
import {isRegisteredReset} from "../../services/auth.service";
import {loaderContext} from "../../EHApp";
import {toast} from "react-toastify";
import AuthLayout from "../../routers/AuthLayout";

export const PasswordChange = () => {
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const [formLoading, setFormLoading] = useState(false);
    const [communication, setCommunication] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm();
    let history = useHistory();

    const {t} = useTranslation('common');

    const userNotFound = () => {
        return (
            <>
                <small className="text-danger"><FontAwesomeIcon className="error-not-found mr-2 f-18"
                                                                icon={faTimesCircle}/> {t('forgot-password.not-exist')}
                </small>
            </>
        );
    }

    const onSubmit = async (data) => {
        setFormLoading(true);
        let idCommunication = "";
        if (validateEmail(data.user)) {
            idCommunication = findCommunicationByLabel(communication, "Electronic mail");
        } else if (validatePhone(data.user)) {
            idCommunication = findCommunicationByLabel(communication, "Short message service");
        } else {
            setFormLoading(false);
            return toast.error(t("global.email-valid"));
        }
        console.log('call isRegisteredReset');
        isRegisteredReset(true, data.user, data.user, idCommunication).then((response) => {
            console.log('response');
            (response)
                ? history.push({
                    pathname: '/auth/forgot/2',
                    state: {
                        data: {user: data.user, idCommunication: idCommunication},
                    },
                })
                : setNotFound(true)
            setFormLoading(false);
        }).catch((error) => {
            setFormLoading(false);
            console.error(error.response);
            if (error.response.status === 403) {
                toast.error(t("global.email-not-exists"));
            } else {
                toast.error(t("global.error"));
            }
        });
    }

    React.useEffect(() => {
        (async () => {
            const response = await fetch(hostname + "/communication/all");
            const body = await response.json();
            setCommunication(body.map((item) => ({label: item.type, value: item.idCommunication})));
        })();
    }, []);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters remember-wrapper">
                    <div className="col-12 half">
                        <AuthLayout>
                            <article className="h-100 row justify-content-center align-items-center">
                                <div className="col-10 col-md-6 col-lg-5 col-xl-4">
                                    <Form className=" block-width " onSubmit={handleSubmit(onSubmit)}>
                                        <h1 className=" animate__animated animate__bounce">{t('forgot-password.forgotten-password')}</h1>
                                        <label
                                            className="recover-description">{t('forgot-password.forgot-password-description')}</label>
                                        <div className="form-group mt-2">
                                            <label>{t('forgot-password.user')}</label>
                                            <input className="form-control form-control-sm" type="text" name="user"
                                                   placeholder={t('forgot-password.username-placeholder')} {...register("user", {
                                                required: t('global.required'),
                                            })} />
                                            {errors.user && <span className="error">{errors.user.message}</span>}
                                        </div>
                                        {notFound && userNotFound()}
                                        <Button disabled={formLoading}
                                                className="submit mt-3 button-link">{t('login.recover-password')}</Button>
                                    </Form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PasswordChange;
