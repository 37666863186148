import {ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {createContext, useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGreaterThan, faSpinner, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {getAllCommunications} from "../../services/communication.service";
import {getAllRoles} from "../../services/role.service";
import {getAllProviderTypes} from "../../services/providertype.service";
import {createTeamProvider, getByMail, validateNpi} from "../../services/provider.service";
import {AuthContext} from "../../utilities/auth/AuthContext";
import {EHIcon} from "../../components/icons/EHIcon";
import moment from "moment/moment";
import {getLicense} from "../../services/license.service";
import CreateSubscriptionButton from "../../components/CommercialAccount/Subscription/CreateSubscriptionButton";
import {toast} from "react-toastify";
import {mailPattern, noSpacesPattern} from "../../utilities";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";

export const docData2 = React.createContext(null);
export const licContext = createContext({
    lic: "",
    docname: "",
    docmail: "",
    docid: "",
    data: ""
});

let typingTimer;

const NewProvider = (props) => {
    const setlic = useContext(licContext);
    const {t} = useTranslation(["myTeam", "common"]);
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const [communication, setCommunication] = useState([]);
    const [providerType, setProviderType] = useState([]);
    const [role, setRole] = useState([]);
    const [npiRequired, setNpiRequired] = useState(false);
    const [validate, setValidate] = useState(null);
    const [providerResponse, setProviderResponse] = useState(null);
    const {user: {user}} = useContext(AuthContext);
    const [error, setError] = useState(null);

    const [disabled, setDisabled] = useState(true);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [validCommunicationContent, setValidCommunicationContent] = useState(true);

    const buttonPaymentRef = useRef();

    const typingHandler = () => {
        let regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
        if (regex.test(watch("communicationContent"))) {
            getByMail(watch("communicationContent")).then((response) => {
                setVerifyEmail(false);
                setDisabled(response !== '');
                if (response) {
                    setValidCommunicationContent(false);
                } else {
                    setValidCommunicationContent(true);
                    setDisabled(false);
                }

            });
        } else {
            setVerifyEmail(false);
            setValidCommunicationContent(false);
        }
    }

    React.useEffect(async () => {

        const roles = await getAllRoles();
        setRole(roles.map((item) => ({label: item.name, value: item.idRole})));
        const types = await getAllProviderTypes();
        setProviderType(types.map((item) => (
            {
                label: item.name,
                value: item.idProviderType,
                licenseCost: item.idLicenseType.cost,
                licenseInternal: item.idLicenseType.internal,
                isManagerType: item.isManagerType,
                npi: item.npiRequired
            })));
        setCommunication((await getAllCommunications()).map((item) => ({
            label: item.type,
            value: item.idCommunication
        })));
        setLoading(false);

    }, []);

    const onPay = () => {
        props.onCreate();
        props.modal.current.openModal();
    }

    const onSubmit = async (data) => {

        if (validate === null) {
            data.idCommunication = "2"; // Al comentar temporalmente el selector idCommunication, lo enviamos directamente
            let provider = providerType.find(e => e.value?.toString() === data.idProviderType);
            if (provider === undefined) {
                setError('Se ha producido un error');
                return;
            }
            if ((data.NPI === null || data.NPI === "" || data.NPI === undefined) && providerType.find((r) => r.value == data.idProviderType).npi == 0) {
                // Si el npi no es requerido y viene vacio continuamos registro

            } else {
                console.log('npi', data.NPI);
                const result = await validateNpi(data.NPI, null, data.firstName, data.lastName);
                if (result && data.NPI !== "") {
                    // Si se valida npi y NPI no esta vacio continuamos registro
                } else {
                    toast.error(t('common:global.npi-invalid'));
                    return;
                }
            }
            let rol = role.find(e => e.label?.toString() === data.rol);
            data['providerTypeName'] = provider?.label;
            data['licenseCost'] = provider?.licenseCost;
            data['licenseInternal'] = provider?.licenseInternal;
            data['idProvider'] = user.idProvider;
            data['idRole'] = rol.value;
            setValidate(data);
            getLicense(user.idProvider).then((license) => {
                setlic.lic = license.idLicenseType.idLicenseType;
            });

        } else {
            try {
                if (validate.licenseInternal !== 1) {
                    setProviderResponse(await createTeamProvider(validate));
                    buttonPaymentRef.current.openModal();
                } else {
                    setProviderResponse(await createTeamProvider(validate));
                    onPay();
                }
            } catch (_error) {

            }
        }
    }

    const NPINotValid = () => {
        return (
            <div className="mt-1">
                <FontAwesomeIcon className="error-not-found mr-2 f-18" icon={faTimesCircle}/>
                <label className="error-not-found">{t('common:global.npi-invalid')}</label>
            </div>
        );
    }


    React.useEffect(() => {
        const subscription = watch((value) => {
            let provider = providerType.find((i) => {
                if (i.value.toString() === value.idProviderType)
                    return i;
            });
            if (provider)
                setNpiRequired(provider.npi);
            else
                setNpiRequired(false);
        });

        return () => subscription.unsubscribe();

    }, [watch, providerType]);

    return (
        <>
            <ModalHeader toggle={props.toogle ? props.toogle : props.modal.current.openModal}>
                <div className="d-flex-row">
                    <div className="d-inline-flex align-self-center">
                        <div className="mr-2">
                            <EHIcon className="d-inline-flex modal-icon align-self-center" height="35px" width="35px"
                                    name="icon add-user"/>
                        </div>
                        <span className="d-inline-flex modal-title ml-1">{t("newUserForm.title")}</span>
                    </div>
                </div>
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    {!loading
                        ?
                        validate == null
                            ? <div className="row body-size">
                                <div className="col-12">
                                    <p className="link-modal">
                                        <span>{t('myTeam:complete-data')}</span>
                                        <span className="mr-2 ml-2" style={{color: '#4a4a4a'}}>
                                    <FontAwesomeIcon icon={faGreaterThan}/></span>
                                        <span style={{color: "rgba(74, 74, 74, 0.5)"}}>{t('myTeam:confirm')}</span></p>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                    <label>{t('common:communication.Electronic mail')}</label>
                                    <div className="input-group input-group-sm mb-3">

                                        <input
                                            className={`form-control form-control-sm`}
                                            type="text"
                                            name="communicationContent" {...register("communicationContent", {
                                            required: t('common:global.required'),
                                            pattern: {
                                                // value: watch("idCommunication") === "2" ? mailPattern : phonePattern,
                                                value: mailPattern,
                                                message: t('common:global.invalid-format')
                                            }

                                        })}
                                            onKeyDown={() => {
                                                setVerifyEmail(true);
                                                setValidCommunicationContent(false);
                                                clearTimeout(typingTimer);
                                                typingTimer = setTimeout(typingHandler, 1000);
                                            }}
                                            placeholder={t('common:communication.Electronic mail')}/>

                                        {errors.communicationContent &&
                                        <div
                                            className="invalid-feedback order-last error">{errors.communicationContent.message}</div>}

                                        <div className="input-group-append">
                                            {verifyEmail
                                                ? <span className="input-group-text input-spinner-group"><FontAwesomeIcon
                                                    icon={faSpinner} className="input-spinner"/></span>
                                                : watch('communicationContent') !== '' && watch('communicationContent') !== undefined
                                                    ? validCommunicationContent
                                                        ?
                                                        <span className="input-group-text input-spinner-group icon-success"><FontAwesomeIcon
                                                            icon={faCheck} className=""/></span>
                                                        : <span className="input-group-text input-spinner-group icon-error"><FontAwesomeIcon
                                                            icon={faTimes} className=""/></span>
                                                    : <></>
                                            }

                                        </div>

                                        {!verifyEmail
                                            ? validCommunicationContent === true
                                                ? <div className="success-message"><small
                                                    className="success-message">{watch('communicationContent') === '' || watch('communicationContent') === undefined ? '' : t('common:global.valid-email')}</small>
                                                </div>
                                                : <div className="success-message"><small
                                                    className="error">{watch('communicationContent') === '' || watch('communicationContent') === undefined ? '' : t('common:global.email-inuse')}</small>
                                                </div>
                                            : <></>
                                        }

                                    </div>
                                    <div className="form-group">
                                        <label>{t("common:create-provider.name")}</label>
                                        <input className="form-control form-control-sm" type="text"
                                               name="firstName" {...register("firstName", {
                                            required: t('common:global.required'),
                                            pattern: {
                                                value: noSpacesPattern,
                                                message: t('common:global.notOnlySpaces')
                                            }

                                        })}
                                               placeholder={t("common:create-provider.name")}
                                               disabled={disabled}/>
                                        {errors.firstName &&
                                        <small className="error">{errors.firstName.message}</small>}
                                    </div>
                                    <div className="form-group">
                                        <label>{t("common:create-provider.lastname")}</label>
                                        <input className="form-control form-control-sm" type="text"
                                               disabled={disabled}
                                               name="lastName" {...register("lastName", {
                                            required: t('common:global.required'),
                                            pattern: {
                                                value: noSpacesPattern,
                                                message: t('common:global.notOnlySpaces')
                                            }
                                        })}
                                               placeholder={t("common:create-provider.lastname")}/>
                                        {errors.lastName &&
                                        <small className="error">{errors.lastName.message}</small>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>{t('newUserForm.rol')}</label>
                                        <select className="form-control form-control-sm" defaultValue=""
                                                disabled={disabled}
                                                name="rol" {...register("rol", {required: t('common:global.required')})}>
                                            <option hidden value="">{t('common:global.select-option-placeholder')}</option>
                                            <option value="MANAGER">{t('common:roles.MANAGER')}</option>
                                            <option value="PROVIDER">{t('common:roles.PROVIDER')}</option>
                                        </select>
                                        {errors.rol &&
                                        <small className="error">{errors.rol.message}</small>}
                                    </div>
                                    {watch('rol') !== '' &&
                                    <>
                                        <div className="form-group">
                                            <label>{t('common:professional-profile.professional-profile')}</label>
                                            <select className="form-control form-control-sm"
                                                    disabled={disabled}
                                                    defaultValue=""

                                                    name="idProviderType" {...register("idProviderType", {required: t('common:global.required')})}>

                                                <option hidden
                                                        value="">{t('common:global.select-option-placeholder')}</option>
                                                {
                                                    providerType.filter((item) => item.isManagerType === ((watch('rol') === 'PROVIDER') ? 0 : 1)).map((item) => {
                                                        return <option key={item.value}
                                                                       value={item.value}>{t("common:providerTypes." + item.label)}</option>
                                                    })
                                                }
                                            </select>
                                            {errors.idProviderType &&
                                            <small className="error">{errors.idProviderType.message}</small>}
                                        </div>

                                        {watch('rol') === 'PROVIDER' &&
                                        <div className="form-group">
                                            <label className="mr-sm-2">NPI</label>
                                            <input className="form-control form-control-sm" type="text"
                                                   name="NPI" {...register("NPI", {
                                                required: {
                                                    value: npiRequired,
                                                    message: t('common:global.required')
                                                },
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: t('common:global.invalid-format')
                                                }
                                            })} placeholder="NPI"/>
                                            {errors.NPI && <NPINotValid/>}
                                        </div>
                                        }
                                    </>
                                    }

                                    {error != null && <p className="error">Error no es posible crear proveedor</p>}
                                </div>
                            </div>
                            : <div className="row body-size justify-content-center">
                                <div className="col-12">
                                    <p className="link-modal">
                                            <span
                                                style={{color: 'rgba(74, 74, 74, 0.5)'}}>{t('myTeam:complete-data')}</span>
                                        <span className="mr-2 ml-2" style={{color: '#4a4a4a'}}><FontAwesomeIcon
                                            icon={faGreaterThan}/></span>
                                        <span>{t('myTeam:confirm')}</span></p>
                                    <h5 className="text-center">{t('myTeam:summary-invitation')}</h5>
                                </div>
                                <div className="col-8">
                                    <ul className="list-group list-group-flush p-0">
                                        <li className="list-group-item p-1">{t('myTeam:professional')}: <strong>{validate.firstName} {validate.lastName}</strong>
                                        </li>
                                        <li className="list-group-item p-1">{validate.idCommunication == 2 ? t('common:global.email') : t('common:global.phone')}: <strong>{validate.communicationContent}</strong>
                                        </li>
                                        <li className="list-group-item p-1">{t('myTeam:rol')} {t('myTeam:assign')}: <strong>{t(`common:roles.${validate.rol}`)} </strong>({t("common:providerTypes." + validate.providerTypeName)})
                                        </li>
                                        {providerType.licenseInternal === false &&
                                        <li className="list-group-item p-1">{t('myTeam:license-cost')}: <strong>${validate.licenseCost}</strong>
                                        </li>}
                                    </ul>
                                </div>
                                <div className="col-11 text-center mt-3">
                                    {providerType.licenseInternal === false ?
                                        <p>{t('myTeam:newUserForm.message-1')}<strong>{t('myTeam:newUserForm.message-2', {day: moment().format('D')})}</strong>{t('myTeam:newUserForm.message-3')}
                                        </p> : <p></p>}
                                </div>
                            </div>
                        :
                        <div className="d-flex align-items-center justify-content-center  body-size">
                            <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                        </div>}
                </ModalBody>
                <ModalFooter>

                    {validate && <button className="btn btn-secondary" onClick={() => {
                        setValidate(null);
                    }}>{t('common:global.cancel')}</button>}

                    <button disabled={loading} className="btn btn-primary"
                            type="submit">{!validate ? t('common:global.next') : providerType.licenseInternal === false ? t('myTeam:newUserForm.confirm') : t('common:global.confirm')}</button>

                </ModalFooter>

            </form>
            <CreateSubscriptionButton {...props} hide={true} provider={providerResponse} ref={buttonPaymentRef}
                                      reload={onPay}/>
        </>
    );
}

export default NewProvider;
