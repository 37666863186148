import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {authTypes, methodTypes} from "../utilities/types/types";
import {request} from "../utilities/base/request";


/**
 * Comprueba si un provider ya se encuentra registrado en la app.

 * @param {boolean} isProvider - Indica si un provider pertenece a la company del admin.
 * @param {string} communicationContent - Email/phone del provider.
 * @param {string} idCommunication - Identificador único del tipo de comunicación (email/phone).
 * @returns {Promise<Provider>} Devuelve un objeto [Provider](/backend/modelos/Communication)
 * @async
 * @function

 */
export const isRegistered = async (isProvider, communicationContent, idCommunication) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.isRegistered,
        null,
        {isProvider, communicationContent, idCommunication}
    )

    return response.data;
}

/**
 * Modifica el progreso en el registro del provider
 * @param {string} idProvider - Identificador único del provider
 * @function
 * @returns {Promise<any>}
 */
export const currentProgress = async (idProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.progress,
        null,
        {idProvider}
    )

    return response.data;
}

/**
 * Verifica que no exista el email/phone registrado previamente
 * @param {boolean} mail - Indica si el parámetro communicationContent es de tipo email
 * @param {string} user - Dirección de email o teléfono a verificar
 * @function
 * @returns {Promise<any>}
 */
export const providerCommunticationVerify = async (mail, user, locale) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.verify,
        null,
        {mail, user, locale}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Login con usuario y contraseña del provider
 * @param {boolean} isProvider - Indica si es un provider
 * @param {string} username - email de usuario
 * @param {string} identification - contraseña de usuario
 * @function
 * @returns {Promise<any>}
 */
export const authLogin = async (isProvider, username, identification) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.login,
        null,
        {isProvider, username, identification}
    )

    return response.data;
}

/**
 * Valida el código introducido por el usuario para validar su email o teléfono
 * @param {string} email - Correo electrónico o teléfono a validar
 * @param {string} code - Código introducido por el usuario
 * @function
 * @returns {Promise<any>}
 */
export const validateCode = async (email, code) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.validate,
        null,
        {user: email, insertedCode: code}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene el tiempo de creación del código.
 * @param {string} email - Correo electrónico o teléfono a validar
 * @function
 * @returns {Promise<any>}
 */
export const getExpiration = async (email) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.expiration,
        null,
        {user: email}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Añade o modifica el email de un provider
 * @param {string} mail - Correo electrónico
 * @param {boolean} isProvider - Indica si es un provider
 * @function
 * @returns {Promise<any>}
 */
export const setEmail = async (mail, isProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.setEmail,
        null,
        {mail, isProvider}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza el Identificador Nacional de Proveedores (NPI) de un provider
 * @function
 * @param {string} NPI - Código del Identificador Nacional de Proveedores
 * @param {boolean} isProvider - Indica si es un provider
 * @returns {Promise<any>}
 */
export const updateNPI = async (NPI, isProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.setEmail,
        null,
        {NPI, isProvider}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Añade o actualiza un número de teléfono del proveedor
 * @function
 * @param {string} phoneNumber - Número de teléfono
 * @param {boolean} isProvider - Indica si es un provider
 *
 * @returns {Promise<any>}
 */
export const setPhone = async (phoneNumber, isProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.setPhone,
        null,
        {phoneNumber, isProvider}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Marca el email como verificado del proveedor
 * @function
 * @param {string} email - Correo electrónico
 * @param {boolean} isProvider - Indica si es un provider
 *
 * @returns {Promise<any>}
 */
export const setEmailVerified = async (email, isProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.verified,
        null,
        {email, isProvider}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Marca el número de teléfono como verificado del proveedor
 * @function
 * @param {string} phoneNumber - Número de teléfono
 * @param {boolean} isProvider - Indica si es un provider
 *
 * @returns {Promise<any>}
 */
export const setPhoneVerified = async (phoneNumber, isProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.phoneVerified,
        null,
        {phoneNumber, isProvider}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Si existe provider o usuario con ese métedo de comunicación envía código a traves de dicho método utilizado.
 * @function
 * @param {boolean} isProvider - Indica si es un provider
 * @param {string} user - email o teléfono que se desea resetear contraseña (NO USADA)
 * @param {string} communicationContent - email o teléfono que se desea resetear contraseña
 * @param {string} idCommunication - Identificador único del tipo de comunicación utilizado
 *
 * @returns {Promise<any>}
 */
export const isRegisteredReset = async (isProvider, user, communicationContent, idCommunication) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.isRegisteredReset,
        null,
        {isProvider, user, communicationContent, idCommunication}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Incrementa el estado de progreso de registro de un proveedor
 * @function
 * @param {string} idProvider Identificador único del preveedor
 *
 * @returns {Promise<any>}
 */
export const progressUpdate = async (idProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.auth.progressUpdate,
        null,
        {idProvider}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Protege la ruta si no se está en proceso de registro y nos devuelve a login
 * @function
 * @param location
 * @param history
 */
export const checkProtectedRoutes = (location, history) => {
    window.addEventListener("popstate", () => {
        history.go(1);
    });
    if (!location.state)
        history.push({pathname: '/auth/login'});
}

/**
 * Cierra sesión de un usuario en la plataforma
 * @function
 * @param history
 * @param dispatch
 */
export const logout = (history, dispatch) => {
    history.replace('/login');
    dispatch({
        type: authTypes.logout,
    })
}


