import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {getAllCommunications} from "../../../../../services/communication.service";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {inviteUser} from "../../../../../services/service.service";
import {useForm} from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import {AuthContext} from "../../../../../utilities/auth/AuthContext";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

export const InvitationModal = forwardRef((props, reference) => {
    const [modal, setModal] = useState(false);
    const context = useContext(AuthContext);
    const [communication, setCommunication] = useState([]);
    const {t} = useTranslation(['video', 'common']);
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const location = useLocation();

    useImperativeHandle(reference, () => ({
        toggleModal: () => {
            console.log('toggle modal');
            toggle();
        },
    }));

    const toggle = () => setModal(!modal);

    const onSubmit = (data) => {
        inviteUser({
            communicationContent: data.communicationContent,
            idCommunication: data.idCommunication,
            idService: window.location.href.match(/([^\/]*)\/*$/)[1],
            locale: data.locale
        }).then(() => {

            console.log('invite');
            toggle();

            props.chatReference.current.getDispatch()({
                add: {
                    isLocal: true,
                    message: {
                        inputValue: `${t('video:invitation-sent')} ${data.communicationContent}.`,
                        time: moment().format()
                    },
                    name: _detectName()
                },
                payload: "plus",
            });

            props.chatReference.current.getState().channels.forEach((item, _index) => {
                if (item.channel.readyState !== "closed")
                    item.channel.send(`${t('video:invitation-sent')} ${data.communicationContent}.`);
            });

        }).catch((error) => {
            console.log(error);
        });
    }

    const _detectName = () => {
        if (context.user.logged) {
            return `${context.user.user.name} ${context.user.user.secondName}`;
        } else {
            if (location.state) {
                return location.state.user;
            }
        }
    }

    useEffect(() => {
        getAllCommunications().then((communications) => {
            setCommunication((communications).map((item) => ({label: item.type, value: item.idCommunication})));
        });

    }, []);

    return (
        <Modal backdrop="static" isOpen={modal} centered={true} toggle={toggle}>
            <ModalHeader toggle={() => setModal(false)}>
                {t('video:invite')}
            </ModalHeader>

            <ModalBody>
                <p>{t('video:message-invite')}</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label>{t('video:title-contact-method')}</label>
                        <select className="form-control form-control-sm"
                                name="idCommunication"  {...register("idCommunication", {required: true})}>
                            <option hidden value="">{t('common:global.select-option-placeholder')}</option>
                            {
                                communication.map((item, _index) => {
                                    return <option key={item.value}
                                                   value={item.value}>{t('common:communication.' + item.label)}</option>
                                })
                            }
                        </select>

                        {errors.idCommunication && <small className="error">{t('required')}</small>}
                    </div>

                    <div className="form-group">
                        <label>{t('contact')}</label>
                        <input className="form-control form-control-sm" type="text" name="communicationContent"
                               {...register("communicationContent", {
                                   required: true,
                                   pattern: watch("idCommunication") === "2"
                                       ? /^\S+@\S+$/i : /[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
                               })}
                               placeholder={watch("idCommunication") === "2"
                                   ? t("email-placeholder")
                                   : watch("idCommunication") === "1" ? t("phone-placeholder") : t("phone-email-placeholder")}/>

                        {errors.communicationContent && <small className="error">{t('required')}</small>}
                    </div>

                    <div className="form-group">
                        <label>{t('language')}</label>
                        <select className="form-control form-control-sm"
                                name="locale"  {...register("locale", {required: true})}>
                            <option hidden value="">{t('select-option-placeholder')}</option>
                            <option key={"en"} value={"en"}>{t('english')}</option>
                            <option key={"es"} value={"es"}>{t('spanish')}</option>
                        </select>

                        {errors.locale && <small className="error">{t('required')}</small>}
                    </div>

                    <button type="submit" className="btn btn-primary mt-2 submit">{t('send-invitation')}</button>
                </form>
            </ModalBody>
        </Modal>
    );
})
