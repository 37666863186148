import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faCheckCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import UserAvatar from "../common/UserAvatar";
import CreateSubscriptionButton from "../CommercialAccount/Subscription/CreateSubscriptionButton";
import {getStripeSubscription, handleActiveCancelSubscription} from "../../services/stripe.service";
import DeleteSubscriptionButton from "../CommercialAccount/Subscription/DeleteSubscriptionButton";
import moment from "moment/moment";
import ReactivateSubscriptionButton from "../CommercialAccount/Subscription/ReactivateSubscriptionButton";
import {getLicense} from "../../services/license.service";

export const ProviderItem = ({props, provider}) => {
    const {t} = useTranslation(["common", "profile"]);
    const [processing, setProcessing] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [costLabel, setCostLabel] = useState("--");
    const [showCancelButton, setShowCancelButton] = useState(false);
    const buttonPaymentRef = useRef();

    useEffect(() => {
        if (provider.idProviderType.isManagerType === 1) return;
        getLicenseCostLabel();
        handleSubscription();
    }, []);

    const handleReload = async () => {
        setTimeout(() => {
            handleSubscription();
        }, 5000);
    }

    const getLicenseCostLabel = () => {
        if (provider.cost === "") {
            setCostLabel("--");
        } else {
            if (provider.cost === null) {
                getLicense(provider.idProvider).then((license) => {
                    setCostLabel(`$${license.idLicenseType.cost}${t("global.costTime")}`);
                });
            } else {
                setCostLabel(`$${provider.cost}${t("global.costTime")}`);
            }
        }
    }

    const handleSubscription = () => {
        setProcessing(true);
        getStripeSubscription(provider.idProvider).then((stripeSubscription) => {
            setSubscription(stripeSubscription);
            setShowCancelButton(handleActiveCancelSubscription(stripeSubscription));
            setProcessing(false);
        });
    }

    const onPay = async () => {
        setProcessing(true);
        await handleReload();
    }

    return (
        <div className="card mb-1">
            <div className="card-body">
                <div className="row rol-item align-items-center">
                    <div className="col-1 order-0 order-md-0 order-lg-0">
                        <FontAwesomeIcon
                            icon={(subscription?.status === 'active' || subscription?.status === 'trialing' || provider.idProviderType.isManagerType === 1) ? faCheckCircle : faBan}
                            className={(subscription?.status === 'active' || subscription?.status === 'trialing' || provider.idProviderType.isManagerType === 1) ? provider.progress == 8 ? "valid-subscription" : "not-completed" : "invalid-subscription"}/>
                    </div>
                    <div className="col-7 col-md-5 col-lg-5 col-xl-4 order-1 order-md-1 order-lg-1">
                        <div className="d-inline-flex">
                            <UserAvatar className="user-avatar" alt="" profile={provider?.idDetail?.profile}/>
                            <div className="pl-2 align-self-center">
                                <span className="p-0 m-0"><strong>{provider.name} {provider.secondName}</strong></span>
                                <small className="d-block">{provider.mail}</small>
                                <small className="">{t("common:providerTypes." + provider.idProviderType?.name)}</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 order-4 order-md-2 order-lg-2">
                        <div className="d-flex flex-column">
                            <span
                                className="d-block">{provider.idProviderType?.idProviderType === 1 ? provider.idSpecialty?.name ? provider.idSpecialty?.name : t('global.without-specialty') : ''}</span>
                            <span>{provider.NPI ? "NPI: " + provider.NPI : t('global.without') + " NPI"}</span>
                        </div>
                    </div>
                    <div className="col-4 col-md-3 col-lg-3 order-2 order-md-3 order-lg-3 text-right tex-md-right">
                        {costLabel}

                    </div>

                    <div className="col-6 col-md-5 col-lg-5 order-5 order-md-6 order-lg-6 text-right text-md-right">
                        {(provider.idProviderType.isManagerType !== 1) ?
                            processing === true
                                ? <FontAwesomeIcon icon={faSpinner} className="spinner-button spinner-button-provider"/>
                                : subscription != null
                                    ? showCancelButton ?
                                        <DeleteSubscriptionButton
                                            {...props}
                                            user={provider}
                                            reload={handleReload}
                                        />
                                        : <>
                                            {subscription.cancel_at_period_end &&
                                            <>
                                                <div className="alert alert-warning text-left">
                                                        <span>
                                                            {t("profile:Stripe.stripe_cancel_at_end_message")}
                                                        </span>
                                                </div>

                                                <ReactivateSubscriptionButton
                                                    {...props}
                                                    user={provider}
                                                    reload={handleReload}
                                                />
                                            </>
                                            }

                                            {provider.isStripeRegister == null &&
                                            <div className="ml-2 d-inline-block">
                                                <CreateSubscriptionButton {...props} hide={false} provider={provider}
                                                                          ref={null} reload={onPay}/>
                                            </div>
                                            }

                                        </>
                                    :
                                    <CreateSubscriptionButton
                                        {...props}
                                        ref={buttonPaymentRef}
                                        reload={onPay}
                                    />
                            : <></>
                        }
                    </div>

                    <div className="col-12 col-md-12 order-6 order-md-8 separator d-md-none"/>

                    <div className="col-12 col-md-7 order-7 order-md-5">
                        <div className="d-flex flex-column">
                            {(provider.idProviderType.isManagerType !== 1) ?
                                <>
                                    <span>
                                        <strong
                                            className="mr-2">{t("profile:Stripe.stripe_subcription_status")}</strong>
                                        {subscription?.status ? t('common:subscriptionStatus.' + subscription?.status) : t("profile:Stripe.no-subscription")}
                                    </span>
                                </>
                                : <></>
                            }
                            {subscription &&
                            <>
                                                <span>
                                                    <strong
                                                        className="mr-2">{t("profile:Stripe.stripe_subcription_periodStart")}</strong>
                                                    {moment(subscription?.currentPeriodStart).format('LLLL')}
                                                    </span>
                                <span><strong
                                    className="mr-2"> {t("profile:Stripe.stripe_subcription_periodEnd")}</strong>
                                    {moment(subscription?.currentPeriodEnd).format('LLLL')}</span>
                                <span><strong
                                    className="mr-2"> {t("profile:Stripe.stripe_subcription_type")}</strong> {t("profile:Stripe.stripe_subcription_cicle")}</span>
                            </>
                            }
                        </div>
                    </div>
                    {(provider.idProviderType.isManagerType !== 1) ?
                        <div className="col-12 col-md-12 order-3 order-md-4 separator "/> : <></>}

                </div>
            </div>
        </div>
    );
}
