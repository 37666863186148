import axios from "axios";
import {localhost} from "../../utilities";

function parseToken(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

/**
 * Helper para llamar a endpoints del api
 * @param method
 * @param url
 * @param params
 * @param data
 * @param authenticationRequired
 * @returns {Promise<AxiosResponse<any>>}
 */
export const request = async (method, url, params, data, authenticationRequired = false,) => {
    const locale = localStorage.getItem('language');
    const headers = {
        'content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }

    const config = {
        headers: authenticationRequired && headers,
        method: method,
        url: url
    };

    if (params) {
        params = {locale, ...params};
        config['params'] = params;
    } else if (data) {
        try {
            data.append('locale', locale);
        } catch (e) {
            data = {locale, ...data};
        }

        config['data'] = data;
    } else {
        config['params'] = {locale};
    }

    if (localStorage.getItem('token') && localStorage.getItem('token') != "undefined") {
        let tokenDate = new Date(parseToken(localStorage.getItem('token')).exp * 1000);
        if (tokenDate < (new Date().getTime() / 1000.0)) {
            localStorage.removeItem('token');
            localStorage.setItem("user", JSON.stringify({logged: false}));
            window.location.href = `${localhost}/auth/login`;
        }
    }

    return axios(config).catch((error) => {

        if (error.response?.status === 401) {
            localStorage.removeItem('token');
            localStorage.setItem("user", JSON.stringify({logged: false}));
            window.location.href = `${localhost}/auth/login`;
        }

        throw error;
    });
}
