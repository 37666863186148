import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {localhost, serviceStatusEnum} from "../../utilities";
import logo from "../../assets/images/logo-beta.svg";
import appointmentsFinishedIcon from "../../assets/images/icons/icon-patient-disconnect.svg";
import LanguageSelector from "../../components/TopNavBar/LanguageSelector";
import i18next from "i18next";
import "../../assets/scss/views/clients.scss";
import {useForm} from "react-hook-form";

export default function ClientFinish(_props) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {t} = useTranslation("common");
    const {id} = useParams();
    const [statusForShow, setStatusForShow] = useState(null);
    let history = useHistory();
    let parameters = id.split(",");
    let data = {
        "idUser": parameters[0],
        "mail": parameters[1],
        "idProvider": parameters[2],
        "idServ": parameters[3]
    };

    useEffect(() => {
        setStatusForShow({
            status: serviceStatusEnum.finished,
            icon: appointmentsFinishedIcon,
            message: t('appointment-check.patient-finished'),
            redirectButton: true
        })
    }, [i18next.language]);

    const onSubmit = (formData) => {
        console.log("onSubmit_:", formData)
        history.push("/client/verification/" + id);

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="container overflow-hidden h-100">
            <div className="mt-3 row d-flex justify-content-between align-items-center">
                <div className="w-25"></div>
                <div className="w-25 text-center"><LanguageSelector/></div>
            </div>
            <div className="d-flex justify-content-center mt-1">
                <img className="" width={250} alt="Logo Healthy Encounter" src={logo}/>
            </div>

            <div className="checkoutContainer">
                <div className="align-self-center self text-center">
                    <img className="mb-3" width="135px" src={statusForShow?.icon} alt=""/>
                    {statusForShow?.title && <h3 className="mt-4">{statusForShow?.title}</h3>}
                    <p className="mt-4">{statusForShow?.message}</p>
                    {statusForShow?.message2 && <p className="mt-4">{statusForShow?.message2}</p>}
                    {statusForShow?.redirectButton && <button type="submit" className="btn btn-primary mt-3">
                        {t("common:appointment-confirmation.access")}
                    </button>}
                </div>
            </div>


        </div>
        </form>

    );
}
