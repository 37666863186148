import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";

export const Timer = (props) => {
    return (
        <div className="d-flex watch-container align-items-center">
            <FontAwesomeIcon icon={faClock}/>
            <span className="counter ml-2">{("0" + Math.floor((props.time / 3600000) % 60)).slice(-2)}:</span>
            <span>{("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:</span>
            <span>{("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}</span>
        </div>
    );
}
