import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBirthdayCake,
    faBriefcaseMedical,
    faEnvelope,
    faExternalLinkAlt,
    faPencilAlt,
    faPhoneAlt,
    faTimesCircle,
    faUser,
    faUserMd
} from "@fortawesome/free-solid-svg-icons";
import defaultAvatar from "../../assets/images/default-avatar.svg";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../utilities/auth/AuthContext";
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import {profileExtractProvider, updateDetailImage, updateProvider, validateNpi} from "../../services/provider.service";
import {isRegistered, logout, updateNPI} from "../../services/auth.service";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import {authTypes} from "../../utilities/types/types";
import {noSpacesPattern, orderByLabel, passwordPattern, phonePattern} from "../../utilities";
import Dropzone from "react-dropzone";
import {getAllSpecialty} from "../../services/specialty.service";
import i18next from "i18next";
import {getAllRoles} from "../../services/role.service";
import {getAdmin} from "../../services/providertype.service";

export const CardProfile = () => {

    const {t} = useTranslation(['profile', 'common']);
    const {user, dispatch} = useContext(AuthContext);
    const [activeEdit, setActiveEdit] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [userBlob, setUserBlob] = useState(null);
    const history = useHistory();
    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm();
    const minDate = moment().subtract(120, "years").format('YYYY-MM-DD');
    const maxDate = moment().subtract(21, "years").format('YYYY-MM-DD');
    const [specialty, setSpecialty] = useState([]);
    const [providerType, setProviderType] = useState([]);
    const [validNPI, setValidNPI] = useState(true);
    const [role, setRole] = useState([]);
    const [npiRequired, setNpiRequired] = useState(false);
    moment.locale(i18next.language);

    React.useEffect(() => {
        (async () => {
            const roles = await getAllRoles();
            setRole(roles.map((item) => ({label: item.name, value: item.idRole})));
            const types = await getAdmin();
            setProviderType(types.map((item) => (
                {
                    label: item.name,
                    value: item.idProviderType,
                    npi: item.npiRequired
                })));

        })();
    }, []);

    useEffect(() => {
        convertToImage();
        extractProvider();
    }, []);

    useEffect(() => {
        moment.locale(i18next.language);
    }, [i18next.language]);

    const goBack = () => {
        setValidNPI(true);
        setActiveEdit(false);
    }

    const onSubmit = async (data) => {

        if (data.idProviderType != 1) {
            data.idSpecialty = null;
        }
        let canSubmit = false, redirect = 0;

        if (data.NPI && data.NPI !== "" && defaultValues.NPI !== data.NPI) {
            const result = await validateNpi(data.NPI);
            if (result) {
                await updateNPI(data.NPI, true);
                setValidNPI(true);
            } else {
                //toast.error(t("common:global.npi-invalid"))
                setValidNPI(false);
                return;
            }
        }

        if (data.phoneNumber !== "" && defaultValues.idPhone?.phoneNumber !== data.phoneNumber) {
            await isRegistered(true, data.phoneNumber, 1).then(response => {
                if (response === false) {
                    canSubmit = false
                    redirect = 1;
                } else {
                    toast.error(t("common:global.phone-registered"));
                    canSubmit = true;
                }

            }).catch(error => {
                console.error(error);
            });
        }

        if (data.mail !== "" && defaultValues.mail !== data.mail) {
            await isRegistered(true, data.mail, 2).then(response => {
                if (response === false) {
                    canSubmit = false;
                    redirect = redirect + 2;
                } else {
                    toast.error(t("common:global.email-registered"));
                    canSubmit = true;
                }
            }).catch(error => {
                console.error(error);
            });
        }

        if (!canSubmit) {
            updateProvider(data).then((_response) => {
                extractProvider();
                setActiveEdit(false);
                if (redirect > 0) {
                    let param = "";
                    switch (redirect) {
                        case 1:
                            param = data.phoneNumber;
                            break;
                        case 2:
                            param = data.mail;
                            break;
                        case 3:
                            param = `${data.phoneNumber}-${data.mail}`;
                    }
                    history.replace('/' + user.user.idCompany.name + `/communication/update/${redirect}/${param}`);
                }
            });
        }
    };

    const toggle = () => {
        setActiveEdit(!activeEdit);
    }

    const setSpecialties = async (_provider) => {
        const response = await getAllSpecialty();
        const translatedSpecialties = response.map((item) => ({
            label: t(`common:specialties.${item.name}`),
            value: item.idSpecialty
        }));
        setSpecialty(orderByLabel(translatedSpecialties));
    }

    const extractProvider = () => {
        profileExtractProvider(user.user.idProvider).then((response) => {

            (async () => {
                await setSpecialties(response);
            })();
            reset(
                {
                    name: response.name,
                    secondName: response.secondName,
                    gender: response.gender,
                    NPI: response.NPI,
                    dateBirth: response.dateBirth,
                    mail: response.mail,
                    DEA: response.DEA,
                    phoneNumber: response.idPhone?.phoneNumber,
                    biography: response.idDetail.biography,
                    idProviderType: response.idProviderType.idProviderType,
                    idSpecialty: response.idSpecialty?.idSpecialty,
                }
            );
            setDefaultValues(response);
        });
    }

    const convertToImage = () => {
        if (user.user.idDetail.profile) {
            let arrayBufferView = new Uint8Array(user.user.idDetail.profile.data);
            let blob = new Blob([arrayBufferView], {type: "image/jpeg"});
            let urlCreator = window.URL || window.webkitURL;
            setUserBlob(urlCreator.createObjectURL(blob));
        }
    };

    const uploadAvatar = (blob) => {
        let formData = new FormData();
        formData.append("profile", (blob) ? blob : null);
        formData.append("idDetail", user.user.idDetail.idDetail);

        updateDetailImage(formData).then((detail) => {
            user.user.idDetail = detail;
            dispatch({
                type: authTypes.profile,
                payload: {
                    user: user.user,
                    token: user.token,
                    company: user.company,
                    license: user.license
                }
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleLogout = () => {
        logout(history, dispatch);
    }

    React.useEffect(() => {
        const subscription = watch((value) => {
            let provider = providerType.find((i) => {
                if (i.value == value.idProviderType)
                    return i;
            });
            if (provider)
                setNpiRequired(provider?.npi);
            else
                setNpiRequired(false);
        });

        return () => subscription.unsubscribe();

    }, [watch, providerType]);

    return (
        <>
            {!activeEdit
                ? <div className="card">
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 text-right">
                                <FontAwesomeIcon onClick={toggle} className="edit-icon" icon={faPencilAlt}/>
                            </div>
                            <div className="col-12 col-md-3 text-center mb-1">
                                <div className="drop-container">
                                    <img src={(userBlob) ? userBlob : defaultAvatar}
                                         className="rounded-circle user-avatar"
                                         alt="profile"
                                    />
                                    <Dropzone accept="image/jpeg, image/png"
                                              minSize={1}
                                              maxSize={1097152}
                                              onDrop={(acceptedFiles, _rejectedFiles) => {
                                                  let reader = new FileReader();
                                                  reader.onload = async (e) => {
                                                      let contents = e.target.result;
                                                      const blob = new Blob([contents]);
                                                      let urlCreator = window.URL || window.webkitURL;
                                                      setUserBlob(urlCreator.createObjectURL(blob));
                                                  };

                                                  if (acceptedFiles.length > 0) {
                                                      uploadAvatar(acceptedFiles[0]);
                                                      reader.readAsArrayBuffer(acceptedFiles[0]);
                                                  }
                                              }}>
                                        {({
                                              getRootProps,
                                              getInputProps,
                                              fileRejections
                                          }) => (
                                            <section className="mb-2">
                                                <div className="section-container" {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    <div className="dropzone-placeholder ">
                                                        <FontAwesomeIcon className="edit-icon-avatar"
                                                                         icon={faPencilAlt}/>
                                                    </div>

                                                    <p className="m-0 p-0 info">
                                                        <small>{t('common:global.only-images')}</small></p>
                                                    {fileRejections.length > 0 && fileRejections[0].file.size > 2097152 && (
                                                        <div className="text-danger">
                                                            {t('common:global.file-large')}
                                                        </div>
                                                    )}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="row profile-data">
                                    <div className="col-12 col-md-6 profile-data-1 mt-2 text-center text-md-left">
                                        <p className="name">
                                            <strong>{defaultValues.name} {defaultValues.secondName}</strong></p>
                                        {/*<p className="specialist">{t("common:providerTypes." + user.idProviderType.name)} | {t("common:specialties." + user.idSpecialty.name)} ({t("common:" + user.idRole.name)})</p>*/}
                                        {/*<p className="prescriptions">{t("common:global.medical-prescriptions")}</p>*/}

                                        <div className="row npi-dea">
                                            <span className="col-6 col-md-4 text-left">NPI:</span>
                                            <span
                                                className="col-6 col-md-8 text-left">{defaultValues.NPI != null && defaultValues.NPI !== "" ? defaultValues.NPI : "--"}</span>
                                            <span className="col-6 col-md-4 text-left">DEA:</span>
                                            <span
                                                className="col-6 col-md-8 text-left">{defaultValues.DEA != null && defaultValues.DEA !== "" ? defaultValues.DEA : "--"}</span>
                                        </div>

                                    </div>
                                    <div className="col-12 col-md-6 profile-data-2 mt-2">
                                        <div className="row">
                                            <p className="col-6 col-md-12 item"><FontAwesomeIcon className="checkIcon"
                                                                                                 icon={faUser}/>{defaultValues.gender === "M" ? t('common:gender.male') :
                                                defaultValues.gender === "F" ? t('common:gender.female') : t('common:gender.other')}
                                            </p>
                                            <p className="col-6 col-md-12 item"><FontAwesomeIcon className="checkIcon"
                                                                                                 icon={faBirthdayCake}/>{(defaultValues.dateBirth) ? moment(defaultValues.dateBirth).format('LL') : "--"}
                                            </p>
                                            <p className="col-12 item"><FontAwesomeIcon className="checkIcon"
                                                                                        icon={faPhoneAlt}/>{(defaultValues.idPhone) ? "+1 " + defaultValues.idPhone?.phoneNumber : "--"}
                                            </p>
                                            <p className="col-12 item"><FontAwesomeIcon className="checkIcon"
                                                                                        icon={faEnvelope}/>{(defaultValues.mail) ? defaultValues.mail : "--"}
                                            </p>
                                            <p className="col-12 item"><FontAwesomeIcon className="checkIcon"
                                                                                        icon={faUserMd}/>{(defaultValues.idProviderType) ? t('common:providerTypes.' + defaultValues.idProviderType.name) : "--"}
                                            </p>
                                            <p className="col-12 item"><FontAwesomeIcon className="checkIcon"
                                                                                        icon={faBriefcaseMedical}/>{(defaultValues.idSpecialty) ? t('common:specialties.' + defaultValues.idSpecialty.name) : "--"}
                                            </p>
                                        </div>

                                    </div>
                                    <div className="col-12 text-center text-md-left">
                                        <button
                                            className="btn btn-danger btn-sm mt-4"
                                            onClick={handleLogout}
                                        ><FontAwesomeIcon
                                            icon={faExternalLinkAlt}/> {t('common:navbar.logout')}
                                        </button>
                                    </div>
                                </div>
                                {defaultValues.idDetail && defaultValues.idDetail.biography != null && defaultValues.idDetail.biography != "" &&
                                <div className="row mt-2 profile-biography">
                                    <div className="col-12">
                                        {defaultValues.idDetail.biography}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mr-1">
                        <div className="col-12 text-right">
                            <FontAwesomeIcon onClick={toggle} className="edit-icon" icon={faPencilAlt}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-12 col-md-6 col-lg-6">
                            <h6>{t("form.subtitle-1")}</h6>
                            <div className="form-group col-12">
                                <label htmlFor="name">{t("form.name")}</label>
                                <input className="form-control form-control-sm"
                                       disabled={npiRequired}
                                       placeholder={t("form.name")}
                                       {...register("name", {
                                           required: t('common:global.required'),
                                           pattern: {
                                               value: noSpacesPattern,
                                               message: t('common:global.notOnlySpaces')
                                           }
                                       })}
                                />
                                {errors.name &&
                                <span className="error ml-2">{errors.name.message}</span>}
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="secondName">{t("form.surname")}</label>
                                <input className="form-control form-control-sm"
                                       disabled={npiRequired}
                                       placeholder={t("form.surname")}
                                       {...register("secondName", {
                                           required: t('common:global.required'),
                                           pattern: {
                                               value: noSpacesPattern,
                                               message: t('common:global.notOnlySpaces')
                                           }
                                       })}
                                />
                                {(errors.secondName) &&
                                <span className="error ml-2">{errors.secondName.message}</span>}
                            </div>

                            <div className="form-group col-12">
                                <label>{t("form.gender")}</label>
                                <select name="select"  {...register("gender")}
                                        className="form-control form-control-sm">
                                    <option value="F">{t("form.female")}</option>
                                    <option value="M">{t("form.male")}</option>
                                    <option value="O">{t("form.other")}</option>
                                </select>
                            </div>

                            <div className="form-group col-12">
                                <label htmlFor="bornDate">{t('common:complete-profile.born-date')}</label>
                                <input type="date"
                                       min={minDate}
                                       max={maxDate}
                                       className="form-control form-control-sm"
                                       placeholder={t("common:complete-profile.born-date")}
                                       {...register("dateBirth", {required: t('common:global.required')})}
                                />
                                {errors.dateBirth &&
                                <span className="error ml-2">{errors.dateBirth.message}</span>}
                            </div>


                            {(defaultValues.idProviderType?.isManagerType === 0) &&
                            <div>
                                <div className="form-group col-12">
                                    <label>{t('common:professional-profile.use-how')}</label>
                                    <select className="form-control form-control-sm"
                                            defaultValue={defaultValues.idProviderType}
                                            name="idProviderType" {...register("idProviderType", {required: true})}>

                                        <option
                                            hidden>{t('common:global.select-option-placeholder')}</option>

                                        {
                                            providerType.map((item) => {
                                                return <option key={item.value}
                                                               value={item.value}>{t("common:providerTypes." + item.label)}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                {watch('idProviderType') == 1 &&
                                <div className="form-group col-12">
                                    <label>{t('common:complete-profile.specialty')}</label>
                                    <select className="form-control form-control-sm"
                                            defaultValue={defaultValues.idSpecialty}
                                            placeholder={t('common:complete-profile.specialty')}
                                            name="idSpecialty" {...register("idSpecialty", {required: true})}>
                                        {
                                            specialty.map((item) => {
                                                return <option key={item.value}
                                                               value={item.value}>{item.label}</option>
                                            })
                                        }
                                    </select>
                                    {errors.idSpecialty &&
                                    <span className="error">{
                                        t('common:global.required')
                                    }</span>
                                    }
                                </div>
                                }

                                {}
                                <div className="form-group col-12">
                                    <label>{t("form.npi")}</label>
                                    <input className="form-control form-control-sm"
                                           name="NPI"
                                           defaultValue={defaultValues.NPI}
                                           {...register("NPI", {
                                               required: {
                                                   value: npiRequired,
                                                   message: t('common:global.required')
                                               },
                                               pattern: {
                                                   value: /^[0-9]*$/,
                                                   message: t('common:global.invalid-format')
                                               }
                                           })}
                                           placeholder={t("form.npi")}/>
                                    {errors.NPI &&
                                    <span className="error ml-2">{errors.NPI.message}</span>}
                                    {!validNPI &&
                                    <span className="error ml-2"> <FontAwesomeIcon onClick={toggle}
                                                                                   className="mr-1 mt-2"
                                                                                   icon={faTimesCircle}/>{t("common:global.npi-invalid")}</span>}
                                    <small className="mt-1 d-block">** {t("common:global.npi-description")}</small>
                                </div>
                            </div>
                            }


                            <div className="form-group col-12">
                                <label>{t("form.email")}</label>
                                <input
                                    className="form-control form-control-sm"
                                    placeholder={t("form.email")}
                                    {...register("mail", {required: t('common:global.required')})}
                                />
                                {errors.mail &&
                                <span className="error ml-2">{errors.mail.message}</span>}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <h6>{t("form.subtitle-2")}</h6>
                            <div className="form-group col-12">
                                <label>{t("form.dea")}</label>
                                <input className="form-control form-control-sm"
                                       {...register("DEA", {
                                           maxLength: {
                                               value: 15,
                                               message: t('common:global.maxlengthDea')
                                           },
                                           pattern: {
                                               value: noSpacesPattern,
                                               message: t('common:global.notOnlySpaces')
                                           }
                                       })}
                                       placeholder={t("form.dea")}/>
                                {errors.DEA &&
                                <span className="error ml-2">{errors.DEA.message}</span>}
                            </div>

                            <div className="form-group col-12">
                                <label>{t("form.phone")}</label>
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="">+1</span>
                                    </div>

                                    <input
                                        className="form-control form-control-sm"
                                        placeholder={t("form.phone")}
                                        {...register("phoneNumber", {
                                            pattern: {
                                                value: phonePattern,
                                                message: t('common:global.invalid-format')
                                            }
                                        })}
                                    />
                                    {errors.phoneNumber &&
                                    <span className="error">{errors.phoneNumber.message}</span>}
                                </div>

                            </div>

                            <div className="form-group col-12">
                                <label>{t("form.presentation")}</label>
                                <input className="form-control form-control-sm"
                                       {...register("biography")}
                                       placeholder={t("form.presentation")}/>
                            </div>

                            <div className="form-group form-check">
                                <input className="form-check-input" name="changePassword"
                                       type="checkbox" {...register("changePassword", {required: false})}
                                       id="changePassword"/>
                                <label className="form-check-label">
                                    {t('form.change-password')}
                                </label>

                            </div>

                            <div className="form-group col-12">
                                <label>{t('common:create-provider.password')}</label>
                                <input className="form-control form-control-sm" min={8}
                                       type="password"
                                       disabled={!watch("changePassword")}
                                       name="newPassword" {...register("newPassword", {
                                    required: {
                                        value: watch("changePassword"),
                                        message: t('common:global.required')
                                    },
                                    pattern: {
                                        message: t('common:global.min-length'),
                                        value: passwordPattern
                                    },
                                })}
                                       placeholder={t('common:create-provider.password')}/>

                                {errors.newPassword &&
                                <span className="error">{errors.newPassword.message}</span>}
                            </div>

                            <div className="form-group col-12">
                                <label>{t('common:create-provider.retype-password')}</label>
                                <input className="form-control form-control-sm"
                                       type="password"
                                       disabled={!watch("changePassword")}
                                       name="newPasswordRepeat" {...register("newPasswordRepeat", {required: watch("changePassword")})}
                                       placeholder={t('common:create-provider.retype-password')}/>
                                {errors.newPasswordRepeat &&
                                <span className="error">{t('common:global.required')}</span>}
                                {(watch("newPassword") !== watch("newPasswordRepeat") && watch("changePassword")) &&
                                <span className="error">{t('common:global.no-match')}</span>}
                            </div>

                        </div>
                    </div>

                    <div className=" d-flex justify-content-end">
                        <button className="btn btn-secondary" onClick={goBack}
                                type="button">{t("common:global.cancel")}</button>
                        <button className="btn btn-primary ml-2"
                                disabled={watch("changePassword") && watch("newPassword") !== watch("newPasswordRepeat") ? true : false}
                                type="submit">{t("common:global.save-changes")}</button>
                    </div>

                </form>
            }

            <ToastContainer/>
        </>
    );
}
