import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

export const OrganizationDetail = ({company}) => {
    const {t} = useTranslation(['myTeam', 'common']);
    return (

        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">
                        <span>{t('myTeam:organization.name')}</span>
                    </div>
                    <div className="col-8">
                        <strong>{company?.brandingName}</strong>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">
                        <span>{t('myTeam:organization.address')}</span>
                    </div>
                    <div className="col-8">
                        <strong>{company?.idAddress?.address ? company?.idAddress?.address : 'N/A'}</strong>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">
                        <span>{t('myTeam:organization.phone')}</span>
                    </div>
                    <div className="col-8">
                        <strong>{company?.phone ? company?.phone : 'N/A'}</strong>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">
                        <span>{t('myTeam:organization.city')}</span>
                    </div>
                    <div className="col-8">
                        <strong>{company?.idAddress?.city ? company?.idAddress?.city : 'N/A'}</strong>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">
                        <span></span>
                    </div>
                    <div className="col-8">
                        <strong></strong>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">
                        <span>{t('myTeam:organization.postalCode')}</span>
                    </div>
                    <div className="col-8">
                        <strong>{company?.idAddress?.zipcode ? company?.idAddress?.zipcode : 'N/A'}</strong>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">
                        <span></span>
                    </div>
                    <div className="col-8">
                        <strong></strong>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">
                        <span>{t('myTeam:organization.country')}</span>
                    </div>
                    <div className="col-8">
                        <strong>{company?.idAddress?.country ? company?.idAddress?.country : 'N/A'}</strong>
                    </div>
                </div>
            </div>
        </div>
    );

}

OrganizationDetail.propTypes = {
    company: PropTypes.object.isRequired,
}