import {useTranslation} from "react-i18next";
import React, {useState, useContext} from "react";
import {AuthContext} from "../../utilities/auth/AuthContext";
import classnames from 'classnames';
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import CertificationList from "../Partial/CertificationList";
import FormationList from "../Partial/FormationList";
import AchievementList from "../Partial/AchievementList";

export const docData = React.createContext(null);

export const ResumeProfile = () => {

    const {user: {user}} = useContext(AuthContext);
    const {t} = useTranslation("profile");
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <>
            <div className="resume-profile position-relative">
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({active: activeTab === '1'})}
                                 onClick={() => {
                                     toggle('1');
                                 }}>{t('tabs.education')}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({active: activeTab === '2'})}
                                 onClick={() => {
                                     toggle('2');
                                 }}>{t("tabs.certifications")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({active: activeTab === '3'})}
                                 onClick={() => {
                                     toggle('3');
                                 }}>{t("tabs.acknowledgments")}</NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <AchievementList user={user}/>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <CertificationList user={user}/>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <FormationList user={user}/>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </>
    );
}