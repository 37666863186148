export const reportReducer = (state, action) => {
    switch (action.type) {
        case 'from':
            return {
                from: action.value,
                toStop: state.toState,
                idSpecialist: state.idSpecialist,
                idUser: state.idUser,
                option: state.option,
                time: state.year
            };
        case 'toStop':
            return {
                from: state.from,
                toStop: action.value,
                idSpecialist: state.idSpecialist,
                idUser: state.idUser,
                option: state.option,
                time: state.year
            };
        case 'idSpecialist':
            return {
                from: state.from,
                toStop: state.toState,
                idSpecialist: action.value,
                idUser: state.idUser,
                option: state.option,
                time: state.year
            };
        case 'idUser':
            return {
                from: state.from,
                toStop: state.toState,
                idSpecialist: state.idSpecialist,
                idUser: action.value,
                option: state.option,
                time: state.year
            };
        case 'option':
            return {
                from: state.from,
                toStop: state.toState,
                idSpecialist: state.idSpecialist,
                idUser: state.idUser,
                option: action.value,
                time: state.year
            };
        case 'reset':
            return {from: '', toStop: '', idSpecialist: "", idUser: "", option: 'all', time: 'year'};
        case 'time':
            return {
                from: state.from,
                toStop: state.toState,
                idSpecialist: state.idSpecialist,
                idUser: state.idUser,
                option: state.option,
                time: action.value
            };
        default:
            throw new Error();
    }
}
