import React, {forwardRef, useContext, useImperativeHandle, useState} from 'react';
import {useTranslation} from "react-i18next";
import StripeCheckoutModal from "./StripeCheckoutModal";
import {createStripeSetupIntent, createStripeSubscription} from "../../../services/stripe.service";
import {CardElement, useStripe} from "@stripe/react-stripe-js";
import {licContext} from "../../../views/Auth/LicenseType";
import {useLocation} from "react-router-dom";
import {AuthContext} from "../../../utilities/auth/AuthContext";
import {toast} from "react-toastify";


const CreateSubscriptionButton = forwardRef((props, ref) => {
    const {t} = useTranslation(["profile", "common"]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState(null);
    const stripe = useStripe();
    const lic = useContext(licContext);
    const location = useLocation();
    const {user} = useContext(AuthContext);

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    useImperativeHandle(ref, () => ({
        openModal() {
            setShowModal(true);
        }
    }));


    const handlePayment = async (elements) => {
        setLoading(true);

        const providerId = location.state?.idProvider ? location.state?.idProvider : props.provider ? props.provider.idProvider : user.user.idProvider;

        const setupIntentResult = await createStripeSetupIntent({
            providerId
        });
        try {
            const confirmSetupIntentResult = await confirmSetupIntent(setupIntentResult.client_secret, elements);

            const subscriptionResult = await createStripeSubscription({
                licenseTypeId: lic.lic,
                id: providerId,
                paymentMethodId: confirmSetupIntentResult.payment_method,
            });

            if (subscriptionResult.subscription.status === 'trialing') {
                onSubscriptionSuccess();
            } else if (subscriptionResult.subscription.latest_invoice.payment_intent != null) {
                //En el caso que no sea trial, aun que la tarjeta del customer ha sido añadida, validamos el pago.
                const paymentIntentResult = await confirmPayment(subscriptionResult.subscription.latest_invoice.payment_intent['client_secret'], subscriptionResult.emails, elements);
                if (paymentIntentResult.status === 'succeeded') {
                    onSubscriptionSuccess();
                } else {
                    setPaymentErrorMessage(paymentIntentResult.status);
                    setLoading(false);
                }
            }
        } catch (error) {
            setLoading(false);
            setPaymentErrorMessage(error.message);
            toast.error(t("common:commercial-account.error-create-subscription"));
            console.error(error);
        }
    }

    const onSubscriptionSuccess = async () => {
        closeModal();
        toast.success(t("common:commercial-account.success-create-subscription"));
        props.reload();
    }

    const confirmPayment = async (secret, providerEmail, elements) => {
        const providerName = location.state?.doctorName ? location.state?.doctorName : `${user.user.name} ${user.user.secondName}`;
        const cardElement = elements.getElement(CardElement);
        const {error, paymentIntent} = await stripe.confirmCardPayment(secret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: providerName,
                    email: providerEmail
                }
            },
            receipt_email: providerEmail
        });

        if (error) {
            throw error;
        } else {
            return paymentIntent;
        }

    }

    const confirmSetupIntent = async (secret, elements) => {
        const {error, setupIntent} = await stripe.confirmCardSetup(secret, {
            payment_method: {
                card: elements,
            },
        });
        if (error) {
            throw error;
        } else {
            return setupIntent;
        }

    }

    return (
        <>
            {!props.hide && <button className='btn btn-outline-primary btn-sm'
                                    diabled={loading.toString()}
                                    onClick={openModal}>
                {t("profile:Stripe.stripe_subscribe_pay")}
            </button>}
            <StripeCheckoutModal
                toggle={showModal}
                title={t("profile:Stripe.stripe_register_subscribe_pay")}
                message={t("profile:Stripe.stripe_register_payment_message", {data: new Date().getDate()})}
                showLoading={loading}
                onPay={handlePayment}
                onClose={closeModal}
                paymentErrorMessage={paymentErrorMessage}
                {...props}/>
        </>
    );
})

export default CreateSubscriptionButton;
