import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";
import {hostname} from "../utilities";
import {APIROUTES} from "./api";

export const getAllSpecialty = async () => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.specialty.all
    ).catch(error => {
        throw error;
    })

    return response.data;
}