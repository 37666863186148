import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import inviteIcon from "../../../../../assets/images/video/invite.svg";

const ButtonInvite = (props) => {
    const {t} = useTranslation("video", "common");

    return (
        <>
            <button data-tip={t("invite")} className="btn btn-link color-white"
                    onClick={() => {
                        console.log('PROPS', props);
                        props.invitationModalReference.current.toggleModal();
                    }}>
                <img src={inviteIcon} alt="inviteIcon"/>
            </button>
            <ReactTooltip/>
        </>

    );
}
export default ButtonInvite;
