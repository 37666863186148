import React, {forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef} from "react";
import {AuthContext} from "../../../../../utilities/auth/AuthContext";
import {Button} from "reactstrap";
import {messageReceived, messageSent} from "../../../Helper";
import "../../../../../assets/scss/styles/_chat.scss";
import {isMobile} from 'react-device-detect';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useLocation} from "react-router-dom";

const reducer = (state, action) => {
    switch (action.payload) {
        case 'plus':
            return {
                messages: [...state.messages, action.add],
                chat: state.chat,
                channels: [...state.channels],
                inputValue: ""
            }
        case 'add-channel':
            return {
                channels: [...state.channels, action.channel],
                messages: [...state.messages],
                chat: state.chat,
                inputValue: state.inputValue
            }
        case 'set-chat':
            return {
                chat: state.chat,
                messages: [...state.messages],
                channels: [...state.channels],
                inputValue: state.inputValue
            }
        case 'toggle-chat':
            return {
                chat: action.toggle,
                messages: [...state.messages],
                channels: [...state.channels],
                inputValue: state.inputValue
            }
        case 'set-channel':
            return {
                chat: action.chat,
                messages: [...state.messages],
                channels: action.channels,
                inputValue: state.inputValue
            }
        case 'reset':
            return {chat: false, messages: [], channels: [], inputValue: ""}
        case 'set-input':
            return {
                chat: state.chat,
                messages: [...state.messages],
                channels: [...state.channels],
                inputValue: action.inputValue
            }
        default:
            throw new Error()
    }
}

const Chat = forwardRef((props, reference) => {
    const context = useContext(AuthContext);
    const [state, dispatch] = useReducer(reducer, {messages: [], channels: [], chat: false, inputValue: ""}, );
    const {t} = useTranslation("video");
    const messagesReference = useRef(null);
    const lastMessageReference = useRef(null);
    const location = useLocation();

    useImperativeHandle(reference, () => ({
        setState: (payload) => {
            dispatch(payload)
        },
        getState: () => state,
        getDispatch: () => dispatch,
    }));

    const _detectName = () => {
        if (context.user.logged) {
            return `${context.user.user.name} ${context.user.user.secondName}`;
        } else {
            if (location.state) {
                return location.state.user;
            }
        }
    }

    const channelSend = () => {
        try{
            if (state.inputValue !== "") {
                dispatch({
                    add: {
                        isLocal: true,
                        message: {inputValue: state.inputValue, time: moment().format()},
                        name: _detectName()
                    },
                    payload: "plus",
                });

                state.channels.forEach((item, _index) => {
                    if (item.channel.readyState !== "closed")
                        item.channel.send(state.inputValue);
                });
            }
        }catch(error) {
            console.error(error);
        }
    }

    const handleKeyPress = (event) => {
        if (event.charCode === 13)
            channelSend();
    }

    useEffect(() => {
        scrollToBottom();
    }, [state.messages]);


    const scrollToBottom = () => {
        lastMessageReference.current?.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <>
            {state.chat &&
            <div className="chatContainer">

                <div className="chatLayout">

                    <div className="chatHeader d-flex justify-content-between align-items-center">
                        <span className="">{t('chat')}</span>
                        {isMobile ? <div>
                            {context.user.logged ?
                                <button className="btn btn-outline-secondary mr-2 mt-2"
                                        onClick={() => {
                                            dispatch({
                                                toggle: false,
                                                payload: "toggle-chat"
                                            });
                                            props.soapReference.current.toggle();
                                        }}>
                                    {t('soap')}
                                </button> : <></>}
                            <button className="btn btn-danger mr-2 mt-2"
                                    onClick={() => {
                                        dispatch({
                                            toggle: false,
                                            payload: "toggle-chat"
                                        });
                                        props.closeSideBar();
                                    }}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </button>
                        </div> : <></>}
                    </div>
                    <div className="chatHistory">
                        <>
                            {
                                state.messages.map((item, index) => {
                                    if (item.isLocal)
                                        return messageSent(item.name, item.message.inputValue, item.message.time, index, (state.messages.length === index + 1), messagesReference)
                                    else
                                        return messageReceived(item.name, item.message.inputValue, item.message.time, index, (state.messages.length === index + 1), messagesReference)
                                })
                            }
                            <div ref={lastMessageReference}/>
                        </>
                    </div>
                    <div className="chatInput d-flex justify-content-between align-items-center">
                        <input type="text" className="w-100" name="message" id="message"
                               onKeyPress={(event) => handleKeyPress(event)}
                               value={state.inputValue}
                               placeholder={t('input-send-placeholder')}
                               onChange={event => {
                                   dispatch({
                                       inputValue: event.target.value,
                                       payload: "set-input",
                                   });
                               }}/>

                        <Button className="sendButton" onClick={() => channelSend()}>
                            <i className="fa fa-paper-plane" aria-hidden="true"/>
                        </Button>
                    </div>
                </div>
            </div>
            }
        </>
    );
});

export default Chat;
