import React, {useContext} from "react";
import {useHistory, useParams} from "react-router-dom";
import {logout, providerCommunticationVerify, setEmail, setPhone, validateCode} from "../../services/auth.service";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button} from "reactstrap";
import 'assets/scss/styles/_emailValidate.scss';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AuthContext} from "../../utilities/auth/AuthContext";

export const ValidateCommunication = (_props) => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {t} = useTranslation('common');
    const {communicationContent} = useParams();
    const {communicationType} = useParams();
    const history = useHistory();
    const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/;
    const {user: {user}, dispatch} = useContext(AuthContext);

    const resendVerification = (_communicationType) => {
        const typeCommunication = (_communicationType != 1)
        providerCommunticationVerify(typeCommunication, communicationContent)
            .then(() => {
                toast(`${t("global.email-sent")} ${communicationContent}`)
            })
            .catch(() => {
                toast(`${t("global.error")}`)
            });
    };

    const onSubmit = (data) => {
        validateCode(communicationContent, data.code)
            .then((response) => {
                if (response)
                    if (!pattern.test(communicationContent)) {
                        setEmail(communicationContent, true)
                            .then((_doctor) => {
                                logout(history, dispatch);
                            })
                            .catch(() => {
                                toast(`${t("global.error")}`)
                            });
                    } else
                        setPhone(communicationContent, true)
                            .then(() => {
                                history.replace('/' + user.idCompany.name + '/profile');
                            })
                            .catch(() => {
                                toast(`${t("global.error")}`);
                            });
                else toast(`${t("global.code-not-valid")}`)
            })
            .catch((error) => {
                toast(error)
            });
    }

    return (
        <>
            <div className="container-fluid">
                <div className="flex-center-vertical">
                    <h1>{t('valid-code.module-type')} {communicationType == 1 ? t('valid-code.phone') : t('valid-code.email')} </h1>
                    <label
                        className="mr-1">{communicationType == 1 ? t('valid-code.module-type-title-phone') : t('valid-code.module-type-title-email')}</label>
                    <label
                        className="font-weight-bold">{communicationType == 1 ? `+1 ${communicationContent}` : communicationContent}</label>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4 mb-sm-4">
                            <label className="mr-sm-2 mt-5">{t('valid-code.insert')}</label>
                            <input className="form-control form-control-sm" type="text"
                                   name="code" {...register("code", {required: true})}
                                   placeholder={t("global.verification-code")}/>
                            {errors.code && <span className="error">{t('global.required')}</span>}
                        </div>

                        <Button className="send-another mt-4" color="link" onClick={() => {
                            resendVerification(communicationType);
                        }}>{communicationType == 1 ? t('valid-code.resend-validation-phone') : t('valid-code.resend-validation-email')}</Button>
                        <Button className="submit mt-3 button-link">{t('global.validate-code')}</Button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ValidateCommunication;
