import React, {useRef} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {UserRoute} from "../routes";
import FooterAdmin from "../components/common/FooterAdmin";


export const UserRoutes = (_props) => {
    const modalSidebar = useRef(null);

    return (
        <>
            <div className="d-flex-mobile w-100" style={{overflow: "auto", overflowX: "hidden"}}>
                <Switch>
                    {UserRoute.map((prop, key) => {
                        return (
                            <Route
                                exact
                                path={prop.layout + prop.path}
                                component={() => <prop.component modal={modalSidebar}/>}
                                key={key}
                            />
                        );
                    })}
                    <Redirect to="/auth/login"/>
                </Switch>
            </div>
            <FooterAdmin/>
        </>
    );
};
