import React from "react";
import {Route} from "react-router-dom";
import {ValidationRoute} from "../routes";

export const ValidationRoutes = () => {
    return (
        <>
            {ValidationRoute.map((prop, key) => {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}/>
                );
            })}
        </>
    );
}
