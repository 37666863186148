import paidIcon from "../../assets/images/icons/check-circle.svg";
import x from "../../assets/images/icons/x.svg";
import React from "react";
import {useTranslation} from "react-i18next";

const AppointmentItemPayment = ({status, price, isPaid}) => {
    const {t} = useTranslation('appointments');
    let imgs;
    let feed = "";
    let url = "";
    let wh = "";
    if (isPaid !== 1) {
        imgs = "notpaid";
        feed = t('scheduled-appointments.npaid');
        url = x;
        wh = "18";
    } else {
        imgs = "paid";
        feed = price == 0 ? t('scheduled-appointments.free') : "";
        url = paidIcon;
        wh = "14";
    }

    return (
        <>
            <span className={imgs}>


                <img className={imgs} width={wh} height={wh} src={url} alt={status}/>

                ${price}
                <span>{feed}</span>
            </span>
        </>
    );
}

export default AppointmentItemPayment;
