import React, {forwardRef, useImperativeHandle} from "react";
import {ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";

const Leave = forwardRef((props, reference) => {
    const {t} = useTranslation('video');
    const hideModal = () => {
        props.dispatch({
            source: false,
            payload: "set-modal",
        });
    }

    useImperativeHandle(reference, () => ({
        toogle: () => {
            hideModal();
        }
    }));

    return <>
        <ModalHeader toggle={() => hideModal()}>{t('leave')}</ModalHeader>
        <ModalBody>
            <p>{t('leave-header')}</p>

        </ModalBody>
        <ModalFooter>
            <button onClick={() => {
                hideModal();
            }} className="btn ml-2 btn-primary text-uppercase">{t('cancel')}</button>
            <button onClick={() => {
                if (props.callback)
                    props.callback();
            }} className="btn btn-primary submit btn-danger text-uppercase">{t('confirm')}</button>
        </ModalFooter>
    </>
});

export default Leave;
