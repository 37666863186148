import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {checkoutUserReceipt, updateStatus} from "../../services/stripe.service";
import logo from "../../assets/images/logo.svg";
import {localhost} from "../../utilities";

export default function ClientCheckOut(_props) {
    const {mail, idServ} = useParams();

    let updateService = async () => {
        const updateStatusResult = await updateStatus(idServ);

        if (updateStatusResult.stat == 1) {

            const userReceipt = await checkoutUserReceipt(mail);
            console.log(userReceipt);
            if (userReceipt.url != null) {
                window.open(userReceipt.url, 'receipt');
            }
            window.open(localhost + "/client/scheduled/" + idServ, '_self');
        }
    }
    useEffect(() => {
        updateService();
    }, []);

    return (
        <div className="row text-center">
            <div className="col-12">
                <img className="" width={250} alt="Healthy Encounter" src={logo}/>
            </div>
        </div>
    );
}
