import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";
import {hostname} from "../utilities";
import {APIROUTES} from "./api";

/**
 * Obtiene la Cuenta de Stripe Connect del provider
 * @param {string} idProvider
 */
export const getStripeConnectedAccount = async (idProvider) => {
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.stripe.getConnectedAccount}${idProvider}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Crea la cuenta de Stripe Connect del provider
 * @param {string} idProvider
 */
export const createStripeAccount = async (idProvider) => {
    const response = await request(
        methodTypes.POST,
        `${hostname}${APIROUTES.stripe.createStripeAccount}${idProvider}`,
        null,
        {idProvider: idProvider}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Crea el link de Stripe para iniciar sesion
 * @param {string} IdAccount
 */
export const createStripeLoginLink = async (idProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.stripe.createStripeLoginLink,
        null,
        {idProvider: idProvider}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Elimina un producto de la cuenta comercial de un provider
 * @param productId
 */
export const deleteConnecProduct = async (productId) => {
    const response = await request(
        methodTypes.POST,
        `${hostname}${APIROUTES.stripe.deleteConnectProduct}${productId}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Crea un producto en la cuenta comercial de un provider
 * @param data
 */
export const createConnectProduct = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.stripe.createConnectProduct,
        null,
        data, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const toggleFreeProduct = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.stripe.toggleFreeProduct,
        null,
        data, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene la suscripción de Stripe del provider
 * @param {string} idProvider
 * @returns {Promise<T>}
 */
export const getStripeSubscription = async (idProvider) => {
    const response = await request(
        methodTypes.GET,

        `${hostname}${APIROUTES.stripe.getSubscription}${idProvider}`
        ,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene los productos activos de un provider
 * @param idProvider
 */
export const getStripeProviderProducts = async (idProvider) => {
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.stripe.getProviderProducts}${idProvider}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getProviderTypes = async () => {
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.provider.types}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const createStripeSetupIntent = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.stripe.createStripeSetupIntent,
        null,
        data, true
    ).catch(error => {
        console.log("createStripeSetupIntent");
        console.log(error);
        throw error;
    })

    return response.data;
}

/**
 * Crea la suscripción de un provider
 * @param data
 */
export const createStripeSubscription = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.stripe.createSubscription,
        null,
        data, true
    ).catch(error => {
        console.error(error);
        throw error;
    })

    return response.data;
}

/**
 * Elimina/Cancela una suscripcion del provider
 * @param idProvider
 * @returns {Promise<T>}
 */
export const deleteStripeSubscription = async (idProvider) => {
    const response = await request(
        methodTypes.POST,
        `${hostname}${APIROUTES.stripe.deleteSubscription}${idProvider}`,
        {id: idProvider},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const reactivateStripeSubscription = async (idProvider) => {
    const response = await request(
        methodTypes.POST,
        `${hostname}${APIROUTES.stripe.reactivateSubscription}${idProvider}`,
        {id: idProvider},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Elimina la cuenta Connect de Stripe del provider
 * @param {string} stripeAccountId - Id de la cuenta Connect de Stripe
 */
export const deleteStripeConnected = async (StripeAccountId) => {
    const response = await request(
        methodTypes.POST,
        `${hostname}${APIROUTES.stripe.deleteConnected}${StripeAccountId}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    console.log(response);
    return response.data;
}

export const payStripeSubscription = async (params) => {
    const response = await request(
        methodTypes.POST,
        `${hostname}${APIROUTES.stripe.paySubscription}${params}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getSubcriptionsList = async () => {
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.stripe.listSubcription}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const checkoutUser = async (data) => {
    console.log('data', data);
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.stripe.checkoutUser,
        null,
        data, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}
export const checkoutUserReceipt = async (mail) => {
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.stripe.checkoutUserReceipt}${mail}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}
export const updateStatus = async (idserv) => {
    const response = await request(
        methodTypes.POST,
        `${hostname}${APIROUTES.stripe.updateStatus}${idserv}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}
export const verifyService = async (id) => {
    const response = await request(
        methodTypes.GET,
        `${hostname}${APIROUTES.stripe.verifyService}${id}`,
        null,
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}
export const saveLicense2 = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.stripe.saveLicense,
        null,
        data, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const handleActiveCancelSubscription = (stripeSubscription) => {
    if (stripeSubscription['status'] !== 'trialing' && stripeSubscription['status'] !== 'active') {
        return false
    } else if (stripeSubscription['status'] === 'trialing' && stripeSubscription['cancel_at_period_end'] === true) {
        return false;
    } else if (stripeSubscription['status'] === 'active' && stripeSubscription['cancel_at_period_end'] === true) {
        return false;
    } else {
        return true;
    }
}