import React from 'react';
import {NavLink} from "react-router-dom";
import {EHIcon} from "../icons/EHIcon";
import {useTranslation} from "react-i18next";

const MenuItemOption = ({option, activeRoute, uri, isMobile}) => {
    const {t} = useTranslation("appointments");

    return (
        <>
            {option.shown &&
            <NavLink
                to={uri + option.path}
                className={`d-flex flex-row nav-link icon ${isMobile? 'ml-1':''}`}
                activeclassname={activeRoute(option.path) ? 'active' : 'no-active'}>
                <EHIcon name={option.icon} width="25" height="25"
                        className={`${activeRoute(option.path) ? 'menuActive' : ''}`}/>
                <span className={`align-self-center ${isMobile? 'ml-2':''}`}>{t(`sidebar.${option.name}`)}</span>
            </NavLink>
            }
        </>
    );
}

export default MenuItemOption;
