import React, {useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button} from "reactstrap";
import 'assets/scss/styles/_phoneValidate.scss';
import {
    checkProtectedRoutes,
    providerCommunticationVerify,
    setPhoneVerified,
    validateCode
} from "../../services/auth.service";
import {loaderContext} from "../../EHApp";
import {toast} from "react-toastify";
import AuthLayout from "../../routers/AuthLayout";

export const PhoneValidate = () => {
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {t} = useTranslation('common');
    let history = useHistory();
    const location = useLocation();

    checkProtectedRoutes(location, history);

    const resendVerification = () => {
        providerCommunticationVerify(true, location.state.communicationContent)
            .then((response) => {
                setLoader(false);
                toast.error(response);
            })
            .catch((error) => {
                setLoader(false);
                toast.error(error.message);
            });
    };

    const onSubmit = (data) => {
        setLoader(true);
        validateCode(location.state.communicationContent, data.code)
            .then((response) => {
                if (response) {
                    setPhoneVerified(location.state.communicationContent, true)
                        .then(() => {
                            setLoader(false);
                            history.push({
                                pathname: '/auth/profile',
                                state: {
                                    idProvider: location.state.idProvider,
                                    doctorName: location.state.doctorName,
                                    identification: location.state.identification,
                                    username: location.state.username
                                },
                            });
                        })
                        .catch(() => {
                            setLoader(false);
                            toast.error(t("global.error"));
                        });
                } else {
                    setLoader(false);
                    toast.error(t("global.code-not-code"));
                }
            })
            .catch((error) => {
                setLoader(false);
                toast.error(error.message);

            });
    }

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters phone-validate-wrapper">
                    <div className="col-12 col-md-8 col-lg-7 col-xl-7 left-half">
                        <AuthLayout>
                            <article className="h-100 row justify-content-center align-items-center">
                                <div className="col-10 col-lg-9 col-xl-9">
                                    <h1>{t('validate-phone.title')}</h1>
                                    <label className="mr-1">{t('validate-phone.sent-validation')}</label>
                                    <label
                                        className="font-weight-bold">{location.state && location.state.communicationContent}</label>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group mb-4 mb-sm-4">
                                            <label
                                                className="mr-sm-2 mt-4">{t('validate-phone.insert-code')}</label>
                                            <input className="form-control form-control-sm" type="text"
                                                   name="code" {...register("code", {required: true})}
                                                   placeholder={t("global.verification-code")}/>
                                            {errors.code && <span className="error">{t('global.required')}</span>}
                                        </div>
                                        <Button className="send-another mt-4" color="link" onClick={() => {
                                            resendVerification();
                                        }}>{t('validate-phone.link-description')}</Button>
                                        <Button
                                            className="submit mt-3 button-link">{t('global.validate-code')}</Button>

                                    </form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                    <div className=" col-md-4 col-lg-5 col-xl-5 d-md-block d-none right-half">
                    </div>
                </div>
            </div>
        </>
    );
}

export default PhoneValidate;
