import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {statsData, validateData} from "../services/service.service";
import moment from "moment";

const Stats = (props) => {
    const [stats, setData] = useState(null);
    const {roomName} = useParams();
    const {t} = useTranslation(['reports', 'common']);

    useEffect(() => {
        (() => {
            statsData((props.roomName) ? props.roomName : roomName).then((stats) => { setData(stats);});
        })();
    }, []);

    return (
        <div className="mt-3 mr-3 ml-3 mb-3">
            <div className="row text-center">
                <div className="container row">
                    <div className="col-5 text-right">{t('common:global.specialist')}:</div>
                    <div className="col-7 text-left"><span>{stats?.idProvider.name} {stats?.idProvider.secondName}</span></div>
                </div>
                <div className="container row">
                    <div className="col-5 text-right">{t('reports:reports.Date')}:</div>
                    <div className="col-7 text-left">{moment(stats?.serviceDate).format('MM-DD-YYYY')}</div>
                </div>
                <div className="container row">
                    <div className="col-5 text-right">{t('reports:reports.Hour')}:</div>
                    <div className="col-7 text-left">{moment(stats?.serviceDate.toString() + "T" + stats?.time.toString()).format('h:mm a')}</div>
                </div>
                <div className="container row">
                    <div className="col-5 text-right">{t('reports:reports.Gain')}:</div>
                    <div className="col-7 text-left">${stats?.price}</div>
                </div>
                <div className="container row">
                    <div className="col-5 text-right">{t('reports:reports.Length')}:</div>
                    <div className="col-7 text-left">{stats?.elapsed}</div>
                </div>
                <div className="container row">
                    <div className="col-5 text-right">{t('reports:reports.soap')}:</div>
                    <div className="col-7 text-left">
                        {stats?.soap}
                        { stats?.soap == null &&
                            <span>N/A</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stats;