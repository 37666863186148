import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {updateDetailLanguage} from "../../services/provider.service";
import {getLocale} from "../../services/service.service";

const LanguageSelector = ({user, forceUpdate, room}) => {
    const {t} = useTranslation('common');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const getInitialLanguage = () => {
        const storageLanguage = localStorage.getItem("language");
        if (room && storageLanguage === null) {
            getLocale(room).then((lang) => {
                lang = lang.toLowerCase();
                i18next.changeLanguage(lang).then();
                localStorage.setItem('language', lang);
                return lang
            });
        } else if (storageLanguage === null) {
            if (i18next.language === 'es' || i18next.language === 'en')
                return i18next.language;
            else
                return 'en';
        } else
            return storageLanguage;

        return "en";
    }

    const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage());

    const changeLanguageHandler = async (lang) => {
        i18next.changeLanguage(lang).then();
        window.recaptchaOptions = {lang: lang};
        if (forceUpdate)
            forceUpdate(lang);

        if (user)
            await updateDetailLanguage(user.idDetail.idDetail, lang);

        localStorage.setItem('language', lang);
        setSelectedLanguage(lang);
    }

    const toggle = () => setDropdownOpen(prevState => !prevState);

    useEffect(() => {
        let language = localStorage.getItem('language')

        if (language)
            setSelectedLanguage(language);
    }, []);

    useEffect(async () => {
        localStorage.setItem('language', selectedLanguage);
    }, [selectedLanguage]);

    return (
        <Dropdown className="align-self-center" group isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle size="sm" color="primary" outline caret className="language-selector" id="dropdown-basic">
                {t('language.' + selectedLanguage)}
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem onClick={() => changeLanguageHandler('es')}>
                    {t('language.es')}
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguageHandler('en')}>
                    {t('language.en')}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default LanguageSelector;
