import React, {useContext, useEffect, useState} from 'react';
import {Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink} from "reactstrap";
import {useTranslation} from "react-i18next";
import {getGroupedServices, getTodayCount} from "../../services/service.service";
import {AuthContext} from "../../utilities/auth/AuthContext";

import {getSpecialist} from "../../services/specielist.service";
import "../../assets/scss/components/listaCitasProgramadas.scss";
import 'moment/locale/es';
import DayGroup from "./DayGroup";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import appointmentsIcon from "../../assets/images/icons/appointments.svg";
import 'rsuite-table/dist/css/rsuite-table.css';
import {serviceStatusEnum} from "../../utilities";

let searchTimeout = null;

const ListaCitasProgramadas = (props) => {
    const {t} = useTranslation('appointments');
    const context = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [service, setService] = useState([]);
    const [specialist, setSpecialist] = useState([]);

    const [selectedSpecialist, setSelectedSpecialist] = useState([]);
    const [search, setSearch] = useState([]);
    const [filterTime, setFilterTime] = useState("4");
    const [filterStatus, setFilterStatus] = useState(1);
    const [filterPage, setFilterPage] = useState(0);

    const [filterTake, setFilterTake] = useState(10);
    const [, setPagesLength] = useState(0);
    const [pages, setPages] = useState([]);
    const [total, setTotal] = useState(0);

    const settingPagination = (result, page, take) => {
        setFilterPage(page);
        setFilterTake(take);
        setTotal(result.total);
        setPagesLength(result.currentCount);

        if (result.groupedServices?.length > 0) {
            setPages(Array(Math.ceil(result.total / filterTake)).fill(null))
        }
    }

    const getServices = async (_context, date, idSpecialist, _search, status, page, take) => {
        setLoading(true);
        const result = await getGroupedServices(_context, date, idSpecialist, _search, status, page, take);
        setService(result.groupedServices);
        settingPagination(result, page, take);
        setLoading(false);
    }

    const timeChange = async (event) => {
        setFilterTime(event.target.value);
        await getServices(context, event.target.value, selectedSpecialist, search, filterStatus, 0, filterTake);
    }

    const statusChange = async (event) => {
        setFilterStatus(event.target.value);
        await getServices(context, filterTime, selectedSpecialist, search, event.target.value, 0, filterTake);
    }

    const specialistChange = async (event) => {
        setSelectedSpecialist(event.target.value);
        await getServices(context, filterTime, event.target.value, search, filterStatus, 0, filterTake);
    }

    const searchChange = async (event) => {
        await getServices(context, filterTime, selectedSpecialist, event.target.value, filterStatus, 0, filterTake);
        setSearching(false);
    }

    const pagesCallback = async (page, take) => {
        await getServices(context, filterTime, selectedSpecialist, search, filterStatus, page, take);
    }

    const getData = async () => {
        setLoading(true);
        const result = await getGroupedServices(context, filterTime, null, null, filterStatus, filterPage, filterTake);
        setService(result.groupedServices);
        settingPagination(result, 0, filterTake);

        getTodayCount(context.user.user.idCompany.idCompany).then((response) => {
            setPages(Array((filterStatus == serviceStatusEnum.pending) ? Math.ceil(response.count / filterTake) : Math.ceil(service.length / filterTake)).fill(null));
        });

        if (result.groupedServices?.length > 0) {
            setPagesLength(Array(Math.ceil(result.total / filterTake)).fill(null));
        }

        setSpecialist((await getSpecialist(context.user.user.idCompany.idCompany)).map((item) => ({
            label: `${item.name} ${item.secondName}`,
            value: item.idProvider
        })));
        setLoading(false);
    }


    useEffect(() => {
        getData().then();
    }, [props.reloadAppointments]);


    useEffect(() => {
        getData().then();
    }, []);

    return (
        <>
            <div className="mt-3">
                <Form>
                    <div className="row ">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div className="input-group input-group-sm">
                                <input className={`form-control form-control-sm`} value={search} onChange={event => {
                                    clearTimeout(searchTimeout);
                                    setSearching(true);
                                    setSearch(event.target.value);
                                    searchTimeout = setTimeout(() => {
                                        searchChange(event).then();
                                    }, 1000);
                                }} placeholder={t('scheduled-appointments.search')}/>
                                {searching ? <div className="input-group-append">
                                    <span className="input-group-text input-spinner-group"><FontAwesomeIcon
                                        icon={faSpinner} className="input-spinner"/></span>
                                </div> : <></>}
                            </div>
                        </div>

                        <FormGroup className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 " onChange={statusChange}>
                            <Input defaultValue={filterStatus} type="select" bsSize="sm" name="select">
                                <option
                                    value={-1}>{t('scheduled-appointments.status.all')}</option>
                                <option
                                    value={serviceStatusEnum.pending}>{t('scheduled-appointments.status.pending')}</option>
                                <option
                                    value={serviceStatusEnum.cancelled}>{t('scheduled-appointments.status.cancelled')}</option>
                                <option
                                    value={serviceStatusEnum.expired}>{t('scheduled-appointments.status.expired')}</option>
                                <option
                                    value={serviceStatusEnum.finished}>{t('scheduled-appointments.status.finished')}</option>
                                <option
                                    value={serviceStatusEnum.inProgress}>{t('scheduled-appointments.status.in-progress')}</option>
                                <option
                                    value={serviceStatusEnum.pendingPayment}>{t('scheduled-appointments.status.pending-payment')}</option>
                            </Input>
                        </FormGroup>

                        <FormGroup className="col-6 col col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <Input type="select" bsSize="sm" name="select" onChange={specialistChange}>
                                <option key={""} value="">{t('scheduled-appointments.all')}</option>
                                {
                                    specialist.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>

                        <FormGroup className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 " onChange={timeChange}>
                            <Input defaultValue={filterTime} type="select" bsSize="sm" name="select">
                                <option value="4">{t('scheduled-appointments.all')}</option>
                                <option value="1">{t('scheduled-appointments.today')}</option>
                                <option value="2">{t('scheduled-appointments.week')}</option>
                                <option value="3">{t('scheduled-appointments.month')}</option>
                            </Input>
                        </FormGroup>
                    </div>
                </Form>
            </div>

            <div className="table-appointments ml-2 mr-2 mt-2 ">
                {loading ? <div className="spinner-container">
                    <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                </div> : <>
                    {service.length > 0 ?
                        <>
                            {service.map((item, index) => {
                                return (
                                    <DayGroup {...props} user={context} key={index} item={item} index={index}
                                              specialist={specialist}
                                              setReload={async () => {
                                                  await getServices(context, filterTime, selectedSpecialist, search, filterStatus, 0, filterTake);
                                                  await props.setReloadAppointments(Date.now());
                                              }}/>
                                );
                            })}
                            <p className="mt-2 mb-2 footer-label">{t('scheduled-appointments.footer-list', {total: total})}</p>
                            <div className="pagination-wrap">
                                <Pagination size="sm">
                                    <PaginationItem disabled={filterPage < 1}>
                                        <PaginationLink
                                            first
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                pagesCallback(0, filterTake).then();
                                            }}
                                        />
                                    </PaginationItem>
                                    <PaginationItem disabled={filterPage < 1}>
                                        <PaginationLink
                                            href="#"
                                            previous
                                            onClick={(e) => {
                                                e.preventDefault();
                                                pagesCallback(filterPage - 1, filterTake).then();
                                            }}
                                        />
                                    </PaginationItem >
                                    {pages.map((_item, index) => {
                                        return (
                                            <PaginationItem   className=" flex-sm-wrap" key={index} active={filterPage === index}>
                                                <PaginationLink href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    pagesCallback(index, filterTake).then();
                                                }}>
                                                    {index + 1}
                                                </PaginationLink>
                                            </PaginationItem>
                                        )
                                    })}
                                    <PaginationItem disabled={filterPage === pages.length - 1}>
                                        <PaginationLink
                                            href="#"
                                            next
                                            onClick={(e) => {
                                                e.preventDefault();
                                                pagesCallback(filterPage + 1, filterTake).then();
                                            }}
                                        />
                                    </PaginationItem>
                                    <PaginationItem disabled={filterPage === pages.length - 1}>
                                        <PaginationLink
                                            href="#"
                                            last
                                            onClick={(e) => {
                                                e.preventDefault();
                                                pagesCallback(pages.length - 1, filterTake).then();
                                            }}
                                        />

                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </>
                        :
                        <div className="noresults-container">
                            <img width="120px" src={appointmentsIcon} alt=""/>
                            <p>{t('scheduled-appointments.noAppointments')}</p>
                        </div>
                    }
                </>
                }
            </div>
        </>
    );
}

export default ListaCitasProgramadas;
