/**
 * Endpoints de servidor
 * @readonly
 */
export const APIROUTES = {
    /**
     * Ruta authentication
     * @enum {string}
     * @readonly
     */
    auth: {
        /**
         * Comprueba si un provider ya se encuentra registrado en la app. {@link authentication/#isRegistered|isRegistered}
         * @link {#company}
         * @type {string}
         */
        isRegistered: "/auth/isRegistered",
        /**
         * Modifica el progreso en el registro del provider
         * @type {string}
         */
        progress: "/provider/progress",
        /**
         * Incrementa el estado de progreso de registro de un proveedor
         * @type {string}
         */
        progressUpdate: "/auth/increase",
        /**
         * Si existe provider o usuario con ese método de comunicación envía código a traves de dicho método utilizado.
         * @type {string}
         */
        isRegisteredReset: "/auth/isRegisteredReset",
        /**
         * Login con usuario y contraseña del provider.
         * @type {string}
         */
        login: "/auth/login",
        /**
         * Marca el número de teléfono como verificado del proveedor.
         * @type {string}
         */
        phoneVerified: "/auth/phone/verified",
        validate: "/auth/validate",
        expiration: "/auth/expiration",
        verified: "/auth/verified",
        verify: "/auth/verify"
    },
    company: {
        getById: "/company/get",
        save: "/company/save",
        updateCompanyOrganization: "/company/updateCompanyOrganization",
        updateCompanyBranding: "/company/updateCompanyBranding",
        getProvidersByIdCompany: "/company/getProvidersByIdCompany",
        getProvidersTotalByIdCompany: "/company/getProviderTotal",
    },
    comunication: {
        all: "/communication/all"
    },
    condition: {
        all: "/condition/all"
    },
    license: {
        all: '/license/get',
        allPaid: '/license/get/paid',
        getLicense: "/license/getlicense/",
    },
    provider: {
        create: "/provider/create",
        createProvider: "/provider/create/provider",
        detail: "/provider/detail",
        getByEmail: "/provider/get/mail",
        getByPhone: "/provider/get/phone",
        getDetail: "/provider/get/detail",
        profile: "/provider/profile/content",
        image: "/provider/profile/image",
        language: "/provider/profile/language",
        types: "/provider/types",
        setEmail: "/provider/mail",
        setFormation: "/provider/set/formation",
        setNpi: "/provider/set/npi",
        setPassword: "/provider/password",
        setPhone: "/provider/phone",
        setLicense: "/provider/set/license",
        update: "/provider/update",
        updateFormation: "/provider/update/formation",
        updateNPI: "/provider/npi",
        validateNpi: "/provider/validate/npi"
    },
    providerType: {
        all: "/providertype/all",
        admin: "/providertype/admin",
        getByRole: "/providertype/getByRole"
    },
    role: {
        all: "/role/all",
        isAdmin: "/role/isAdmin",
        isManager: "/role/isManager"
    },
    user: {
        getByPhone: "/user/phone",
        getByCompany: "/user/company",
        getByEmail: "/user/mail"
    },
    service: {
        allByDoctor: "/service/all",
        concluded: "/service/concluded",
        filter: "/service/filter",
        filterChart: "/service/chart/filter",
        count: "/service/count",
        countPendent: "/service/countPendent",
        paid: "/service/paid",
        validate: "/service/validate",
        stats: "/service/stats",
        providerCommunication: "/service/mail/provider",
        log: "/service/log",
        create: "/service/new",
        group: "/user/group",
        locale: "/service/getLocale",
        invite: "/service/invite",
        sendURL: "/sendURL",
        service: "/service/get",
        serviceDelete: "/service/cancel",
        status: "/service/status",
        mail: "/service/mail",
        specialist: "/service/specialist",
        specialistByCompany: "/provider/company",
        today: "/service/today",
        update: "/service/update"
    },
    specialist: {
        getByCompany: "/provider/company",
        getByType: "/specialist/types"
    },
    specialty: {
        all: "/specialty/all"
    },
    stripe: {
        createConnectProduct: "/stripe/createConnectProduct/",
        toggleFreeProduct: "/stripe/toggleFreeProduct/",
        createStripeAccount: "/stripe/createStripeAccount/",
        createStripeLoginLink: "/stripe/createStripeLoginLink/",
        createSubscription: "/stripe/createSubscription/",
        deleteConnected: "/stripe/connected/delete/",
        deleteConnectProduct: "/stripe/deleteConnectProduct/",
        deleteSubscription: "/stripe/deleteSubscription/",
        reactivateSubscription: "/stripe/reactivateSubscription/",
        getConnectedAccount: "/stripe/getConnectedAccount/",
        getProviderProducts: "/stripe/getProviderProducts/",
        getSubscription: "/stripe/getSubscription/",
        paySubscription: "/stripe/paySubscription/",
        listSubcription: "/stripe/subcription",
        checkoutUser: "/stripe/checkout_cus",
        checkoutUserReceipt: "/stripe/checkout_cus_receipt/",
        updateStatus: "/stripe/customer/conferenceUpdate/",
        verifyService: "/stripe/verifyPayment/",
        saveLicense: "/stripe/saveLicense/",
        createStripeSetupIntent: "/stripe/createSetupIntent/"
    }
}
