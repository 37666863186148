import {useForm} from "react-hook-form";
import {updateCompanyOrganization} from "../../services/company.service";
import {useTranslation} from "react-i18next";
import Countries from "../Countries/Countries";
import PropTypes from "prop-types";
import React from "react";
import {phonePattern} from "../../utilities";

export const OrganizationForm = ({company, reload, close}) => {
    const {t} = useTranslation(["myTeam", "common"]);
    const {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            brandingName: company?.brandingName,
            phone: company?.phone,
            address: company?.idAddress?.address,
            country: company?.idAddress?.country,
            postal_code: company?.idAddress?.zipcode,
            city: company?.idAddress?.city,
        }
    });
    const onSubmit = (data) => {
        data.idCompany = company.idCompany;
        updateCompanyOrganization(data).then((_company) => {
            reload();
            close();
        });
    };

    const goBack = () => {
        close();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
                <div className="form-group col-md-8 col-lg-9">
                    <label htmlFor="addressField">{t('myTeam:organization.formName')}</label>
                    <input placeholder="ej. Healthy Encounter" type="text"
                           name="brandingName"
                           className="form-control form-control-sm" id="ComercialField"
                           {...register("brandingName", {required: t('common:global.required')})}
                    />
                    {errors.brandingName &&
                    <span className="error ml-2">{errors.brandingName.message}</span>}
                </div>
                <div className="form-group col-md-4 col-lg-3">
                    <label htmlFor="phoneField">{t('myTeam:organization.phone')}</label>
                    <input name="phone" placeholder={t('myTeam:organization.phone')}
                           className="form-control form-control-sm" id="countryCodeField"
                           {...register("phone", {
                               required: t('common:global.required'), pattern: {
                                   value: phonePattern,
                                   message: t('common:global.invalid-format')
                               }
                           })}
                    />
                    {errors.phone &&
                    <span className="error ml-2">{errors.phone.message}</span>}
                </div>
                <div className="w-100"></div>
                <div className="form-group col-md-8 col-lg-9">
                    <label htmlFor="addressField">{t('myTeam:organization.address')}</label>
                    <input name="address" placeholder={t('myTeam:organization.address')}
                           className="form-control form-control-sm" id="addressField"
                           {...register("address", {required: t('common:global.required')})}
                    />
                    {errors.address &&
                    <span className="error ml-2">{errors.address.message}</span>}
                </div>

                <div className="form-group col-md-4 col-lg-3">
                    <label htmlFor="countryField">{t('myTeam:organization.country')}</label>
                    <select placeholder={t('myTeam:organization.selectCountry')} name="country"
                            id="countryField" className="form-control form-control-sm"
                            {...register("country", {required: t('common:global.required')})}
                    >
                        <Countries/>
                    </select>
                    {errors.country &&
                    <span className="error ml-2">{errors.country.message}</span>}
                </div>

                <div className="form-group col-md-3">
                    <label htmlFor="postalCodeField">{t('myTeam:organization.postalCode')}</label>
                    <input name="postal_code" placeholder={t('myTeam:organization.postalCode')}
                           className="form-control form-control-sm" id="addressField"
                           {...register("postal_code", {required: t('common:global.required')})}
                    />
                    {errors.postal_code &&
                    <span className="error ml-2">{errors.postal_code.message}</span>}
                </div>

                <div className="form-group col-md-3">
                    <label htmlFor="cityField">{t('myTeam:organization.city')}</label>
                    <input name="city" placeholder={t('myTeam:organization.city')}
                           className="form-control form-control-sm" id="cityField"
                           {...register("city", {required: t('common:global.required')})}
                    />
                    {errors.city &&
                    <span className="error ml-2">{errors.city.message}</span>}
                </div>

                <div className="form-group col-md-12 d-flex justify-content-end">
                    <button className="btn btn-secondary btn-sm mr-2" type="button"
                            onClick={goBack}>{t('common:global.cancel')}</button>
                    <button className="btn btn-primary btn-sm submit" type="submit">{t('saveChanges')}</button>
                </div>
            </div>
        </form>
    );
}

OrganizationForm.propTypes = {
    company: PropTypes.object.isRequired
}

