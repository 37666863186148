import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {createConnectProduct} from "../../../services/stripe.service";
import CreateProductModal from "./CreateProductModal";

function CreateProductButton({user, reload, ...props}) {

    const {t} = useTranslation(["profile", "common"]);
    const [showModal, setShowModal] = useState(false);
    const [processing, setProcessing] = useState(false);


    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const onCreateProduct = async ({productName, productPrice}) => {
        console.log('onCreate Product');
        setProcessing(true);
        try {
            await createConnectProduct({
                productName: productName,
                productPrice: productPrice,
                idProvider: user.idProvider
            });
            toast.success(t("common:commercial-account.success-create-product"));
            reload();
            closeModal();
        } catch (error) {
            console.error(error);
            toast.error(t("common:commercial-account.error-create-product"));
        }
        setProcessing(false);
    }

    return (
        <>
            <button className="btn btn-outline-primary btn-sm"
                    onClick={openModal}>
                <FontAwesomeIcon icon={faPlus} className="mr-1"/>
                {t("profile:Stripe.stripe_product_add")}
            </button>
            <CreateProductModal
                {...props}
                toggle={showModal}
                onClose={closeModal}
                showProcessing={processing}
                onCreateProduct={onCreateProduct}
            />
        </>
    );
}

export default CreateProductButton;
