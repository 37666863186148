import React from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import FooterAuth from "./FooterAuth";

const FooterLinks = ({className}) => {
    const {t} = useTranslation('common');
    const year = new Date().getFullYear();

    return (
        <ul className={className}>
            <li className="mr-auto p-2">
                <span className="">©{year} TeleMedik Innova Health Solutions</span>
            </li>
            <li className="p-2">
                <a target="_blank"
                   href={(i18next.language === "es")
                       ? `https://www.telemedik.com/index.php/terminos-y-condiciones-healthy-encounter/`
                       : `https://www.telemedik.com/index.php/en/terms-and-conditions-healthy-encounter/`}>
                    <span className="list-text">{t('global.terms-conditions')}</span>
                </a>
            </li>
        </ul>
    );
}
export default FooterLinks;
