import "../../css/videoHeader.scss";
import {useCallback} from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import ButtonAppointmentInfo from "./ButtonAppointmentInfo";
import {Watch} from "./Watch";

const VideoHeader = (props) => {
    const HeaderLeft = useCallback(() => {
        return (
            <>
                <div>
                    <ButtonAppointmentInfo {...props}/>
                </div>


                <MobileView>
                    <Watch ref={props.watchReference}/>
                </MobileView>
            </>
        )
    }, [ props.videoState().service]);

    const HeaderRight = useCallback(() => {
        return (
            <>
                <BrowserView>
                    <div className="d-flex align-items-center justify-content-end"/>
                </BrowserView>
                <MobileView>
                    <div/>
                </MobileView>
            </>
        );
    }, []);

    return (
        <>
            <BrowserView>
                <div className="videoHeader d-flex justify-content-between align-items-center h-100">
                    <HeaderLeft/>
                    <div
                        className="time-container d-flex align-items-center justify-content-center">
                        <Watch ref={props.watchReference}/>
                    </div>
                    <HeaderRight/>
                </div>
            </BrowserView>
            <MobileView>
                <div className="videoHeader d-flex justify-content-between align-items-center h-100">
                    <HeaderLeft/>
                    <HeaderRight/>
                </div>
            </MobileView>
        </>
    );
}

export default VideoHeader;
