import React, {forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophoneSlash, faVideoSlash} from '@fortawesome/free-solid-svg-icons';
import {StatusAlert} from "../header/statusAlert";
import {StatsData} from "../header/statsData";

const viewReducer = (state, action) => {
    switch (action.payload) {
        case 'reset':
            return {connections: []}
        case 'set':
            return {connections: action.set}
        case 'update':
            return {connections: [...state.connections, action.add]}

        case 'audio-video':
            let connections = [...state.connections];
            connections.map((connection, _index) => {
                try {
                    console.log('socketId ', connection.socketId);
                    connection.video = action.filter[connection.socketId].status.video;
                    connection.audio = action.filter[connection.socketId].status.audio;
                } catch (err) {
                    console.error(err);
                }
            });
            return {connections: [...connections]}
        default:
            throw new Error("What's going on?")

    }
}
const RemoteVideoStream = forwardRef((_props, reference) => {
    const [connection, dispatchConnection] = useReducer(viewReducer, {connections: []});
    const statusAlertReference = useRef(null);
    const statsDataReference = useRef(null);


    useImperativeHandle(reference, () => ({
        setDispatch: (payload) => {
            dispatchConnection(payload)
        },

        getState: () => {
            return connection;
        },

        setQualityConnection: (qualityConnection) => {
            console.log('[STATS] set quality connection:', qualityConnection);
            // console.log('Connection', connection);
            if (connection.connections.length > 0) {
                statusAlertReference.current.showAlert(qualityConnection);
                statsDataReference.current.showStats(qualityConnection);
            }
        },

        disableStatusAlert: () => {
            statusAlertReference.current.disableAlert();
        },
    }));

    useEffect(() => {

    }, [connection]);

    return (
        <>
            {
                connection.connections.map(((item, _index) => {
                    return (
                        <div key={item.id} className="videoMainItem">
                            <video id={item.socketId}
                                   className="video-stream-remote" playsInline={true} autoPlay={true}
                                   ref={(_reference) => {
                                       if (_reference) {
                                           _reference.srcObject = item.stream;
                                       }
                                   }}/>
                            <div className="infoWrapper">
                                {!item.audio && <div className="audioStatusIcon">
                                    <FontAwesomeIcon className="checkIcon" icon={faMicrophoneSlash}/>
                                </div>}
                                <span className="labelName">{item.text}</span>
                                <StatusAlert socketId={item.socketId} ref={statusAlertReference}></StatusAlert>
                                <StatsData socketId={item.socketId} ref={statsDataReference}></StatsData>
                                {!item.video &&
                                    <div className="videoStatusIcon">
                                        <FontAwesomeIcon className="videoIcon" icon={faVideoSlash}/>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }))
            }
        </>

    );
})

export default RemoteVideoStream;
