import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import audioIconOff from "../../../../../assets/images/video/audio-off.svg";
import audioIconOn from "../../../../../assets/images/video/audio-active.svg";
import {forwardRef, useImperativeHandle, useState} from "react";

const ButtonMuteMicrophone = forwardRef((props, reference) => {
    const {t} = useTranslation(["video", "common"],);
    const [muteStatus, setMuteStatus] = useState(false);

    useImperativeHandle(reference, () => ({
        toggleMute: () => {
            setMuteStatus(!muteStatus);
        }
    }));

    return (
        <>
            <button data-tip={muteStatus ? t("microphone-active") : t("microphone-deactivate")}
                    className="btn btn-link color-white"
                    onClick={() => {
                        setMuteStatus(!muteStatus);
                        props.localVideoReference.current.setAudio(muteStatus);
                    }}>
                {muteStatus
                    ? <img src={audioIconOff} alt="audio off"/>
                    : <img src={audioIconOn} alt="audio on"/>
                }
            </button>
            <ReactTooltip/>
        </>

    );
})
export default ButtonMuteMicrophone;
