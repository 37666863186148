import React, {useContext} from 'react';
import LicenseAlert from "./LicenseAlert";
import ProductsAlert from "./ProductsAlert";
import {AuthContext} from "../../../utilities/auth/AuthContext";

function ProviderAlerts({className}) {
    const {user: {user}} = useContext(AuthContext);
    return (
        (user.idRole.name === "ADMIN") ?
            <div className={className}>
                <LicenseAlert/>
                <ProductsAlert/>
            </div>
            : <></>
    );
}

export default ProviderAlerts;