import React, {useContext} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {AuthContext} from "../utilities/auth/AuthContext";
import {PrivateRoute} from "./PrivateRoute";

import {AdminRoutes} from "./AdminRoutes";
import AuthRoutes from "./AuthRoutes";
import {ValidationRoutes} from "./ValidationRoutes";
import {UserRoutes} from "./UserRoutes";
import {VideoRoutes} from "./VideoRoutes";

const AppRouter = () => {
    const {user} = useContext(AuthContext);
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/auth" render={props => <AuthRoutes {...props} />}/>
                    <Route path="/validate" render={props => <ValidationRoutes {...props} />}/>
                    <PrivateRoute
                        isAuthenticated={user.logged}
                        path={(user.company) ? user.company : "/"}
                        public={UserRoutes}
                        video={VideoRoutes}
                        component={AdminRoutes}/>

                    <Route path="/*" exact>
                        <Redirect to={user.company ? user.company : '/'}/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default AppRouter;
