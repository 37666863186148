import {forwardRef, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {kEnableStatsLog} from "../../../../../utilities";

export const StatsData = forwardRef((_props, reference) => {
    const [showStats, setShowStats] = useState(kEnableStatsLog);
    const [stats, setStats] = useState({
        durations: {
            bandwidth: 0,
            cpu: 0,
            other: 0,
            none: 0
        },
        reason: 'none'
    });
    const {t} = useTranslation(["video", "common"]);

    useImperativeHandle(reference, () => ({
        showStats: (qualityConnection) => {
            if (qualityConnection.id === _props.socketId) {
                setStats(qualityConnection);
                setShowStats(kEnableStatsLog);
            }
        },
        disableStats: () => {
            setShowStats(false);
        }
    }));

    return (
        <>
            {
                showStats ? (
                    <div className="StatsDataWrapper">
                        <div className="statsData">
                            <p>bandwidth: {stats.durations.bandwidth ?? 0}</p>
                            <p>cpu: {stats.durations.cpu ?? 0}</p>
                            <p>other: {stats.durations.other ?? 0}</p>
                            <p>none: {stats.durations.none ?? 0}</p>
                            <p>Quality Limitation: {stats.reason ?? 'none'}</p>
                        </div>

                    </div>
                ) : null
            }
        </>
    );

});

