import ReportesPage from "./views/Admin/ReportesPage";
import CitasProgramadasPage from "./views/Admin/CitasProgramadasPage";
import MiEquipoPage from "./views/Admin/MiEquipoPage";
import MiPerfilPage from "./views/Admin/MiPerfilPage";
import Login from "./views/Auth/Login";

import PasswordChange from "./views/Auth/PasswordChange";
import EndPasswordChange from "./views/Auth/EndPasswordChange";
import Register from "./views/Auth/Register";
import EmailValidate from "./views/Auth/EmailValidate";
import PhoneValidate from "./views/Auth/PhoneValidate";

import ProfessionalProfile from "./views/Auth/ProfessionalProfile";
import LicenseType from "./views/Auth/LicenseType";
import Receipt from "./views/Auth/Receipt";
import ConfigureAccount from "./views/Auth/ConfigureAccount";
import Branding from "./views/Auth/Branding";

import Experience from "./views/Auth/Experience";
import Billing from "./views/Auth/Billing";
import VideoConsult from "./views/Admin/VideoConsult";
import ValidationService from "./views/Admin/ValidationService";

import ClientCheckOut from "./views/User/ClientCheckOut";
import ClientCheckOutReceipt from "./views/User/ClientCheckOutReceipt";
import ClientVideoData from "./views/User/ClientVideoData";
import ValidateCommunication from "./views/Modal/ValidateCommunication";
import Onboarding from "./views/Auth/Onboarding";

import ClientScheduled from "./views/User/ClientScheduled";
import CommercialAccount from "./views/Admin/CommercialAccount";
import Remove from "./views/Video/Remove";
import Stats from "./views/Stats";
import ClientFinish from "./views/User/ClientFinish";

export const AuthRoute = [
  {
    path: "/login/:redirect?",
    name: "Login",
    icon: "nc-icon nc-app",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forgot/1",
    name: "Forgot Password",
    icon: "nc-icon nc-app",
    component: PasswordChange,
    layout: "/auth",
  },
  {
    path: "/forgot/2",
    name: "Forgot Password",
    icon: "nc-icon nc-app",
    component: EndPasswordChange,
    layout: "/auth",
  },
  {
    path: "/create",
    name: "Create",
    icon: "nc-icon nc-app",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/email/validate",
    name: "Validate Email",
    icon: "nc-icon nc-app",
    component: EmailValidate,
    layout: "/auth",
  },
  {
    path: "/phone/validate",
    name: "Validate Phone",
    icon: "nc-icon nc-app",
    component: PhoneValidate,
    layout: "/auth",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "nc-icon nc-app",
    component: ProfessionalProfile,
    layout: "/auth",
  },
  {
    path: "/payment/1",
    name: "Payment",
    icon: "nc-icon nc-app",
    component: LicenseType,
    layout: "/auth",
  },
  {
    path: "/payment/3",
    name: "Payment",
    icon: "nc-icon nc-app",
    component: Receipt,
    layout: "/auth",
  },
  {
    path: "/add/profile",
    name: "Complete Profile",
    icon: "nc-icon nc-app",
    component: ConfigureAccount,
    layout: "/auth",
  },
  {
    path: "/billing",
    name: "Billing",
    icon: "nc-icon nc-app",
    component: Billing,
    layout: "/auth",
  },
  {
    path: "/formation",
    name: "Billing",
    icon: "nc-icon nc-app",
    component: Experience,
    layout: "/auth",
  },
  {
    path: "/branding",
    name: "Branding",
    icon: "nc-icon nc-app",
    component: Branding,
    layout: "/auth",
  },
  {
    path: "/stripe",
    name: "Stripe",
    icon: "nc-icon nc-app",
    component: Onboarding,
    layout: "/auth"
  },
];

export const ValidationRoute = [
    {
      path: "/:company/transaction/:idService",
      name: "Payment Validation",
      component: ValidationService,
      layout: "/validate",
      roles: ['83559991-0180-4d9d-8119-39be5d8dfa53', '6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf']
    },
];

export const AdminRoute = [
  {
    path: "/new-appointment",
    name: "newAppointment",
    icon: "icon new-appointment",
    shown: true,
    component: CitasProgramadasPage,
    layout: "/admin",
    roles: ['83559991-0180-4d9d-8119-39be5d8dfa53', '6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf', '337d070b-b521-4acd-b9a6-cc87938a96df']
  },
  {
    path: "/appointments",
    name: "appointments",
    icon: "icon appointments",
    shown: true,
    component: CitasProgramadasPage,
    layout: "/admin",
    roles: ['83559991-0180-4d9d-8119-39be5d8dfa53', '6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf', '337d070b-b521-4acd-b9a6-cc87938a96df']
  },
  {
    path: "/stats/:roomName",
    name: "stats",
    icon: "icon",
    shown: false,
    component: Stats,
    layout: "/admin",
    roles: ['83559991-0180-4d9d-8119-39be5d8dfa53', '6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf', '337d070b-b521-4acd-b9a6-cc87938a96df']
  },
  {
    path: "/reports",
    name: "reports",
    icon: "icon reports",
    shown: true,
    component: ReportesPage,
    layout: "/admin",
    roles: ['83559991-0180-4d9d-8119-39be5d8dfa53', '6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf', '337d070b-b521-4acd-b9a6-cc87938a96df']
  },
  {
    path: "/my-team",
    name: "myTeam",
    icon: "icon my-team",
    shown: true,
    component: MiEquipoPage,
    layout: "/admin",
    roles: ['6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf', '83559991-0180-4d9d-8119-39be5d8dfa53']
  },
  {
    path: "/profile",
    name: "profile",
    icon: "icon profile",
    shown: true,
    component: MiPerfilPage,
    layout: "/admin",
    roles: ['83559991-0180-4d9d-8119-39be5d8dfa53', '6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf', '337d070b-b521-4acd-b9a6-cc87938a96df']
  },
  {
    path: "/commercial-account",
    name: "commercialAccount",
    icon: "icon commercial-account",
    shown: true,
    component: CommercialAccount,
    layout: "/admin",
    roles: ['6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf']
  },

  {
    path: "/conference/:roomName",
    name: "Conference",
    shown: false,
    component: VideoConsult,
    layout: "/admin",
    roles: ['83559991-0180-4d9d-8119-39be5d8dfa53', '6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf']
  },
  {
    path: "/communication/update/:communicationType/:communicationContent",
    name: "Communication Update",
    icon: "nc-icon nc-app",
    component: ValidateCommunication,
    layout: "/admin",
    roles: ['83559991-0180-4d9d-8119-39be5d8dfa53', '6409c4fb-fc97-4c30-ba0e-4d2b07b2fbcf', '337d070b-b521-4acd-b9a6-cc87938a96df']
  },
];

export const FullScreenRoute = [
  {
    path: "/conference/:roomName",
    name: "Conference",
    shown: false,
    component: VideoConsult,
    layout: "/admin",
    roles: []
  },
];

export const UserRoute = [
  {
    path: "/video/",
    name: "WebRTC Conference",
    component: VideoConsult,
    layout: "/client",
  },
  {
    path: "/conference/:roomName",
    name: "Client Conference",
    component: VideoConsult,
    layout: "/client",
  },
  {
    path: "/stats/:roomName",
    name: "Client Conference",
    component: Stats,
    layout: "/client",
  },
  {
    path: "/scheduled/:roomName",
    name: "Scheduled Conference",
    component: ClientScheduled,
    layout: "/client",
  },

  {
    path: "/finished/:id",
    name: "Finished Conference",
    component: ClientFinish,
    layout: "/client",
  },

  {
    path: "/removed",
    name: "Conference Ended",
    component: Remove,
    layout: "/client",
  },
  {
    path: "/verification/:id",
    name: "Conference Pay",
    component: ClientCheckOut,
    layout: "/client",
  },
  {
    path: "/userReceipt/:mail/:idServ",
    name: "Conference Receipt",
    component: ClientCheckOutReceipt,
    layout: "/client",
  },

  /* GUEST CLIENT */
  {
    path: "/invited/:roomName",
    name: "Conference Data",
    component: ClientVideoData,
    layout: "/guest",
  },
  {
    path: "/conference/:roomName",
    name: "Guest Conference",
    component: VideoConsult,
    layout: "/guest",
  },
];
