import React, {useContext} from "react";
import {useForm} from "react-hook-form";
import {Link, NavLink, useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {AuthContext} from "../../utilities/auth/AuthContext";
import {authTypes} from "../../utilities/types/types";
import {loaderContext} from "../../EHApp";
import {authLogin, providerCommunticationVerify} from "../../services/auth.service";
import AuthLayout from "../../routers/AuthLayout";
import 'assets/scss/styles/_login.scss';
import {mailPattern} from "../../utilities";

export const Login = (props) => {

    const {t} = useTranslation('common');
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {dispatch} = useContext(AuthContext);
    const {redirect} = useParams();
    const history = useHistory();

    const onSubmit = (data) => {
        setLoader(true);
        authLogin(true, data.username, data.identification).then((response => {

            let current = {
                pathname: null,
                state: {
                    idProvider: response.user.idProvider,
                    doctorName: response.user.name,
                    username: data.username,
                    identification: data.identification,
                    company: response.company,
                    isProvider: false
                },
                setLocation: true
            };

            setLoader(false);

            switch (response.user.progress) {
                case 1:
                    let mailBool = true, navigate = "/auth/email/validate";
                    if (response.user.mail == null) {
                        mailBool = false;
                        navigate = "/auth/phone/validate";
                    }

                    providerCommunticationVerify(mailBool, (mailBool) ? response.user.mail : response.user.idPhone.phoneNumber)
                        .then((communication) => {
                            setLoader(false);
                            if (communication.status === 400) {
                                toast.error(t("global.phone-incorrect"))
                            } else {
                                current.pathname = navigate;
                                current.state = {
                                    communicationContent: (mailBool) ? response.user.mail : response.user.idPhone.phoneNumber,
                                    idProvider: response.user.idProvider,
                                    doctorName: response.user.name,
                                    username: data.username,
                                    identification: data.identification
                                }
                                history.push(current);
                            }

                        }).catch((error) => {
                        setLoader(false);
                        console.log(error.message);
                    });

                    break;
                case 2:
                    current.pathname = '/auth/profile';
                    history.push(current);
                    break;
                case 3:
                    current.pathname = '/auth/payment/1';
                    history.push(current);
                    break;
                case 4:
                    current.pathname = '/auth/payment/3';
                    history.push(current);
                    break;
                case 5:
                    current.state.isProvider = response.user.idCompany !== null;
                    current.pathname = '/auth/add/profile';
                    history.push(current);
                    break;
                case 6:
                    current.pathname = '/auth/formation';
                    history.push(current);
                    break;
                case 7:
                    current.pathname = '/auth/branding';
                    history.push(current);
                    break;
                case 8:
                    dispatch({
                        type: authTypes.login,
                        payload: {
                            user: response.user,
                            token: response.token,
                            company: "/" + response.company.name
                        }
                    });

                    if (!redirect)
                        props.history.replace('/' + response.company.name + "/appointments");
                    else
                        props.history.replace('/' + response.company.name + `/communication/update/${redirect}`);
                    break;
            }

        })).catch(error => {
            if (error.response !== undefined) {
                if (error.response.status === 400) {
                    console.error(error);
                    toast.error(t("global.user-incorrect"));
                } else {
                    console.error(error);
                    toast.error(t("global.error-database"));
                }
            }
            setLoader(false);
        });
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row vh-100  no-gutters login-wrapper">
                    <div className="col-12 col-md-7 col-lg-6 col-xl-5 left-half">
                        <AuthLayout showLanguageSelector={true}>
                            <article className="h-100 row no-gutters justify-content-center align-items-center">
                                <div className="col-10 col-lg-9 col-xl-8">
                                    <h1 className="align-self-center">{t('login.module-title')}</h1>
                                    <label>{t('login.dont-account')}</label>
                                    <Link className="create-account mt-3" to="/auth/create"
                                    > {t('login.module-description')}</Link>

                                    <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label>{t('login.user')}</label>
                                            <input type="email" className="form-control form-control-sm" type="text"
                                                   name="username" {...register("username", {
                                                required: t('global.required'),
                                                pattern: {
                                                    message: t('global.email-valid'),
                                                    value: mailPattern
                                                }
                                            })}
                                                   placeholder={t('login.username-placeholder')}/>
                                            {errors.username &&
                                            <span className="error">{errors.username.message}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label className="mr-sm-2">{t('login.password')}</label>
                                            <input className="form-control form-control-sm" type="password"
                                                   autoComplete="on"
                                                   name="password" {...register("identification", {required: t('global.required')})}
                                                   placeholder={t('login.password')}/>
                                            {errors.identification &&
                                            <span className="error">{errors.identification.message}</span>}
                                        </div>

                                        <button
                                            className="btn btn-primary btn-sm submit mt-3 w-100">{t('login.session-start')}</button>
                                        <p className="text-center mt-3">
                                            <NavLink className="inline mt-3" exact to="/auth/forgot/1"
                                                     activeClassName="active">{t('login.forgot-password')}</NavLink>
                                        </p>
                                    </form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                    <div className=" col-md-5 col-lg-6 col-xl-7 d-md-block d-none right-half">
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
