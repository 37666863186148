import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/styles.scss';

//Translations
import i18next from 'i18next';
import commonES from "./translations/es/common.json";
import commonEN from "./translations/en/common.json";

import reportsES from "./translations/es/reports.json";
import reportsEN from "./translations/en/reports.json";

import myTeamES from "./translations/es/myTeam.json";
import myTeamEN from "./translations/en/myTeam.json";

import profileES from "./translations/es/profile.json";
import profileEN from "./translations/en/profile.json";

import appointmentsES from "./translations/es/appointments.json";
import appointmentsEN from "./translations/en/appointments.json";

import videoES from "./translations/es/video.json";
import videoEN from "./translations/en/video.json";

import {I18nextProvider} from "react-i18next";

import {EHApp} from "./EHApp";

i18next.init({
    interpolation: {escapeValue: false},
    lng:  localStorage.getItem('language') || 'es',
    debug: false,
    resources: {
        en: {
            common: commonEN,
            reports: reportsEN,
            appointments: appointmentsEN,
            myTeam: myTeamEN,
            profile: profileEN,
            video: videoEN
        },
        es: {
            common: commonES,
            reports: reportsES,
            appointments: appointmentsES,
            myTeam: myTeamES,
            profile: profileES,
            video: videoES
        }
    }
});

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <EHApp/>
    </I18nextProvider>,
    document.getElementById('root')
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();