import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Timer} from "./Timer";

let interval = null;
export const Watch = forwardRef((_props, reference) => {

    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [time, setTime] = useState(0);

    const watchStart = (_time) => {
        handleStart(_time);
    }

    const watchReset = () => {
        setIsActive(false);
        setTime(0);
    }

    useImperativeHandle(reference, () => ({
        watchStart: watchStart,
        watchReset: watchReset
    }));

    useEffect(() => {
        if (isActive && isPaused === false) {
            interval = setInterval(() => {
                setTime( Math.abs(new Date().getTime() - time));
            }, 100);
        } else
            clearInterval(interval);

        return () => {
            clearInterval(interval);
        };
    }, [isActive, isPaused]);

    const handleStart = (_time) => {
        setTime(_time);
        setIsActive(true);
        setIsPaused(false);
    };

    return (
        <Timer time={time}/>
    );
});
