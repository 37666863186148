import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useFieldArray, useForm} from "react-hook-form";
import {getDetail, updateExperience} from "../../services/provider.service";
import {Accordion} from "react-bootstrap";
import {noSpacesOnly} from "../../utilities";

let isValid = true;
const FormationList = forwardRef((props, ref) => {

    const {t} = useTranslation('common');
    const toggleModal = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [accomplishmentEdit, setAccomplishmentEdit] = useState(true);
    const reference = useRef(null);

    useImperativeHandle(ref, () => ({
        externalSubmit: async () => {
            if (accomplishmentField.length > 0)
                reference.current.click();
        },
        checkValid: () => {
            console.log(isValid)
            return isValid;
        }
    }));

    const {
        control,
        register,
        formState: {errors},
        handleSubmit,
        watch: watchFieldArray
    } = useForm({
        mode: "onChange"
    });

    const {
        fields: accomplishmentField,
        append: accomplishmentAppend,
        remove: accomplishmentRemove
    } = useFieldArray({control, name: "accomplishment"});

    const {
        register: modalRegister,
        handleSubmit: modalHandleSubmit,
        watch,
        reset,
        formState: {errors: modalError}
    } = useForm();
    const onSubmitModal = data => {
        data['edit'] = true;
        accomplishmentAppend(data);
        setAccomplishmentEdit(false);
        toggleModal();
        reset({});
        reference.current.click();
    };

    const onSubmit = (data) => {
        for (const [, value] of Object.entries(data.accomplishment))
            delete value['edit']
        setAccomplishmentEdit(true);
        updateExperience({
            accomplishment: JSON.stringify(data.accomplishment),
            idProvider: (props.user) ? props.user.idProvider : props.idProvider
        }).then(() => {
            console.log("saved")
            return true
        }).catch((error) => {
            console.log(error.message)
            return false
        });
    }

    useEffect(() => {
        if (props.user) {
            getDetail(props.user.idDetail.idDetail).then(response => {
                let temp = JSON.parse(response.accomplishment);
                for (let element of temp)
                    accomplishmentAppend(element)
            }).catch(error => {
                console.error(error);
            })
        } else
            setAccomplishmentEdit(false);
    }, []);

    return (
        <>
            <h5 style={{position: "absolute", right: "60px", top: "10px"}}>
                {props.user && accomplishmentField.length > 0 &&
                <FontAwesomeIcon onClick={() => {
                    setAccomplishmentEdit(!accomplishmentEdit)
                }} className="edit-icon" icon={faPencilAlt}/>
                }
            </h5>

            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} size="lg" backdrop={"static"}>
                <ModalHeader toggle={toggleModal}>
                    {t('account-experience.add')} {t('account-experience.achievement')}
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={modalHandleSubmit(onSubmitModal)}>
                        <div className="row mt-4">

                            <div className="col-12 col-md-6">
                                <FormGroup>
                                    <Label for="name">{t('account-experience.name')}</Label>
                                    <input className="form-control-sm form-control"
                                           {...modalRegister(`name`, {
                                               required: true, maxLength: 255, pattern: noSpacesOnly
                                           })}
                                           placeholder={t('account-experience.name')}
                                           defaultValue=""/>
                                    {modalError.name && modalError.name.type === "required" &&
                                    <span className="error">{t('global.required')}</span>}
                                    {modalError.name && modalError.name.type === "maxLength" &&
                                    <span className="error">{t('global.max-length')}</span>}
                                    {modalError.name && modalError.name.type === "pattern" &&
                                    <span className="error">{t('global.notOnlySpaces')}</span>}
                                </FormGroup>
                            </div>
                            <div className="col-12 col-md-6">
                                <FormGroup>
                                    <Label for="name">{t('account-experience.institution')}</Label>
                                    <input className="form-control-sm form-control"
                                           {...modalRegister(`institution`, {
                                               required: true, maxLength: 255, pattern: noSpacesOnly
                                           })}
                                           placeholder={t('account-experience.institution')}
                                           defaultValue=""/>
                                    {modalError.institution && modalError.institution.type === "required" &&
                                    <span className="error">{t('global.required')}</span>}
                                    {modalError.institution && modalError.institution.type === "maxLength" &&
                                    <span className="error">{t('global.max-length')}</span>}
                                    {modalError.institution && modalError.institution.type === "pattern" &&
                                    <span className="error">{t('global.notOnlySpaces')}</span>}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3">
                                <FormGroup>
                                    <Label for="name">{t('account-experience.emition-date')}</Label>
                                    <input type="date" className="form-control-sm form-control"
                                           {...modalRegister(`emitionDate`, {
                                               required: true
                                           })}
                                           defaultValue=""/>
                                    {modalError.emitionDate && <span className="error">{t('global.required')}</span>}
                                </FormGroup>
                            </div>
                            <div className="col-12 col-md-8 col-lg-9">
                                <FormGroup>
                                    <Label for="name">{t('account-experience.description')}</Label>
                                    <input className="form-control-sm form-control"
                                           {...modalRegister(`description`, {
                                               required: false, maxLength: 255
                                           })}
                                           placeholder={t('account-experience.description')}
                                           defaultValue=""/>
                                    {modalError.description && modalError.description.type === "required" &&
                                    <span className="error">{t('global.required')}</span>}
                                    {modalError.description && modalError.description.type === "maxLength" &&
                                    <span className="error">{t('global.max-length')}</span>}
                                </FormGroup>
                            </div>
                        </div>

                        <ModalFooter>
                            <button type="submit"
                                    className="btn btn-primary"> {t('account-experience.submit')}  </button>
                        </ModalFooter>

                    </form>
                </ModalBody>
            </Modal>

            <form style={{marginTop: "4em"}} onSubmit={handleSubmit((data, _event) => {
                isValid = true;
                if (props.submitData)
                    onSubmit(data)
                else if (props.user) onSubmit(data)
            }, () => {
                if (!props.user && accomplishmentField.length > 0) isValid = false
            })}>
                {accomplishmentField.map((item, index) => (
                    <div className="card mb-3" key={item.id}>
                        <div className="card-body">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey={"0"}>
                                    <Accordion.Header>
                                        <div>
                                            <div
                                                className="d-flex flex-row justify-content-between  align-items-center mb-4">
                                                <h6 className="text-left"> {watchFieldArray(`accomplishment[${index}].name`)}</h6>

                                                {!accomplishmentEdit &&
                                                <FontAwesomeIcon className="remove-item-list ml-5 mr-3" onClick={() => {
                                                    if (!accomplishmentEdit) {
                                                        accomplishmentRemove(index);
                                                        if (accomplishmentField.length === 1)
                                                            reference.current.click();

                                                        setAccomplishmentEdit(false);
                                                    }
                                                }} icon={faTrash}/>
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="position-relative">
                                            <div className="row mt-4">

                                                <div className="col-12 col-md-6">
                                                    <FormGroup>
                                                        <Label for="name">{t('account-experience.name')}</Label>
                                                        {(accomplishmentEdit) ?
                                                            <label
                                                                className="form-control-sm form-control-plaintext">{watchFieldArray(`accomplishment[${index}].name`)}</label>
                                                            :
                                                            <input
                                                                className={(accomplishmentEdit) ? "form-control-sm form-control-plaintext" : "form-control-sm form-control"}
                                                                {...register(`accomplishment[${index}].name`, {
                                                                    required: true,
                                                                    maxLength: 255,
                                                                    pattern: noSpacesOnly
                                                                })}
                                                                placeholder={t('account-experience.name')}
                                                                disabled={accomplishmentEdit}
                                                                control={control}
                                                                defaultValue=""/>}
                                                        {errors.accomplishment?.[index]?.name && errors.accomplishment?.[index]?.name.type === "required" &&
                                                        <span className="error">{t('global.required')}</span>}
                                                        {errors.accomplishment?.[index]?.name && errors.accomplishment?.[index]?.name.type === "maxLength" &&
                                                        <span className="error">{t('global.max-length')}</span>}
                                                        {errors.accomplishment?.[index]?.name && errors.accomplishment?.[index]?.name.type === "pattern" &&
                                                        <span className="error">{t('global.notOnlySpaces')}</span>}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <FormGroup>
                                                        <Label for="name">{t('account-experience.institution')}</Label>
                                                        {(accomplishmentEdit) ?
                                                            <label
                                                                className="form-control-sm form-control-plaintext">{watchFieldArray(`accomplishment[${index}].institution`)}</label>
                                                            :
                                                            <input
                                                                className={(accomplishmentEdit) ? "form-control-sm form-control-plaintext" : "form-control-sm form-control"}
                                                                {...register(`accomplishment[${index}].institution`, {
                                                                    required: true,
                                                                    maxLength: 255,
                                                                    pattern: noSpacesOnly
                                                                })}
                                                                control={control}
                                                                disabled={accomplishmentEdit}
                                                                placeholder={t('account-experience.institution')}
                                                                defaultValue=""/>}
                                                        {errors.accomplishment?.[index]?.institution && errors.accomplishment?.[index]?.institution.type === "required" &&
                                                        <span className="error">{t('global.required')}</span>}
                                                        {errors.accomplishment?.[index]?.institution && errors.accomplishment?.[index]?.institution.type === "maxLength" &&
                                                        <span className="error">{t('global.max-length')}</span>}
                                                        {errors.accomplishment?.[index]?.institution && errors.accomplishment?.[index]?.institution.type === "pattern" &&
                                                        <span className="error">{t('global.notOnlySpaces')}</span>}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <FormGroup>
                                                        <Label>{t('account-experience.emition-date')}</Label>
                                                        <input type="date"
                                                               className={(accomplishmentEdit) ? "conceal-input" : "form-control-sm form-control"}
                                                               {...register(`accomplishment[${index}].emitionDate`, {
                                                                   required: true
                                                               })}
                                                               control={control}
                                                               disabled={accomplishmentEdit}
                                                               defaultValue=""/>
                                                        {errors.accomplishment?.[index]?.emitionDate &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-12 col-md-8 col-lg-9">
                                                    <FormGroup>
                                                        <Label>{t('account-experience.description')}</Label>
                                                        <textarea rows="5" style={{minHeight: "100px"}}
                                                                  className={(accomplishmentEdit) ? "form-control-sm form-control-plaintext" : "form-control-sm form-control"}
                                                                  {...register(`accomplishment[${index}].description`, {
                                                                      required: false, maxLength: 255
                                                                  })}
                                                                  disabled={accomplishmentEdit}
                                                                  control={control}
                                                                  placeholder={(accomplishmentEdit) ? "--" : t('account-experience.description')}
                                                                  defaultValue=""/>
                                                        {errors.accomplishment?.[index]?.description && errors.accomplishment?.[index]?.description.type === "required" &&
                                                        <span className="error">{t('global.required')}</span>}
                                                        {errors.accomplishment?.[index]?.description && errors.accomplishment?.[index]?.description.type === "maxLength" &&
                                                        <span className="error">{t('global.max-length')}</span>}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                ))}

                {accomplishmentField.length === 0 ? <div>{t("global.no-data")}</div> :
                    <button className={(props.user && !accomplishmentEdit) ? "btn btn-primary mb-3" : "d-none"}
                            ref={reference} disabled={accomplishmentEdit} type="submit">
                        {t("global.save")}</button>
                }
            </form>

            <div className="add-item-list" onClick={() => {
                setModal(!modal);
            }}>
                <FontAwesomeIcon icon={faPlus}/>
            </div>
        </>
    );
});

export default FormationList;
