import React, {useEffect, useReducer, useState, createContext} from "react";
import {AuthContext} from "utilities/auth/AuthContext";
import AppRouter from "routers/AppRouter";
import {authReducer} from "utilities/auth/authReducer";
import Loading from 'react-fullscreen-loading';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {ToastContainer} from "react-toastify";
import {setCompanyThemeColor} from "./utilities";
import i18next from "i18next";

export const loaderContext = React.createContext(null);
export const licenseStats = createContext(true);

let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {locale: localStorage.getItem('language') || 'es'});

const init = () => {
    return JSON.parse(localStorage.getItem('user')) || {
        logged: false,
        license: false
    }
}

export const EHApp = () => {

    const [user, dispatch] = useReducer(authReducer, {}, init);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        localStorage.setItem('token', user.token);
        localStorage.setItem('user', JSON.stringify(user));
        if (user.logged !== false) {
            setCompanyThemeColor(user.user.idCompany.color, user.user.idCompany.secondColor);
        }
    }, [user]);

    useEffect(() =>{
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {locale: i18next.language});
    })

    return (
        <AuthContext.Provider value={{user, dispatch}}>
            <loaderContext.Provider value={{loading: [loader, setLoader]}}>
                <Elements stripe={stripePromise}>
                    <AppRouter/>
                    <Loading loading={loader} loaderColor="#1565C0FF"/>
                    <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} newestOnTop={false}
                        closeOnClick/>
                </Elements>
            </loaderContext.Provider>
        </AuthContext.Provider>
    )
}
