import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../utilities/auth/AuthContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {getStripeProviderProducts} from "../../../services/stripe.service";

function ProductsAlert() {

    const {t} = useTranslation("profile");
    const {user: {user}} = useContext(AuthContext);
    const [haveProducts, setHaveProducts] = useState(true);

    useEffect(async () => {
        const products = await getStripeProviderProducts(user.idProvider);
        setHaveProducts(products.length > 0);
    }, []);
    return (
        <>
            {!haveProducts &&
            <div className="alert alert-warning d-flex justify-content-start" role="alert">
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-4 align-self-center"/>
                <div className=" align-self-center">
                    <p className="m-0 p-0"><strong>{t("profile.onboarding-products")}</strong></p>
                    <small className="mr-2 align-self-center">{t("profile.onboarding-products-message")}</small>
                </div>
            </div>
            }
        </>

    );
}

export default ProductsAlert;