import React from "react";
import PropTypes from 'prop-types';
import {useLocation} from "react-router-dom";

export const PrivateRoute = (props) => {
    const history = useLocation();
    if (props.isAuthenticated)
        if (history.pathname.split('/')[2] === 'conference') {
            return (<props.video {...props} uri={props.path}/>);
        } else {
            return (<props.component {...props} uri={props.path}/>);
        }
    else
        return (<props.public {...props} uri={props.path}/>);
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}
