import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";

/**
 * Obtiene todos los tipos de proveedor
 * @return {Promise<T>}
 */
export const getAllProviderTypes = async () => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.providerType.all,
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene todos los tipos de proveedor que sean admin
 * @return {Promise<T>}
 */
export const getAdmin = async () => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.providerType.admin,
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getProviderTypeByRole = async () => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.providerType.getByRole,
    ).catch(error => {
        throw error;
    })

    return response.data;
}