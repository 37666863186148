import "../../css/videoFooter.scss";
import ButtonInvite from "./ButtonInvite";
import ButtonMuteMicrophone from "./ButtonMuteMicrophone";
import ButtonConnectDisconnect from "./ButtonConnectDisconnect";
import ButtonMuteCamera from "./ButtonMuteCamera";
import ButtonSoap from "./ButtonSoap";
import ButtonChat from "./ButtonChat";
import {BrowserView, MobileView} from 'react-device-detect';
import ButtonOptions from "./ButtonOptions";
import {AuthContext} from "../../../../../utilities/auth/AuthContext";
import {useContext} from "react";

const VideoFooter = (props) => {
    const context = useContext(AuthContext);
    return (
        <div className="videoFooter">
            <div className="footerWrapper d-flex justify-content-between">
                <BrowserView>
                    <div className="wrapper-buttons d-flex justify-content-start  align-items-center h-100">
                        <ButtonInvite {...props}/>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="wrapper-mobile-buttons d-flex justify-content-start  align-items-center h-100">
                        <ButtonInvite {...props}/>
                    </div>
                </MobileView>

                <div className="footer-center d-flex justify-content-center  align-items-center h-100">
                    <ButtonMuteMicrophone {...props}/>
                    <ButtonConnectDisconnect {...props}/>
                    <ButtonMuteCamera {...props}/>
                </div>

                <BrowserView>
                    <div className="d-flex wrapper-buttons align-items-center justify-content-end h-100">
                        <ButtonSoap {...props}/>
                        <ButtonChat {...props}/>
                    </div>
                </BrowserView>

                <MobileView>
                    <div className="d-flex wrapper-mobile-buttons align-items-center justify-content-end h-100">
                        {context.user.logged
                            ?
                            <ButtonOptions {...props}/>
                            :
                            <ButtonChat {...props}/>}
                    </div>
                </MobileView>
            </div>
        </div>
    );
}

export default VideoFooter;
