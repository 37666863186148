import React, {useContext} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import UserAvatar from "../common/UserAvatar";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {logout} from "../../services/auth.service";
import {AuthContext} from "../../utilities/auth/AuthContext";

const UserProfileDropdown = (props) => {

    const {user: {user}, dispatch} = useContext(AuthContext);
    const {t} = useTranslation('common');
    const [userDropdownOpen, setUserDropdownOpen] = React.useState(false);
    const history = useHistory();

    const dropdownToggle = () => setUserDropdownOpen(!userDropdownOpen);

    const handleLogout = () => {
        logout(history, dispatch);
    }

    return <Dropdown
        isOpen={userDropdownOpen}
        toggle={() => dropdownToggle()}>
        <DropdownToggle nav>
            <UserAvatar className="user-avatar" alt="Avatar" profile={user.idDetail.profile}/>
        </DropdownToggle>

        <DropdownMenu right>
            <div className="row pl-4 pr-4">
                <div className="col-12 text-center mt-2">
                    <UserAvatar className="profile-image" alt="Avatar" profile={user.idDetail.profile}/>
                </div>
                <div className="col-12 text-center mt-2">
                    <span><strong>{user.name} {user.secondName}</strong></span>
                </div>
                <div className="col-12 text-center">
                    <small>{user.mail}</small>
                </div>
                <div className="col-12 text-center mt-2">
                    <Link to={props.uri + '/profile'}
                          className="btn btn-outline-primary btn-sm w-100">{t('navbar.viewProfile')}</Link>
                </div>
                <div className="col-12 text-center mt-3">
                    <button
                        className="btn btn-link btn-sm"
                        onClick={handleLogout}
                    >{t('navbar.logout')}
                    </button>
                </div>
            </div>
        </DropdownMenu>
    </Dropdown>
}

UserProfileDropdown.propTypes = {
    uri: PropTypes.string.isRequired,
}

export default UserProfileDropdown;
