import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import {AuthContext} from "../../../../../utilities/auth/AuthContext";
import {useContext} from "react";
import chatIcon from "../../../../../assets/images/video/chat.svg";
import {isBrowser} from 'react-device-detect';

const ButtonChat = (props) => {
    const {t} = useTranslation(["video", "common"]);
    const context = useContext(AuthContext);
    return (
        <>
            <button data-tip={t("chat")} className="btn btn-link color-white"
                    onClick={() => {
                        if (context.user.logged)
                            if (isBrowser) props.soapReference.current.setFalse();

                        if (props.chatReference.current.getState().chat) {
                            props.chatReference.current.setState({
                                toggle: false,
                                payload: "toggle-chat"
                            });

                            props.dispatch({
                                source: false,
                                payload: "set-sidebar",
                            });

                        } else {
                            props.chatReference.current.setState({
                                toggle: true,
                                payload: "toggle-chat"
                            });

                            props.dispatch({
                                source: true,
                                payload: "set-sidebar",
                            });
                        }
                    }}>
                <img src={chatIcon} alt=""/>
            </button>
            <ReactTooltip/>


        </>
    );
}
export default ButtonChat;
