import moment from "moment";


export const kEnableStats = `${process.env.REACT_APP_ENABLE_STATS}`;
export const kEnableStatsLog = `${process.env.REACT_APP_ENABLE_STATS_LOG}` === '1';
export const kRefreshStatsInterval = `${process.env.REACT_APP_REFRESH_STATS_INTERVAL}`;
export const kInitStats = `${process.env.REACT_APP_INIT_STATS}`;
export const kQualityLimitationLevel1 = `${process.env.REACT_APP_QUALITY_LIMITATION_LEVEL_1}`;
export const kQualityLimitationLevel2 = `${process.env.REACT_APP_QUALITY_LIMITATION_LEVEL_2}`;
export const kQualityLimitationLevel3 = `${process.env.REACT_APP_QUALITY_LIMITATION_LEVEL_3}`;
export const kQualityLimitationLevel4 =`${process.env.REACT_APP_QUALITY_LIMITATION_LEVEL_4}`;
export const hostname = `${process.env.REACT_APP_SERVER_DOMAIN}`;
export const localhost = `${process.env.REACT_APP_LOCALHOST_DOMAIN}`;
export const beta_version = true;
export const passwordPattern = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})$/;
export const mailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phonePattern = /[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
export const noSpacesPattern = /[\S\s]+[\S]+/;
export const noSpacesOnly = /.*[^ ].*/;


export const localToUtc = (date) => {
    let utcDate = date.utc();
    return {
        hour: utcDate.format('HH:mm').toString(),
        date: utcDate.format('YYYY-MM-DD').toString()
    }
}

export const formatDuration = (seconds) => {
    return new moment().startOf('day')
        .seconds(seconds)
        .format('H[h] m[m] ss[s]');
}

export const formatTableElapsed = (seconds) => {
    return new moment().startOf('day')
        .seconds(seconds)
        .format('H[:]mm[:]ss');
}

export const formatElapsed = (duration) => {
    const time = duration.reduce((a, b) => +a + +b.elapsed, 0);
    return new moment().startOf('day')
        .seconds(time)
        .format('H[h] m[m] ss[s]');
}

export const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
}

export const validatePhone = (phone) => {
    return /^[0-9]{10}$/.test(phone);
}

export const findCommunicationByLabel = (arr, label) => {
    let result = arr.find(x => x.label === label);
    return result.value;
}

export const orderByLabel = (items) => {
    return items.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
}

export const trim = (string) => {
    return string.replace(/^\s+|\s+$/gm, '');
}

export const hexToRgb = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const setCompanyThemeColor = (color, secondColor) => {
    let root = document.documentElement;
    const pc = hexToRgb(color || '#1565c0');
    const sc = hexToRgb(secondColor || '#f9a825');
    root.style.setProperty('--color-primary', `${pc.r},${pc.g},${pc.b}`);
    root.style.setProperty('--color-secondary', `${sc.r},${sc.g},${sc.b}`);
}

export const serviceStatusEnum = {
    pendingPayment: 0,
    pending: 1,
    expired: 2,
    inProgress: 3,
    cancelled: 4,
    finished: 5
}

export const RGBAToHex = (rgba) => {
    let inParts = rgba.substring(rgba.indexOf("(")).split(","),
        r = parseInt(trim(inParts[0].substring(1)), 10),
        g = parseInt(trim(inParts[1]), 10),
        b = parseInt(trim(inParts[2]), 10),
        a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);

    let outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16),
        Math.round(a * 255).toString(16).substring(0, 2)
    ];

    outParts.forEach(function (part, i) {
        if (part.length === 1)
            outParts[i] = '0' + part;
    })

    return ('#' + outParts.join(''));
}
