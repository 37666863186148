import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import editIcon from "../../assets/images/icons/edit.svg";
import {BrandingForm} from "./BrandingForm";
import {BrandingDetail} from "./BrandingDetail";
import PropTypes from "prop-types";

export const Branding = (props) => {

    const {t} = useTranslation(["myTeam", "common"]);
    const [editBranding, setEditBranding] = useState(false);

    const handleEditbranding = () => {
        setEditBranding(!editBranding);
    }

    return (
        <div className="branding">
            <div className="d-flex justify-content-between">
                <h5>{t('myTeam:branding.title')}</h5>
                {!editBranding && <img onClick={handleEditbranding} src={editIcon} alt="edit" className="edit-icon"/>}
            </div>
            {editBranding ? <BrandingForm {...props} close={handleEditbranding}/> :
                <BrandingDetail company={props.company}/>
            }
        </div>
    )
}

Branding.propTypes = {
    company: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
}

export default Branding;
