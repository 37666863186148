import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {methodTypes} from "../utilities/types/types";
import {request} from "../utilities/base/request";

/**
 * Obtiene todos los roles de la base de datos
 * @return {Promise<T>}
 */
export const getAllRoles = async () => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.role.all,
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene verdadero o falso dependiendo si el rol del usuario es ADMIN
 * @param {string} idProvider Identificador único del preveedor
 * @returns {Promise<T>}
 */
export const getUserHasAdminRole = async (idProvider) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.role.isAdmin,
        {idProvider: idProvider}, null, true
    ).catch(error => {
        throw error;
    })
    return response.data;
}

/**
 * Obtienen verdadero o falso dependiendo si el rol del usuario es MANAGER
 * @param {string} idProvider Identificador único del preveedor
 * @returns {Promise<T>}
 */
export const getUserHasManagerRole = async (idProvider) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.role.isManager,
        {idProvider: idProvider}, null, true
    ).catch(error => {
        throw error;
    })
    return response.data;
}