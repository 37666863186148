const iceServers = [
    {
        urls: 'turn:34.228.239.59:3478?transport=udp',
        username: 'ehealth',
        credential: '3gDp7Ay2Vv',
    },
    {
        urls: 'stun:34.228.239.59:49152',
        username: 'ehealth',
        credential: '3gDp7Ay2Vv',
    },
];

module.exports = {
    iceServers: iceServers,
};
