import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const ButtonOptions = (props) => {
    const {t} = useTranslation("video", "common");

    const onSelectChat = (e) => {
        e.preventDefault();
        props.chatReference.current.setState({
            toggle: true,
            payload: "toggle-chat"
        });

        props.dispatch({
            source: true,
            payload: "set-sidebar",
        });
    }

    const onSelectSoap = (e) => {
        e.preventDefault();
        props.soapReference.current.toggle();
        props.dispatch({
            source: true,
            payload: "set-sidebar",
        });
    }

    return (
        <>
            <div className="dropdown">
                <button type="button" className="color-white btn btn-lg btn-link mr-2 mr-2" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <FontAwesomeIcon icon={faEllipsisV}/>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#" onClick={(e) => {
                        onSelectChat(e);
                    }}>{t('chat')}</a>
                    <a className="dropdown-item" href="#" onClick={(e) => {
                        onSelectSoap(e);
                    }}>{t('soap')}</a>
                </div>
            </div>
            <ReactTooltip/>


        </>
    );
}
export default ButtonOptions;
