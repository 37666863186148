import React from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import FooterLinks from "./footerLinks";

const FooterAdmin = () => {
    const {t} = useTranslation('common');

    return (
        <footer className="terms-footer"
                style={{ display: (window.location.pathname.includes("conference")) ? 'none' : 'block'}}>
            <FooterLinks className={"d-flex flex-row justify-content-start"}/>
        </footer>

    );
}

export default FooterAdmin
