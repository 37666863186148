import {authTypes} from "../types/types";
import {setCompanyThemeColor} from "../../utilities";

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case authTypes.login:
            return {
                ...action.payload,
                logged: true,
                license: false,
            }
        case authTypes.theme:
            setCompanyThemeColor(action.payload.user.action.payload.user.idCompany.color, action.payload.user.idCompany.secondColor);
            return {
                ...action.payload,
                logged: true,
                license: action.license
            }

        case authTypes.logout:
            return {
                logged: false,
                license: false
            }

        case authTypes.license:
            return {
                ...state,
                license: action.license
            }

        case authTypes.profile:
            return {
                ...action.payload,
                logged: true
            }

        default:
            return state;
    }
}
