import React, {useEffect, useState} from "react";
import logoDefault from "../../assets/images/logo.svg";
import logoDefaultBeta from "../../assets/images/logo-beta.svg";
import {beta_version} from "../../utilities";

const CompanyLogo = ({logo, className, alt}) => {

    const [logoBlob, setLogoBlob] = useState(null);

    useEffect(() => {
        if (logo && logo.data?.length > 4) {
            let arrayBufferView = new Uint8Array(logo.data);
            let blob = new Blob([arrayBufferView], {type: "image/jpeg"});
            let urlCreator = window.URL || window.webkitURL;
            setLogoBlob(urlCreator.createObjectURL(blob));
        }
    }, [logo]);

    return (

        <img className={className}
             alt={alt}
             src={(logoBlob) ? logoBlob : beta_version === true ? logoDefaultBeta : logoDefault}/>

    );
}

export default CompanyLogo
