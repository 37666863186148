import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {serviceUpdate} from "../../services/service.service";
import {FormGroup, Label, ModalBody, ModalHeader} from "reactstrap";
import calendar from "../../assets/images/icons/calendar-blue.svg";
import clock from "../../assets/images/icons/clock-blue.svg";
import {localToUtc, mailPattern, noSpacesPattern, orderByLabel, phonePattern} from "../../utilities";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {getAllCommunications} from "../../services/communication.service";
import {getAllConditions} from "../../services/condition.service";
import {ModalFooter} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {EHIcon} from "../../components/icons/EHIcon";
import providerIcon from "../../assets/images/icons/provider.svg";
import {getByMail, getByPhone} from "../../services/user.service";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";

const ServiceEdit = (props) => {
    const {t} = useTranslation(['appointments', 'common']);
    const [communication, setCommunication] = useState([]);
    const [condition, setCondition] = useState([]);
    const minDate = moment.utc().local().format('YYYY-MM-DD');
    const [validate, setValidate] = useState(null);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [validCommunicationContent, setValidCommunicationContent] = useState(true);
    const [inUseCommunicationContent, setInUseCommunicationContent] = useState(false);
    const [communicationContentId, setCommunicationContentId] = useState("");

    const emailRegex = new RegExp(mailPattern);
    const phoneRegex = new RegExp(phonePattern);
    let typingTimer;


    const [loading, setLoading] = useState(false);

    const mapDateUtcToLocal = (date, hour) => {
        let stillUtc = new moment.utc(`${date} ${hour}`);
        return {
            hour: stillUtc.local().format('HH:mm'),
            date: stillUtc.local().format('YYYY-MM-DD')
        }
    }

    const {register, handleSubmit, watch, setValue, reset, formState: {errors}} = useForm({
        defaultValues: {
            name: props.service.idUser.name,
            secondName: props.service.idUser.secondName,
            dateBirth: props.service.idUser.dateBirth,
            gender: props.service.idUser.gender,
            language: props.service.idUser.language,
            idCommunication: props.service.idUser.idCommunication.idCommunication,
            communicationContent: (props.service.idUser.idCommunication.idCommunication == 2) ?
                props.service.idUser.mail || "" : props.service.idUser.idPhone?.phoneNumber || "",
            idCondition: props.service.idCondition.idCondition,
            specialist: props.service.idProvider.idProvider,
            serviceDate: mapDateUtcToLocal(props.service.serviceDate, props.service.time).date,
            time: mapDateUtcToLocal(props.service.serviceDate, props.service.time).hour
        }
    });


    React.useEffect(async () => {
        console.log(props.service.serviceDate);
        console.log(props.service.time);
        console.log(i18next.language);
        let stillUtc = mapDateUtcToLocal();
        console.log(stillUtc.hour);
        console.log(stillUtc.date);

        setCommunication((await getAllCommunications()).map((item) => ({
            label: item.type,
            value: item.idCommunication
        })));
        const conditions = await getAllConditions();
        const translatedConditions = conditions.map((item) => ({
            label: t(`common:conditions.${item.name}`),
            value: item.idCondition
        }));
        setCondition(orderByLabel(translatedConditions));
        reset();
    }, []);

    const onSubmit = (data) => {
        if (validate === null) {
            if (validCommunicationContent === false) return;
            data["idUser"] = props.service.idUser.idUser;
            data["idService"] = props.service.idService;
            data["idCommunication"] = data["idCommunication"].toString();
            setValidate({
                data,
                doctor: props.specialist.find((s) => s.value === data.specialist).label
            });
            console.log("validate")
            console.log(data)
        } else {

            setLoading(true);
            let date = new moment(`${validate.data.serviceDate} ${validate.data.time}`, "YYYY-MM-DD HH:mm").local();
            validate.data = mapAppointmentDate(validate.data);
            validate.data.localDate = date.format('YYYY-MM-DD HH:mm');
            serviceUpdate(validate.data).then(() => {
                props.setReload();
                props.modal.current.openModal();
            });
        }
    }

    const mapAppointmentDate = (_validate) => {
        let date = new moment(`${_validate.serviceDate} ${_validate.time}`, "YYYY-MM-DD HH:mm").locale(i18next.language);
        let utcDate = localToUtc(date);
        _validate.time = utcDate.hour;
        _validate.serviceDate = utcDate.date;
        return _validate;
    }

    const isFuture = (data) => {
        let selected = new moment(`${watch("serviceDate")}T${data}`);
        let current = new moment();
        return selected.isAfter(current);
    }

    const communicationChangeHandler = (idCommunication) => {

        setCommunicationContentId(idCommunication)
        setValue("communicationContent","")
        setVerifyEmail(true);
        setInUseCommunicationContent(null);
        if (idCommunication == 2) {
            console.log('change communication to email');
            if (emailRegex.test(watch("communicationContent"))) {
                console.log("email: valid format", watch("communicationContent"));
                if (watch("communicationContent") !== "" && watch("communicationContent") !== undefined) {
                    getByMail(watch("communicationContent")).then((response) => {
                        setVerifyEmail(false);
                        if (response) {
                            setValidCommunicationContent(false);
                            setInUseCommunicationContent(true);
                        } else {
                            setValidCommunicationContent(true);
                        }
                    });
                } else {
                    console.log('communication content email empty');
                    setVerifyEmail(false);
                    setValidCommunicationContent(false);
                }

            } else {
                console.log("email: invalid format", watch("communicationContent"));
                setVerifyEmail(false);
                setValidCommunicationContent(null);
            }

        } else if (idCommunication == 1) {
            console.log('change communication to phone');
            if (phoneRegex.test(watch("communicationContent"))) {
                console.log("phone: valid format", watch("communicationContent"));
                if (watch("communicationContent") !== "" && watch("communicationContent") !== undefined) {
                    getByPhone(watch("communicationContent")).then((response) => {
                        setVerifyEmail(false);
                        if (response) {
                            if (response.user.idUser !== props.service.idUser.idUser) {
                                setValidCommunicationContent(false);
                                setInUseCommunicationContent(true);
                            } else {
                                setValidCommunicationContent(true);
                            }
                        } else {
                            setValidCommunicationContent(true);
                        }
                    });
                } else {
                    console.log('communication content phone empty');
                    setVerifyEmail(false);
                    setValidCommunicationContent(false);
                }
            } else {
                console.log("phone: invalid format", watch("communicationContent"));
                setVerifyEmail(false);
                setValidCommunicationContent(false);
            }
        }
    }

    const typingHandler = () => {
        setInUseCommunicationContent(false);
        if (watch("idCommunication") == 2) {
            if (watch("communicationContent") !== props.service.idUser.mail) {
                if (emailRegex.test(watch("communicationContent"))) {
                    if (watch("communicationContent") !== "" && watch("communicationContent") !== undefined) {
                        getByMail(watch("communicationContent")).then((response) => {
                            setVerifyEmail(false);
                            if (response) {
                                setValidCommunicationContent(false);
                                setInUseCommunicationContent(true);
                            } else {
                                setValidCommunicationContent(true);
                            }
                        });
                    } else {
                        setVerifyEmail(false);
                        setValidCommunicationContent(false);
                    }
                } else {
                    setVerifyEmail(false);
                    setValidCommunicationContent(false);
                }

            } else {
                setVerifyEmail(false);
                setValidCommunicationContent(true);
            }
        } else if (watch("idCommunication") == 1) {

            if (phoneRegex.test(watch("communicationContent"))) {
                if (watch("communicationContent") !== "" && watch("communicationContent") !== undefined) {
                    getByPhone(watch("communicationContent")).then((response) => {
                        setVerifyEmail(false);
                        if (response) {
                            if (response.user.idUser !== props.service.idUser.idUser) {
                                setValidCommunicationContent(false);
                                setInUseCommunicationContent(true);
                            } else {
                                setValidCommunicationContent(true);
                            }

                        } else {
                            setValidCommunicationContent(true);
                        }
                    });
                } else {
                    setVerifyEmail(false);
                    setValidCommunicationContent(false);
                }
            } else {
                setVerifyEmail(false);
                setValidCommunicationContent(true);
            }

        } else {
            setVerifyEmail(false);
            setValidCommunicationContent(true);
        }
    }


    return (
        <>
            <ModalHeader toggle={props.toogle ? props.toogle : props.modal.current.openModal}>
                <div className="d-flex-row">
                    <div className="d-inline-flex">
                        <div className="mr-2">
                            <EHIcon className="d-inline-flex modal-icon" height="35px" width="35px"
                                    name="icon video-call"/>
                        </div>
                        <span className="d-inline-flex modal-title">{t("scheduled-appointments.form.edit-title")}</span>
                    </div>
                </div>
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    {!loading ?
                        !validate
                            ? <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <h5>{t("scheduled-appointments.form.subtitle-1")}</h5>
                                    <div className="form-group">
                                        <label>{t("scheduled-appointments.form.name")}</label>
                                        <input className="form-control form-control-sm" type="text"
                                               name="name" {...register("name", {
                                            required: t('scheduled-appointments.form.required'),
                                            pattern: {
                                                value: noSpacesPattern,
                                                message: t('common:global.notOnlySpaces')
                                            }
                                        })}
                                               placeholder={t("scheduled-appointments.form.name")}/>
                                        {errors.name &&
                                        <small className="error">{errors.name.message}</small>}
                                    </div>
                                    <div className="form-group">
                                        <label>{t("scheduled-appointments.form.surname")}</label>
                                        <input className="form-control form-control-sm" type="text"
                                               name="secondName" {...register("secondName", {
                                            required: t('scheduled-appointments.form.required'),
                                            pattern: {
                                                value: noSpacesPattern,
                                                message: t('common:global.notOnlySpaces')
                                            }
                                        })}
                                               placeholder={t("scheduled-appointments.form.surname")}/>
                                        {errors.secondName &&
                                        <small className="error">{errors.secondName.message}</small>}
                                    </div>
                                    <FormGroup>
                                        <Label>{t("scheduled-appointments.form.dateBirth")}</Label>
                                        <input type="date" className="form-control-sm form-control"
                                               name="dateBirth" {...register("dateBirth", {required: true})} />
                                        {errors.dateBirth &&
                                        <small className="error">{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>
                                    <div className="form-group">
                                        <label>{t('scheduled-appointments.form.gender')}</label>
                                        <select className="form-control form-control-sm"
                                                name="gender" {...register("gender", {required: true})}>
                                            <option value="F">{t('common:gender.female')}</option>
                                            <option value="M">{t('common:gender.male')}</option>
                                            <option value="O">{t('common:gender.other')}</option>
                                        </select>
                                        {errors.gender &&
                                        <small className="error">{t('scheduled-appointments.form.required')}</small>}
                                    </div>

                                    <FormGroup>
                                        <Label for="language">{t('scheduled-appointments.form.language')}</Label>
                                        <select className="form-control-sm form-control"
                                                name="language" {...register("language", {required: true})}>
                                            <option value="EN">{t('common:language.en')}</option>
                                            <option value="ES">{t('common:language.es')}</option>
                                        </select>
                                        {errors.language &&
                                        <small
                                            className="error">{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>

                                    <div className="form-group">
                                        <label>{t('scheduled-appointments.form.contactMethod')}</label>
                                        <select className="form-control form-control-sm"
                                                name="idCommunication" {...register("idCommunication", {required: true})}
                                                onChange={(change) => {
                                                    communicationChangeHandler(change.target.value);
                                                }
                                                }>
                                            {
                                                communication.map((item) => {
                                                    return <option
                                                        key={item.value}
                                                        value={item.value}>{t(`common:communication.${item.label}`)}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                    <>
                                        <label>{t("scheduled-appointments.form.contact")}</label>
                                        <div className="input-group input-group-sm mb-3">
                                            <input
                                                className={"form-control form-control-sm"}
                                                type="text"
                                                name="communicationContent"
                                                {...register("communicationContent", {
                                                    required: {
                                                        value: true,
                                                        message: t('scheduled-appointments.form.required')
                                                    },
                                                    pattern: {
                                                        value: watch("idCommunication") == 2 ? mailPattern : phonePattern,
                                                        message: t('scheduled-appointments.form.invalid-format')
                                                    }
                                                })}
                                                onKeyDown={(_event) => {
                                                    clearTimeout(typingTimer);
                                                    setValidCommunicationContent(null);
                                                    setVerifyEmail(true);
                                                    typingTimer = setTimeout(typingHandler, 2000);
                                                }}
                                                placeholder={communicationContentId == 2
                                                    ? t("scheduled-appointments.form.email-placeholder"): t("scheduled-appointments.form.phone-placeholder") }/>
                                            {errors.communicationContent
                                                ? <small
                                                    className=" order-last error">{watch("communicationContent") == ""? "": errors.communicationContent.message}</small>
                                                : <>
                                                    <div className="input-group-append">
                                                        {verifyEmail
                                                            ? <span
                                                                className="input-group-text input-spinner-group"><FontAwesomeIcon
                                                                icon={faSpinner} className="input-spinner"/></span>
                                                            : watch('communicationContent') !== '' && watch('communicationContent') !== undefined
                                                                ? validCommunicationContent
                                                                    ? <span
                                                                        className="input-group-text input-spinner-group icon-success"><FontAwesomeIcon
                                                                        icon={faCheck} className=""/></span>
                                                                    : <span
                                                                        className="input-group-text input-spinner-group icon-error"><FontAwesomeIcon
                                                                        icon={faTimes} className=""/></span>
                                                                : <></>
                                                        }

                                                    </div>

                                                    {!verifyEmail
                                                        ? validCommunicationContent === true
                                                            ? <div className="success-message">
                                                                <small
                                                                    className="success-message">{t('common:global.valid')}</small>
                                                            </div>
                                                            : <div className="success-message">
                                                                <small
                                                                    className="error">{inUseCommunicationContent === true ? t('common:global.inuse') : watch("communicationContent") == ""? "": t('common:global.invalid-format')}</small>
                                                            </div>


                                                        /*
                                                            <div className="success-message">
                                                                <small className="error">{watch('communicationContent') === '' || watch('communicationContent') === undefined ? '' : t('common:global.inuse')}</small>
                                                            </div>
                                                            */

                                                        : <></>
                                                    }
                                                </>}
                                        </div>
                                    </>


                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                    <h5>{t("scheduled-appointments.form.subtitle-2")}</h5>
                                    <div className="form-group">
                                        <label>{t("scheduled-appointments.form.chiefComplaint")}</label>
                                        <select className="form-control form-control-sm"
                                                name="idCondition"
                                                {...register("idCondition", {required: true})}>
                                            {
                                                condition.map((item) => {
                                                    return <option
                                                        key={item.value}
                                                        value={item.value}>
                                                        {item.label}
                                                    </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>{t("scheduled-appointments.form.assigned")}:</label>
                                        <select className="form-control form-control-sm" name="specialist"
                                                {...register("specialist", {required: true})}>
                                            {
                                                props.specialist.map((item) => {
                                                    return <option key={item.value} value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {errors.specialist &&
                                        <small className="error">{t('scheduled-appointments.form.required')}</small>}
                                    </div>

                                    <div className="form-group">
                                        <label>{t('scheduled-appointments.form.date')}</label>
                                        <input className="form-control form-control-sm" type="date"
                                               min={minDate}
                                               name="serviceDate" {...register("serviceDate", {required: true})}/>
                                        {errors.serviceDate &&
                                        <small className="error">{t('scheduled-appointments.form.required')}</small>}
                                    </div>

                                    <div className="form-group">
                                        <label>{t("scheduled-appointments.form.hour")}</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="time"
                                            id="24h"
                                            name="time"
                                            {...register("time", {
                                                required: {
                                                    value: true,
                                                    message: t('scheduled-appointments.form.required')
                                                }, validate: isFuture

                                            })}
                                            placeholder="HH:MM"
                                        />

                                        {errors.time &&
                                        <small className="error">{errors.time.message}</small>}
                                        {
                                            errors.time && errors.time.type === "validate" && (
                                                <small
                                                    className="error"> {t('scheduled-appointments.time-greater')} </small>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            : <div className="row">
                                <div className="col-12">
                                    <label className="w-100 text-center">{t('scheduled-appointments.form.edit-message-1')}
                                        <strong>{validate.data.name} {validate.data.secondName}</strong>
                                        {t('scheduled-appointments.form.edit-message-2')}
                                        <strong>{validate.data.communicationContent}</strong>
                                        {t('scheduled-appointments.form.edit-message-3')}
                                    </label>

                                    <label className="w-100 text-center mt-4">
                                        <img alt="" className="pr-2 iconAppointmentConfirm" src={providerIcon}/>
                                        {validate.doctor}
                                    </label>

                                    <label className="w-100 text-center">
                                        <img alt="" className="pr-2" src={calendar}/>
                                        {new moment(validate.data.serviceDate).format("MM/DD/YYYY")}
                                    </label>

                                    <label className="w-100 text-center mt-2">
                                        <img
                                            src={clock}/> {validate && new moment(`${validate.data.serviceDate}T${validate.data.time}:00`).format("hh:mm A")}
                                    </label>
                                </div>
                            </div>
                        :
                        <div className="d-flex align-items-center justify-content-center  body-size">
                            <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    {validate &&
                    <button className="btn btn-secondary" onClick={() => {
                        setValidate(null);
                    }}>
                        {t("scheduled-appointments.back")}
                    </button>
                    }


                    <button disabled={loading}
                            className="btn btn-primary submit"
                            color="primary">
                        {!validate ? t("scheduled-appointments.form.next") : t("scheduled-appointments.form.save")}
                    </button>
                </ModalFooter>
            </form>
        </>
    );
}

export default ServiceEdit;
