import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {methodTypes} from "../utilities/types/types";
import {request} from "../utilities/base/request";

/**
 * Registra un provider de tipo Admin
 * @param {{name: string, secondName: string, communicationContent: string, identification: string, idCommunication: number, acceptTerms: boolean}} properties - Propiedades del provider
 * @return {Promise<T>} - Provider creado
 */
export const createAdminProvider = async (properties) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.create,
        null,
        properties
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza las propiedades de un proveedor
 * @param {{name: string, secondName: string, gender: string, NPI: string, dateBirth: string, mail: string, DEA: string, phoneNumber: string, bigraphy: string, idSpecialty: number}} properties Propiedades del proveedor a actualizar
 * @return {Promise<Provider>} Provider actualizado
 */
export const updateProvider = async (properties) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.update,
        null,
        properties, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene los datos del perfil de un proveedor
 * @param {string} idProvider Identificador único del proveedor
 * @return {Promise<T>} Perfil del proveedor
 */
export const profileExtractProvider = async (idProvider) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.profile,
        null,
        {idProvider}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza la imagen de perfil del preveedor
 * @param {FormData<{profile: any, idDetail: string}>} formData - Objeto tipo FormData con la imagen y el identificador unico del detalle del proveedor
 * @return {Promise<T>} providerDetail
 */
export const updateDetailImage = async (formData) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.image,
        null,
        formData, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza el idioma por defecto del proveedor
 * @param {string} idDetail Identificador único del detalle del provider
 * @param {string} [language = en] Idioma Inglés (en), Español (es)
 * @return {Promise<T>} providerDetail
 */
export const updateDetailLanguage = async (idDetail, language = "en") => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.language,
        null,
        {idDetail, language}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Crea un nuevo proveedor al equipo del Proveedor principal
 * @param {{firstName:string, lastName: string, idRole: string, NPI: string, idProvider: string, idProviderType:number,idCommunication: string, communicationContent: string }} properties Propiedades del proveedor
 * @return {Promise<T>} Provider
 */
export const createTeamProvider = async (properties) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.createProvider,
        null,
        properties
    ).catch(error => {
        throw error;
    })
    return response.data;
}

/**
 * Valida si el Código de Identificación Nacional de Proveedores(NPI) está registrado con nombre y apellido facilitado en el proceso de registro
 * @param {string} npi Código de Indentificación Nacional de proveedores (NPI)
 * @param {string} idProvider Identificador único del proveedor
 * @param {string} firstName Nombre del proveedor
 * @param {string} lastName Apellido/s del proveedor
 * @return {Promise<T>} JSON Document
 * @see https://npiregistry.cms.hhs.gov/registry/help-api
 */
export const validateNpi = async (npi, idProvider, _firstName = null, _lastName = null) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.validateNpi,
        null,
        {number: npi, idProvider: idProvider}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza el Código de Identificación Nacional de Proveedores si éste no está ya en uso por otro proveedor
 * @param {string} idProvider - Indentificador único del proveedor
 * @param {string} idRole - Identificador único del rol
 * @param {string} NPI - Código de identificación Nacional de Proveedores (NPI)
 * @param {string} idProviderType - Identificador único de tipo de proveedor
 * @return {Promise<T>} Booleano indicando si la operación tuvo éxito
 */
export const setNpi = async (idProvider, idRole, NPI, idProviderType) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.setNpi,
        null,
        {idProvider, idRole, NPI, idProviderType}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza la contraseña de un proveedor
 * @param {string} password
 * @param {string} code
 * @param {boolean} isEmail
 * @param {string} communicationContent
 * @return {Promise<T>} Booleano indicando si la operación tuvo éxito
 */
export const setPassword = async (password, code, isEmail, communicationContent) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.setPassword,
        null,
        {password, code, isEmail, communicationContent}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Crea la licencia al proveedor dependiendo del tipo de licencia seleccionado
 * @param {string} idProvider Identificador único del proveedor
 * @param {string} idLicense Identificador único del tipo de licencia (licenseType)
 * @return {Promise<T>} License
 */
export const setProviderLicense = async (idProvider, idLicense) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.setLicense,
        null,
        {idProvider, idLicense}
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza detalles del proveedor
 * @param {FormData<{DEA: string, biography: string, gender: string, specialty:string, dateBirth: string, profile: any, idProvider: string, language_: string}>} detail Detalles del proveedor
 * @return {Promise<T>} provider actualizado
 */
export const setDetail = async (detail) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.detail,
        null,
        detail
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene los detalles del proveedor
 * @param {string} idDetail Identificador único del detalle del proveedor
 * @return {Promise<T>} ProviderDetail
 */
export const getDetail = async (idDetail) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.getDetail,
        null,
        {idDetail}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza la formación de un proveedor (NO USADA)
 * @param data
 * @return {Promise<T>}
 */
export const setExperience = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.setFormation,
        null,
        data
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza la formación de un proveedor
 * @param {Object} experience Objeto con la experiencia del proveedor
 * @param {string} experience.idProvider Identificador único del proveedor
 * @param {string} [experience.accomplishment] Logro en formato JSON
 * @param {string} [experience.certifications] Certificación en formato JSON
 * @param {string} [experience.education] Formación en formato JSON
 * @return {Promise<Object>} - Provider actualizado
 */
export const updateExperience = async (experience) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.updateFormation,
        null,
        experience,
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene datos del proveedor pasando su email
 * @param {string} email Correo electrónico del proveedor
 * @return {Promise<T>} Provider
 */
export const getByMail = async (email) => {

    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.getByEmail,
        null,
        {email: email}, true
    ).catch(error => {
        throw error;
    })
    return response.data;
}

/**
 * Obtiene datos del proveedor pasando su teléfono
 * @param {string} phone Teléfono del proveedor
 * @return {Promise<T>} Provider
 */
export const getByPhone = async (phone) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.provider.getByPhone,
        null,
        {phone: phone}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}
