import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import CompanyLogo from "../common/CompanyLogo";

export const BrandingDetail = ({company}) => {
    const {t} = useTranslation(["myTeam", "common"]);
    return (
        <div className="row mt-2 p-3 mb-4 align-items-center branding">
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 logoBrandingContainer">
                <CompanyLogo className="logo " alt="Healthy Encounter" logo={company?.logo}/>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-4 mt-3 mt-md-1">
                <div className="d-flex mb-2 justify-content-center">
                    <span className="mr-3 labelColor">{t('myTeam:branding:primaryColor')}</span>
                    <div className="branding-color-box">
                        <div style={{backgroundColor: company?.color}} className="branding-color"/>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="mr-3 labelColor">{t('myTeam:branding:secondaryColor')}</span>
                    <div className="branding-color-box">
                        <div style={{backgroundColor: company?.secondColor}} className="branding-color"/>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4 col-xl-5 mt-3 mt-md-1">
                <div className="">
                    <span className="d-flex justify-content-center">{t('myTeam:branding.customLink')}:</span>
                    <span className="d-flex justify-content-center">
                        <strong>{process.env.REACT_APP_LOCALHOST_DOMAIN}/{company?.name}</strong>
                    </span>
                </div>
            </div>
        </div>
    );
}

BrandingDetail.propTypes = {
    company: PropTypes.object.isRequired,
}
