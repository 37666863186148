import React from 'react';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

function ItemProduct({product, onDelete}) {
    const {t} = useTranslation(["profile", "common"]);
    return (
        <div className="card">
            <div className="card-header m-0 p-0">
                <div className="d-flex justify-content-between align-items-center">
                    <span className="ml-2"><strong>{product.productName}</strong></span>
                    <button onClick={() => onDelete(product.productId)}
                            className="m-1 btn btn-danger btn-sm"><FontAwesomeIcon icon={faTimes} className=""/>
                    </button>
                </div>
            </div>
            <div className="card-body">
                <span><strong>{t("Stripe.stripe_product_price")}</strong>${product.productPrice}</span>
            </div>
        </div>
    );
}

export default ItemProduct;
