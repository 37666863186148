import {Button, ModalBody, ModalFooter} from "reactstrap";
import {serviceStatus} from "../../services/service.service";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import {serviceStatusEnum} from "../../utilities";

const FinishService = (props) => {
    const {t} = useTranslation(['video', 'common']);
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    const hideModal = () => {
        props.dispatch({
            source: false,
            payload: "set-modal",
        });
    }

    return (<>
        <ModalBody>
            <h5>{t("video:close-service")}</h5>
            {!loading ?
                <p>{t("video:closeAppointmentDescription")} {props.user}
                    {'. '} {t("video:closeAppointmentDescription2")}</p>
                :
                <div className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                </div>
            }
        </ModalBody>
        <ModalFooter>
            <Button disabled={loading}
                    color="secondary"
                    onClick={() => hideModal()
                    }>{t("common:global.cancel")}</Button>
            <Button color="primary"
                    disabled={loading}
                    onClick={() => {
                        setLoading(true);
                        serviceStatus(props.idService, serviceStatusEnum.finished).then((_response) => {
                            hideModal();
                            props.socket.emit('remove', {});
                            history.push("/client/provider-finished");
                        });
                    }}>{t("video:close-appointment")}</Button>{' '}

        </ModalFooter>
    </>);
}

export default FinishService;
