import React from 'react';
import notfound_icon from "../assets/images/404-icon.svg";
import {Link} from "react-router-dom";
import CompanyLogo from "../components/common/CompanyLogo";

const OutPage = (props) => {
    return (
        <div className="d-flex align-items-center vh-100 notfound">
            <div className="row text-center">
                <div className="col-12">
                    <CompanyLogo className="logo logo-outpage" alt="healthy-encounter-logo"/>
                </div>
                <img className="col-12 mt-4" src={notfound_icon} alt="smile" width={100} height={100}/>
                <h1 className="col-12">404 Error</h1>
                <h5 className="col-12 subtitle mt-2">Page not found</h5>
                <Link className="col-12" to="/">Home</Link>
            </div>
        </div>
    );
}

export default OutPage;
