import React from 'react';
import {useTranslation} from "react-i18next";

import "../../assets/scss/components/perfil.scss";
import {CardProfile} from "../../components/Perfil/CardProfile";
import {ResumeProfile} from "../../components/Perfil/ResumeProfile";
import {LineSeparator} from "../../components/common/LineSeparator";
import ProviderAlerts from "../../components/CommercialAccount/Alerts/ProviderAlerts";

const MiPerfilPage = () => {

    const {t} = useTranslation("profile");

    return (
        <div className="pb-3 px-3" style={{overflowX: "hidden", overflowY: "auto", height: "100%"}}>
            <ProviderAlerts className="mt-3"/>
            <div className="page-header row mb-4">
                <div className="col">
                    <h1 className="page-title">{t("profile.title")}</h1>
                </div>
            </div>
            <CardProfile/>
            <LineSeparator className='mt-3 mb-3'/>
            <ResumeProfile/>
        </div>
    );
}

export default MiPerfilPage;
