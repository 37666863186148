import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Button} from "reactstrap";
import {passwordPattern, validateEmail} from "utilities";
import 'assets/scss/styles/_endPasswordChange.scss';
import {setPassword} from "../../services/provider.service";
import {loaderContext} from "../../EHApp";
import {toast} from "react-toastify";
import AuthLayout from "../../routers/AuthLayout";

export const EndPasswordChange = () => {
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {register, handleSubmit, watch, formState: {errors, isSubmitting}} = useForm();
    let history = useHistory();
    const location = useLocation();
    const {t} = useTranslation('common');

    const onSubmit = (data) => {
        console.log(validateEmail(location.state.data["user"]));

        return new Promise(async (resolve) => {
            console.log("CONSOLE")
            setPassword(
                data.identification,
                data.code,
                validateEmail(location.state.data["user"]),
                location.state.data["user"]).then((response) => {
                console.log(response);
                (!response)
                    ? toast.error(t("global.code-invalid"))
                    : history.push({
                        pathname: '/auth/login',
                        state: {
                            data: {
                                updated: true
                            },
                        },
                    });
            }).catch((error) => {
                console.log(error);
                resolve();
                toast.error(error.message);
            });
        });
    }

    useEffect(() => {
        window.addEventListener("popstate", () => {
            history.go(1);
        });
    }, []);

    return (
        <>

            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters endRemember-wrapper">
                    <div className="col-12 half">
                        <AuthLayout>
                            <article className="h-100 row justify-content-center align-items-center">
                                <div className="col-10 col-md-6 col-lg-5 col-xl-4">
                                    <form className="pb-4 block-width m-0" onSubmit={handleSubmit(onSubmit)}>
                                        <h1>{t('forgot-password.add-password')}</h1>
                                        <label
                                            className="recover-description">{t('forgot-password.add-password-description')}</label>
                                        <div className="form-group">
                                            <label className="">{t('global.verification-code')}</label>
                                            <input autoComplete="off" className="form-control form-control-sm"
                                                   type="text"
                                                   name="code" {...register("code", {required: t('global.required')})} />
                                            {errors.code && <span className="error">{errors.code.message}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label
                                                className="">{t('forgot-password.new-password')}</label>
                                            <input autoComplete="new-password" className="form-control form-control-sm"
                                                   type="password"
                                                   name="identification" {...register("identification", {
                                                required: t('global.required'),
                                                pattern: {
                                                    message: t('global.min-length'),
                                                    value: passwordPattern
                                                },
                                            })} placeholder={t('forgot-password.new-password')}/>
                                            {errors.identification &&
                                            <span className="error">{errors.identification.message}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>{t('forgot-password.repeat-new-password')}</label>
                                            <input autoComplete="new-password" className="form-control form-control-sm"
                                                   type="password"
                                                   name="retypeIdentification" {...register("retypeIdentification", {required: true})}
                                                   placeholder={t('forgot-password.repeat-new-password')}/>
                                            {errors.retypeIdentification &&
                                            <span className="error">{t('global.required')}</span>}
                                            {watch("identification") !== watch("retypeIdentification") &&
                                            <span className="error"> {t('global.doesnt-match')}</span>}
                                        </div>

                                        <Button
                                            disabled={isSubmitting || watch("identification") !== watch("retypeIdentification")}
                                            className="submit mt-3 button-link">{t('global.change-password')}</Button>
                                    </form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EndPasswordChange;
