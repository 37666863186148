import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const DayGroupHeader = (props) => {
    const {t} = useTranslation('appointments');
    moment.updateLocale(i18next.language, {
        calendar: {
            sameDay: `[${t('dates.Today')}], LL`,
            nextDay: `[${t('dates.Tomorrow')}], LL`,
            nextWeek: 'dddd, LL',
            lastDay: `[${t('dates.Yesterday')}], LL`,
            lastWeek: `[${t('dates.Last')}] dddd, LL`,
            sameElse: 'LL'
        }
    });

    return (
        <div className="collapsed card-header-citas row pt-2 pb-2" id="heading1"
             data-toggle="collapse" data-target={"#collapse" + props.index} aria-expanded="false"
             aria-controls={"collapse" + props.index}>
            <span className="col-12 col-sm-9 ">{moment(props.item.date).calendar()}</span>
            <div className="col-12 col-sm-3 text-left text-sm-right appointments-counter">
                {props.item.appointments?.length} {t('scheduled-appointments.appointments')}
            </div>
        </div>
    );
}

export default DayGroupHeader;
