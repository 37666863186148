import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {localhost, serviceStatusEnum} from "../../utilities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faEdit, faPaperPlane, faTrash, faVideo} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import ServiceCommunicate from "../../views/Modal/ServiceCommunicate";
import ServiceEdit from "../../views/Modal/ServiceEdit";
import ServiceDelete from "../../views/Modal/ServiceDelete";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../utilities/auth/AuthContext";
import {toast} from "react-toastify";
import {serviceStatus, serviceUserInform} from "../../services/service.service";
import moment from "moment";
import i18next from "i18next";

const AppointmentItemOptions = ({itemAppointment, ...props}) => {
    const {t} = useTranslation('appointments');
    const context = useContext(AuthContext);
    const [toolTipText, setToolTipText] = useState(t('scheduled-appointments.start'))

    const isActionCopyDisabled = itemAppointment.status === serviceStatusEnum.expired ||
        itemAppointment.status === serviceStatusEnum.cancelled ||
        itemAppointment.status === serviceStatusEnum.finished;
    const isActionSendDisabled = itemAppointment.status === serviceStatusEnum.expired ||
        itemAppointment.status === serviceStatusEnum.cancelled ||
        itemAppointment.status === serviceStatusEnum.finished;
    const isActionEditDisabled = itemAppointment.status === serviceStatusEnum.inProgress ||
        itemAppointment.status === serviceStatusEnum.cancelled ||
        itemAppointment.status === serviceStatusEnum.finished;
    const isActionDeleteDisabled = itemAppointment.status === serviceStatusEnum.inProgress ||
        itemAppointment.status === serviceStatusEnum.cancelled ||
        itemAppointment.status === serviceStatusEnum.finished;
    const isActionInitDisabled = !(itemAppointment.status === serviceStatusEnum.pending) && !(itemAppointment.status === serviceStatusEnum.inProgress);
    const isActionInitProvider = itemAppointment?.idProvider?.idProvider !== context.user.user.idProvider;

    const offsetEnMinutos = moment().utcOffset();

    return (
        <>
            <CopyToClipboard
                data-tip={t('scheduled-appointments.copyLink')}
                text={`${localhost}/client/verification/${itemAppointment.idUser.idUser + "," + itemAppointment.idUser.mail + "," + itemAppointment.idProvider.idProvider + "," + itemAppointment.idService}`}
                onCopy={() => {
                    toast(t('scheduled-appointments.form.copy-to-clipboard'));
                }}>
                <button className="btn btn-default m-0 p-0 mr-1"
                        disabled={isActionCopyDisabled}>
                    <FontAwesomeIcon className={isActionCopyDisabled ? "actions disabled" : "actions"} icon={faCopy}/>
                </button>

            </CopyToClipboard>
            <ReactTooltip/>
            <button data-tip={t('scheduled-appointments.share')}
                    className="btn btn-default m-0 p-0 mr-1"
                    disabled={isActionSendDisabled}
                    onClick={() => {
                        props.modal.current.setModal(<ServiceCommunicate
                            {...props}
                            idProvider={itemAppointment.idProvider.idProvider}
                            idService={itemAppointment.idService}
                            modal={props.modal}
                            idUser={itemAppointment.idUser}
                            isPhone={(!(itemAppointment.idUser.mail && itemAppointment.idUser.mail !== '0'))}
                            accessLink={`${localhost}/client/verification/${itemAppointment.idUser.idUser + "," + itemAppointment.idUser.mail + "," + itemAppointment.idProvider.idProvider + "," + itemAppointment.idService}`}/>);
                    }}>
                <FontAwesomeIcon className={isActionSendDisabled ? "actions disabled" : "actions"}
                                 icon={faPaperPlane}/>
            </button>
            <ReactTooltip/>
            <button data-tip={t('scheduled-appointments.edit')}
                    className="btn btn-default m-0 p-0 mr-1"
                    disabled={isActionEditDisabled}
                    onClick={() => {
                        props.modal.current.setModal(
                            <ServiceEdit {...props}
                                         specialist={props.specialist}
                                         service={itemAppointment}
                                         modal={props.modal}/>);
                    }}>

                <FontAwesomeIcon className={isActionDeleteDisabled ? "actions disabled" : "actions"} icon={faEdit}/>
            </button>
            <ReactTooltip/>
            <button data-tip={t('scheduled-appointments.cancel')}
                    className="btn btn-default m-0 p-0 mr-4"
                    disabled={isActionDeleteDisabled}
                    onClick={() => {
                        props.modal.current.setModal(
                            <ServiceDelete {...props}
                                           idService={itemAppointment.idService}
                                           idProvider={itemAppointment.idProvider.idProvider}
                                           itemAppointment={itemAppointment}
                                           idUser={itemAppointment.idUser}
                                           locale={i18next.language}
                                           modal={props.modal}/>);
                    }}>

                <
                    FontAwesomeIcon
                    className={isActionDeleteDisabled ? "actions disabled" : "actions"}
                    icon={faTrash}
                />
            </button>
            <ReactTooltip/>
            <span onMouseEnter={() => {
                const serviceDate = moment.utc(`${itemAppointment.serviceDate} ${itemAppointment.time}`);
                const time = moment(serviceDate).utcOffset(offsetEnMinutos).toDate()
                let currentTime = new moment(new Date()).add(30, "minutes").toDate();
                let currentMoment = new moment(currentTime);

                if (isActionInitDisabled) {
                    setToolTipText(t('scheduled-appointments.disabledAppointment'));
                } else if (isActionInitProvider) {
                    setToolTipText(t('scheduled-appointments.providerAppointment'));
                } else if (!currentMoment.isAfter(time)) {
                    let serviceDayDate = moment(time, 'YYYY-MM-DD').startOf('day');
                    let hoy = moment().startOf('day');
                    console.log(serviceDayDate, hoy);
                    if (serviceDayDate.isAfter(hoy) && !serviceDayDate.isSame(hoy)) {
                        setToolTipText(t('scheduled-appointments.notForTodayAppointment'));
                    } else {
                        setToolTipText(t('scheduled-appointments.notForNext30minutes'));
                    }
                } else {
                    setToolTipText(t('scheduled-appointments.start'));
                }
            }}
                  data-tip={toolTipText}
                  data-tip-disable={false}
            >
                <button
                    disabled={isActionInitDisabled || isActionInitProvider}
                    onClick={() => {
                        const serviceDate = moment.utc(`${itemAppointment.serviceDate} ${itemAppointment.time}`);
                        const time = moment(serviceDate).utcOffset(offsetEnMinutos).toDate()
                        let currentTime = new moment(new Date()).add(30, "minutes").toDate();
                        let currentMoment = new moment(currentTime);

                        if (currentMoment.isAfter(time)
                            && !(isActionInitDisabled)
                            && itemAppointment.isPaid === 1
                            && itemAppointment.idProvider.idProvider === context.user.user.idProvider) {
                            let localDate = moment.utc(`${itemAppointment.serviceDate} ${itemAppointment.time}`).utcOffset(offsetEnMinutos);
                            serviceStatus(itemAppointment.idService, 3).then((_response) => {
                                serviceUserInform(itemAppointment.idService, itemAppointment.idProvider, itemAppointment.idUser, localDate.format('YYYY-MM-DD HH:mm')).then((_resp) => {
                                    localStorage.setItem("appointment", itemAppointment.idService);
                                    window.open(`/${context.user.user.idCompany.name}/conference/${itemAppointment.idService}/`, '_blank')
                                });
                            });

                        } else
                            console.log("Wait until is open.")
                    }}
                    className={"btn btn-primary btn-sm btn-init"}>
                    <FontAwesomeIcon icon={faVideo}/>
                </button>
            </span>
            <ReactTooltip/>
        </>
    )
        ;
}

export default AppointmentItemOptions;
