import {Form, FormGroup, Label, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import {AuthContext} from "../../utilities/auth/AuthContext";
import {getByMail, getByPhone} from "../../services/user.service";

import calendar from "../../assets/images/icons/calendar-blue.svg";
import clock from "../../assets/images/icons/clock-blue.svg";
import money from "../../assets/images/email-icons/costo.svg";
import providerIcon from "../../assets/images/icons/provider.svg";

import {createNewService} from "../../services/service.service";
import {getAllCommunications} from "../../services/communication.service";
import {getAllConditions} from "../../services/condition.service";
import {getSpecialistByType} from "../../services/specielist.service";

import 'assets/scss/views/createCitaModal.scss';
import {localToUtc, mailPattern, noSpacesPattern, orderByLabel, phonePattern} from "../../utilities";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {EHIcon} from "../../components/icons/EHIcon";
import {getProviderTypes, getStripeProviderProducts} from "../../services/stripe.service";
import {consultationTypes} from "../../utilities/types/types";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";

let typingTimer;

const ServiceCreate = (props) => {

    const {t} = useTranslation(["appointments", "common"]);
    const [communication, setCommunication] = useState([]);
    const [condition, setCondition] = useState([]);
    const [personal, setPersonal] = useState([]);
    const [loading, setLoading] = useState(false);

    const minDate = moment.utc().local().format('YYYY-MM-DD');
    const {user: {user}} = useContext(AuthContext);
    const {register, handleSubmit, watch, setValue, formState: {errors}} = useForm();
    const [validate, setValidate] = useState(null);
    const [doctorStripeProducts, setDoctorStripeProducts] = useState([]);

    const [providerTypes, setProviderTypes] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [personalLoading, setPersonalLoading] = useState(false);
    const [create, setCreate] = useState(true);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [validCommunicationContent, setValidCommunicationContent] = useState(true);

    const [disableHipaaFields, setDisableHipaaFields] = useState(true);
    const [userResponse, setUserResponse] = useState(null);
    const [validBirth, setValidBirth] = useState(false);
    const [validGender, setValidGender] = useState(false);

    const getSpecialistName = (id) => {
        return personal.find(s => s.value === id).label;
    }

    //console.log('minDate', minDate);

    React.useEffect(async () => {
        console.log(minDate);
        setCommunication((await getAllCommunications()).map((item) => ({
            label: item.type,
            value: item.idCommunication
        })));
        const conditions = await getAllConditions();
        const translatedConditions = conditions.map((item) => ({
            label: t(`common:conditions.${item.name}`),
            value: item.idCondition
        }));
        setCondition(orderByLabel(translatedConditions));

        const providerProducts = await getStripeProviderProducts(user.idProvider);
        let doctorProducts = providerProducts.map((product) => ({
            label: `${product.productName} - $${product.productPrice}`,
            value: product.id
        }));
        if (user.idCompany.productsFree == 1) {
            doctorProducts = [{
                label: t('appointments:scheduled-appointments.freeProduct'),
                value: consultationTypes.FREE
            }, ...doctorProducts];
        }
        setDoctorStripeProducts(doctorProducts);
        const pt = await getProviderTypes();
        setProviderTypes(pt.filter((item) => item.idProviderType !== 5));
    }, []);

    const typingHandler = () => {
        let regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");

        if (watch("idCommunication") == 2 && regex.test(watch("communicationContent"))) {
            getByMail(watch("communicationContent"), props.idCompany).then((response) => {
                manageResponse(response);
            });
        } else if (watch("idCommunication") == 1 && /[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/.test(watch("communicationContent"))) {
            getByPhone(watch("communicationContent"), props.idCompany).then((response) => {
                manageResponse(response);
            });
        } else {
            setVerifyEmail(false);
            setValidCommunicationContent(false);
        }
    }

    const manageResponse = (response) => {
        if (response) {
            setValidCommunicationContent(true);
            setUserResponse(response);
            setValue("language", response.user.language)
            if (response.equalCompany === true) {
                setDisableHipaaFields(true);
                setDisabled(false);
                setCreate(true);
                setValue("name", response.user.name)
                setValue("surname", response.user.secondName)
                setValue("dateBirth", response.user.dateBirth)
                setValue("gender", response.user.gender)
                setValidGender(true)
                setValidBirth(true)
            } else {
                setDisableHipaaFields(false)
                setDisabled(true);
                setCreate(true);
                setValidGender(false)
                setValidBirth(false)
            }
        } else {
            setDisableHipaaFields(false)
            setDisabled(false);
            setCreate(false);
            setValidCommunicationContent(false);
            setValidGender(true)
            setValidBirth(true)
            setValue("name", "");
            setValue("surname", "");
            setValue("dateBirth", "");
            setValue("gender", "");
            setValue("language", "");
        }
        setVerifyEmail(false);
    }

    const validateBirth = (birthForm) => {
        if (userResponse && userResponse.equalCompany === false) {
            if (birthForm === userResponse.user.dateBirth) {
                setValidBirth(true)
                if (validGender) {
                    setDisabled(false)
                    setValue("name", userResponse.user.name)
                    setValue("surname", userResponse.user.secondName)
                    setValue("dateBirth", userResponse.user.dateBirth)
                    setValue("gender", userResponse.user.gender)
                }
            }
        } else {
            setValidBirth(true)
        }
    }

    const validateGender = (genderForm) => {
        if (userResponse && userResponse.equalCompany === false) {
            if (genderForm === userResponse.user.gender) {
                setValidGender(true)
                if (validBirth) {
                    setDisabled(false)
                    setValue("name", userResponse.user.name)
                    setValue("surname", userResponse.user.secondName)
                    setValue("dateBirth", userResponse.user.dateBirth)
                    setValue("gender", userResponse.user.gender)
                }
            }
        } else {
            setValidGender(true)
        }
    }

    const onSubmit = (data) => {
        if (validBirth && validGender) {
            if (validate === null) {
                setValidate(data);
            } else {
                setLoading(true);
                setValidate(mapAppointmentDate(validate));
                createNewService(validate, user, (result) => {
                    setLoading(false);
                    if (props.onCreate)
                        props.onCreate();

                    if (props.toogle)
                        props.toogle()
                    else
                        props.modal.current.openModal();
                });
            }
        }
    }

    const mapAppointmentDate = (_validate) => {
        let localDate = new moment(`${validate.serviceDate} ${validate.hour}`, "YYYY-MM-DD HH:mm").local();
        let date = new moment(`${_validate.serviceDate} ${_validate.hour}`, "YYYY-MM-DD HH:mm").locale(i18next.language);
        let utcDate = localToUtc(date);
        _validate.hour = utcDate.hour;
        _validate.serviceDate = utcDate.date;
        _validate.localDate = localDate.format('YYYY-MM-DD HH:mm');
        return _validate;
    }

    const isFuture = (data) => {
        let selected = new moment(`${watch("serviceDate")}T${data}`);
        let current = new moment();
        return selected.isAfter(current);
    }

    return (
        <>
            <ModalHeader toggle={props.toogle ? props.toogle : props.modal.current.openModal}>
                <div className="d-flex-row ">
                    <div className="d-inline-flex">
                        <div className="mr-2">
                            <EHIcon className="d-inline-flex modal-icon" height="35px" width="35px"
                                    name="icon video-call"/>
                        </div>
                        <span className="d-inline-flex modal-title">{t("scheduled-appointments.form.title")}</span>
                    </div>
                </div>
            </ModalHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    {!loading ?
                        !validate
                            ? <div className="row body-size">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <h5>{t("scheduled-appointments.form.subtitle-1")}</h5>
                                    <FormGroup className="">
                                        <Label
                                            for="idCommunication">{t('scheduled-appointments.form.contactMethod')}</Label>
                                        <select className="option-color-placeholder form-control-sm form-control"
                                                defaultValue=""
                                                name="idCommunication"
                                                onChange={() => {
                                                    setUserResponse(null)
                                                }}
                                                {...register("idCommunication", {required: true})}
                                        >
                                            <option hidden value="">{t('common:global.select-option-placeholder')}</option>
                                            {
                                                communication.map((item, _index) => {
                                                    return <option key={item.value}
                                                                   value={item.value}>
                                                        {t(`common:communication.${item.label}`)}
                                                    </option>
                                                })
                                            }

                                        </select>
                                        {errors.idCommunication &&
                                        <small className="error">{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>

                                    {watch("idCommunication") !== undefined &&
                                    <>
                                        <Label>{t("scheduled-appointments.form.contact")}</Label>
                                        <div className="input-group input-group-sm mb-3">

                                            <input type="text"
                                                   className={`form-control form-control-sm `}
                                                   name="communicationContent"
                                                /*onChange={() => {
                                                }}*/
                                                   {...register("communicationContent", {
                                                       required: t('common:global.required'),
                                                       pattern: {
                                                           value: watch("idCommunication") == 2
                                                               ? mailPattern
                                                               : phonePattern,
                                                           message: t('common:global.invalid-format')
                                                       }
                                                   })}
                                                   onKeyDown={() => {
                                                       setUserResponse(null)

                                                       setVerifyEmail(true);
                                                       setValidCommunicationContent(false);
                                                       clearTimeout(typingTimer);
                                                       typingTimer = setTimeout(typingHandler, 1000);
                                                   }}
                                                   placeholder={
                                                       watch("idCommunication") == 2
                                                           ? t("scheduled-appointments.form.email-placeholder")
                                                           : watch("idCommunication") == 1
                                                               ? t("scheduled-appointments.form.phone-placeholder")
                                                               : t("scheduled-appointments.form.phone-email-placeholder")}/>

                                            {errors.communicationContent &&
                                            <small className="error">{errors.communicationContent.message}</small>
                                            }

                                            <div className="input-group-append">
                                                {verifyEmail
                                                    ? <span
                                                        className="input-group-text input-spinner-group"><FontAwesomeIcon
                                                        icon={faSpinner} className="input-spinner"/></span>
                                                    : (watch('communicationContent') !== '' && watch('communicationContent') !== undefined)
                                                        ? validCommunicationContent
                                                            ? <span
                                                                className="input-group-text input-spinner-group icon-success"><FontAwesomeIcon
                                                                icon={faCheck} className=""/></span>
                                                            : <></>
                                                        : <></>
                                                }
                                            </div>

                                            {(userResponse && userResponse.equalCompany === false) &&
                                            <small className="error">
                                                {t('scheduled-appointments.form.user-other-company')}
                                            </small>
                                            }
                                        </div>
                                    </>
                                    }

                                    <FormGroup>
                                        <Label>{t("scheduled-appointments.form.name")}</Label>
                                        <input type="text" className="form-control-sm form-control"
                                               disabled={disabled || create}
                                               name="name" {...register("name", {
                                            required: t('scheduled-appointments.form.required'),
                                            pattern: {
                                                value: noSpacesPattern,
                                                message: t('common:global.notOnlySpaces')
                                            }
                                        })}
                                               placeholder={t("scheduled-appointments.form.name")}/>
                                        {errors.name &&
                                        <small
                                            className={(disabled || create ? "d-none" : "error")}>{errors.name.message}</small>}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>{t("scheduled-appointments.form.surname")}</Label>
                                        <input type="text" className="form-control-sm form-control"
                                               disabled={disabled || create}
                                               name="surname" {...register("surname", {
                                            required: t('scheduled-appointments.form.required'),
                                            pattern: {
                                                value: noSpacesPattern,
                                                message: t('common:global.notOnlySpaces')
                                            }
                                        })}
                                               placeholder={t("scheduled-appointments.form.surname")}/>
                                        {errors.surname &&
                                        <small
                                            className={(disabled || create ? "d-none" : "error")}>{errors.surname.message}</small>}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>{t("scheduled-appointments.form.dateBirth")}</Label>
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="date"
                                                   className="form-control-sm form-control"
                                                   disabled={disableHipaaFields}
                                                   name="dateBirth"
                                                   {...register("dateBirth", {required: true})}
                                                   onChange={(value) => {
                                                       setValidBirth(false);
                                                       validateBirth(value.target.value)
                                                   }}
                                            />
                                            {(!disableHipaaFields && create) &&
                                            <div className="input-group-append">
                                                {validBirth
                                                    ? <span
                                                        className="input-group-text input-spinner-group icon-success"><FontAwesomeIcon
                                                        icon={faCheck}/></span>
                                                    : <span
                                                        className="input-group-text input-spinner-group icon-error"><FontAwesomeIcon
                                                        icon={faTimes}/></span>
                                                }
                                            </div>
                                            }
                                            {errors.dateBirth &&
                                            <small className={(disableHipaaFields ? "d-none" : "error")}>
                                                {t('scheduled-appointments.form.required')}
                                            </small>
                                            }
                                            {(!errors.dateBirth && userResponse && userResponse.equalCompany === false && !validBirth) &&
                                            <small className="error">
                                                {t('scheduled-appointments.form.invalid-user-birth')}
                                            </small>
                                            }
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="gender">{t('scheduled-appointments.form.gender')}</Label>
                                        <div className="input-group input-group-sm mb-3">
                                            <select defaultValue=""
                                                    className="form-control-sm form-control"
                                                    disabled={disableHipaaFields}
                                                    name="gender"
                                                    {...register("gender", {required: true})}
                                                    onChange={(value) => {
                                                        setValidGender(false);
                                                        validateGender(value.target.value)
                                                    }}
                                            >
                                                <option hidden
                                                        value="">{t('common:global.select-option-placeholder')}</option>
                                                <option value="F">{t('common:gender.female')}</option>
                                                <option value="M">{t('common:gender.male')}</option>
                                                <option value="O">{t('common:gender.other')}</option>
                                            </select>
                                            {(!disableHipaaFields && create) &&
                                            <div className="input-group-append">
                                                {validGender
                                                    ? <span
                                                        className="input-group-text input-spinner-group icon-success"><FontAwesomeIcon
                                                        icon={faCheck}/></span>
                                                    : <span
                                                        className="input-group-text input-spinner-group icon-error"><FontAwesomeIcon
                                                        icon={faTimes}/></span>
                                                }
                                            </div>
                                            }
                                            {errors.gender &&
                                            <small className={(disableHipaaFields ? "d-none" : "error")}>
                                                {t('scheduled-appointments.form.required')}
                                            </small>
                                            }
                                            {(!errors.gender && userResponse && userResponse.equalCompany === false && !validGender) &&
                                            <small className="error">
                                                {t('scheduled-appointments.form.invalid-user-gender')}
                                            </small>
                                            }
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="language">{t('scheduled-appointments.form.language')}</Label>
                                        <select defaultValue="" className="form-control-sm form-control"
                                                disabled={disabled || create}
                                                name="language" {...register("language", {required: true})}>
                                            <option hidden value="">{t('common:global.select-option-placeholder')}</option>
                                            <option value="EN">{t('common:language.en')}</option>
                                            <option value="ES">{t('common:language.es')}</option>
                                        </select>
                                        {errors.language &&
                                        <small
                                            className={(disabled || create ? "d-none" : "error")}>{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>


                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <h5>{t("scheduled-appointments.form.subtitle-2")}</h5>
                                    <FormGroup>
                                        <Label for="idCondition">{t("scheduled-appointments.form.chiefComplaint")}</Label>
                                        <select defaultValue="" className="form-control-sm form-control" disabled={disabled}
                                                name="idCondition" {...register("idCondition", {required: true})}>
                                            <option hidden value="">{t('common:global.select-option-placeholder')}</option>
                                            {
                                                condition.map((item, _index) => {
                                                    return <option key={item.value}
                                                                   value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {errors.idCondition &&
                                        <small
                                            className={(disabled ? "d-none" : "error")}>{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t("scheduled-appointments.form.specialty")}:</Label>
                                        <select name="providerType" className="form-control-sm form-control"
                                                disabled={disabled}
                                                defaultValue="" {...register("providerType", {required: true})}
                                                onChange={async (event) => {
                                                    setPersonalLoading(true);
                                                    setPersonal((await getSpecialistByType(user.idCompany.idCompany, event.target.value)).map((item) => ({
                                                        label: `${item.name} ${item.secondName}`,
                                                        value: item.idProvider
                                                    })));
                                                    setPersonalLoading(false);
                                                }}>
                                            <option hidden
                                                    value="">{t('scheduled-appointments.form.select-option-placeholder')}</option>
                                            {
                                                providerTypes.map((item, _index) => {
                                                    return <option key={item.idProviderType}
                                                                   value={item.idProviderType}>{t('common:providerTypes.' + item.name)}</option>
                                                })
                                            }
                                        </select>
                                        {errors.providerType &&
                                        <small
                                            className={(disabled ? "d-none" : "error")}>{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>{t("scheduled-appointments.form.assigned")}:</Label>
                                        <select name="specialist" className="form-control-sm form-control"
                                                disabled={disabled || personalLoading === true}
                                                defaultValue="" {...register("specialist", {required: true})}>
                                            <option hidden
                                                    value="">{personalLoading === true ? "cargando..." : t('scheduled-appointments.form.select-specialist-placeholder')}</option>
                                            {
                                                personal.map((item, _index) => {
                                                    return <option key={item.value} value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {errors.specialist &&
                                        <small
                                            className={(disabled ? "d-none" : "error")}>{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('scheduled-appointments.form.date')}</Label>
                                        <input type="date" className="form-control-sm form-control" disabled={disabled}
                                               min={minDate}
                                               name="serviceDate" {...register("serviceDate", {required: true})}/>
                                        {errors.serviceDate &&
                                        <small
                                            className={(disabled ? "d-none" : "error")}>{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>


                                    <FormGroup>
                                        <Label>{t("scheduled-appointments.form.hour")}</Label>
                                        <input
                                            disabled={disabled}
                                            type="time"
                                            name="hour"
                                            id="24h"
                                            className="form-control-sm form-control"
                                            {...register("hour", {
                                                required: {
                                                    value: true,
                                                    message: t('scheduled-appointments.form.required')
                                                }, validate: isFuture

                                            })}
                                            placeholder="time placeholder"
                                        />
                                        {errors.hour &&
                                        <small
                                            className={(disabled ? "d-none" : "error")}>{errors.hour.message}</small>}
                                        {
                                            errors.hour && errors.hour.type === "validate" && (
                                                <small
                                                    className={(disabled ? "d-none" : "error")}> {t('scheduled-appointments.time-greater')} </small>
                                            )
                                        }
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="price">{t("scheduled-appointments.form.price")}</Label>
                                        <select className="option-color-placeholder form-control-sm form-control"
                                                defaultValue=""
                                                disabled={disabled}
                                                name="price" {...register("price", {
                                            required: true
                                        })}>
                                            <option hidden value="">{t('common:global.select-option-placeholder')}</option>
                                            {
                                                doctorStripeProducts.map((item, index) => {
                                                    return <option key={index}
                                                                   value={item.value}>
                                                        {item.label}
                                                    </option>
                                                })
                                            }
                                        </select>
                                        {errors.price &&
                                        <small
                                            className={(disabled ? "d-none" : "error")}>{t('scheduled-appointments.form.required')}</small>}
                                    </FormGroup>
                                </div>
                            </div>
                            : <div className="row body-size">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label
                                                className="w-100 text-center">{t('scheduled-appointments.form.edit-message-1')}
                                                <strong>{validate && validate.name} {validate && validate.surname}</strong>
                                                {t('scheduled-appointments.form.edit-message-2')}
                                                <strong>{validate && validate.communicationContent}</strong>
                                                {t('scheduled-appointments.form.edit-message-3')}
                                            </label>
                                            <Label className="w-100 text-center mt-4">
                                                <img className="iconAppointmentCreateConfirm"
                                                     src={providerIcon}/> {validate && getSpecialistName(validate.specialist)}
                                            </Label>

                                            <Label className="w-100 text-center">
                                                <img className="iconAppointmentCreateConfirm"
                                                     src={calendar}/> {validate && moment(validate.serviceDate).local().format('LL')}
                                            </Label>

                                            <Label className="w-100 text-center">
                                                <img className="iconAppointmentCreateConfirm"
                                                     src={clock}/> {validate && moment(`${validate.serviceDate}T${validate.hour}:00`).local().format("hh:mm A")}
                                            </Label>

                                            <Label className="w-100 text-center">
                                                <img className="iconAppointmentCreateConfirm"
                                                     src={money}/> {validate && doctorStripeProducts.filter(item => item.value == validate.price)[0].label}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        <div className="d-flex align-items-center justify-content-center  body-size">
                            <FontAwesomeIcon icon={faSpinner} className="spinner"/>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    {validate &&
                    <button className="btn btn-secondary" onClick={() => {
                        setValidate(null);
                    }}>
                        {t("scheduled-appointments.back")}
                    </button>
                    }

                    <button disabled={loading} className="btn btn-primary submit"
                            type="submit"
                    >{!validate ? t("scheduled-appointments.form.next") : t("scheduled-appointments.form.save")}</button>
                </ModalFooter>
            </Form>
        </>
    );
}

export default ServiceCreate;
