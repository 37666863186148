import React, {useContext, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {setNpi, validateNpi} from "../../services/provider.service";
import {getAllRoles} from "../../services/role.service";
import {loaderContext} from "../../EHApp";
import {getAdmin} from "../../services/providertype.service";
import {checkProtectedRoutes} from "../../services/auth.service";
import "assets/scss/styles/_professionalProfile.scss";
import AuthLayout from "../../routers/AuthLayout";
import {toast} from "react-toastify";

export const ProfessionalProfile = () => {
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const [, setNotValid] = useState(false);
    const [npiRequired, setNpiRequired] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const {t} = useTranslation('common');
    let history = useHistory();
    const location = useLocation();

    const [role, setRole] = useState([]);
    const [providerType, setProviderType] = useState([]);
    const activeStep = 1;

    checkProtectedRoutes(location, history);

    const NPINotValid = () => {
        return (
            <div className="mt-1">
                <FontAwesomeIcon className="error-not-found mr-2 f-18" icon={faTimesCircle}/>
                <label className="error-not-found">{t('global.npi-invalid')}</label>
            </div>
        );
    }

    const onSubmit = async (data) => {
        setLoader(true);
        let adminRole = role.find((r) => r.label === "ADMIN");
        let idRole = adminRole?.value;
        let idProviderType = data.idProviderType;
        if (providerType) {
            if ((data.NPI === null || data.NPI === "") && providerType.find((r) => r.value == data.idProviderType).npi == 0) {
                setNPI(data.NPI, idRole, idProviderType);
            } else {
                const result = await validateNpi(data.NPI, location.state.idProvider);

                if (result && data.NPI !== "")
                    setNPI(data.NPI, idRole, idProviderType);

                else {
                    setLoader(false);
                    setNotValid(true);
                    toast.error(t('global.npi-invalid'));
                }
            }
        }

    }

    const setNPI = (NPI, idRole, idProviderType) => {
        setNpi(location.state.idProvider, idRole, NPI, idProviderType)
            .then((available) => {
                setLoader(false);
                if (available) {
                    history.push({
                        pathname: '/auth/payment/1',
                        state: {
                            idProvider: location.state.idProvider,
                            doctorName: location.state.doctorName,
                            identification: location.state.identification,
                            username: location.state.username,
                            idProviderType: idProviderType
                        },
                    });
                } else
                    toast.error(t("global.npi-used"));
            })
            .catch(() => {
                setLoader(false);
                toast.error(t("global.error"));
            });
    }

    React.useEffect(() => {
        (async () => {
            setLoader(true);
            const roles = await getAllRoles();
            setRole(roles.map((item) => ({label: item.name, value: item.idRole})));
            const types = await getAdmin();
            setProviderType(types.map((item) => (
                {
                    label: item.name,
                    value: item.idProviderType,
                    npi: item.npiRequired
                })));
            setLoader(false);
        })();

        window.addEventListener("popstate", () => {
            history.go(1);
        });

        if (!location.state)
            history.push({pathname: '/auth/login'});
    }, []);

    React.useEffect(() => {
        const subscription = watch((value) => {
            setSubmitDisabled(false);
            let provider = providerType.find((i) => {
                if (i.value === value.idProviderType)
                    return i;
            });

            if (provider)
                setNpiRequired(provider?.npi);
            else
                setNpiRequired(false);
        });

        return () => subscription.unsubscribe();

    }, [watch, providerType]);

    return (
        <>

            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters profession-profile-wrapper">
                    <div className="col-12 col-md-8 col-lg-7 col-xl-7 left-half">
                        <AuthLayout activeStep={activeStep}>
                            <article className="h-100 row no-gutters justify-content-center align-items-center">
                                <div className="col-10 col-lg-9 col-xl-9">
                                    <h1 style={{fontSize: "30px"}}>{t('professional-profile.title')}</h1>
                                    <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>{t('professional-profile.use-how')}</label>
                                                    <select className="form-control form-control-sm"

                                                            name="idProviderType" {...register("idProviderType", {required: true})}>

                                                        {
                                                            providerType.map((item) => {
                                                                return <option key={item.value}
                                                                               value={item.value}>{t("providerTypes." + item.label)}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.idProviderType &&
                                                    <span className="error">{t('global.required')}</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label className="mr-sm-2">NPI</label>
                                                    <input className="form-control form-control-sm" type="text"
                                                           name="NPI" {...register("NPI", {
                                                        required: {
                                                            value: npiRequired,
                                                            message: t('global.required')
                                                        },
                                                        pattern: {
                                                            value: /^[0-9]*$/,
                                                            message: t('global.invalid-format')
                                                        }
                                                    })} placeholder="NPI"/>
                                                    {errors.NPI && <NPINotValid/>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label className="mt-1">
                                                    {t('professional-profile.must-validate')}
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <button disabled={submitDisabled}
                                                        className="btn btn-primary btn-sm submit mt-4">{t('global.next')}</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                    <div className=" col-md-4 col-lg-5 col-xl-5 d-md-block d-none right-half">
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfessionalProfile;
