import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";

const AppointmentItemPatient = ({user}) => {
    return (
        <>
            <p>{user.name} {user.secondName}</p>
            <small className="d-block">{user.mail != "0" &&
            <span><FontAwesomeIcon icon={faEnvelope} className="mr-1"/>{user.mail}</span>}</small>
            <small className="d-block">{user.idPhone &&
            <span><FontAwesomeIcon icon={faPhoneAlt} className="mr-1"/>{user.idPhone.phoneNumber}</span>
            }</small>
        </>
    );
}
export default AppointmentItemPatient;
