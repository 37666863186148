import {hostname} from "../utilities";
import {APIROUTES} from "./api";
import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";

/**
 * Obtiene todas los especialistas asociados a una compañía
 * @function
 * @param idCompany {string} Identificador único de la compañía
 * @return {Promise<T>}
 */
export const getSpecialist = async (idCompany) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.specialist.getByCompany,
        {idCompany: idCompany},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getSpecialistByType = async (idCompany, idType) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.specialist.getByType,
        {idCompany: idCompany, idType: idType},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}