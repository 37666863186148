import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../utilities/auth/AuthContext";
import CommercialAccountDetail from "../../components/CommercialAccount/commercialAccount/CommercialAccountDetail";

import SubscriptionDetail from "../../components/CommercialAccount/Subscription/SubscriptionDetail";
import ProductDetail from "../../components/CommercialAccount/Product/ProductDetail";
import {getStripeConnectedAccount} from "../../services/stripe.service";

const CommercialAccount = (props) => {
    const {user: {user}} = useContext(AuthContext);
    const [stripeAccountStatus, setStripeAccountStatus] = useState(true);
    const {t} = useTranslation(["profile", "common"]);

    useEffect(() => {
        getStripeConnectedAccount(user.idProvider).then((stripeAccount) => {
            setStripeAccountStatus(stripeAccount.isStripeRegister === 1);
        });
    }, []);

    return (
        <div className="pb-3 px-3" style={{overflowX: "hidden", overflowY: "auto", height: "100%"}}>
            <div className="page-header row mb-4">
                <div className="col">
                    <h1 className="page-title">{t("common:sidebar.commercial-account")}</h1>
                </div>
            </div>
            <div className="row overflow-auto">
                <div className="col-12 mt-3">
                    <SubscriptionDetail {...props}/>
                </div>
                <div className="col-12 mt-3">
                    <CommercialAccountDetail {...props}/>
                </div>
                {stripeAccountStatus === true &&
                <div className="col-12 mt-3 mb-5">
                    <ProductDetail {...props}/>
                </div>
                }
            </div>
        </div>
    );
}

export default CommercialAccount;
