import React, {forwardRef, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import signal0 from "../../../../../assets/images/signals/signal0.png";
import signal1 from "../../../../../assets/images/signals/signal1.png";
import signal2 from "../../../../../assets/images/signals/signal2.png";
import signal3 from "../../../../../assets/images/signals/signal3.png";
import signal4 from "../../../../../assets/images/signals/signal4.png";

import {
    kEnableStats,
    kQualityLimitationLevel1,
    kQualityLimitationLevel2, kQualityLimitationLevel3,
    kQualityLimitationLevel4
} from "../../../../../utilities";


export const StatusAlert = forwardRef((_props, reference) => {
    const [showAlert, setShowAlert] = useState(kEnableStats);
    const [alertQuality, setAlertQuality] = useState({
        durations: {
            bandwidth: 0,
            cpu: 0,
            other: 0,
            none: 0
        },
        reason: null
    });
    const {t} = useTranslation(["video", "common"]);

    useImperativeHandle(reference, () => ({
        showAlert: (qualityConnection) => {
            if (qualityConnection.id === _props.socketId) {
                setAlertQuality(qualityConnection);
                setShowAlert(kEnableStats);
            }
        },
        disableAlert: () => {
            setShowAlert(false);
        }
    }));

    const getAlertStyle = (qualityConnection) => {
        if (qualityConnection.reason != null) {
            const durations = qualityConnection.durations;
            if (durations.cpu > kQualityLimitationLevel4 || durations.bandwidth > kQualityLimitationLevel4 || durations.other > kQualityLimitationLevel4) {
                return 'badConnectionAlert';
            } else if (durations.cpu > kQualityLimitationLevel3 || durations.bandwidth > kQualityLimitationLevel3 || durations.other > kQualityLimitationLevel3) {
                return 'badConnectionWarning2';
            } else if (durations.cpu > kQualityLimitationLevel2 || durations.bandwidth > kQualityLimitationLevel2 || durations.other > kQualityLimitationLevel2) {
                return 'badConnectionWarning1';
            } else if (durations.cpu > kQualityLimitationLevel1 || durations.bandwidth > kQualityLimitationLevel1 || durations.other > kQualityLimitationLevel1) {
                return 'badConnectionWarning0';
            } else if (durations.cpu < kQualityLimitationLevel1 || durations.bandwidth < kQualityLimitationLevel1 || durations.other < kQualityLimitationLevel1) {
                return 'badConnectionSuccess';
            }
        } else {
            return 'badConnectionDisabled';
        }

    };

    const getSignalIcon = (qualityConnection) => {
        if (qualityConnection) {
            const durations = qualityConnection.durations;
            let signalScale = 0;
            if (durations.cpu > kQualityLimitationLevel4 || durations.bandwidth > kQualityLimitationLevel4 || durations.other > kQualityLimitationLevel4) {
                return signal4; // Muy mala
            } else if (durations.cpu > kQualityLimitationLevel3 || durations.bandwidth > kQualityLimitationLevel3 || durations.other > kQualityLimitationLevel3) {
                return signal3; // Mala
            } else if (durations.cpu > kQualityLimitationLevel2 || durations.bandwidth > kQualityLimitationLevel2 || durations.other > kQualityLimitationLevel2) {
                return signal2; // Regular
            } else if (durations.cpu > kQualityLimitationLevel1 || durations.bandwidth > kQualityLimitationLevel1 || durations.other > kQualityLimitationLevel1) {
                return signal1; // Buena
            } else if (durations.cpu < kQualityLimitationLevel1 || durations.bandwidth < kQualityLimitationLevel1 || durations.other < kQualityLimitationLevel1) {
                return signal0; // Excelente
            }
        } else {
            return signal0;
        }
    }

    return (
        <>
            {showAlert ? (
                <div className="BadConnectionWrapper">

                    <img className={`signal ${getAlertStyle(alertQuality)}`} src={getSignalIcon(alertQuality)}
                         alt="signal"/>

                </div>
            ) : null}
        </>
    );

});

