import React, {useContext, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "reactstrap";
import 'assets/scss/styles/_emailValidate.scss';
import {
    checkProtectedRoutes,
    getExpiration,
    providerCommunticationVerify,
    setEmailVerified,
    validateCode
} from "../../services/auth.service";
import {loaderContext} from "../../EHApp";
import {toast} from "react-toastify";
import AuthLayout from "../../routers/AuthLayout";

export const EmailValidate = () => {
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {t} = useTranslation('common');
    const [expiration, setExpiration] = useState(null);
    let history = useHistory();
    const location = useLocation();
    const activeStep = 0;

    checkProtectedRoutes(location, history);

    const resendVerification = () => {
        const storageLanguage = localStorage.getItem("language");
        setLoader(true);
        providerCommunticationVerify(true, location.state.communicationContent, storageLanguage)
            .then(() => {
                setLoader(false);
                toast.success(t("global.email-sent"));
                getExpiration(location.state.communicationContent).then((response) => {
                    setExpiration(response);
                }).catch((error) => {
                    console.log(error)
                });
            })
            .catch(function () {
                setLoader(false);
                toast.error(t("global.error"));
            });
    };

    const onSubmit = (data) => {
        setLoader(true);
        validateCode(location.state.communicationContent, data.code)
            .then((response) => {
                if (response) {
                    setEmailVerified(location.state.communicationContent, true)
                        .then((doctor) => {
                            setLoader(false);
                            let defaultRoute = '/auth/profile';
                            if (doctor.progress === 3)
                                defaultRoute = '/auth/payment/1';

                            history.push({
                                pathname: defaultRoute,
                                state: {
                                    idProvider: location.state.idProvider,
                                    doctorName: location.state.doctorName,
                                    identification: location.state.identification,
                                    username: location.state.username
                                },
                            });

                        })
                        .catch(() => {
                            setLoader(false);
                            toast.error(t("global.error"));
                        });
                } else {
                    setLoader(false);
                    toast.error(t("global.code-not-valid"));
                }

            })
            .catch((error) => {
                setLoader(false);
                toast.error(error.message);

            });
    }

    React.useEffect(() => {
        getExpiration(location.state.communicationContent).then((response) => {
            setExpiration(response);
        }).catch((error) => {
            console.log(error)
        });
    }, []);

    return (
        <>

            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters email-validate-wrapper">
                    <div className="col-12 col-md-8 col-lg-7 col-xl-7 left-half">
                        <AuthLayout activeStep={activeStep}>
                            <article className="h-100 row no-gutters justify-content-center align-items-center">
                                <div className="col-10 col-lg-9 col-xl-9">
                                    <h1>{t('valid-code.module-title')}</h1>
                                    <label className="mr-1">{t('valid-code.email')}</label>
                                    <label
                                        className="font-weight-bold">{location.state && location.state.communicationContent}</label>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group mb-4 mb-sm-4">
                                            <label className="mr-sm-2 mt-5">{t('valid-code.insert')}</label>
                                            <input className="form-control form-control-sm" type="text"
                                                   name="code" {...register("code", {required: true})}
                                                   placeholder={t("global.verification-code")}/>
                                            {errors.code && <span className="error">{t('global.required')}.</span>}
                                            <label className="mr-sm-2 mt-2">{t('valid-code.expires')}: <span
                                                className="font-weight-bold">{expiration}</span></label>
                                        </div>
                                        <Button className="send-another mt-3" color="link" onClick={() => {
                                            resendVerification();
                                        }}>{t('valid-code.resend-validation')}</Button>
                                        <Button className="submit mt-3 button-link">{t('global.validate-code')}</Button>
                                    </form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>

                    <div className=" col-md-4 col-lg-5 col-xl-5 d-md-block d-none right-half"></div>
                </div>
            </div>
        </>
    );
}

export default EmailValidate;
