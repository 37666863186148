import React from 'react';
import {useTranslation} from "react-i18next";

const Countries = () => {
    const {i18n} = useTranslation();
    if (i18n.language === 'en')
        return (
            <>
                <option value="AF">Afghanistan</option>
                <option value="AX">Åland Islands</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia, Plurinational State of</option>
                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>

                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo</option>
                <option value="CD">Congo, the Democratic Republic of the</option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="CI">Côte d'Ivoire</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CW">Curaçao</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands (Malvinas)</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GG">Guernsey</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>

                <option value="HM">Heard Island and McDonald Islands</option>
                <option value="VA">Holy See (Vatican City State)</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran, Islamic Republic of</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IM">Isle of Man</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KP">Korea, Democratic People's Republic of</option>
                <option value="KR">Korea, Republic of</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Lao People's Democratic Republic</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macao</option>
                <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>

                <option value="FM">Micronesia, Federated States of</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PS">Palestinian Territory, Occupied</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Réunion</option>

                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="BL">Saint Barthélemy</option>
                <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="MF">Saint Martin (French part)</option>
                <option value="PM">Saint Pierre and Miquelon</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SX">Sint Maarten (Dutch part)</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">South Georgia and the South Sandwich Islands</option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard and Jan Mayen</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan, Province of China</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks and Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VE">Venezuela, Bolivarian Republic of</option>
                <option value="VN">Viet Nam</option>
                <option value="VG">Virgin Islands, British</option>
                <option value="VI">Virgin Islands, U.S.</option>
                <option value="WF">Wallis and Futuna</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
            </>
        );
    else
        return (
            <>
                <option value="AF"> Afganistán</option>
                <option value="AL"> Albania</option>
                <option value="AD"> Andorra</option>
                <option value="DE"> Alemania</option>
                <option value="AO"> Angola</option>
                <option value="AI"> Anguila</option>
                <option value="DZ"> Argelia</option>
                <option value="AQ"> Antártida</option>
                <option value="AG"> Antigua y Barbuda</option>
                <option value="SA"> Arabia Saudita</option>
                <option value="AR"> Argentina</option>
                <option value="AM"> Armenia</option>
                <option value="AW"> Aruba</option>
                <option value="AU"> Australia</option>
                <option value="AT"> Austria</option>
                <option value="AZ"> Azerbaiyán</option>

                <option value="BS"> Bahamas</option>
                <option value="BH"> Bahréin</option>
                <option value="BD"> Bangladesh</option>
                <option value="BB"> Barbados</option>
                <option value="BY"> Bielorrusia</option>
                <option value="BE"> Bélgica</option>
                <option value="BZ"> Belice</option>
                <option value="BJ"> Benín</option>
                <option value="BM"> Bermudas</option>
                <option value="BO"> Bolivia, Estado Plurinacional de</option>
                <option value="BQ"> Bonaire, Sint Eustatius y Saba</option>
                <option value="BA"> Bosnia y Herzegovina</option>
                <option value="BW"> Botswana</option>
                <option value="BR"> Brasil</option>
                <option value="BN"> Brunei Darussalam</option>
                <option value="BG"> Bulgaria</option>
                <option value="BF"> Burkina Faso</option>
                <option value="BI"> Burundi</option>
                <option value="BT"> Bután</option>

                <option value="CV"> Cabo Verde</option>
                <option value="KH"> Camboya</option>
                <option value="CM"> Camerún</option>
                <option value="CA"> Canadá</option>
                <option value="TD"> Chad</option>
                <option value="CL"> Chile</option>
                <option value="CN"> China</option>
                <option value="CY"> Chipre</option>
                <option value="CO"> Colombia</option>
                <option value="KM"> Comoras</option>
                <option value="CG"> Congo</option>
                <option value="CD"> Congo, República Democrática del</option>
                <option value="KP"> Corea, República Popular Democrática de</option>
                <option value="CR"> Costa Rica</option>
                <option value="CI"> Costa de Marfil</option>
                <option value="HR"> Croacia</option>
                <option value="CU"> Cuba</option>
                <option value="CW"> Curazao</option>

                <option value="DK"> Dinamarca</option>
                <option value="DJ"> Djibouti</option>
                <option value="DM"> Dominica</option>

                <option value="EC"> Ecuador</option>
                <option value="EG"> Egipto</option>
                <option value="SV"> El Salvador</option>
                <option value="AE"> Emiratos Árabes Unidos</option>
                <option value="ER"> Eritrea</option>
                <option value="SK"> Eslovaquia</option>
                <option value="SI"> Eslovenia</option>
                <option value="ES"> España</option>
                <option value="US"> Estados Unidos</option>
                <option value="EE"> Estonia</option>
                <option value="ET"> Etiopía</option>

                <option value="RU"> Federación de Rusia</option>
                <option value="PH"> Filipinas</option>
                <option value="FI"> Finlandia</option>
                <option value="FR"> Francia</option>
                <option value="FJ"> Fiyi</option>

                <option value="GF"> Guayana Francesa</option>
                <option value="GA"> Gabón</option>
                <option value="GM"> Gambia</option>
                <option value="GE"> Georgia</option>
                <option value="GH"> Ghana</option>
                <option value="GI"> Gibraltar</option>
                <option value="GR"> Grecia</option>
                <option value="GL"> Groenlandia</option>
                <option value="GD"> Granada</option>
                <option value="GP"> Guadalupe</option>
                <option value="GU"> Guam</option>
                <option value="GT"> Guatemala</option>
                <option value="GG"> Guernsey</option>
                <option value="GN"> Guinea</option>
                <option value="GW"> Guinea-Bissau</option>
                <option value="GQ"> Guinea Ecuatorial</option>
                <option value="GY"> Guyana</option>

                <option value="HT"> Haití</option>
                <option value="HN"> Honduras</option>
                <option value="HK"> Hong Kong</option>
                <option value="HU"> Hungría</option>

                <option value="IN"> India</option>
                <option value="ID"> Indonesia</option>
                <option value="IR"> Irán, República Islámica de</option>
                <option value="IQ"> Irak</option>
                <option value="IE"> Irlanda</option>
                <option value="IM"> Isla de Man</option>
                <option value="NF"> Isla Norfolk</option>
                <option value="AX"> Islas Åland</option>
                <option value="BV"> Isla Bouvet</option>
                <option value="CX"> Isla de Navidad</option>
                <option value="IS"> Islandia</option>
                <option value="KY"> Islas Caimán</option>
                <option value="CC"> Islas Cocos (Keeling)</option>
                <option value="CK"> Islas Cook</option>
                <option value="FO"> Islas Feroe</option>
                <option value="GS"> Islas Georgias del Sur y Sandwich del Sur</option>
                <option value="HM"> Islas Heard y McDonald</option>
                <option value="FK"> Islas Malvinas</option>
                <option value="MP"> Islas Marianas del Norte</option>
                <option value="MH"> Islas Marshall</option>
                <option value="UM"> Islas menores alejadas de los Estados Unidos</option>
                <option value="SB"> Islas Salomón</option>
                <option value="TC"> Islas Turcas y Caicos</option>
                <option value="VG"> Islas Vírgenes Británicas</option>
                <option value="VI"> Islas Vírgenes, EE. UU.</option>
                <option value="IL"> Israel</option>
                <option value="IT"> Italia</option>

                <option value="JM"> Jamaica</option>
                <option value="JP"> Japón</option>
                <option value="JE"> Jersey</option>
                <option value="JO"> Jordan</option>

                <option value="KZ"> Kazajstán</option>
                <option value="KE"> Kenia</option>
                <option value="KI"> Kiribati</option>
                <option value="KW"> Kuwait</option>
                <option value="KG"> Kirguistán</option>

                <option value="LV"> Letonia</option>
                <option value="LB"> Líbano</option>
                <option value="LS"> Lesoto</option>
                <option value="LR"> Liberia</option>
                <option value="LY"> Libia</option>
                <option value="LI"> Liechtenstein</option>
                <option value="LT"> Lituania</option>
                <option value="LU"> Luxemburgo</option>

                <option value="MO"> Macao</option>
                <option value="MK"> Macedonia, ex República Yugoslava de</option>
                <option value="MG"> Madagascar</option>
                <option value="MW"> Malaui</option>
                <option value="MY"> Malasia</option>
                <option value="MV"> Maldivas</option>
                <option value="ML"> Malí</option>
                <option value="MT"> Malta</option>
                <option value="MA"> Marruecos</option>
                <option value="MQ"> Martinica</option>
                <option value="MR"> Mauritania</option>
                <option value="MU"> Mauricio</option>
                <option value="YT"> Mayotte</option>
                <option value="MX"> México</option>
                <option value="FM"> Micronesia, Estados Federados de</option>
                <option value="MD"> Moldavia, República de</option>
                <option value="MC"> Mónaco</option>
                <option value="MN"> Mongolia</option>
                <option value="ME"> Montenegro</option>
                <option value="MS"> Montserrat</option>
                <option value="MZ"> Mozambique</option>
                <option value="MM"> Myanmar</option>

                <option value="NA"> Namibia</option>
                <option value="NR"> Nauru</option>
                <option value="NP"> Nepal</option>

                <option value="NC"> Nueva Caledonia</option>
                <option value="NZ"> Nueva Zelanda</option>
                <option value="NI"> Nicaragua</option>
                <option value="NE"> Níger</option>
                <option value="NG"> Nigeria</option>
                <option value="NU"> Niue</option>
                <option value="NO"> Noruega</option>

                <option value="OM"> Omán</option>

                <option value="NL"> Países bajos</option>
                <option value="PK"> Pakistán</option>
                <option value="PW"> Palaos</option>
                <option value="PA"> Panamá</option>
                <option value="PG"> Papua Nueva Guinea</option>
                <option value="PY"> Paraguay</option>
                <option value="PE"> Perú</option>
                <option value="PN"> Pitcairn</option>
                <option value="PL"> Polonia</option>
                <option value="PF"> Polinesia Francesa</option>
                <option value="PT"> Portugal</option>
                <option value="PR"> Puerto Rico</option>

                <option value="QA"> Qatar</option>

                <option value="GB"> Reino Unido</option>
                <option value="SY"> República Árabe Siria</option>
                <option value="CF"> República Centroafricana</option>
                <option value="CZ"> República Checa</option>
                <option value="KR"> República de Corea</option>
                <option value="LA"> República Democrática Popular Lao</option>
                <option value="DO"> República Dominicana</option>

                <option value="RE"> Reunión</option>
                <option value="RO"> Rumania</option>
                <option value="RW"> Ruanda</option>

                <option value="EH"> Sáhara Occidental</option>
                <option value="KN"> Saint Kitts y Nevis</option>
                <option value="BL"> San Bartolomé</option>
                <option value="SM"> San Marino</option>
                <option value="MF"> San Martín (parte francesa)</option>
                <option value="PM"> San Pedro y Miquelón</option>
                <option value="VC"> San Vicente y las Granadinas</option>
                <option value="WS"> Samoa</option>
                <option value="AS"> Samoa Americana</option>
                <option value="SH"> Santa Elena, Ascensión y Tristán da Cunha</option>
                <option value="LC"> Santa Lucía</option>
                <option value="VA"> Santa Sede (Estado de la Ciudad del Vaticano)</option>
                <option value="ST"> Santo Tomé y Príncipe</option>
                <option value="SN"> Senegal</option>
                <option value="RS"> Serbia</option>
                <option value="SC"> Seychelles</option>
                <option value="SL"> Sierra Leona</option>
                <option value="SG"> Singapur</option>
                <option value="SX"> Sint Maarten (parte holandesa)</option>
                <option value="SO"> Somalia</option>
                <option value="LK"> Sri Lanka</option>
                <option value="ZA"> Sudáfrica</option>
                <option value="SS"> Sudán del Sur</option>
                <option value="SD"> Sudán</option>
                <option value="SE"> Suecia</option>
                <option value="CH"> Suiza</option>
                <option value="SR"> Surinam</option>
                <option value="SJ"> Svalbard y Jan Mayen</option>
                <option value="SZ"> Swazilandia</option>

                <option value="TW"> Taiwán, provincia de China</option>
                <option value="TZ"> Tanzania, República Unida de</option>
                <option value="TH"> Tailandia</option>
                <option value="TJ"> Tayikistán</option>
                <option value="TF"> Territorios Australes Franceses</option>
                <option value="IO"> Territorio Británico del Océano Índico</option>
                <option value="PS"> Territorio Palestino, Ocupado</option>
                <option value="TL"> Timor-Leste</option>
                <option value="TG"> Togo</option>
                <option value="TK"> Tokelau</option>
                <option value="TO"> Tonga</option>
                <option value="TT"> Trinidad y Tobago</option>
                <option value="TN"> Túnez</option>
                <option value="TR"> Turquía</option>
                <option value="TM"> Turkmenistán</option>
                <option value="TV"> Tuvalu</option>

                <option value="UG"> Uganda</option>
                <option value="UA"> Ucrania</option>
                <option value="UY"> Uruguay</option>
                <option value="UZ"> Uzbekistán</option>

                <option value="VU"> Vanuatu</option>
                <option value="VE"> Venezuela, República Bolivariana de</option>
                <option value="VN"> Vietnam</option>

                <option value="WF"> Wallis y Futuna</option>

                <option value="YE"> Yemen</option>

                <option value="ZM"> Zambia</option>
                <option value="ZW"> Zimbabue</option>
            </>
        );
}

export default Countries;
