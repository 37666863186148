import {hostname} from "../utilities";
import moment from "moment";
import {APIROUTES} from "./api";
import {request} from "../utilities/base/request";
import {methodTypes} from "../utilities/types/types";

/**
 * Da de alta un nuevo servicio de videoconsulta
 * @param formData
 * @param user
 * @param callback
 * @returns {Promise<*>}
 */
export const createNewService = async (formData, user, callback) => {
    console.log(formData);
    const response = await request(methodTypes.POST, hostname + APIROUTES.service.create, null, {
        idProvider: formData.specialist,
        idCompany: user.idCompany.idCompany,
        locale: (localStorage.getItem('language')) ? localStorage.getItem('language') : 'es',
        service: {
            reason: formData.reason,
            idCondition: formData.idCondition,
            specialist: formData.specialist,
            serviceDate: formData.serviceDate,
            time: formData.hour,
            localDate: formData.localDate,
            idProviderProduct: formData.price,
        },
        user: {
            name: formData.name,
            secondName: formData.surname,
            identification: "12341234",
            idCommunication: formData.idCommunication,
            mail: formData.idCommunication === "2" && formData.communicationContent,
            phone: formData.idCommunication === "1" && formData.communicationContent,
            dateBirth: moment(formData.dateBirth).format('YYYY-MM-DD'),
            acceptTerms: true,
            language: formData.language,
            gender: formData.gender,
        },
    }, true).catch(error => {
        throw error;
    });

    return callback(response.data);
}

/**
 * Obtiene los especialistas asociados a la compañía
 * @param idCompany
 * @param idProvider
 * @returns {Promise<any>}
 */
export const getServiceSpecialist = async (idCompany, idProvider) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.today,
        {idCompany: idCompany, idProvider: idProvider},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene la información de un servicio de videoconsulta
 * @param idService
 * @returns {Promise<any>}
 */
export const getService = async (idService) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.service,
        {idService: idService},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene el número de citas del dia en curso
 * @param idCompany
 * @returns {Promise<any>}
 */
export const getTodayCount = async (idCompany) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.count,
        {idCompany: idCompany},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene el número de citas pendientes del dia en curso
 * @param idCompany
 * @returns {Promise<any>}
 */
export const getTodayPendentCount = async (idCompany) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.countPendent,
        {idCompany: idCompany},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const isPaid = async (idService) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.paid,
        null,
        {idService: idService}, false
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const validateData = async (idService) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.validate,
        null,
        {idService: idService}, false
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const statsData = async (idService) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.stats,
        {idService: idService},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const providerCommunication = async (idService, idUser, idProvider, localServiceDate) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.providerCommunication,
        null,
        {idService: idService, idUser: idUser, idProvider: idProvider, localServiceDate}, false
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const retrieveLog = async (idRoom) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.log,
        null,
        {roomName: idRoom}, false
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getLocale = async (idService) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.locale,
        null,
        {idService: idService}, false
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const filterServicesConcluded = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.filter,
        null,
        data, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const filterServicesChart = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.filterChart,
        null,
        data, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getServicesConcluded = async (idCompany) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.concluded,
        {idCompany: idCompany},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Envia mensaje a usuario con enlace para unirse a la videoconsulta
 * @param data
 * @returns {Promise<any>}
 */
export const inviteUser = async (data) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.invite,
        null,
        data, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Actualiza un servicio de videoconsulta
 * @param data
 * @returns {Promise<any>}
 */
export const serviceUpdate = async (data) => {

    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.update,
        null,
        data, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const getGroupedServices = async (context,date, idSpecialist, search, status, limit, take) => {
    console.log('date', date);
    console.log('idSpecialist', idSpecialist);
    console.log('search', search);
    console.log('status', status);
    console.log('limit', limit);
    console.log('take', take);

    const response =(await getToday(context.user.user.idCompany.idCompany,
        date, search, status, limit, take, idSpecialist));
    const results = response.service;
    const currentCount = response.service.length;
    const total = response.total || 0;
    const services = results.reduce(function (r, a) {

        const fechaUTC = moment.utc(`${a.serviceDate} ${a.time}`);
        const offsetEnMinutos = moment().utcOffset();
        const fechaLocal = fechaUTC.utcOffset(offsetEnMinutos);
        const localDate = fechaLocal.format('YYYY-MM-DD');
        r[localDate] = r[localDate] || [];
        r[localDate].push(a);
        return r;
    }, Object.create(null));


    const appointments = [];

    if (appointments) {
        Object.keys(services).forEach((key) => {
            services[key].sort((a, b) => {
                let textA = a.time;
                let textB = b.time;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            appointments.push({
                date: key,
                appointments: services[key]
            });
        });
        appointments.sort((a, b) => {
            let textA = a.date;
            let textB = b.date;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }

    return {groupedServices: appointments || [], total, currentCount};
}

/**
 * Obtiene los servicios actuales
 * @param idCompany
 * @param idProvider
 * @param time
 * @param idSpecialist
 * @param search
 * @param status
 * @param limit
 * @param take
 * @returns {Promise<any>}
 */

export const getToday = async (idCompany, time, search, status, limit, take, idSpecialist) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.today,
        {
            idCompany: idCompany,
            idProvider: idSpecialist,
            status: status,
            time: time,
            take: take,
            limit: limit,
            search: search
        },
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Elimina(Cancela) un servicio
 * @param idService
 * @returns {Promise<any>}
 */
export const serviceDelete = async (idService, idProvider, idUser, locale) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.serviceDelete,
        null,
        {idService: idService, idProvider: idProvider, idUser: idUser, locale: locale}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const serviceUserInform = async (idService, provider, user, localServiceDate) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.mail,
        null,
        {idService: idService, idProvider: provider.idProvider, idUser: user.idUser, localServiceDate}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const serviceStatus = async (idService, status) => {
    const response = await request(
        methodTypes.POST,
        hostname + APIROUTES.service.status,
        null,
        {idService: idService, status: status}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

/**
 * Obtiene los especialistas de una compañía
 * @param idCompany
 * @returns {Promise<any>}
 */
export const getSpecialistByCompany = async (idCompany) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.specialistByCompany,
        {idCompany: idCompany},
        null, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}
/**
 * Obtienen todos los servicios (deprecated)
 * @param idProvider
 * @returns {Promise<any>}
 */
export const getAllServices = async (idProvider) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.all,
        null,
        {idProvider}, true
    ).catch(error => {
        throw error;
    })

    return response.data;
}

export const validateService = async (props, idService, companyName) => {
    const response = await request(
        methodTypes.GET,
        hostname + APIROUTES.service.get,
        null,
        {idService}, true
    ).catch(error => {
        throw error;
    })

    if (response.data.idPayment)
        props.history.replace('/' + companyName + "/conference/" + response.data.roomKey + "/");
    else if (!response.data.idPayment)
        props.history.replace('/stripe/' + idService);
}
