import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {validateService} from "../../services/service.service";

function ValidationService(props) {
    let {idService, companyName} = useParams();
    useEffect(() => {
        validateService(props, idService, companyName).then();
    }, []);

    return (<></>);
}

export default ValidationService;
