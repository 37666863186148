import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import 'assets/scss/styles/_onboarding.scss';
import {authLogin, checkProtectedRoutes} from "../../services/auth.service";
import {AuthContext} from "../../utilities/auth/AuthContext";
import {authTypes} from "../../utilities/types/types";
import {loaderContext} from "../../EHApp";
import commercialAccountIcon from "../../assets/images/icons/commercial-account.svg";
import CreateConnectButton from "../../components/CommercialAccount/commercialAccount/CreateConnectButton";
import AuthLayout from "../../routers/AuthLayout";
import {toast} from "react-toastify";

export const Onboarding = (props) => {
    const {loading} = useContext(loaderContext);
    const {t} = useTranslation('common');
    let history = useHistory();
    const location = useLocation();
    const [, setLoader] = loading;
    const {dispatch} = useContext(AuthContext);

    checkProtectedRoutes(location, history);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters onboarding-wrapper">
                    <div className="col-12 half">
                        <AuthLayout>
                            <article className="h-100 row justify-content-center align-items-center">
                                <div className="col-10 col-md-6 col-lg-5 col-xl-4">
                                    <div className="row justify-content-center">
                                        <img className="mb-4" width="130px" src={commercialAccountIcon} alt=""/>
                                        <div className="col-12 text-center">
                                            <p>{t('common:payment.onboarding-message')}</p>
                                            <CreateConnectButton idProvider={location.state?.idProvider}
                                                                 userLogin={true}/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <button onClick={() => {
                                                authLogin(true, location.state.username, location.state.identification).then((response => {
                                                    console.log("location")
                                                    dispatch({
                                                        type: authTypes.login,
                                                        payload: {
                                                            user: response.user,
                                                            token: response.token,
                                                            company: location.state.company
                                                        }
                                                    });

                                                    props.history.replace(location.state.company + "/appointments");
                                                })).catch(error => {
                                                    if (error.response !== undefined) {
                                                        if (error.response.status === 400) {
                                                            toast.error(t('global.user-incorrect'));
                                                        } else {
                                                            toast.error(t('global.error-database'));
                                                        }
                                                    }
                                                });
                                            }}
                                                    className="btn btn-link mt-2 w-100 center">{t('branding.omit')}</button>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Onboarding;
