import React, {useContext, useState} from "react";
import ColorPicker from "components/ColorPicker/ColorPicker";
import Dropzone from "react-dropzone";
import {useHistory, useLocation} from "react-router-dom";

import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Form, Label} from "reactstrap";
import 'assets/scss/styles/_branding.scss';
import slugify from 'react-slugify';
import {loaderContext} from "../../EHApp";
import {saveCompany} from "../../services/company.service";
import {checkProtectedRoutes} from "../../services/auth.service";

import AuthLayout from "../../routers/AuthLayout";
import {toast} from "react-toastify";
import exports from "random-words"

export const Branding = (_props) => {
    const {loading} = useContext(loaderContext);
    const [, setLoader] = loading;
    const {register, handleSubmit, control, setValue, formState: {errors}} = useForm();
    const {t} = useTranslation('common');
    const [image, setImage] = useState(null);
    const [blob, setBlob] = useState(null);
    let history = useHistory();

    const [primaryColor, setPrimaryColor] = useState("#1565c0");
    const [secondaryColor, setSecondaryColor] = useState("#f9a825");
    const inputReference = React.useRef(0);
    const location = useLocation();
    const activeStep = 6;

    checkProtectedRoutes(location, history);

    const onSubmit = (data) => {
        setLoader(true);
        let formData = new FormData();
        if (data.name !== undefined || data.name !== "")
            formData.append("name", slugify(data.name));
        else {
            formData.append("name", slugify(location.state.doctorName));
        }

        formData.append("color", primaryColor);
        formData.append("colorSecondary", secondaryColor);
        formData.append("logo", blob);
        formData.append("idProvider", location.state.idProvider);

        saveCompany(formData).then((response) => {
            if (response === true) {
                setLoader(false);
                toast.error(t("company-registered"));
            } else {

                history.push({
                    pathname: '/auth/stripe',
                    state: {
                        idProvider: location.state.idProvider,
                        doctorName: location.state.doctorName,
                        identification: location.state.identification,
                        company: "/" + response.name,
                        username: location.state.username,
                    },
                });

                setLoader(false);
            }

        }).catch((error) => {
            setLoader(false);
            toast.error(error.message);
        });
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row vh-100 no-gutters branding-wrapper">
                    <div className="col-12 half">
                        <AuthLayout activeStep={activeStep}>
                            <article className="h-100 row justify-content-center align-items-center">
                                <div className="col-11 col-sm-10 col-md-8 col-lg-6 form-wrapper">
                                    <Form className="pb-1" onSubmit={handleSubmit(onSubmit)}>
                                        <h1 className="m-0 text-center">{t('branding.add-branding')}</h1>
                                        <label
                                            className="recover-description mt-4 mb-4 text-center">{t('branding.description')}</label>

                                        <div className="square-form mb-0">
                                            <Dropzone class="dropzone" accept="image/jpeg, image/png" minSize={0}
                                                      maxSize={1097152} onDrop={(acceptedFiles, _rejectedFiles) => {
                                                let reader = new FileReader();
                                                reader.onload = (e) => {
                                                    let contents = e.target.result;
                                                    console.log(contents);
                                                    const _blob = new Blob([contents]);
                                                    const url = URL.createObjectURL(_blob);
                                                    setImage(url);
                                                };

                                                if (acceptedFiles.length > 0) {
                                                    setBlob(acceptedFiles[0]);
                                                    reader.readAsArrayBuffer(acceptedFiles[0]);
                                                }
                                            }}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input className="drop-input" {...getInputProps()} />
                                                            <img className="image-branding" src={image} alt=""/>
                                                            <p className="m-0 p-0">
                                                                <small>{t('global.only-images')}</small></p>
                                                            <label>{t('branding.add-logo')}</label>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>

                                        <div className="form-group mb-4 mt-2">
                                            <div className="row color-divider d-flex">
                                                <div className="col-6 mb-2">
                                                    <div className="d-flex justify-content-end">
                                                        <label
                                                            className="form-label-dark color-primary-label mr-2">{t('branding.primary-color')}</label>
                                                        <Controller
                                                            control={control}
                                                            name='colorPrimary'
                                                            defaultValue={""}
                                                            rules={{required: false}}
                                                            render={({field}) => (
                                                                <ColorPicker color={primaryColor}
                                                                             onSelectColor={(color) => {
                                                                                 field.onChange(color);
                                                                                 setPrimaryColor(color);
                                                                             }}/>
                                                            )}/>

                                                        {errors.colorPrimary &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <div className="d-flex">
                                                        <Controller
                                                            control={control}
                                                            name='colorSecondary'
                                                            defaultValue={""}
                                                            rules={{required: false}}
                                                            render={({field}) => (
                                                                <ColorPicker color={secondaryColor}
                                                                             onSelectColor={(color) => {
                                                                                 field.onChange(color);
                                                                                 setSecondaryColor(color);
                                                                             }}/>
                                                            )}/>
                                                        <Label
                                                            className="ml-2 color-secondary-label form-label-dark">{t('branding.secondary-color')}</Label>
                                                        {errors.colorSecondary &&
                                                        <span className="error">{t('global.required')}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <label className="recover-description">{t('branding.url-description')}:</label>
                                        <div className="form-group mt-3 mb-4">
                                            <div className="row justify-content-center">
                                                <div
                                                    className="col-12 col-md-6 col-lg-4 align-self-center p-0 m-0 pr-1">
                                                    <label
                                                        className="form-label-dark d-block font-weight-bold text-center text-md-right  p-0 m-0">{process.env.REACT_APP_LOCALHOST_DOMAIN}/</label>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 align-self-center p-0 m-0">
                                                    <input
                                                        onBlurCapture={(e) => {
                                                            setValue('name', e.target.value);
                                                        }}

                                                        onKeyPress={(e) => {
                                                            if (e.key === " ") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        className="form-control form-control-sm text-center text-md-left"
                                                        type="text" {...register("name", {required: true})}
                                                        placeholder={t('branding.organization')}
                                                        ref={inputReference}
                                                    />
                                                    {errors.name &&
                                                    <span className="error">{t('global.required')}</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <Button
                                                    className="button-link submit mt-2 center w-50 max-350">{t('global.save')}</Button>
                                                <Button onClick={() => {
                                                    let generated = exports({exactly: 2, join: ''});
                                                    inputReference.current.value = generated;
                                                    setValue('name', generated);
                                                }}
                                                        className="btn-transparent mt-2 center w-25 max-350">{t('global.generate')}</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </article>
                        </AuthLayout>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Branding;
