import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import soapIcon from "../../../../../assets/images/video/soap.svg";
import {AuthContext} from "../../../../../utilities/auth/AuthContext";
import {useContext} from "react";
import {BrowserView} from 'react-device-detect';

const ButtonSoap = (props) => {
    const {t} = useTranslation(["video", "common"]);
    const context = useContext(AuthContext);
    return (
        <>
            {context.user.logged &&
            <BrowserView>
                <button data-tip={t("soap")} className="btn btn-link color-white"
                        onClick={() => {
                            props.chatReference.current.setState({
                                toggle: false,
                                payload: "toggle-chat"
                            });

                            if (props.soapReference.current.getNoteView()) {
                                props.soapReference.current.toggle();
                                props.dispatch({
                                    source: false,
                                    payload: "set-sidebar",
                                });
                            } else {
                                props.soapReference.current.toggle();
                                props.dispatch({
                                    source: true,
                                    payload: "set-sidebar",
                                });
                            }
                        }}>
                    <img src={soapIcon} alt=""/>
                </button>
                <ReactTooltip/>
            </BrowserView>
            }
        </>
    );
}
export default ButtonSoap;
